import React, { useEffect, useRef, useState } from "react";
import { Engine, Scene, FreeCamera, ArcRotateCamera,Vector3, Mesh, IParticleSystem,Skeleton,AnimationGroup,Path3D,Axis,Space,
    Vector2,StandardMaterial, SceneLoader,HemisphericLight, MeshBuilder, AbstractMesh,Texture } from "@babylonjs/core"; 
import * as BABYLON from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import * as $ from 'jquery';
import { MarbleProceduralTexture, GrassProceduralTexture, RoadProceduralTexture, WoodProceduralTexture } from "@babylonjs/procedural-textures";
import "../Professional.css";
import { WaterMaterial } from '@babylonjs/materials';

interface SceneComponentProps {
    id: string; // required
    [key: string]: any; // allow additional props if needed
}


// Define an interface for the return type
interface ValueWithIndex {
    value: number;
    index: number;
}


const EngineerMain: React.FC<SceneComponentProps> = ({ id, ...rest }) => {
    const reactCanvas = useRef<HTMLCanvasElement | null>(null);
    const boxRef = useRef<AbstractMesh | undefined>(undefined); // Use useRef for the box
    // State to track which main menu is open. Could be "File", "Views", etc., or null if none.
    const [openMenu, setOpenMenu] = useState<string | null>(null);
    const [scene, setScene] = useState<Scene | null>(null);
    // States for UI toggles
    const [showSelectUnitPanel, setShowSelectUnitPanel] = useState(false);
    const [showAllUnitComponents, setShowAllUnitComponents] = useState(true);
    const [showUnits, setShowUnits] = useState([true,true,true,true,true]); 
    const [showSuperDeck, setShowSuperDeck] = useState(true);  
    const toolbarRef = useRef<HTMLDivElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null); 
    const [inputFile, setInputFile] = useState<string | null>(null);
    const [lines, setLines] = useState<string[]>([]); 
    const [fileLoaded, setFileLoaded] = useState(false); 
    const [fileReady, setFileReady] = useState(false); 

    // Input general structures viewing options
    const [zoomSpeed, setZoomSpeed] = useState<number | null>(null);
    const [superstructureON, setSuperstructureON] = useState<string | null>(null);  
    const [allON, setAllON] = useState<string | null>(null);
    const [deckON, setDeckON] = useState<string | null>(null);  
    const [barrierON, setBarrierON] = useState<string | null>(null); 
    const [girderON, setGirderON] = useState<string | null>(null);   
    const [substructureON, setSubstructureON] = useState<string | null>(null);  
    const [capON, setCapON] = useState<string | null>(null);  
    const [columnON, setColumnON] = useState<string | null>(null);  
    const [footingON, setFootingON] = useState<string | null>(null);  
    const [pileON, setPileON] = useState<string | null>(null);  
    const [unitOnNum, setUnitOnNum] = useState<string[]>([]);
    const [unitOnOff, setUnitOnOff] = useState<string[]>([]);
    const [unitON, setUnitON] = useState<string[][]>([]);
    const [mainOnNum, setMainOnNum] = useState<string[]>([]);
    const [mainOnOff, setMainOnOff] = useState<string[]>([]);
    const [wallON, setWallON] = useState<string | null>(null);
    const [approadON, setApproadON] = useState<string | null>(null);
    const [baselineON, setBaselineON] = useState<string | null>(null);
    const [laneON, setLaneON] = useState<string | null>(null);
    const [groundtextON, setGroundtextON] = useState<string | null>(null);
    const [riverON, setRiverON] = useState<string | null>(null);
    const [skyON, setSkyON] = useState<string | null>(null);
    const [carsON, setCarsON] = useState<string | null>(null);
    const [flowON, setFlowON] = useState<string | null>(null);
    const [NHCURVE, setNHCURVE] = useState<number | null>(null);
    const [NVCURVE, setNVCURVE] = useState<number | null>(null);
    const [NSECURVE, setNSECURVE] = useState<number | null>(null);
    const [NALIGNMENT, setNALIGNMENT] = useState<number | null>(null);
    const [KC_show, setKC_show] = useState<number[]>([]);

    const [NUNITON, setNUNITON] = useState<number | null>(null);
    const [NMAINON, setNMAINON] = useState<number | null>(null);
    const [hcurveCircular, setHcurveCircular] = useState<number[][]>([]);
    const [vcurveCircular, setVcurveCircular] = useState<number[][]>([]);   
    const [securvePairs, setSecurvePairs] = useState<number[][]>([]);  
    const [alignmentNum, setAlignmentNum] = useState<string[]>([]);
    const [alignmentCurves, setAlignmentCurves] = useState<string[][]>([]);  
    const [hcurveNum, setHcurveNum] = useState<string[]>([]);
    const [hcurveSeg, setHcurveSeg] = useState<number[]>([]);    
    const [hcurveXPC, setHcurveXPC] = useState<number[]>([]);
    const [hcurveYPC, setHcurveYPC] = useState<number[]>([]);
    const [hcurveANG, setHcurveANG] = useState<number[]>([]);
    const [vcurveNum, setVcurveNum] = useState<string[]>([]);
    const [securveNum, setSecurveNum] = useState<string[]>([]);
    const [vcurveType, setVcurveType] = useState<number[]>([]);    
    const [vcurveSeg, setVcurveSeg] = useState<number[]>([]);
    const [securveSeg, setSecurveSeg] = useState<number[]>([]);

    const [NMAIN, setNMAIN] = useState<number | null>(null);
    const [NROAD, setNROAD] = useState<number | null>(null);
    const [NSTRUCT, setNSTRUCT] = useState<number | null>(null);
    const [NEMBANK, setNEMBANK] = useState<number | null>(null);
    const [NWALL, setNWALL] = useState<number | null>(null);
    const [NRIVER, setNRIVER] = useState<number | null>(null);
    const [NRAIL, setNRAIL] = useState<number | null>(null);


    const [mainNum, setMainNum] = useState<string[]>([]);
    const [mainType, setMainType] = useState<string[]>([]);   
    const [mainAlign, setMainAlign] = useState<string[]>([]);   
    const [mainBegStation, setMainBegStation] = useState<number[]>([]);   
    const [mainBridgeApproach1, setMainBridgeApproach1] = useState<string[]>([]);  
    const [mainBridgeBenApproachSB, setMainBridgeBenApproachSB] = useState<number[]>([]);  
    const [mainBridgeApproachSB1, setMainBridgeApproachSB1] = useState<string[]>([]);  
    const [mainBridgeBenStation, setMainBridgeBenStation] = useState<number[]>([]); 
    const [mainBridgeNum, setMainBridgeNum] = useState<string[]>([]); 
    const [mainBridgeEndStation, setMainBridgeEndStation] = useState<number[]>([]); 
    const [mainBridgeApproachSB2, setMainBridgeApproachSB2] = useState<string[]>([]); 
    const [mainBridgeEndApproachSB, setMainBridgeEndApproachSB] = useState<number[]>([]); 
    const [mainBridgeApproach2, setMainBridgeApproach2] = useState<string[]>([]); 
    const [mainEndStation, setMainEndStation] = useState<number[]>([]); 
    const [mainAppType, setMainAppType] = useState<string[]>([]); 
    const [mainAppSec, setMainAppSec] = useState<string[]>([]); 
    const [mainDistMSE, setMainDistMSE] = useState<number[]>([]); 
    const [mainEmbSlope, setMainEmbSlope] = useState<number[]>([]); 
    const [roadNum, setRoadNum] = useState<string[]>([]); 
    const [roadAlign, setRoadAlign] = useState<string[]>([]); 
    const [roadBegStation, setRoadBegStation] = useState<number[]>([]); 
    const [roadEndStation, setRoadEndStation] = useState<number[]>([]); 
    const [roadWidth, setRoadWidth] = useState<number[]>([]); 
    const [roadMain, setRoadMain] = useState<number[]>([]); 
    const [roadMainSkew1, setRoadMainSkew1] = useState<number[]>([]); 
    const [roadMainSkew2, setRoadMainSkew2] = useState<number[]>([]); 
    const [roadSeg, setRoadSeg] = useState<number[]>([]); 
    const [roadGeometry, setRoadGeometry] = useState<(number | string)[][]>([]);
    const [structNum, setStructNum] = useState<string[]>([]); 
    const [structAlign, setStructAlign] = useState<string[]>([]); 
    const [structBegStation, setStructBegStation] = useState<number[]>([]); 
    const [structEndStation, setStructEndStation] = useState<number[]>([]); 
    const [structWidth, setStructWidth] = useState<number[]>([]); 
    const [structMain, setStructMain] = useState<number[]>([]); 
    const [structMainType, setStructMainType] = useState<number[]>([]); 
    const [structMainMirror, setStructMainMirror] = useState<number[]>([]); 
    const [structSeg, setStructSeg] = useState<number[]>([]); 
    const [structGeometry, setStructGeometry] = useState<(number | string)[][]>([]);

    const [embankNum, setEmbankNum] = useState<string[]>([]); 
    const [embankAlign, setEmbankAlign] = useState<string[]>([]); 
    const [embankBegStation, setEmbankBegStation] = useState<number[]>([]); 
    const [embankEndStation, setEmbankEndStation] = useState<number[]>([]); 
    const [embankWidth, setEmbankWidth] = useState<number[]>([]); 
    const [embankMain, setEmbankMain] = useState<number[]>([]); 
    const [embankMainType, setEmbankMainType] = useState<number[]>([]); 
    const [embankMainMirror, setEmbankMainMirror] = useState<number[]>([]); 
    const [embankSeg, setEmbankSeg] = useState<number[]>([]); 
    const [embankGeometry, setEmbankGeometry] = useState<(number | string)[][]>([]);

    const [wallNum, setWallNum] = useState<string[]>([]); 
    const [wallAlign, setWallAlign] = useState<string[]>([]); 
    const [wallBegStation, setWallBegStation] = useState<number[]>([]); 
    const [wallEndStation, setWallEndStation] = useState<number[]>([]); 
    const [wallRoad, setWallRoad] = useState<string[]>([]); 
    const [wallMain, setWallMain] = useState<string[]>([]); 
    const [wallMainType, setWallMainType] = useState<string[]>([]); 
    const [wallMainSide, setWallMainSide] = useState<string[]>([]); 
    const [wallSeg, setWallSeg] = useState<number[]>([]); 
    const [wallGeometry, setWallGeometry] = useState<(number | string)[][]>([]);


    const [riverNum, setRiverNum] = useState<string[]>([]); 
    const [riverAlign, setRiverAlign] = useState<string[]>([]); 
    const [riverBegStation, setRiverBegStation] = useState<number[]>([]); 
    const [riverEndStation, setRiverEndStation] = useState<number[]>([]); 
    const [riverWidth, setRiverWidth] = useState<number[]>([]); 
    const [riverMain, setRiverMain] = useState<number[]>([]); 
    const [riverMainSta, setRiverMainSta] = useState<number[]>([]); 
    const [riverMainSkew, setRiverMainSkew] = useState<number[]>([]); 


    const [railNum, setRailNum] = useState<string[]>([]); 
    const [railAlign, setRailAlign] = useState<string[]>([]); 
    const [railBegStation, setRailBegStation] = useState<number[]>([]); 
    const [railEndStation, setRailEndStation] = useState<number[]>([]); 
    const [railTrackNum, setRailTrackNum] = useState<number[]>([]); 
    const [railTrackSpa, setRailTrackSpa] = useState<number[]>([]); 
    const [railWidth, setRailWidth] = useState<number[]>([]); 
    const [railMain, setRailMain] = useState<number[]>([]); 
    const [railMainSta, setRailMainSta] = useState<number[]>([]); 
    const [railMainSkew, setRailMainSkew] = useState<number[]>([]); 

    const [NSTRIPE, setNSTRIPE] = useState<number | null>(null);
    const [NBARRIER, setNBARRIER] = useState<number | null>(null);
    const [NDECK, setNDECK] = useState<number | null>(null);
    const [NHAUNCH, setNHAUNCH] = useState<number | null>(null);
    const [NBEARING, setNBEARING] = useState<number | null>(null);
    const [NAPPSLAB, setNAPPSLAB] = useState<number | null>(null);
    const [NGIRDER, setNGIRDER] = useState<number | null>(null);
    const [NBEAM, setNBEAM] = useState<number | null>(null);
    const [NSPAN, setNSPAN] = useState<number | null>(null);

    const [stripeNum, setStripeNum] = useState<string[]>([]); 
    const [stripeAlign, setStripeAlign] = useState<string[]>([]); 
    const [stripeMain, setStripeMain] = useState<string[]>([]); 
    const [stripeStaBegin, setStripeStaBegin] = useState<number[]>([]); 
    const [stripeStaEnd, setStripeStaEnd] = useState<number[]>([]); 
    const [stripeSkewBegin, setStripeSkewBegin] = useState<number[]>([]); 
    const [stripeSkewEnd, setStripeSkewEnd] = useState<number[]>([]); 
    const [stripeSec, setStripeSec] = useState<string[]>([]); 
    const [stripeType, setStripeType] = useState<string[]>([]); 
    const [stripeColor, setStripeColor] = useState<string[]>([]); 
    const [stripeSeg, setStripeSeg] = useState<number[]>([]); 
    const [stripeGeometry, setStripeGeometry] = useState<(number | string)[][]>([]);

    const [barrierNum, setBarrierNum] = useState<string[]>([]); 
    const [barrierDeckLeft, setBarrierDeckLeft] = useState<string[]>([]); 
    const [barrierDeckLeftSec, setBarrierDeckLeftSec] = useState<string[]>([]); 
    const [barrierDeckRight, setBarrierDeckRight] = useState<string[]>([]); 
    const [barrierDeckRightSec, setBarrierDeckRightSec] = useState<string[]>([]); 
    const [barrierCount, setBarrierCount] = useState<number[]>([]); 
    const [barrierGeometry, setBarrierGeometry] = useState<(number | string)[][]>([]);

    const [deckNum, setDeckNum] = useState<string[]>([]); 
    const [deckSec, setDeckSec] = useState<number[]>([]); 
    const [deckGeometry, setDeckGeometry] = useState<(number | string)[][]>([]);

    const [haunchNum, setHaunchNum] = useState<string[]>([]); 
    const [haunchDepth, setHaunchDepth] = useState<number[]>([]); 
    const [haunchWidth, setHaunchWidth] = useState<number[]>([]); 
    const [haunchShape, setHaunchShape] = useState<string[]>([]); 
    const [haunchShapeDes, setHaunchShapeDes] = useState<string[]>([]); 
    const [bearingNum, setBearingNum] = useState<string[]>([]); 
    const [bearingType, setBearingType] = useState<string[]>([]); 
    const [bearingStandard, setBearingStandard] = useState<string[]>([]);
    const [bearingLength, setBearingLength] = useState<number[]>([]);
    const [bearingWidth, setBearingWidth] = useState<number[]>([]);         
    const [bearingHeight, setBearingHeight] = useState<number[]>([]);

    const [appSBNum, setAppSBNum] = useState<string[]>([]); 
    const [appSBBridge, setAppSBBridge] = useState<string[]>([]); 
    const [appSBLoc, setAppSBLoc] = useState<string[]>([]);
    const [appSBBar, setAppSBBar] = useState<string[]>([]);
    const [appSBSkewBeg, setAppSBSkewBeg] = useState<number[]>([]);
    const [appSBSkewEnd, setAppSBSkewEnd] = useState<number[]>([]); 
    const [appSBSlope, setAppSBSlope] = useState<number[]>([]);
    const [appSBLongOff, setAppSBLongOff] = useState<number[]>([]);
    const [appSBTranOff, setAppSBTranOff] = useState<number[]>([]);
    const [appSBTopDist, setAppSBTopDist] = useState<number[]>([]);
    const [appSBSeg, setAppSBSeg] = useState<number[]>([]);
    const [appSBLongSec, setAppSBLongSec] = useState<string[]>([]);
    const [appSBGeometry, setAppSBGeometry] = useState<(number | string)[][]>([]);

    const [girderNum, setGirderNum] = useState<string[]>([]); 
    const [girderType, setGirderType] = useState<string[]>([]); 
    const [girderGroup, setGirderGroup] = useState<string[]>([]);
    const [girderToDeck, setGirderToDeck] = useState<number[]>([]);
    const [girderSec, setGirderSec] = useState<number[]>([]); 
    const [girderGeometry, setGirderGeometry] = useState<(number | string)[][]>([]);

    const [beamNum, setBeamNum] = useState<string[]>([]); 
    const [beamType, setBeamType] = useState<string[]>([]); 
    const [beamGroup, setBeamGroup] = useState<string[]>([]);
    const [beamDistToDeck, setBeamDistToDeck] = useState<number[]>([]);
    const [beamSec, setBeamSec] = useState<string[]>([]);
    const [beamBLOffBeg, setBeamBLOffBeg] = useState<number[]>([]);
    const [beamBLOffEnd, setBeamBLOffEnd] = useState<number[]>([]);
    const [beamLongOffBeg, setBeamLongOffBeg] = useState<number[]>([]);
    const [beamLongOffEnd, setBeamLongOffEnd] = useState<number[]>([]);
    const [beamBrgOffBeg, setBeamBrgOffBeg] = useState<number[]>([]);
    const [beamBrgOffEnd, setBeamBrgOffEnd] = useState<number[]>([]);
    const [beamHuanchWidth, setBeamHuanchWidth] = useState<number[]>([]);
    const [beamHaunchThick, setBeamHaunchThick] = useState<number[]>([]);

    const [spanNum, setSpanNum] = useState<string[]>([]); 
    const [spanBridge, setSpanBridge] = useState<string[]>([]); 
    const [spanUnit, setSpanUnit] = useState<string[]>([]);
    const [spanGeometry, setSpanGeometry] = useState<(number | string)[][]>([]);

    const [NPILE, setNPILE] = useState<number | null>(null);
    const [NFOOTING, setNFOOTING] = useState<number | null>(null);
    const [NCOL, setNCOL] = useState<number | null>(null);
    const [NCAP, setNCAP] = useState<number | null>(null);
    const [NENDBENT, setNENDBENT] = useState<number | null>(null);
    const [NPIER, setNPIER] = useState<number | null>(null);
    const [NPEDESTAL, setNPEDESTAL] = useState<number | null>(null);

    const [capNum, setCapNum] = useState<string[]>([]); 
    const [capLength, setCapLength] = useState<number[]>([]); 
    const [capSlope, setCapSlope] = useState<number[]>([]); 
    const [capPedestal, setCapPedestal] = useState<string[]>([]); 
    const [capLongOff, setCapLongOff] = useState<number[]>([]); 
    const [capTranOff, setCapTranOff] = useState<number[]>([]); 
    const [capTopDist, setCapTopDist] = useState<number[]>([]); 
    const [capSeg, setCapSeg] = useState<number[]>([]); 
    const [capLongSec, setCapLongSec] = useState<string[]>([]); 
    const [capGeometry, setCapGeometry] = useState<(number | string)[][]>([]);

    const [columnNum, setColumnNum] = useState<string[]>([]); 
    const [columnLongOff, setColumnLongOff] = useState<number[]>([]); 
    const [columnTranOff, setColumnTranOff] = useState<number[]>([]); 
    const [columnTopDist, setColumnTopDist] = useState<number[]>([]); 
    const [columnSeg, setColumnSeg] = useState<number[]>([]); 
    const [colGeometry, setColGeometry] = useState<(number | string)[][]>([]);

    const [footingNum, setFootingNum] = useState<string[]>([]); 
    const [footingDirection, setFootingDirection] = useState<string[]>([]); 
    const [footingGeometry, setFootingGeometry] = useState<(number | string)[][]>([]);

    const [pileNum, setPileNum] = useState<string[]>([]); 
    const [pileFooting, setPileFooting] = useState<string[]>([]); 
    const [pileLongOff, setPileLongOff] = useState<number[]>([]); 
    const [pileTranOff, setPileTranOff] = useState<number[]>([]); 
    const [pileEmbed, setPileEmbed] = useState<number[]>([]); 
    const [pileSection, setPileSection] = useState<string[]>([]); 
    const [pileGeometry, setPileGeometry] = useState<(number | string)[][]>([]);

    const [endbentNum, setEndbentNum] = useState<string[]>([]); 
    const [endbentBridge, setEndbentBridge] = useState<string[]>([]); 
    const [endbentLoc, setEndbentLoc] = useState<string[]>([]); 
    const [endbentSkew, setEndbentSkew] = useState<number[]>([]); 
    const [endbentBW, setEndbentBW] = useState<string[]>([]);
    const [endbentCheekH, setEndbentCheekH] = useState<number[]>([]); 
    const [endbentCheekW, setEndbentCheekW] = useState<number[]>([]); 
    const [endbentCheekT, setEndbentCheekT] = useState<number[]>([]); 
    const [endbentCap, setEndbentCap] = useState<string[]>([]); 
    const [endbentPile, setEndbentPile] = useState<string[]>([]); 
    const [endbentPileLength, setEndbentPileLength] = useState<number[]>([]); 

    const [pierNum, setPierNum] = useState<string[]>([]); 
    const [pierBridge, setPierBridge] = useState<string[]>([]); 
    const [pierUnitLeft, setPierUnitLeft] = useState<string[]>([]); 
    const [pierUnitRight, setPierUnitRight] = useState<string[]>([]); 
    const [pierGeometry, setPierGeometry] = useState<(number | string)[][]>([]);

    const [pedestalNum, setPedestalNum] = useState<string[]>([]); 
    const [pedestalLength, setPedestalLength] = useState<number[]>([]); 
    const [pedestalWidth, setPedestalWidth] = useState<number[]>([]); 
    const [pedestalBrg1, setPedestalBrg1] = useState<number[]>([]); 
    const [pedestalBrg2, setPedestalBrg2] = useState<number[]>([]); 
    const [pedestalLongOff1, setPedestalLongOff1] = useState<number[]>([]); 
    const [pedestalLongOff2, setPedestalLongOff2] = useState<number[]>([]); 
    const [pedestalGeometry1, setPedestalGeometry1] = useState<(number | string)[][]>([]);
    const [pedestalGeometry2, setPedestalGeometry2] = useState<(number | string)[][]>([]);

    const [NUNIT, setNUNIT] = useState<number | null>(null);
    const [unitNum, setUnitNum] = useState<string[]>([]); 
    const [unitBridge, setUnitBridge] = useState<string[]>([]); 
    const [unitGeometry, setUnitGeometry] = useState<(number | string)[][]>([]);

    const [NSTRIPESEC, setNSTRIPESEC] = useState<number | null>(null);
    const [NROADSEC, setNROADSEC] = useState<number | null>(null);
    const [NMSESEC, setNMSESEC] = useState<number | null>(null);
    const [NAPPSBSEC, setNAPPSBSEC] = useState<number | null>(null);
    const [NDECKSEC, setNDECKSEC] = useState<number | null>(null);
    const [NGIRSEC, setNGIRSEC] = useState<number | null>(null);
    const [NBARRSEC, setNBARRSEC] = useState<number | null>(null);
    const [NCAPSEC, setNCAPSEC] = useState<number | null>(null);
    const [NCOLSEC, setNCOLSEC] = useState<number | null>(null);
    const [NFOOTSEC, setNFOOTSEC] = useState<number | null>(null);
    const [NPILESEC, setNPILESEC] = useState<number | null>(null);

    const [stripesecNum, setStripesecNum] = useState<string[]>([]); 
    const [stripesecDepth, setStripesecDepth] = useState<number[]>([]); 
    const [stripesecWidth, setStripesecWidth] = useState<number[]>([]); 

    const [roadsecNum, setRoadsecNum] = useState<string[]>([]); 
    const [roadsecDepth, setRoadsecDepth] = useState<number[]>([]); 
    const [roadsecWidth, setRoadsecWidth] = useState<number[]>([]); 
    const [roadsecShape, setRoadsecShape] = useState<string[]>([]); 
    const [roadsecShapeDes, setRoadsecShapeDes] = useState<string[]>([]); 

    const [msesecNum, setMsesecNum] = useState<string[]>([]); 
    const [msesecCapWidth, setMsesecCapWidth] = useState<number[]>([]); 
    const [msesecCapDepth, setMsesecCapDepth] = useState<number[]>([]); 
    const [msesecWallEmbed, setMsesecWallEmbed] = useState<number[]>([]); 
    const [msesecWallThick, setMsesecWallThick] = useState<number[]>([]); 
    const [msesecLevingPadWidth, setMsesecLevingPadWidth] = useState<number[]>([]); 
    const [msesecLevingPadThick, setMsesecLevingPadThick] = useState<number[]>([]); 
    const [msesecLevingPadCover, setMsesecLevingPadCover] = useState<number[]>([]); 

    const [appsbsecNum, setAppsbsecNum] = useState<string[]>([]); 
    const [appsbsecDepth, setAppsbsecDepth] = useState<number[]>([]); 
    const [appsbsecWidth, setAppsbsecWidth] = useState<number[]>([]); 
    const [appsbsecHaunch, setAppsbsecHaunch] = useState<number[]>([]); 
    const [appsbsecShape, setAppsbsecShape] = useState<string[]>([]); 
    const [appsbsecShapeDes, setAppsbsecShapeDes] = useState<string[]>([]); 

    const [decksecNum, setDecksecNum] = useState<string[]>([]); 
    const [decksecDepth, setDecksecDepth] = useState<number[]>([]); 
    const [decksecWidth, setDecksecWidth] = useState<number[]>([]); 
    const [decksecShape, setDecksecShape] = useState<string[]>([]); 
    const [decksecShapeDes, setDecksecShapeDes] = useState<string[]>([]); 
    
    const [girdersecNum, setGirdersecNum] = useState<string[]>([]); 
    const [girdersecDepth, setGirdersecDepth] = useState<number[]>([]); 
    const [girdersecWidth, setGirdersecWidth] = useState<number[]>([]); 
    const [girdersecShape, setGirdersecShape] = useState<string[]>([]); 
    const [girdersecShapeDes, setGirdersecShapeDes] = useState<string[]>([]); 

    const [barriersecNum, setBarriersecNum] = useState<string[]>([]); 
    const [barriersecDepth, setBarriersecDepth] = useState<number[]>([]); 
    const [barriersecWidth, setBarriersecWidth] = useState<number[]>([]); 
    const [barriersecMirror, setBarriersecMirror] = useState<string[]>([]); 
    const [barriersecShape, setBarriersecShape] = useState<string[]>([]); 
    const [barriersecShapeDes, setBarriersecShapeDes] = useState<string[]>([]); 

    const [piercapsecNum, setPiercapsecNum] = useState<string[]>([]); 
    const [piercapsecWidth, setPiercapsecWidth] = useState<number[]>([]); 
    const [piercapsecDepth, setPiercapsecDepth] = useState<number[]>([]); 
    const [piercapsecThick, setPiercapsecThick] = useState<number[]>([]); 
    const [piercapsecShape, setPiercapsecShape] = useState<string[]>([]); 
    const [piercapsecShapeDes, setPiercapsecShapeDes] = useState<string[]>([]); 

    const [piercolsecNum, setPiercolsecNum] = useState<string[]>([]); 
    const [piercolsecDepth, setPiercolsecDepth] = useState<number[]>([]); 
    const [piercolsecWidth, setPiercolsecWidth] = useState<number[]>([]);  
    const [piercolsecShape, setPiercolsecShape] = useState<string[]>([]); 
    const [piercolsecShapeDes, setPiercolsecShapeDes] = useState<string[]>([]); 

    const [pierfootsecNum, setPierfootsecNum] = useState<string[]>([]); 
    const [pierfootsecWidth, setPierfootsecWidth] = useState<number[]>([]); 
    const [pierfootsecLength, setPierfootsecLength] = useState<number[]>([]);  
    const [pierfootsecDepth, setPierfootsecDepth] = useState<number[]>([]);  
    const [pierfootsecShape, setPierfootsecShape] = useState<string[]>([]); 
    const [pierfootsecShapeDes, setPierfootsecShapeDes] = useState<string[]>([]); 

    const [pilesecNum, setPilesecNum] = useState<string[]>([]); 
    const [pilesecDepth, setPilesecDepth] = useState<number[]>([]); 
    const [pilesecWidth, setPilesecWidth] = useState<number[]>([]);  
    const [pilesecShape, setPilesecShape] = useState<string[]>([]); 
    const [pilesecShapeDes, setPilesecShapeDes] = useState<string[]>([]); 

    const [N_RECTAN, setN_RECTAN] = useState<number | null>(null);
    const [N_CIRCLE, setN_CIRCLE] = useState<number | null>(null);
    const [N_TRAP, setN_TRAP] = useState<number | null>(null);
    const [N_TRIAN, setN_TRIAN] = useState<number | null>(null);
    const [N_QUAD, setN_QUAD] = useState<number | null>(null);
    const [N_ISHAPE, setN_ISHAPE] = useState<number | null>(null);
    const [N_BOX, setN_BOX] = useState<number | null>(null);
    const [N_SLOPE, setN_SLOPE] = useState<number | null>(null);
    const [N_CUSTOM, setN_CUSTOM] = useState<number | null>(null);
      
    const [RECTAN_NAME, setRECTAN_NAME] = useState<string[]>([]); 
    const [RECTAN_WIDTH, setRECTAN_WIDTH] = useState<number[]>([]); 
    const [RECTAN_DEPTH, setRECTAN_DEPTH] = useState<number[]>([]); 
    const [RECTAN_REF, setRECTAN_REF] = useState<string[]>([]); 
    const [RECTAN_REF_Y, setRECTAN_REF_Y] = useState<number[]>([]); 
    const [RECTAN_REF_Z, setRECTAN_REF_Z] = useState<number[]>([]); 
    const [shapeRECTAN, setShapeRECTAN] = useState<(BABYLON.Vector3)[][]>([]);
    const [CIRCLE_NAME, setCIRCLE_NAME] = useState<string[]>([]); 
    const [CIRCLE_RADIUS, setCIRCLE_RADIUS] = useState<number[]>([]); 
    const [CIRCLE_REF, setCIRCLE_REF] = useState<string[]>([]); 
    const [CIRCLE_REF_Y, setCIRCLE_REF_Y] = useState<number[]>([]); 
    const [CIRCLE_REF_Z, setCIRCLE_REF_Z] = useState<number[]>([]); 
    const [TRAP_NAME, setTRAP_NAME] = useState<string[]>([]); 
    const [TRAP_TWIDTH, setTRAP_TWIDTH] = useState<number[]>([]); 
    const [TRAP_BWIDTH, setTRAP_BWIDTH] = useState<number[]>([]); 
    const [TRAP_DEPTH, setTRAP_DEPTH] = useState<number[]>([]); 
    const [TRAP_REF, setTRAP_REF] = useState<string[]>([]); 
    const [TRAP_REF_Y, setTRAP_REF_Y] = useState<number[]>([]); 
    const [TRAP_REF_Z, setTRAP_REF_Z] = useState<number[]>([]); 
    const [shapeTRAP, setShapeTRAP] = useState<(BABYLON.Vector3)[][]>([]);
    const [TRIAN_NAME, setTRIAN_NAME] = useState<string[]>([]); 
    const [TRIAN_X0, setTRIAN_X0] = useState<number[]>([]); 
    const [TRIAN_Y0, setTRIAN_Y0] = useState<number[]>([]); 
    const [TRIAN_X1, setTRIAN_X1] = useState<number[]>([]); 
    const [TRIAN_Y1, setTRIAN_Y1] = useState<number[]>([]); 
    const [TRIAN_X2, setTRIAN_X2] = useState<number[]>([]); 
    const [TRIAN_Y2, setTRIAN_Y2] = useState<number[]>([]); 
    const [TRIAN_REF, setTRIAN_REF] = useState<string[]>([]); 
    const [TRIAN_REF_Y, setTRIAN_REF_Y] = useState<number[]>([]); 
    const [TRIAN_REF_Z, setTRIAN_REF_Z] = useState<number[]>([]); 
    const [shapeTRIAN, setShapeTRIAN] = useState<(BABYLON.Vector3)[][]>([]);

    const [QUAD_NAME, setQUAD_NAME] = useState<string[]>([]); 
    const [QUAD_X0, setQUAD_X0] = useState<number[]>([]); 
    const [QUAD_Y0, setQUAD_Y0] = useState<number[]>([]); 
    const [QUAD_X1, setQUAD_X1] = useState<number[]>([]); 
    const [QUAD_Y1, setQUAD_Y1] = useState<number[]>([]); 
    const [QUAD_X2, setQUAD_X2] = useState<number[]>([]); 
    const [QUAD_Y2, setQUAD_Y2] = useState<number[]>([]); 
    const [QUAD_X3, setQUAD_X3] = useState<number[]>([]); 
    const [QUAD_Y3, setQUAD_Y3] = useState<number[]>([]); 
    const [QUAD_REF, setQUAD_REF] = useState<string[]>([]); 
    const [QUAD_REF_Y, setQUAD_REF_Y] = useState<number[]>([]); 
    const [QUAD_REF_Z, setQUAD_REF_Z] = useState<number[]>([]); 
    const [shapeQUAD, setShapeQUAD] = useState<(BABYLON.Vector3)[][]>([]);
    const [ISHAPE_NAME, setISHAPE_NAME] = useState<string[]>([]); 
    const [ISHAPE_BTF, setISHAPE_BTF] = useState<number[]>([]); 
    const [ISHAPE_TTF, setISHAPE_TTF] = useState<number[]>([]); 
    const [ISHAPE_BBF, setISHAPE_BBF] = useState<number[]>([]); 
    const [ISHAPE_TBF, setISHAPE_TBF] = useState<number[]>([]); 
    const [ISHAPE_DWEB, setISHAPE_DWEB] = useState<number[]>([]); 
    const [ISHAPE_TWEB, setISHAPE_TWEB] = useState<number[]>([]); 
    const [ISHAPE_REF, setISHAPE_REF] = useState<string[]>([]); 
    const [ISHAPE_REF_Y, setISHAPE_REF_Y] = useState<number[]>([]); 
    const [ISHAPE_REF_Z, setISHAPE_REF_Z] = useState<number[]>([]); 

    const [shapeISHAPE, setShapeISHAPE] = useState<(BABYLON.Vector3)[][]>([]);
    const [BOX_NAME, setBOX_NAME] = useState<string[]>([]); 
    const [BOX_depth, setBOX_depth] = useState<number[]>([]); 
    const [BOX_widthGirder, setBOX_widthGirder] = useState<number[]>([]); 
    const [BOX_lengthCantilever, setBOX_lengthCantilever] = useState<number[]>([]); 
    const [BOX_slopeWeb, setBOX_slopeWeb] = useState<number[]>([]); 
    const [BOX_thickWeb, setBOX_thickWeb] = useState<number[]>([]); 
    const [BOX_thickCantilever, setBOX_thickCantilever] = useState<number[]>([]); 
    const [BOX_thickTopSlab, setBOX_thickTopSlab] = useState<number[]>([]); 
    const [BOX_thickTopHaunch, setBOX_thickTopHaunch] = useState<number[]>([]); 
    const [BOX_thickBotSlab, setBOX_thickBotSlab] = useState<number[]>([]); 
    const [BOX_thickBotHaunch, setBOX_thickBotHaunch] = useState<number[]>([]); 
    const [BOX_widthTopHaunchOut, setBOX_widthTopHaunchOut] = useState<number[]>([]); 
    const [BOX_widthTopHaunchIn, setBOX_widthTopHaunchIn] = useState<number[]>([]); 
    const [BOX_widthBotHaunch, setBOX_widthBotHaunch] = useState<number[]>([]); 
    const [BOX_REF, setBOX_REF] = useState<string[]>([]); 
    const [BOX_REF_Y, setBOX_REF_Y] = useState<number[]>([]); 
    const [BOX_REF_Z, setBOX_REF_Z] = useState<number[]>([]); 
    const [shapeBOX, setShapeBOX] = useState<(BABYLON.Vector3)[][]>([]);
    const [shapeSLOPE, setShapeSLOPE] = useState<(BABYLON.Vector3)[][]>([]);
    const [CUSTOM_NAME, setCUSTOM_NAME] = useState<string[]>([]); 
    const [CUSTOM_NUMBER, setCUSTOM_NUMBER] = useState<number[]>([]); 
    const [CUSTOM_X, setCUSTOM_X] = useState<number[][]>([]); 
    const [CUSTOM_Y, setCUSTOM_Y] = useState<number[][]>([]); 
    const [CUSTOM_REF, setCUSTOM_REF] = useState<string[]>([]); 
    const [CUSTOM_REF_Y, setCUSTOM_REF_Y] = useState<number[]>([]); 
    const [CUSTOM_REF_Z, setCUSTOM_REF_Z] = useState<number[]>([]); 

    const [SLOPE_NAME, setSLOPE_NAME] = useState<string[]>([]); 
    const [SLOPE_NUMBER, setSLOPE_NUMBER] = useState<number[]>([]); 
    const [SLOPE_THICK, setSLOPE_THICK] = useState<number[]>([]); 
    const [SLOPE_SE, setSLOPE_SE] = useState<number[][]>([]); 
    const [SLOPE_DIST, setSLOPE_DIST] = useState<number[][]>([]); 
    const [SLOPE_REF, setSLOPE_REF] = useState<string[]>([]); 
    const [SLOPE_REF_Y, setSLOPE_REF_Y] = useState<number[]>([]); 
    const [SLOPE_REF_Z, setSLOPE_REF_Z] = useState<number[]>([]); 
    const [shapeCUSTOM, setShapeCUSTOM] = useState<(BABYLON.Vector3)[][]>([]);
    const [NCAPSHAPE, setNCAPSHAPE] = useState<number | null>(null);
    const [NTUBSHAPE, setNTUBSHAPE] = useState<number | null>(null);
    const [NPLATESHAPE, stNPLATESHAPE] = useState<number | null>(null);
    const [NAASHTOSHAPE, setNAASHTOSHAPE] = useState<number | null>(null);
    const [NFIBSHAPE, setNFIBSHAPE] = useState<number | null>(null);
    const [NNPBRG, setNNPBRG] = useState<number | null>(null);
    const [NPOTBRG, setNPOTBRG] = useState<number | null>(null);
    const [NDISKBRG, setNDISKBRG] = useState<number | null>(null);
    const [capShape_name, setCapShape_name] = useState<string[]>([]); 
    const [capShape_seg, setCapShape_seg] = useState<number[]>([]); 
    const [capShape_width, setCapShape_width] = useState<number[]>([]); 
    const [capShape_colwidth, setCapShape_colwidth] = useState<number[]>([]); 
    const [capShape_depth_tip, setCapShape_depth_tip] = useState<number[]>([]); 
    const [capShape_vary1, setCapShape_vary1] = useState<number[]>([]); 
    const [capShape_vary2, setCapShape_vary2] = useState<number[]>([]); 
    const [capShape_vary3, setCapShape_vary3] = useState<number[]>([]); 
    const [capShape_depth_root, setCapShape_depth_root] = useState<number[]>([]); 
    const [capShape_mid1, setCapShape_mid1] = useState<number[]>([]); 
    const [capShape_mid2, setCapShape_mid2] = useState<number[]>([]); 
    const [capShape_mid3, setCapShape_mid3] = useState<number[]>([]); 
    const [capShape_mid4, setCapShape_mid4] = useState<number[]>([]); 
    const [capShape_mid5, setCapShape_mid5] = useState<number[]>([]); 
    const [capShape_mid6, setCapShape_mid6] = useState<number[]>([]); 
    const [capShape_mid7, setCapShape_mid7] = useState<number[]>([]); 
    const [capShape_vary_length, setCapShape_vary_length] = useState<number[]>([]); 
    const [capShape_left_length, setCapShape_left_length] = useState<number[]>([]); 
    const [capShape_mid_length, setCapShape_mid_length] = useState<number[]>([]); 
    const [capShape_right_length, setCapShape_right_length] = useState<number[]>([]); 
    const [tubShape_name, setTubShape_name] = useState<string[]>([]); 
    const [tubShape_topwidth, setTubShape_topwidth] = useState<number[]>([]); 
    const [tubShape_webslope, setTubShape_webslope] = useState<number[]>([]); 
    const [tubShape_webdepth, setTubShape_webdepth] = useState<number[]>([]); 
    const [tubShape_webthick, setTubShape_webthick] = useState<number[]>([]); 
    const [tubShape_botflext, setTubShape_botflext] = useState<number[]>([]); 
    const [tubShape_topflwidth, setTubShape_topflwidth] = useState<number[]>([]); 
    const [tubShape_topflthick, setTubShape_topflthick] = useState<number[]>([]); 
    const [tubShape_botflthick, setTubShape_botflthick] = useState<number[]>([]); 
    const [tubShape_points, setTubShape_points] = useState<BABYLON.Vector3[][]>([]); 
    const [plateShape_name, setPlateShape_name] = useState<string[]>([]); 
    const [plateShape_topflwidth, setPlateShape_topflwidth] = useState<number[]>([]); 
    const [plateShape_topflthick, setPlateShape_topflthick] = useState<number[]>([]); 
    const [plateShape_botflwidth, setPlateShape_botflwidth] = useState<number[]>([]); 
    const [plateShape_botflthick, setPlateShape_botflthick] = useState<number[]>([]); 
    const [plateShape_webdepth, setPlateShape_webdepth] = useState<number[]>([]); 
    const [plateShape_webthick, setPlateShape_webthick] = useState<number[]>([]); 
    const [plateShape_points, setPlateShape_points] = useState<(BABYLON.Vector3)[][]>([]);

    const [aashtoShape_name, setAashtoShape_name] = useState<string[]>([]); 
    const [aashtoShape_D1, setAashtoShape_D1] = useState<number[]>([]); 
    const [aashtoShape_D2, setAashtoShape_D2] = useState<number[]>([]); 
    const [aashtoShape_D3, setAashtoShape_D3] = useState<number[]>([]); 
    const [aashtoShape_D4, setAashtoShape_D4] = useState<number[]>([]); 
    const [aashtoShape_D5, setAashtoShape_D5] = useState<number[]>([]); 
    const [aashtoShape_D6, setAashtoShape_D6] = useState<number[]>([]); 
    const [aashtoShape_B1, setAashtoShape_B1] = useState<number[]>([]); 
    const [aashtoShape_B2, setAashtoShape_B2] = useState<number[]>([]); 
    const [aashtoShape_B3, setAashtoShape_B3] = useState<number[]>([]); 
    const [aashtoShape_B4, setAashtoShape_B4] = useState<number[]>([]); 
    const [aashtoShape_B5, setAashtoShape_B5] = useState<number[]>([]); 
    const [aashtoShape_points, setAashtoShape_points] = useState<(BABYLON.Vector3)[][]>([]);
    
    const [fibShape_name, setFibShape_name] = useState<string[]>([]); 
    const [fibShape_D1, setFibShape_D1] = useState<number[]>([]); 
    const [fibShape_D2, setFibShape_D2] = useState<number[]>([]); 
    const [fibShape_D3, setFibShape_D3] = useState<number[]>([]); 
    const [fibShape_D4, setFibShape_D4] = useState<number[]>([]); 
    const [fibShape_D5, setFibShape_D5] = useState<number[]>([]); 
    const [fibShape_D6, setFibShape_D6] = useState<number[]>([]); 
    const [fibShape_B1, setFibShape_B1] = useState<number[]>([]); 
    const [fibShape_B2, setFibShape_B2] = useState<number[]>([]); 
    const [fibShape_B3, setFibShape_B3] = useState<number[]>([]); 
    const [fibShape_B4, setFibShape_B4] = useState<number[]>([]); 
    const [fibShape_B5, setFibShape_B5] = useState<number[]>([]); 
    const [fibShape_B6, setFibShape_B6] = useState<number[]>([]); 
    const [fibShape_B7, setFibShape_B7] = useState<number[]>([]); 
    const [fibShape_B8, setFibShape_B8] = useState<number[]>([]); 
    const [fibShape_B9, setFibShape_B9] = useState<number[]>([]); 


    const [fibShape_points, setFibShape_points] = useState<(BABYLON.Vector3)[][]>([]);
    const [nppBrgName, setNppBrgName] = useState<string[]>([]); 
    const [nppBrgWidth, setNppBrgWidth] = useState<number[]>([]); 
    const [nppBrgLength, setNppBrgLength] = useState<number[]>([]); 
    const [nppBrgHeight, setNppBrgHeight] = useState<number[]>([]); 
    const [NMODEL, setNMODEL] = useState<number | null>(null);
    const [NCLINE, setNCLINE] = useState<number | null>(null);
    const [modelName, setModelName] = useState<string[]>([]); 
    const [modelShow, setModelShow] = useState<string[]>([]); 
    const [modelType, setModelType] = useState<string[]>([]); 
    const [modelCenterLine, setModelCenterLine] = useState<string[]>([]); 
    const [modelUnit, setModelUnit] = useState<string[]>([]); 
    const [modelSuper, setModelSuper] = useState<string[]>([]); 
    const [modelSub, setModelSub] = useState<string[]>([]); 
    const [modelPointBearing, setModelPointBearing] = useState<string[]>([]); 
    const [modelPoint10th, setModelPoint10th] = useState<string[]>([]); 
    const [modelPoint20th, setModelPoint20th] = useState<string[]>([]); 
    const [modelPointXframe, setModelPointXframe] = useState<string[]>([]); 
    const [modelPointSplice, setModelPointSplice] = useState<string[]>([]); 
    const [clineName, setClineName] = useState<string[]>([]); 
    const [clineChord, setClineChord] = useState<string[]>([]); 
    const [clineBrgOffset, setClineBrgOffset] = useState<number[]>([]); 
    const [clineSec, setClineSec] = useState<number[]>([]); 
    
    const [NREFPOINT, setNREFPOINT] = useState<number | null>(null);
    const [NTOPO, setNTOPO] = useState<number | null>(null);
    const [NDTM, setNDTM] = useState<number | null>(null);
    const [NAERIAL, setNAERIAL] = useState<number | null>(null);
    const [NUTILITY, setNUTILITY] = useState<number | null>(null);

    const [refPointName, setRefPointName] = useState<string[]>([]); 
    const [refPointX, setRefPointX] = useState<number[]>([]); 
    const [refPointY, setRefPointY] = useState<number[]>([]); 
    const [refPointZ, setRefPointZ] = useState<number[]>([]); 
    const [topoName, setTopoName] = useState<string[]>([]); 
    const [topoShow, setTopoShow] = useState<string[]>([]); 
    const [topoRef, setTopoRef] = useState<number[]>([]); 
    const [topoURL, setTopoURL] = useState<number[]>([]); 
    const [clineGeometry, setClineGeometry] = useState<(number)[][]>([]);

    const [aerialName, setAerialName] = useState<string[]>([]); 
    const [aerialShow, setAerialShow] = useState<string[]>([]); 
    const [aerialRef, setAerialRef] = useState<string[]>([]); 
    const [aerialXpix, setAerialXpix] = useState<number[]>([]); 
    const [aerialRY, setAerialRY] = useState<number[]>([]); 
    const [aerialRX, setAerialRX] = useState<number[]>([]); 
    const [aerialYpix, setAerialYpix] = useState<number[]>([]); 
    const [aerialX, setAerialX] = useState<number[]>([]); 
    const [aerialY, setAerialY] = useState<number[]>([]); 
    const [aerialJPG, setAerialJPG] = useState<string[]>([]); 

    const [utilityName, setUtilityName] = useState<string[]>([]); 
    const [utilityShow, setUtilityShow] = useState<string[]>([]); 
    const [utilityRef, setUtilityRef] = useState<string[]>([]); 
    const [utilityURL, setUtilityURL] = useState<string[]>([]); 

    const [dtmName, setDtmName] = useState<string[]>([]); 
    const [dtmShow, setDtmShow] = useState<string[]>([]); 
    const [dtmRef, setDtmRef] = useState<string[]>([]); 
    const [dtmURL, setDtmURL] = useState<string[]>([]); 


     // Unit definations
    var deg = Math.PI/180;
    // Unit ratio for girder section dimention input
    var unit_ratio = 1/12; // Inche - > foot   
    // Just example arrays for fitScale
    let X_show: number[] = [];
    let Y_show: number[] = [];
    let Z_show: number[] = [];


    function newHandle(label: string, scene: BABYLON.Scene): string {
        var max = 0;   
        for (var i = 0; i < scene.meshes.length; i++) {
            var name = scene.meshes[i].name;
            if (name.indexOf(label + "-") > -1 && name.indexOf("S" + "-" + label + "-") == -1 
                && name.indexOf("i" + label) == -1 && name.indexOf("j" + label) == -1) {                 
                var number = Number(name.substring(name.lastIndexOf("-") + 1));
                if (number > max) {
                    max = number;
                } 
            } 
        }
        var handle = label + "-" + (max + 1);
        return handle;
    }
 
    function removeNotes(text: string[]): void {  // Specify text as an array of strings
        const noteStart: [number[], number[]] = [[], []]; // Define the type for noteStart
        const noteEnd: [number[], number[]] = [[], []]; // Define the type for noteEnd
        let note0 = 0;
        let note1 = 0;
    
        for (let i = 0; i < text.length; i++) {
            if (text[i].includes("/*")) { 
                noteStart[0][note0] = i; 
                noteStart[1][note0] = text[i].indexOf("/*"); 
                note0++;
            }
            if (text[i].includes("*/")) { 
                noteEnd[0][note1] = i; 
                noteEnd[1][note1] = text[i].indexOf("*/"); 
                note1++;
            }
        }
    
        if (noteStart[0].length === noteEnd[0].length) { 
            for (let i = 0; i < noteStart[0].length; i++) {
                if (noteStart[0][i] === noteEnd[0][i]) {
                    text[noteStart[0][i]] = text[noteStart[0][i]].slice(0, noteStart[1][i]) + text[noteStart[0][i]].slice(noteEnd[1][i] + 2);
                }
                if (noteEnd[0][i] - noteStart[0][i] === 1) {
                    text[noteStart[0][i]] = text[noteStart[0][i]].slice(0, noteStart[1][i]);
                    text[noteEnd[0][i]] = text[noteEnd[0][i]].slice(noteEnd[1][i] + 2);
                }
                if (noteEnd[0][i] - noteStart[0][i] > 1) {
                    text[noteStart[0][i]] = text[noteStart[0][i]].slice(0, noteStart[1][i]);
                    text[noteEnd[0][i]] = text[noteEnd[0][i]].slice(noteEnd[1][i] + 2);
                    for (let j = noteStart[0][i] + 1; j < noteEnd[0][i]; j++) {
                        text[j] = ''; // Remove the entire line by setting it to an empty string
                    }
                }
            }
        } 
     }
    

        // Function to return the max value of vector and associated column id
        function maxValue(vector: number[]): [number, number] { // Specify the type for vector
            let max = -9999999999; // Initialize max to a very low value
            let id = -1; // Initialize id to an invalid index
    
            for (let i = 0; i < vector.length; i++) {
                if (max < vector[i]) { 
                    max = vector[i]; // Update max with the current value
                    id = i; // Update id with the current index
                }
            }
            
            return [max, id]; // Return max value and its index as a tuple
        }
    

        // Function to return the min value of vector and associated column id
        function minValue(vector: number[]): [number, number] { // Specify the type for vector
            let min = 9999999999; // Initialize min to a very high value
            let id = -1; // Initialize id to an invalid index
    
            for (let i = 0; i < vector.length; i++) {
                if (min > vector[i]) { 
                    min = vector[i]; // Update min with the current value
                    id = i; // Update id with the current index
                }
            }
            
            return [min, id]; // Return min value and its index as a tuple
        }


        function matrix(rows: number, cols: number, defaultValue: any): any[][] {
            const arr: any[][] = []; // Define the return type as a 2D array
        
            for (let i = 0; i < rows; i++) {
                arr.push([]);
                for (let j = 0; j < cols; j++) {
                    arr[i][j] = defaultValue; // Assign the default value
                }
            }
        
            return arr;
        }
        
    

    useEffect(() => {
        if (fileLoaded) {
            setFileReady(true);
        }
    }, [fileLoaded]);


    // Process inputFile when it changes
    useEffect(() => {
        if (inputFile) {
            // Directly split without normalization
            const lines = inputFile.split('\n');
            removeNotes(lines);
            //console.log("Lines after note removal:", lines[1]);     
            setLines(lines);

                // Function for finding the row numbers for chapter in a text input file; a chapter is the first-level label     
                function FIND_CHAPTER(SEP: string): number[] {
                    const NN: number[] = [9999, 9999]; // Initialize an array to hold the chapter indices
                
                    for (let i = 0; i < lines.length; i++) {
                        if (lines[i].indexOf(SEP) > -1 && lines[i].indexOf("{") > -1) {
                            NN[0] = i + 1; // Chapter start line index
                            for (let j = i + 1; j < lines.length; j++) {
                                if (lines[j].indexOf("{") > -1) { 
                                    NN[1] = j; // Chapter end line index
                                    break;
                                }
                            }
                            break; // Exit the outer loop after finding the first chapter
                        }
                    }
                
                    return NN;
                }
                

                
                // Basic Functions for Getting Info from the Input File

                // Function for finding the row numbers for an input section in a certain chapter; a session is the 2nd level label   
                function FIND_SECTION(KC: number[], SEP: string): number[] {
                    const NN: number[] = [9999, 9999]; // Initialize an array to hold the section indices
                
                    for (let i = KC[0]; i < KC[1]; i++) {
                        if (lines[i].indexOf(SEP) > -1 && lines[i].indexOf("[") > -1) {
                            NN[0] = i + 1; // Section start line index
                            for (let j = i + 1; j < lines.length; j++) {
                                if (lines[j].indexOf("[") > -1 || lines[j].indexOf("{") > -1) { 
                                    NN[1] = j; // Section end line index
                                    break;
                                }
                            }
                            break; // Exit the outer loop after finding the first section
                        }
                    }
                
                    return NN;
                }
                
                
                // Function for finding the row numbers for an input section with a lable identified    
                function FINDI(KL: number[], SEP: string, n: number): number[] {
                    const NN: number[] = [9999, 9999]; // Initialize an array to hold the indices
                
                    for (let i_line = KL[0]; i_line < KL[1]; i_line++) {
                        if (lines[i_line].indexOf(SEP) > -1) {
                            NN[0] = i_line + 1; // Set the start index
                            NN[1] = NN[0] + n + 1; // Allow one additional comment line
                            break; // Exit after finding the first match
                        }
                    }
                
                    return NN;
                }
                
                // Function for finding the number if input within a range of rows with a lable identified    
                function NINPUT(KL: number[], SEP: string): number {
                    const separator = SEP + "=";    
                    let nLabel = 0;
                
                    for (let i_line = KL[0]; i_line < KL[1]; i_line++) {
                        if (lines[i_line].indexOf(separator) > -1) {
                            nLabel += 1; // Increment the count for each label found
                        }
                    }
                
                    return nLabel;
                }
                
                // Function for reading input values from a data file   
                function VALUE(KL: number[], label0: string, n: number): (string | number)[] {
                    const label = label0 + "=" + ",";
                    const value: (string | number)[] = []; // Array can hold both strings and numbers
                    const charEnd: number[] = [];   
                    let nLine = 0;  
                
                    for (let i = KL[0]; i < KL[1]; i++) {
                        if (i >= lines.length) { break; } // Adjust to avoid going out of bounds
                        if (lines[i].indexOf(label) > -1) {             
                            let nComma = 0; 
                            for (let k = lines[i].indexOf(label) + label.length; k < lines[i].length; k++) {
                                if (lines[i].charAt(k) === ",") {
                                    charEnd[nComma] = k; 
                                    nComma++;
                                }
                            }
                            for (let ii = 0; ii < n; ii++) {
                                let start: number;
                                let end: number;
                                
                                if (ii === 0) { 
                                    start = lines[i].indexOf(label) + label.length;
                                    end = charEnd[0];
                                } else {
                                    start = charEnd[ii - 1] + 1;
                                    end = charEnd[ii];
                                }
                
                                const valueSub = Number(lines[i].substring(start, end));
                                if (isNaN(valueSub)) {
                                    value[ii + nLine * n] = lines[i].substring(start, end);
                                } else {
                                    value[ii + nLine * n] = valueSub;
                                } 
                            }
                            nLine++;
                        }       
                    }
                
                    /*
                    for (let i = 0; i < value.length; i++) {
                        // Check if value[i] is a string before calling string methods
                        if (typeof value[i] === 'string') {
                            // Replace the input "#" for "," to avoid conflict with comma separator
                            if (value[i].indexOf("#") > -1) { 
                                value[i] = value[i].replace(/#/g, ','); // Use regex to replace all occurrences
                            }
                        }
                    } 
                        */
                
                    return value;
                    }
                
            

            // Additional processing of lines as needed...
            const KC_show = FIND_CHAPTER("GEOMETRY");
            setKC_show(KC_show);
            let label = "3DVIEW";
            let KL_show = FIND_SECTION(KC_show,String(label));
 
            const NUNITON = NINPUT(FIND_SECTION(KC_show,String(label)),"UNITON");
            setNUNITON(NUNITON); 
            const NMAINON = NINPUT(FIND_SECTION(KC_show,String(label)),"MAINON");
            setNMAINON(NMAINON); 
       
          // Input 3D View information  
   
           setZoomSpeed(Number(VALUE(KL_show, "ZOOM", 1)[0]));
   
           const allON = String(VALUE(KL_show, "ALLBRON", 1)[0]);
           setAllON(allON); 
              
           setSuperstructureON(String(VALUE(KL_show, "SUPERON", 1)[0])); 
   
    
           setDeckON(String(VALUE(KL_show, "DECKON", 1)[0])); 
   
     
           setBarrierON(String(VALUE(KL_show, "BARRON", 1)[0]));   
   
            
           setGirderON(String(VALUE(KL_show, "GIRDON", 1)[0])); 
   
   
           setSubstructureON(String(VALUE(KL_show, "SUBON", 1)[0])); 
   
            
           setCapON(String(VALUE(KL_show, "CAPON", 1)[0])); 
   
   
           setColumnON(String(VALUE(KL_show, "COLON", 1)[0])); 
   
   
           setFootingON(String(VALUE(KL_show, "FOOTON", 1)[0]));  
   
   
           setPileON(String(VALUE(KL_show, "PILEON", 1)[0]));      
       
           if (allON === "YES") {
               setSuperstructureON("YES"); 
               setDeckON("YES"); 
               setBarrierON("YES");      
               setGirderON("YES"); 
               setSubstructureON("YES"); 
               setCapON("YES");  
               setColumnON("YES"); 
               setFootingON("YES"); 
               setPileON("YES"); 
           }
       

           let new_unitOnNum: string[] = [];  
           let new_unitOnOff: string[] = [];  
           let new_unitON = matrix(Number(NUNITON), 11, 0); 
       
           // Declare max only once
           let max1 = 2 + 11; // Ensure this declaration is at the appropriate scope
       
           for (let i = 0; i < Number(NUNITON); i++) {
               const values = VALUE(KL_show, "UNITON", max1); // Get values once to avoid repeated calls
       
               // Ensure values are assigned correctly
               const valueNum = values[max1 * i];
               new_unitOnNum[i] = (typeof valueNum === 'string') ? valueNum : ''; // Default to 0 if not a number
       
               const valueOnOff = values[max1 * i + 1];
               new_unitOnOff[i] = (typeof valueOnOff === 'string') ? valueOnOff : ''; // Convert to boolean
               
               // Assign values to unitON with proper type checking
               new_unitON[i][0] = (typeof values[max1 * i + 2] === 'string') ? values[max1 * i + 2] : 0; // Show Barrier
               new_unitON[i][1] = (typeof values[max1 * i + 3] === 'string') ? values[max1 * i + 3] : 0; // Show Deck 
               new_unitON[i][2] = (typeof values[max1 * i + 4] === 'string') ? values[max1 * i + 4] : 0; // Show Girder
               new_unitON[i][3] = (typeof values[max1 * i + 5] === 'string') ? values[max1 * i + 5] : 0; // Show Approach Slab 
               new_unitON[i][4] = (typeof values[max1 * i + 6] === 'string') ? values[max1 * i + 6] : 0; // Show End Bent Backwall
               new_unitON[i][5] = (typeof values[max1 * i + 7] === 'string') ? values[max1 * i + 7] : 0; // Show End Bent Cap
               new_unitON[i][6] = (typeof values[max1 * i + 8] === 'string') ? values[max1 * i + 8] : 0; // Show End Bent Foundation
               new_unitON[i][7] = (typeof values[max1 * i + 9] === 'string') ? values[max1 * i + 9] : 0; // Show Pier Cap 
               new_unitON[i][8] = (typeof values[max1 * i + 10] === 'string') ? values[max1 * i + 10] : 0; // Show Pier Column
               new_unitON[i][9] = (typeof values[max1 * i + 11] === 'string') ? values[max1 * i + 11] : 0; // Show Pier Footing
               new_unitON[i][10] = (typeof values[max1 * i + 12] === 'string') ? values[max1 * i + 12] : 0; // Show Pier Foundation                    
           }
       
           setUnitOnNum(new_unitOnNum);
           setUnitOnOff(new_unitOnOff);
           setUnitON(new_unitON);
   
           for (let i = 0; i < Number(NUNITON); i++) {
               if (unitOnOff[i] === 'YES') { 
                   for (let j = 0; j < 11; j++) {
                       unitON[i][j] = "YES"; // Assign "NO" to unitON
                   }
               }
           }
       
           for (let i = 0; i < Number(NUNITON); i++) {
               if (allON === "YES") { 
                   unitOnOff[i] = "YES"; 
                   for (let j = 0; j < 11; j++) {
                       unitON[i][j] = "YES"; // Assign "YES" to unitON
                   }
               }
           }
       
   
           // Input MAIN viewing options
   
           let new_mainOnNum: string[] = []; 
           let new_mainOnOff: string[] = [];  
   
           const max2 = 2;
       
           for (let i = 0; i < Number(NMAINON); i++) {
               const values = VALUE(KL_show, "MAINON", max2); // Get values once to avoid repeated calls
       
               // Check type and assign only numbers to mainOnNum
               const unitValue = values[max2 * i];
               if (typeof unitValue === 'string') {
                   new_mainOnNum[i] = unitValue;  // Assign directly if it's a number
               } else {
                   new_mainOnNum[i] = ''; // Default to 0 if not a number
               }
       
               // Check type and assign to mainOnOff
               const onOffValue = values[max2 * i + 1];
               if (typeof onOffValue === 'string') {
                   new_mainOnOff[i] = onOffValue
               } else {
                   new_mainOnOff[i] = ''; // Default to false if not a string
               }
           }
           setMainOnNum(new_mainOnNum);
           setMainOnOff(new_mainOnOff);
           
   
            setWallON(String(VALUE(KL_show,"WALLON",1))); // Show approach retaining walls
   
   
            setApproadON(String(VALUE(KL_show,"ROADON",1))); // Show approach roads
   
   
            setBaselineON(String(VALUE(KL_show,"BASEON",1))); // Show baseline
   
   
            setLaneON(String(VALUE(KL_show,"LANEON",1)));  // Show lanes
   
   
            setGroundtextON(String(VALUE(KL_show,"GRDTON",1)));  // Show lanes    
   
   
            setRiverON(String(VALUE(KL_show,"RIVERON",1))); // Show rivers
   
   
            setSkyON(String(VALUE(KL_show,"SKYON",1)));  // Show sky texture
   
   
            setCarsON(String(VALUE(KL_show,"CARSON",1))); //Show cars 
   
   
            setFlowON(String(VALUE(KL_show,"FLOWON",1))); //Show flow camer    
       
                
            // Alignment Information   
            // Input horizontal curve information 
            label = "ALIGNMENT";
            KL_show = FIND_SECTION(KC_show,label);     

            const NHCURVE = NINPUT(FIND_SECTION(KC_show,label),"HCURVE");
            setNHCURVE(NHCURVE);
         
            const NVCURVE = NINPUT(FIND_SECTION(KC_show,label),"VCURVE");
            setNVCURVE(NVCURVE);  
   
            const NSECURVE = NINPUT(FIND_SECTION(KC_show,label),"SECURVE");
            setNSECURVE(NSECURVE); 
   
            const NALIGNMENT = NINPUT(FIND_SECTION(KC_show,label),"ALIGNMT");
            setNALIGNMENT(NALIGNMENT);  
            // Input Horizontal Curve information  
       
       

        // Declare the arrays with explicit types
        let new_hcurveNum: string[] = [];  
        let new_hcurveSeg: number[] = [];  
        let new_hcurveXPC: number[] = [];
        let new_hcurveYPC: number[] = [];
        let new_hcurveANG: number[] = []; 
        let maxCirSeg = 50; // Max horizontal circular segments allowed    
        const new_hcurveCircular = matrix(Number(NHCURVE), 3 * maxCirSeg, 0); 
            
        for (let i = 0; i < Number(NHCURVE); i++) {
            const max = 5 + maxCirSeg * 3;    
            const values = VALUE(KL_show, "HCURVE", max); // Get values once to avoid repeated calls
        
            // Assign values with type checks
            new_hcurveNum[i] = (typeof values[max * i] === 'string') ? String(values[max * i]) : ''; // Ensure it's a string
            new_hcurveSeg[i] = (typeof values[max * i + 1] === 'number') ? Number(values[max * i + 1]) : 0; 
            new_hcurveXPC[i] = (typeof values[max * i + 2] === 'number') ? Number(values[max * i + 2]) : 0; 
            new_hcurveYPC[i] = (typeof values[max * i + 3] === 'number') ? Number(values[max * i + 3]) : 0; 
            new_hcurveANG[i] = (typeof values[max * i + 4] === 'number') ? Number(values[max * i + 4]) * deg : 0;
        
            for (let j = 0; j < new_hcurveSeg[i]; j++) {
                const baseIndex = i * max + 5 + 3 * j; // Base index for circular segment values
        
                new_hcurveCircular[i][3 * j] = (typeof values[baseIndex] === 'number') ? Number(values[baseIndex]) : 0;
                new_hcurveCircular[i][3 * j + 1] = (typeof values[baseIndex + 1] === 'number') ? Number(values[baseIndex + 1]) : 0;
                new_hcurveCircular[i][3 * j + 2] = (typeof values[baseIndex + 2] === 'number') ? Number(values[baseIndex + 2]) : 0;                    
            }
        }


        setHcurveNum(new_hcurveNum);
        setHcurveSeg(new_hcurveSeg);
        setHcurveXPC(new_hcurveXPC);
        setHcurveYPC(new_hcurveYPC);
        setHcurveANG(new_hcurveANG);
        setHcurveCircular(new_hcurveCircular);

        // Input Vertical Curve information  
        let new_vcurveNum: string[] = [];      // Declare as an array of numbers
        let new_vcurveType: number[] = [];     // Declare as an array of numbers
        let new_vcurveSeg: number[] = [];      // Declare as an array of numbers
        let maxCirSeg1 = 50;                // Max vertical circular segments or points allowed    
        const new_vcurveCircular = matrix(Number(NVCURVE), 5 * maxCirSeg1, 0); 
    
        for (let i = 0; i < Number(NVCURVE); i++) {
            const max = 3 + maxCirSeg1 * 5;    
            new_vcurveNum[i] = String(VALUE(KL_show, "VCURVE", max)[max * i]); 
            new_vcurveType[i] = Number(VALUE(KL_show, "VCURVE", max)[max * i + 1]);     
            new_vcurveSeg[i] = Number(VALUE(KL_show, "VCURVE", max)[max * i + 2]); 
    
            if (new_vcurveType[i] === 1) {
                for (let j = 0; j < new_vcurveSeg[i]; j++) {
                    new_vcurveCircular[i][5 * j] = VALUE(KL_show, "VCURVE", max)[i * max + 3 + 5 * j];
                    new_vcurveCircular[i][5 * j + 1] = VALUE(KL_show, "VCURVE", max)[i * max + 3 + 5 * j + 1];
                    new_vcurveCircular[i][5 * j + 2] = VALUE(KL_show, "VCURVE", max)[i * max + 3 + 5 * j + 2]; 
                    new_vcurveCircular[i][5 * j + 3] = VALUE(KL_show, "VCURVE", max)[i * max + 3 + 5 * j + 3]; 
                    new_vcurveCircular[i][5 * j + 4] = VALUE(KL_show, "VCURVE", max)[i * max + 3 + 5 * j + 4];                                                
                }
            } 
            if (new_vcurveType[i] === 2) {
                for (let j = 0; j < new_vcurveSeg[i]; j++) {
                    new_vcurveCircular[i][2 * j] = VALUE(KL_show, "VCURVE", max)[i * max + 3 + 2 * j];
                    new_vcurveCircular[i][2 * j + 1] = VALUE(KL_show, "VCURVE", max)[i * max + 3 + 2 * j + 1];                                               
                }
            } 
        }
    

        setVcurveType(new_vcurveType);
        setVcurveSeg(new_vcurveSeg);

        setVcurveNum(new_vcurveNum);
        setVcurveCircular(new_vcurveCircular);

        // Input SuperElevation Curve information  
        let new_securveNum: string[] = [];      // Declare as an array of numbers
        let new_securveSeg: number[] = [];      // Declare as an array of numbers
        let maxPoints = 50;                  // Max SE Points allowed    
        const new_securvePairs = matrix(Number(NSECURVE), 2 * maxPoints, 0); 
    
        for (let i = 0; i < Number(NSECURVE); i++) {
            const max = 2 + maxPoints * 2;    
            const values = VALUE(KL_show, "SECURVE", max); // Get values once to avoid repeated calls
    
            // Ensure values are assigned correctly, checking the type first
            new_securveNum[i] = (typeof values[max * i] === 'string') ? String(values[max * i]) : ''; 
            new_securveSeg[i] = (typeof values[max * i + 1] === 'number') ? Number(values[max * i + 1]) : 0; 
    
            for (let j = 0; j < new_securveSeg[i]; j++) {
                new_securvePairs[i][2 * j] = (typeof values[i * max + 2 + 2 * j] === 'number') ? Number(values[i * max + 2 + 2 * j]) : 0;
                new_securvePairs[i][2 * j + 1] = (typeof values[i * max + 2 + 2 * j + 1] === 'number') ? Number(values[i * max + 2 + 2 * j + 1]) : 0;                                               
            }
        }
   
        setSecurveSeg(new_securveSeg);
        setSecurveNum(new_securveNum);
        setSecurvePairs(new_securvePairs);
    
        // Define Alignments
        let new_alignmentNum: string[] = [];  
        let new_alignmentCurves: string[][] = matrix(Number(NALIGNMENT), 3, 0); // Declare as a 2D array of numbers
    
        for (let i = 0; i < Number(NALIGNMENT); i++) {   
            new_alignmentNum[i] = String(VALUE(KL_show,"ALIGNMT",4)[4*i]); 
            new_alignmentCurves[i][0] = String(VALUE(KL_show,"ALIGNMT",4)[4*i+1]); 
            new_alignmentCurves[i][1] = String(VALUE(KL_show,"ALIGNMT",4)[4*i+2]); 
            new_alignmentCurves[i][2] = String(VALUE(KL_show,"ALIGNMT",4)[4*i+3]); 
        }
        setAlignmentNum(new_alignmentNum);
        setAlignmentCurves(new_alignmentCurves);


         // Define project information
         label = "PROJECT";
         KL_show = FIND_SECTION(KC_show,label);  


         var NMAIN = NINPUT(FIND_SECTION(KC_show,label),"MAIN"); 
         var NROAD = NINPUT(FIND_SECTION(KC_show,label),"ROAD") || 0;
         var NSTRUCT = NINPUT(FIND_SECTION(KC_show,label),"STRUCT"); 
         var NEMBANK = NINPUT(FIND_SECTION(KC_show,label),"EMBANK"); 
         var NWALL = NINPUT(FIND_SECTION(KC_show,label),"WALL");      
         var NRIVER = NINPUT(FIND_SECTION(KC_show,label),"RIVER"); 
         var NRAIL = NINPUT(FIND_SECTION(KC_show,label),"RAIL");  
                    
         setNMAIN(NMAIN)
         setNROAD(NROAD)
         setNSTRUCT(NSTRUCT)
         setNEMBANK(NRIVER)
         setNWALL(NWALL)
         setNRIVER(NRIVER)
         setNRAIL(NRAIL)

        // Define main road and bridges in the project
        const mainNum: string[] = []; 
        const mainType: string[] = [];         
        const mainAlign: string[] = [];
        const mainBegStation: number[] = [];
        const mainBridgeApproach1: string[] = [];     
        const mainBridgeBenApproachSB: number[] = [];
        const mainBridgeApproachSB1: string[] = [];     
        const mainBridgeBenStation: number[] = [];
        const mainBridgeNum: string[] = [];     
        const mainBridgeEndStation: number[] = [];
        const mainBridgeApproachSB2: string[] = [];  
        const mainBridgeEndApproachSB: number[] = [];
        const mainBridgeApproach2: string[] = [];        
        const mainEndStation: number[] = [];     
        const mainAppType: string[] = [];
        const mainAppSec: string[] = [];  // Approach section at the end bents   
        const mainDistMSE: number[] = []; // Distance from FFBW to FF MSE
        const mainEmbSlope: number[] = []; // Slope for the wrap around embankment
    
        const max16 = 17;
    
        for (let i = 0; i < NMAIN; i++) {   
            mainNum.push(String(VALUE(KL_show, "MAIN", max16)[max16 * i]));
            mainType.push(String(VALUE(KL_show, "MAIN", max16)[max16 * i + 1]));
            mainAlign.push(String(VALUE(KL_show, "MAIN", max16)[max16 * i + 2]));
            mainBegStation.push(Number(VALUE(KL_show, "MAIN", max16)[max16 * i + 3]));
            mainBridgeApproach1.push(String(VALUE(KL_show, "MAIN", max16)[max16 * i + 4]));  
            mainBridgeBenApproachSB.push(Number(VALUE(KL_show, "MAIN", max16)[max16 * i + 5]));
            mainBridgeApproachSB1.push(String(VALUE(KL_show, "MAIN", max16)[max16 * i + 6]));       
            mainBridgeBenStation.push(Number(VALUE(KL_show, "MAIN", max16)[max16 * i + 7]));
            mainBridgeNum.push(String(VALUE(KL_show, "MAIN", max16)[max16 * i + 8]));       
            mainBridgeEndStation.push(Number(VALUE(KL_show, "MAIN", max16)[max16 * i + 9]));
            mainBridgeApproachSB2.push(String(VALUE(KL_show, "MAIN", max16)[max16 * i + 10])); 
            mainBridgeEndApproachSB.push(Number(VALUE(KL_show, "MAIN", max16)[max16 * i + 11]));  
            mainBridgeApproach2.push(String(VALUE(KL_show, "MAIN", max16)[max16 * i + 12]));  
            mainEndStation.push(Number(VALUE(KL_show, "MAIN", max16)[max16 * i + 13]));        
            mainAppType.push(String(VALUE(KL_show, "MAIN", max16)[max16 * i + 14]));  
            mainAppSec.push(String(VALUE(KL_show, "MAIN", max16)[max16 * i + 15]));         
            mainDistMSE.push(Number(VALUE(KL_show, "MAIN", max16)[max16 * i + 16]));  
            mainEmbSlope.push(Number(VALUE(KL_show, "MAIN", max16)[max16 * i + 17])); 
        }  
    
        setMainNum(mainNum)
        setMainType(mainType)
        setMainAlign(mainAlign)
        setMainBegStation(mainBegStation)
        setMainBridgeApproach1(mainBridgeApproach1)
        setMainBridgeBenApproachSB(mainBridgeBenApproachSB)
        setMainBridgeApproachSB1(mainBridgeApproachSB1)
        setMainBridgeBenStation(mainBridgeBenStation)
        setMainBridgeNum(mainBridgeNum)
        setMainBridgeEndStation(mainBridgeEndStation)
        setMainBridgeApproachSB2(mainBridgeApproachSB2)
        setMainBridgeEndApproachSB(mainBridgeEndApproachSB)
        setMainBridgeApproach2(mainBridgeApproach2)
        setMainEndStation(mainEndStation)
        setMainAppType(mainAppType)
        setMainAppSec(mainAppSec)
        setMainDistMSE(mainDistMSE)
        setMainEmbSlope(mainEmbSlope)
     

            // Define roads// Define roads
        let roadNum: string[] = [];             // Declare as an array of numbers
        let roadAlign: string[] = [];           // Declare as an array of numbers (or strings based on your needs)
        let roadBegStation: number[] = [];      // Declare as an array of numbers
        let roadEndStation: number[] = [];      // Declare as an array of numbers
        let roadWidth: number[] = [];           // Declare as an array of numbers
        let roadMain: number[] = [];            // Declare as an array of numbers
        let roadMainSkew1: number[] = [];       // Declare as an array of numbers
        let roadMainSkew2: number[] = [];       // Declare as an array of numbers
        let roadSeg: number[] = [];              // Declare as an array of numbers
        let maxSecs1 = 20;                        // Max sections allowed    
        const roadGeometry: (number | string)[][] = matrix(NROAD, 2 * maxSecs1, 0);
    
        for (let i = 0; i < NROAD; i++) {
            const max = 9 + maxSecs1 * 2;    
            const values = VALUE(KL_show, "ROAD", max); // Get values once to avoid repeated calls
    
            // Ensure values are assigned correctly, checking the type first
            roadNum[i] = (typeof values[max * i] === 'string') ? String(values[max * i]) : '';   
            roadAlign[i] = (typeof values[max * i + 1] === 'string') ? String(values[max * i + 1]) : '';   
            roadBegStation[i] = (typeof values[max * i + 2] === 'number') ? Number(values[max * i + 2]) : 0; 
            roadEndStation[i] = (typeof values[max * i + 3] === 'number') ? Number(values[max * i + 3]) : 0; 
            roadWidth[i] = (typeof values[max * i + 4] === 'number') ? Number(values[max * i + 4]) : 0;
            roadMain[i] = (typeof values[max * i + 5] === 'number') ? Number(values[max * i + 5]) : 0;  
            roadMainSkew1[i] = (typeof values[max * i + 6] === 'number') ? Number(values[max * i + 6]) * deg : 0;  
            roadMainSkew2[i] = (typeof values[max * i + 7] === 'number') ? Number(values[max * i + 7]) * deg : 0;       
            roadSeg[i] = (typeof values[max * i + 8] === 'number') ? Number(values[max * i + 8]) : 0;           
    
            for (let j = 0; j < roadSeg[i]; j++) {
                roadGeometry[i][2 * j] = (typeof values[i * max + 9 + 2 * j] === 'number') ? values[i * max + 9 + 2 * j] : 0; // station to 1st section
                roadGeometry[i][2 * j + 1] = (typeof values[i * max + 9 + 2 * j + 1] === 'string') ? String(values[i * max + 9 + 2 * j + 1]) : ''; // Section number                                              
            }
        }

        setRoadNum(roadNum)
        setRoadAlign(roadAlign)
        setRoadBegStation(roadBegStation)
        setRoadEndStation(roadEndStation)
        setRoadWidth(roadWidth)
        setRoadMain(roadMain)
        setRoadMainSkew1(roadMainSkew1)
        setRoadMainSkew2(roadMainSkew2)
        setRoadSeg(roadSeg)
        setRoadGeometry(roadGeometry)

         // Define structures
         var structNum = [];
         var structAlign = [];  
         var structBegStation = [];
         var structEndStation = [];
         var structWidth = [];  
         var structMain = []; 
         var structMainType = [];
         var structMainMirror = [];                 
         var structSeg = [];
         var maxSecs2 = 20; // Max sections allowed    
         var structGeometry = matrix(NSTRUCT,2*maxSecs2,0); 
    
         for(var i=0; i<NSTRUCT; i++){
         var max17 = 9 + maxSecs2*2;    
         structNum[i] = String(VALUE(KL_show,"STRUCT",max17)[max17*i]);   
         structAlign[i] = String(VALUE(KL_show,"STRUCT",max17)[max17*i+1]);   
         structBegStation[i] = Number(VALUE(KL_show,"STRUCT",max17)[max17*i+2]); 
         structEndStation[i] = Number(VALUE(KL_show,"STRUCT",max17)[max17*i+3]); 
         structWidth[i] = Number(VALUE(KL_show,"STRUCT",max17)[max17*i+4]);
         structMain[i] = Number(VALUE(KL_show,"STRUCT",max17)[max17*i+5]);  
         structMainType[i] = Number(VALUE(KL_show,"STRUCT",max17)[max17*i+6]);  
         structMainMirror[i] = Number(VALUE(KL_show,"STRUCT",max17)[max17*i+7]);      
         structSeg[i] = Number(VALUE(KL_show,"STRUCT",max17)[max17*i+8]);           
          for(var j=0;j<structSeg[i];j++){
              structGeometry[i][2*j] = VALUE(KL_show,"STRUCT",max17)[i*max17 + 9 + 2*j];  // station to 1st section
              structGeometry[i][2*j+1] = VALUE(KL_show,"STRUCT",max17)[i*max17 + 9 + 2*j + 1]; // Section number                                              
          }
         }
    
         setStructNum(structNum)
         setStructAlign(structAlign)
         setStructBegStation(structBegStation)
         setStructEndStation(structEndStation)
         setStructWidth(structWidth)
         setStructMain(structMain)
         setStructMainType(structMainType)
         setStructMainMirror(structMainMirror)
         setStructSeg(structSeg)
         setStructGeometry(structGeometry)

         // Define embankment
         var embankNum = [];
         var embankAlign = [];  
         var embankBegStation = [];
         var embankEndStation = [];  
         var embankWidth = [];  
         var embankMain = []; 
         var embankMainType = [];
         var embankMainMirror = [];                     
         var embankSeg = [];
         var maxSecs3 = 20; // Max sections allowed    
         var embankGeometry = matrix(NEMBANK,2*maxSecs3,0); 
    
         for(var i=0; i<NEMBANK; i++){
         var max18 = 9 + maxSecs3*2;    
         embankNum[i] = String(VALUE(KL_show,"EMBANK",max18)[max18*i]);   
         embankAlign[i] = String(VALUE(KL_show,"EMBANK",max18)[max18*i+1]);   
         embankBegStation[i] = Number(VALUE(KL_show,"EMBANK",max18)[max18*i+2]); 
         embankEndStation[i] = Number(VALUE(KL_show,"EMBANK",max18)[max18*i+3]); 
         embankWidth[i] = Number(VALUE(KL_show,"EMBANK",max18)[max18*i+4]);
         embankMain[i] = Number(VALUE(KL_show,"EMBANK",max18)[max18*i+5]);  
         embankMainType[i] = Number(VALUE(KL_show,"EMBANK",max18)[max18*i+6]);  
         embankMainMirror[i] = Number(VALUE(KL_show,"EMBANK",max18)[max18*i+7]);      
         embankSeg[i] = Number(VALUE(KL_show,"EMBANK",max18)[max18*i+8]);           
          for(var j=0;j<embankSeg[i];j++){
              embankGeometry[i][2*j] = VALUE(KL_show,"EMBANK",max18)[i*max18 + 9 + 2*j];  // station to 1st section
              embankGeometry[i][2*j+1] = VALUE(KL_show,"EMBANK",max18)[i*max18 + 9 + 2*j + 1]; // Section number                                              
          }
         }
    
         setEmbankNum(embankNum)
         setEmbankAlign(embankAlign)
         setEmbankBegStation(embankBegStation)
         setEmbankEndStation(embankEndStation)
         setEmbankWidth(embankWidth)
         setEmbankMain(embankMain)
         setEmbankMainType(embankMainType)
         setEmbankMainMirror(embankMainMirror)
         setEmbankSeg(embankSeg)
         setEmbankGeometry(embankGeometry)


        // Define wall
        const wallNum: string[] = [];  // Assuming wallNum should hold strings
        const wallAlign: string[] = [];  
        const wallBegStation: number[] = [];
        const wallEndStation: number[] = [];   
        const wallRoad: string[] = [];  // Assuming wallRoad should hold strings
        const wallMain: string[] = []; 
        const wallMainType: string[] = [];
        const wallMainSide: string[] = [];                                 
        const wallSeg: number[] = [];
        const maxSecs4 = 20; // Max sections allowed    
        const wallGeometry: (number | string)[][] = matrix(NWALL, 2 * maxSecs4, 0); 
    
        for (let i = 0; i < NWALL; i++) {
            const max = 9 + maxSecs4 * 2;    
            const values = VALUE(KL_show, "WALL", max); // Retrieve values once for efficiency
            
            // Assign values to the walls with type checks and conversions
            wallNum[i] = String(values[max * i]); // Ensure wallNum is a string
            wallAlign[i] = String(values[max * i + 1]); // Ensure wallAlign is a string
            wallBegStation[i] = Number(values[max * i + 2]) || 0; // Convert to number, default to 0
            wallEndStation[i] = Number(values[max * i + 3]) || 0; 
            wallRoad[i] = String(values[max * i + 4]); // Ensure wallRoad is a string
            wallMain[i] = String(values[max * i + 5]); // Ensure wallMain is a string
            wallMainType[i] = String(values[max * i + 6]); // Ensure wallMainType is a string
            wallMainSide[i] = String(values[max * i + 7]); // Ensure wallMainSide is a string
            wallSeg[i] = Number(values[max * i + 8]) || 0; // Convert to number, default to 0
            
            for (let j = 0; j < wallSeg[i]; j++) {
                wallGeometry[i][2 * j] = values[i * max + 9 + 2 * j];  // Station to 1st section
                wallGeometry[i][2 * j + 1] = values[i * max + 9 + 2 * j + 1]; // Section number                                              
            }
        }
    
        setWallNum(wallNum)
        setWallAlign(wallAlign)
        setWallBegStation(wallBegStation)
        setWallEndStation(wallEndStation)
        setWallRoad(wallRoad)
        setWallMain(wallMain)
        setWallMainType(wallMainType)
        setWallMainSide(wallMainSide)
        setWallSeg(wallSeg)
        setWallGeometry(wallGeometry)

         // Define river
         var riverNum = [];
         var riverAlign = [];  
         var riverBegStation = [];
         var riverEndStation = [];   
         var riverWidth = [];  
         var riverMain = []; 
         var riverMainSta = [];
         var riverMainSkew = [];                                 
         for(var i=0; i<NRIVER; i++){
         var max19 = 8;    
         riverNum[i] = String(VALUE(KL_show,"RIVER",max19)[max19*i]);   
         riverAlign[i] = String(VALUE(KL_show,"RIVER",max19)[max19*i+1]);   
         riverBegStation[i] = Number(VALUE(KL_show,"RIVER",max19)[max19*i+2]); 
         riverEndStation[i] = Number(VALUE(KL_show,"RIVER",max19)[max19*i+3]); 
         riverWidth[i] = Number(VALUE(KL_show,"RIVER",max19)[max19*i+4]);
         riverMain[i] = Number(VALUE(KL_show,"RIVER",max19)[max19*i+5]);  
         riverMainSta[i] = Number(VALUE(KL_show,"RIVER",max19)[max19*i+6]);  
         riverMainSkew[i] = Number(VALUE(KL_show,"RIVER",max19)[max19*i+7])*deg;                
         }


         setRiverNum(riverNum)
         setRiverAlign(riverAlign)
         setRiverBegStation(riverBegStation)
         setRiverEndStation(riverEndStation)
         setRiverWidth(riverWidth)
         setRiverMain(riverMain)
         setRiverMainSta(riverMainSta)
         setRiverMainSta(riverMainSta)
         setRiverMainSkew(riverMainSkew)

         // Define rail
         var railNum = [];
         var railAlign = [];  
         var railBegStation = [];
         var railEndStation = [];  
         var railTrackNum = [];   
         var railTrackSpa = [];   
         var railWidth = [];  
         var railMain = []; 
         var railMainSta = [];
         var railMainSkew = [];   
    
         for(var i=0; i<NRAIL; i++){
         var max20 = 10;    
         railNum[i] = String(VALUE(KL_show,"RAIL",max20)[max20*i]);   
         railAlign[i] = String(VALUE(KL_show,"RAIL",max20)[max20*i+1]);   
         railBegStation[i] = Number(VALUE(KL_show,"RAIL",max20)[max20*i+2]); 
         railEndStation[i] = Number(VALUE(KL_show,"RAIL",max20)[max20*i+3]); 
         railTrackNum[i] = Number(VALUE(KL_show,"RAIL",max20)[max20*i+4]); 
         railTrackSpa[i] = Number(VALUE(KL_show,"RAIL",max20)[max20*i+5]); 
         railWidth[i] = Number(VALUE(KL_show,"RAIL",max20)[max20*i+6]);
         railMain[i] = Number(VALUE(KL_show,"RAIL",max20)[max20*i+7]);  
         railMainSta[i] = Number(VALUE(KL_show,"RAIL",max20)[max20*i+8]);  
         railMainSkew[i] = Number(VALUE(KL_show,"RAIL",max20)[max20*i+9])*deg;                
         }
    
         setRailNum(railNum)
         setRailAlign(railAlign)
         setRailBegStation(railBegStation)
         setRailEndStation(railEndStation)
         setRailTrackNum(railTrackNum)
         setRailTrackSpa(railTrackSpa)
         setRailWidth(railWidth)
         setRailMain(railMain)
         setRailMainSta(railMainSta)
         setRailMainSkew(railMainSkew)

         // Define superstructure elements
         label = "SUPERSTRUCTURE";
         KL_show = FIND_SECTION(KC_show,label);  

         var NSTRIPE = NINPUT(FIND_SECTION(KC_show,label),"STRIPE");  
         var NBARRIER = NINPUT(FIND_SECTION(KC_show,label),"BARRIER"); 
         var NDECK = NINPUT(FIND_SECTION(KC_show,label),"DECK"); 
         var NHAUNCH = NINPUT(FIND_SECTION(KC_show,label),"HAUNCH");   
         var NBEARING = NINPUT(FIND_SECTION(KC_show,label),"BEARING");         
         var NAPPSLAB = NINPUT(FIND_SECTION(KC_show,label),"APPRSB"); 
         var NGIRDER = NINPUT(FIND_SECTION(KC_show,label),"GIRDER"); 
         var NBEAM = NINPUT(FIND_SECTION(KC_show,label),"BEAM");      
         var NSPAN = NINPUT(FIND_SECTION(KC_show,label),"SPAN"); 


         setNSTRIPE(NSTRIPE)
         setNBARRIER(NBARRIER)
         setNDECK(NDECK)
         setNHAUNCH(NHAUNCH)
         setNBEARING(NBEARING)
         setNAPPSLAB(NAPPSLAB)
         setNGIRDER(NGIRDER)
         setNBEAM(NBEAM)
         setNSPAN(NSPAN)

        // Define Striped lanes
        const stripeNum: string[] = []; // Assuming stripeNum should hold strings
        const stripeAlign: string[] = []; 
        const stripeMain: string[] = [];      
        const stripeStaBegin: number[] = [];  
        const stripeStaEnd: number[] = [];   
        const stripeSkewBegin: number[] = [];  
        const stripeSkewEnd: number[] = []; 
        const stripeSec: string[] = []; // Assuming it holds strings
        const stripeType: string[] = [];  
        const stripeColor: string[] = [];       
        const stripeSeg: number[] = [];
        const maxSecs5 = 10; // Max sections allowed    
        const stripeGeometry: (number | string)[][] = matrix(NSTRIPE, 3 * maxSecs5, 0); 
    

        for (let i = 0; i < NSTRIPE; i++) {
            const max = 11 + maxSecs5 * 3;    
            const values = VALUE(KL_show, "STRIPE", max); // Retrieve values once for efficiency
            
            // Assign values to the stripes with type checks and conversions
            stripeNum[i] = String(values[max * i]); // Ensure stripeNum is a string
            stripeAlign[i] = String(values[max * i + 1]); 
            stripeMain[i] = String(values[max * i + 2]);     
            stripeStaBegin[i] = Number(values[max * i + 3]) || 0; 
            stripeStaEnd[i] = Number(values[max * i + 4]) || 0; 
            stripeSkewBegin[i] = Number(values[max * i + 5]) * deg || 0; 
            stripeSkewEnd[i] = Number(values[max * i + 6]) * deg || 0; 
            stripeSec[i] = String(values[max * i + 7]); 
            stripeType[i] = String(values[max * i + 8]);    
            stripeColor[i] = String(values[max * i + 9]);                    
            stripeSeg[i] = Number(values[max * i + 10]) || 0; 
            
            for (let j = 0; j < stripeSeg[i]; j++) {
                stripeGeometry[i][3 * j] = values[i * max + 11 + 3 * j];  // Begin station for the segment
                stripeGeometry[i][3 * j + 1] = values[i * max + 11 + 3 * j + 1]; // End station for the segment           
                stripeGeometry[i][3 * j + 2] = values[i * max + 11 + 3 * j + 2]; // Offset to baseline for the segment                                          
            }
        }  
    
        setStripeNum(stripeNum)
        setStripeAlign(stripeAlign)
        setStripeMain(stripeMain)
        setStripeStaBegin(stripeStaBegin)
        setStripeStaEnd(stripeStaEnd)
        setStripeSkewBegin(stripeSkewBegin)
        setStripeSkewEnd(stripeSkewEnd)
        setStripeSec(stripeSec)
        setStripeType(stripeType)
        setStripeColor(stripeColor)
        setStripeSeg(stripeSeg)
        setStripeGeometry(stripeGeometry)



        // Define Barriers
        const barrierNum: string[] = []; // Assuming barrierNum should hold strings
        const barrierDeckLeft: string[] = [];  
        const barrierDeckLeftSec: string[] = [];       
        const barrierDeckRight: string[] = []; 
        const barrierDeckRightSec: string[] = [];               
        const barrierCount: number[] = [];  // Number of barriers in the cross-section
        const maxBar = 5; // Max number of barriers allowed    
        const barrierGeometry: (number | string)[][] = matrix(NBARRIER, 2 * maxBar, 0); 
    

        for (let i = 0; i < NBARRIER; i++) {
            const max = 6 + maxBar * 2;    
            const values = VALUE(KL_show, "BARRIER", max); // Retrieve values once for efficiency
            
            // Assign values to the barriers with type checks and conversions
            barrierNum[i] = String(values[max * i]); // Ensure barrierNum is a string
            barrierDeckLeft[i] = String(values[max * i + 1]); 
            barrierDeckLeftSec[i] = String(values[max * i + 2]); 
            barrierDeckRight[i] = String(values[max * i + 3]); 
            barrierDeckRightSec[i] = String(values[max * i + 4]); 
            barrierCount[i] = Number(values[max * i + 5]) || 0; 
            
            for (let j = 0; j < barrierCount[i]; j++) {
                barrierGeometry[i][2 * j] = Number(values[i * max + 6 + 2 * j]) || 0;  // Distance from baseline to 1st barrier 
                barrierGeometry[i][2 * j + 1] = String(values[i * max + 6 + 2 * j + 1]) || ''; // Barrier section number                                              
            }
        }


        setBarrierNum(barrierNum)
        setBarrierDeckLeft(barrierDeckLeft)
        setBarrierDeckLeftSec(barrierDeckLeftSec)
        setBarrierDeckRight(barrierDeckRight)
        setBarrierDeckRightSec(barrierDeckRightSec)
        setBarrierCount(barrierCount)
        setBarrierGeometry(barrierGeometry)

        // Deck
        const deckNum: string[] = []; // Assuming deckNum should hold strings
        const deckSec: number[] = [];  // Number of deck sections in the span    
        const maxSec1 = 10; // Max number of deck sections allowed    
        const deckGeometry: (number | string)[][] = matrix(NDECK, 4 * maxSec1, 0);                

        for (let i = 0; i < NDECK; i++) {   
            const max = 2 + maxSec1 * 4;             
            const values = VALUE(KL_show, "DECK", max); // Retrieve values once for efficiency
            
            // Assign values to the decks with type checks and conversions
            deckNum[i] = String(values[max * i]); // Ensure deckNum is a string
            deckSec[i] = Number(values[max * i + 1]) || 0; 
            
            for (let j = 0; j < deckSec[i]; j++) {
                deckGeometry[i][4 * j] = Number(values[i * max + 2 + 4 * j]) || 0;  // Begin ratio of distance from begin span to specific deck section
                deckGeometry[i][4 * j + 1] = Number(values[i * max + 2 + 4 * j + 1]) || 0;  // End ratio of distance from begin span to specific deck section          
                deckGeometry[i][4 * j + 2] = String(values[i * max + 2 + 4 * j + 2]);  // Offset to baseline          
                deckGeometry[i][4 * j + 3] = String(values[i * max + 2 + 4 * j + 3]); // Deck section number  
            }
        }  
        setDeckNum(deckNum)
        setDeckSec(deckSec)
        setDeckGeometry(deckGeometry)

        // Haunch 
        const haunchNum: string[] = []; // Assuming haunchNum should hold strings
        const haunchDepth: number[] = [];        
        const haunchWidth: number[] = [];        
        const haunchShape: string[] = []; // Assuming haunchShape should hold strings
        const haunchShapeDes: string[] = []; // Assuming haunchShapeDes should hold strings
    

        for (let i = 0; i < NHAUNCH; i++) {   
            const values = VALUE(KL_show, "HAUNCH", 5); // Retrieve values once for efficiency
            haunchNum[i] = String(values[5 * i]); // Ensure haunchNum is a string
            haunchDepth[i] = Number(values[5 * i + 1]) || 0; // Convert to number, default to 0
            haunchWidth[i] = Number(values[5 * i + 2]) || 0; // Convert to number, default to 0
            haunchShape[i] = String(values[5 * i + 3]); // Ensure haunchShape is a string
            haunchShapeDes[i] = String(values[5 * i + 4]); // Ensure haunchShapeDes is a string
        }  
    
        setHaunchNum(haunchNum)
        setHaunchDepth(haunchDepth)
        setHaunchWidth(haunchWidth)
        setHaunchShape(haunchShape)
        setHaunchShapeDes(haunchShapeDes)

        // Bearing
        const bearingNum: string[] = []; 
        const bearingType: string[] = [];        
        const bearingStandard: string[] = [];        
        const bearingLength: number[] = []; // Assuming length is a number
        const bearingWidth: number[] = []; // Assuming width is a number
        const bearingHeight: number[] = []; // Assuming height is a number
        const max4 = 6;        
    

        for (let i = 0; i < NBEARING; i++) {   
            const values = VALUE(KL_show, "BEARING", max4); // Retrieve values once for efficiency
            
            bearingNum[i] = String(values[max4 * i]); // Ensure bearingNum is a string
            bearingType[i] = String(values[max4 * i + 1]); // Ensure bearingType is a string
            bearingStandard[i] = String(values[max4 * i + 2]); // Ensure bearingStandard is a string
            bearingLength[i] = Number(values[max4 * i + 3]) || 0; // Convert to number, default to 0
            bearingWidth[i] = Number(values[max4 * i + 4]) || 0; // Convert to number, default to 0
            bearingHeight[i] = Number(values[max4 * i + 5]) || 0; // Convert to number, default to 0
        }      
    
        setBearingNum(bearingNum)
        setBearingType(bearingType)
        setBearingStandard(bearingStandard)
        setBearingLength(bearingLength)
        setBearingWidth(bearingWidth)
        setBearingHeight(bearingHeight)

        // Define Approach Slab
        const appSBNum: string[] = [];  // Assuming appSBNum should hold strings
        const appSBBridge: string[] = [];     
        const appSBLoc: string[] = [];
        const appSBBar: string[] = []; // Assuming this should also hold strings
        const appSBSkewBeg: number[] = []; 
        const appSBSkewEnd: number[] = [];          
        const appSBSlope: number[] = []; // Assuming slope might be a string
        const appSBLongOff: number[] = [];  // Assuming offsets are strings
        const appSBTranOff: number[] = [];  // Assuming offsets are strings        
        const appSBTopDist: number[] = [];  // Assuming distance might be a string
        const appSBSeg: number[] = [];
        const appSBLongSec: string[] = [];  // Assuming this is also a string
        const maxSecs6 = 20; // Max sections allowed    
        const appSBGeometry: (number | string)[][] = matrix(NAPPSLAB, 2 * maxSecs6, 0); 
    
        for (let i = 0; i < NAPPSLAB; i++) {
            const max = 12 + maxSecs6 * 2;    
            const values = VALUE(KL_show, "APPRSB", max); // Retrieve values once for efficiency
            
            appSBNum[i] = String(values[max * i]); 
            appSBBridge[i] = String(values[max * i + 1]); 
            appSBLoc[i] = String(values[max * i + 2]); 
            appSBBar[i] = String(values[max * i + 3]);      
            appSBSkewBeg[i] = Number(values[max * i + 4]) * deg || 0; 
            appSBSkewEnd[i] = Number(values[max * i + 5]) * deg || 0; 
            appSBSlope[i] = Number(values[max * i + 6]); 
            appSBLongOff[i] = Number(values[max * i + 7]); 
            appSBTranOff[i] = Number(values[max * i + 8]); 
            appSBTopDist[i] = Number(values[max * i + 9]);                            
            appSBSeg[i] = Number(values[max * i + 10]) || 0; 
            appSBLongSec[i] = String(values[max * i + 11]); 
            
            for (let j = 0; j < appSBSeg[i]; j++) {
                appSBGeometry[i][2 * j] = Number(values[i * max + 12 + 2 * j]) || 0;  // Distance to 1st section 
                appSBGeometry[i][2 * j + 1] = Number(values[i * max + 12 + 2 * j + 1]) || 0; // Section number                                              
            }
        }
    
        setAppSBNum(appSBNum)
        setAppSBBridge(appSBBridge)
        setAppSBLoc(appSBLoc)
        setAppSBBar(appSBBar)
        setAppSBSkewBeg(appSBSkewBeg)
        setAppSBSkewEnd(appSBSkewEnd)
        setAppSBSlope(appSBSlope)
        setAppSBLongOff(appSBLongOff)
        setAppSBTranOff(appSBTranOff)
        setAppSBTopDist(appSBTopDist)
        setAppSBSeg(appSBSeg)
        setAppSBLongSec(appSBLongSec)
        setAppSBGeometry(appSBGeometry)

        // Define Steel or Concrete Girders
        const girderNum: string[] = []; 
        const girderType: string[] = []; 
        const girderGroup: string[] = [];
        const girderToDeck: number[] = []; // Assuming this should be a number
        const girderSec: number[] = []; // Number of girder sections in the span    
        const maxSec2 = 30; // Max number of girder sections allowed    
        const girderGeometry: (number | string)[][] = matrix(NGIRDER, 4 * maxSec2, 0);                
    
        for (let i = 0; i < NGIRDER; i++) {   
            const max = 5 + maxSec2 * 4;             
            const values = VALUE(KL_show, "GIRDER", max); // Retrieve values once for efficiency
            
            girderNum[i] = String(values[max * i]); 
            girderType[i] = String(values[max * i + 1]); 
            girderGroup[i] = String(values[max * i + 2]); 
            girderToDeck[i] = Number(values[max * i + 3]) || 0;                
            girderSec[i] = Number(values[max * i + 4]) || 0; 
            
            for (let j = 0; j < girderSec[i]; j++) {
                girderGeometry[i][4 * j] = Number(values[i * max + 5 + 4 * j]) || 0;  // Begin ratio of distance from begin span to specific girder section
                girderGeometry[i][4 * j + 1] = Number(values[i * max + 5 + 4 * j + 1]) || 0;  // End ratio of distance from begin span to specific girder section          
                girderGeometry[i][4 * j + 2] = String(values[i * max + 5 + 4 * j + 2]);  // Offset to baseline          
                girderGeometry[i][4 * j + 3] = String(values[i * max + 5 + 4 * j + 3]); // Girder section number  
            }
        }  


        setGirderNum(girderNum)
        setGirderType(girderType)
        setGirderGroup(girderGroup)
        setGirderToDeck(girderToDeck)
        setGirderSec(girderSec)
        setGirderGeometry(girderGeometry)

        // Define prestressed beams
        const beamNum: string[] = []; 
        const beamType: string[] = []; // Beam Type
        const beamGroup: string[] = []; // Beam group
        const beamDistToDeck: number[] = []; // Distance from top of beam to top of deck
        const beamSec: string[] = []; // Beam Section (assuming this should be a string)
        const beamBLOffBeg: number[] = []; // Beam baseline offset at begin of the span
        const beamBLOffEnd: number[] = []; // Beam baseline offset at end of the span     
        const beamLongOffBeg: number[] = []; // Beam longitudinal offset at begin of the span
        const beamLongOffEnd: number[] = []; // Beam longitudinal offset at end of the span 
        const beamBrgOffBeg: number[] = []; // Beam bearing longitudinal offset at begin of the span
        const beamBrgOffEnd: number[] = []; // Beam bearing longitudinal offset at end of the span 
        const beamHuanchWidth: number[] = []; // Beam haunch width
        const beamHaunchThick: number[] = []; // Beam haunch thickness
        const max5 = 13;
    
        for (let i = 0; i < NBEAM; i++) {   
            const values = VALUE(KL_show, "BEAM", max5); // Retrieve values once for efficiency
            
            beamNum[i] = String(values[max5 * i]); // Ensure beamNum is a string
            beamType[i] = String(values[max5 * i + 1]); // Ensure beamType is a string
            beamGroup[i] = String(values[max5 * i + 2]); // Ensure beamGroup is a string
            beamDistToDeck[i] = Number(values[max5 * i + 3]) || 0; // Convert to number, default to 0
            beamSec[i] = String(values[max5 * i + 4]); // Ensure beamSec is a string
            beamBLOffBeg[i] = Number(values[max5 * i + 5]) || 0; // Convert to number, default to 0
            beamBLOffEnd[i] = Number(values[max5 * i + 6]) || 0; // Convert to number, default to 0
            beamLongOffBeg[i] = Number(values[max5 * i + 7]) || 0; // Convert to number, default to 0
            beamLongOffEnd[i] = Number(values[max5 * i + 8]) || 0; // Convert to number, default to 0
            beamBrgOffBeg[i] = Number(values[max5 * i + 9]) || 0; // Convert to number, default to 0
            beamBrgOffEnd[i] = Number(values[max5 * i + 10]) || 0; // Convert to number, default to 0
            beamHuanchWidth[i] = Number(values[max5 * i + 11]) || 0; // Convert to number, default to 0
            beamHaunchThick[i] = Number(values[max5 * i + 12]) || 0; // Convert to number, default to 0
        }      
    

        setBeamNum(beamNum)
        setBeamType(beamType)
        setBeamGroup(beamGroup)
        setBeamDistToDeck(beamDistToDeck)
        setBeamSec(beamSec)
        setBeamBLOffBeg(beamBLOffBeg)
        setBeamBLOffEnd(beamBLOffEnd)
        setBeamLongOffBeg(beamLongOffBeg)
        setBeamLongOffEnd(beamLongOffEnd)
        setBeamBrgOffBeg(beamBrgOffBeg)
        setBeamBrgOffEnd(beamBrgOffEnd)
        setBeamHuanchWidth(beamHuanchWidth)
        setBeamHaunchThick(beamHaunchThick)

        // Define span information for prestressed beams
        const spanNum: string[] = []; 
        const spanBridge: string[] = []; // Assuming this should be a string
        const spanUnit: string[] = []; // Assuming this should also be a string
        const spanGeometry: (number | string)[][] = matrix(NSPAN, 5, 0); 
        const max6 = 8;
    
        for (let i = 0; i < NSPAN; i++) {   
            const values = VALUE(KL_show, "SPAN", max6); // Retrieve values once for efficiency
    
            spanNum[i] = String(values[max6 * i]); // Ensure spanNum is a string
            spanBridge[i] = String(values[max6 * i + 1]);  
            spanUnit[i] = String(values[max6 * i + 2]);  
            spanGeometry[i][0] = Number(values[max6 * i + 3]) || 0; // Begin Station
            spanGeometry[i][1] = Number(values[max6 * i + 4]) || 0; // End Station
            spanGeometry[i][2] = Number(values[max6 * i + 5]) * deg || 0; // Begin Skew
            spanGeometry[i][3] = Number(values[max6 * i + 6]) * deg || 0; // End Skew
            spanGeometry[i][4] = String(values[max6 * i + 7]); // Beam group  
        }
    

        setSpanNum(spanNum)
        setSpanBridge(spanBridge)
        setSpanUnit(spanUnit)
        setSpanGeometry(spanGeometry)


         // Define substructure elements
         label = "SUBSTRUCTURE";
         KL_show = FIND_SECTION(KC_show,label);  

         var NPILE = NINPUT(FIND_SECTION(KC_show,label),"PILE"); 
         var NFOOTING = NINPUT(FIND_SECTION(KC_show,label),"FOOTING");
         var NCOL = NINPUT(FIND_SECTION(KC_show,label),"COL"); 
         var NCAP = NINPUT(FIND_SECTION(KC_show,label),"CAP");                      
         var NENDBENT = NINPUT(FIND_SECTION(KC_show,label),"ENDBENT");           
         var NPIER = NINPUT(FIND_SECTION(KC_show,label),"PIER"); 
         var NPEDESTAL = NINPUT(FIND_SECTION(KC_show,label),"PEDESTL"); 
            
         setNPILE(NPILE)
         setNFOOTING(NFOOTING)
         setNCOL(NCOL)
         setNCAP(NCAP)
         setNENDBENT(NENDBENT)
         setNPIER(NPIER)
         setNPEDESTAL(NPEDESTAL)


        // Pier Cap
        const capNum: string[] = []; 
        const capLength: number[] = []; 
        const capSlope: number[] = []; 
        const capPedestal: string[] = []; 
        const capLongOff: number[] = []; 
        const capTranOff: number[] = [];      
        const capTopDist: number[] = [];
        const capSeg: number[] = []; // Number of segments
        const capLongSec: string[] = []; // Assuming this is a string
        const maxSecs7 = 20; // Max sections allowed    
        const capGeometry: (number | string)[][] = matrix(NCAP, 2 * maxSecs7, 0); 
    
        for (let i = 0; i < NCAP; i++) {
            const max = 9 + maxSecs7 * 2;    
            const values = VALUE(KL_show, "CAP", max); // Retrieve values once for efficiency
            
            capNum[i] = String(values[max * i]); 
            capLength[i] = Number(values[max * i + 1]) || 0; 
            capSlope[i] = Number(values[max * i + 2]); 
            capPedestal[i] = String(values[max * i + 3]);      
            capLongOff[i] = Number(values[max * i + 4]); 
            capTranOff[i] = Number(values[max * i + 5]); 
            capTopDist[i] = Number(values[max * i + 6]);                            
            capSeg[i] = Number(values[max * i + 7]) || 0; 
            capLongSec[i] = String(values[max * i + 8]); 
            
            for (let j = 0; j < capSeg[i]; j++) {
                capGeometry[i][2 * j] = Number(values[i * max + 9 + 2 * j]) || 0;  // Distance to 1st section 
                capGeometry[i][2 * j + 1] = Number(values[i * max + 9 + 2 * j + 1]) || 0; // Section number                                              
            }
        }
    

        setCapNum(capNum)
        setCapLength(capLength)
        setCapSlope(capSlope)
        setCapPedestal(capPedestal)
        setCapLongOff(capLongOff)
        setCapTranOff(capTranOff)
        setCapTopDist(capTopDist)
        setCapSeg(capSeg)
        setCapLongSec(capLongSec)
        setCapGeometry(capGeometry)



        // Pier Columns
        const columnNum: string[] = []; // Assuming columnNum should hold strings
        const columnLongOff: number[] = []; // Assuming longitudinal offset is a number
        const columnTranOff: number[] = []; // Assuming transverse offset is a number
        const columnTopDist: number[] = []; // Assuming top distance might be a string
        const columnSeg: number[] = []; // Number of segments
        const maxSecs8 = 20; // Max sections allowed    
        const colGeometry: (number | string)[][] = matrix(NCOL, 2 * maxSecs8, 0); 
        const max7 = 5 + maxSecs8 * 2;  
    
        for (let i = 0; i < NCOL; i++) {  
            const values = VALUE(KL_show, "COL", max7); // Retrieve values once for efficiency
            
            columnNum[i] = String(values[max7 * i]); // Ensure columnNum is a string
            columnLongOff[i] = Number(values[max7 * i + 1]) || 0; // Convert to number, default to 0
            columnTranOff[i] = Number(values[max7 * i + 2]) || 0; // Convert to number, default to 0
            columnTopDist[i] = Number(values[max7 * i + 3]);                
            columnSeg[i] = Number(values[max7 * i + 4]) || 0; // Convert to number, default to 0
            
            for (let j = 0; j < columnSeg[i]; j++) {
                colGeometry[i][2 * j] = Number(values[i * max7 + 5 + 2 * j]) || 0;  // Distance to 1st section
                colGeometry[i][2 * j + 1] = String(values[i * max7 + 5 + 2 * j + 1]) || 0; // Section number                                              
            }
        }
       

        setColumnNum(columnNum)
        setColumnLongOff(columnLongOff)
        setColumnTranOff(columnTranOff)
        setColumnTopDist(columnTopDist)
        setColumnSeg(columnSeg)
        setColGeometry(colGeometry)


        // Pier Footing
        const footingNum: string[] = [];  
        const footingDirection: string[] = []; // Assuming this should hold strings
        const footingGeometry: (string | number)[][] = matrix(NFOOTING, 4, 0);  
    
        for (let i = 0; i < NFOOTING; i++) {   
            const values = VALUE(KL_show, "FOOTING", 6); // Retrieve values once for efficiency
            
            footingNum[i] = String(values[6 * i]); // Ensure footingNum is a string
            footingDirection[i] = String(values[6 * i + 1]);  // Input either "LONG" or "PLAN"      
            footingGeometry[i][0] = String(values[6 * i + 2]); // Footing plane section
            footingGeometry[i][1] = Number(values[6 * i + 3]) || 0; // Longitudinal offset
            footingGeometry[i][2] = Number(values[6 * i + 4]) || 0; // Transverse offset
            footingGeometry[i][3] = Number(values[6 * i + 5]) || 0; // Footing skew
        }
    

        setFootingNum(footingNum)
        setFootingDirection(footingDirection)
        setFootingGeometry(footingGeometry)


        // Pile Foundation
        const pileNum: string[] = []; 
        const pileFooting: string[] = []; 
        const pileLongOff: number[] = [];
        const pileTranOff: number[] = [];  
        const pileEmbed: number[] = []; // Assuming this might be a string
        const pileSection: string[] = []; // Assuming this might also be a string
        const pileGeometry: (number | string)[][] = matrix(NPILE, 5, 0);  
        const maxNumPile = 100;
        const max8 = 11 + 2 * maxNumPile;
    
        for (let i = 0; i < NPILE; i++) {   
            const values = VALUE(KL_show, "PILE", max8); // Retrieve values once for efficiency
            
            pileNum[i] = String(values[max8 * i]); 
            pileFooting[i] = String(values[max8 * i + 1]); 
            pileLongOff[i] = Number(values[max8 * i + 2]) || 0; 
            pileTranOff[i] = Number(values[max8 * i + 3]) || 0;     
            pileEmbed[i] = Number(values[max8 * i + 4]); 
            pileSection[i] = String(values[max8 * i + 5]); 
            pileGeometry[i][0] = String(values[max8 * i + 6]); // Pile layout type
    
            if (pileGeometry[i][0] == '1') { // Adjusted for string comparison
                pileGeometry[i][1] = Number(values[max8 * i + 7]); // Longitudinal rows
                pileGeometry[i][2] = Number(values[max8 * i + 8]); // Transverse rows
                pileGeometry[i][3] = Number(values[max8 * i + 9]); // Longitudinal row spacing
                pileGeometry[i][4] = Number(values[max8 * i + 10]); // Transverse row spacing
            } else {
                pileGeometry[i][1] = Number(values[max8 * i + 7]); // Number of piles
                for (let j = 0; j < Number(pileGeometry[i][1]); j++) {
                    pileGeometry[i][2 + 2 * j] = Number(values[i * max8 + 8 + 2 * j]) || 0;  // Distance to 1st section 
                    pileGeometry[i][2 + 2 * j + 1] = Number(values[i * max8 + 8 + 2 * j + 1]) || 0; // Section number  
                }
            }
        }
    
        setPileNum(pileNum)
        setPileFooting(pileFooting)
        setPileLongOff(pileLongOff)
        setPileTranOff(pileTranOff)
        setPileEmbed(pileEmbed)
        setPileSection(pileSection)
        setPileGeometry(pileGeometry)

        // End Bents
        const endbentNum: string[] = []; 
        const endbentBridge: string[] = []; 
        const endbentLoc: string[] = []; 
        const endbentSkew: number[] = [];      
        const endbentBW: string[] = [];
        const endbentCheekH: number[] = []; // Cheekwall height
        const endbentCheekW: number[] = []; // Cheekwall width
        const endbentCheekT: number[] = []; // Cheekwall thickness     
        const endbentCap: string[] = []; 
        const endbentPile: string[] = [];
        const endbentPileLength: number[] = []; 
        const max11 = 11;                                
    
        for (let i = 0; i < NENDBENT; i++) {   
            endbentNum[i] = String(VALUE(KL_show, "ENDBENT", max11)[max11 * i]); 
            endbentBridge[i] = String(VALUE(KL_show, "ENDBENT", max11)[max11 * i + 1]); 
            endbentLoc[i] = String(VALUE(KL_show, "ENDBENT", max11)[max11 * i + 2]); // Location
            endbentSkew[i] = Number(VALUE(KL_show, "ENDBENT", max11)[max11 * i + 3]) * deg; // Skew     
            endbentBW[i] = String(VALUE(KL_show, "ENDBENT", max11)[max11 * i + 4]); // Backwall
            endbentCheekH[i] = Number(VALUE(KL_show, "ENDBENT", max11)[max11 * i + 5]); // Cheekwall Height
            endbentCheekW[i] = Number(VALUE(KL_show, "ENDBENT", max11)[max11 * i + 6]); // Cheekwall Width 
            endbentCheekT[i] = Number(VALUE(KL_show, "ENDBENT", max11)[max11 * i + 7]); // Cheekwall Thickness            
            endbentCap[i] = String(VALUE(KL_show, "ENDBENT", max11)[max11 * i + 8]); // Cap 
            endbentPile[i] = String(VALUE(KL_show, "ENDBENT", max11)[max11 * i + 9]); // End bent pile
            endbentPileLength[i] = Number(VALUE(KL_show, "ENDBENT", max11)[max11 * i + 10]); // Pile length                       
        } 
    

        setEndbentNum(endbentNum)
        setEndbentBridge(endbentBridge)
        setEndbentLoc(endbentLoc)
        setEndbentSkew(endbentSkew)
        setEndbentBW(endbentBW)
        setEndbentCheekH(endbentCheekH)
        setEndbentCheekW(endbentCheekW)
        setEndbentCheekT(endbentCheekT)
        setEndbentCap(endbentCap)
        setEndbentPile(endbentPile)
        setEndbentPileLength(endbentPileLength)

        // Piers
        const pierNum: string[] = []; 
        const pierBridge: string[] = []; 
        const pierUnitLeft: string[] = [];
        const pierUnitRight: string[] = [];
        const colmax = 10; // Assume 10 columns maximum per pier       
        const pierGeometry: (string | number)[][] = matrix(NPIER, 4 + colmax * 5, 0); 
        const max9 = 8 + colmax * 5;   
    
        for (let i = 0; i < NPIER; i++) {   
            const values = VALUE(KL_show, "PIER", max9); // Retrieve values once for efficiency
            
            pierNum[i] = String(values[max9 * i]); 
            pierBridge[i] = String(values[max9 * i + 1]); 
            pierUnitLeft[i] = String(values[max9 * i + 2]); 
            pierUnitRight[i] = String(values[max9 * i + 3]);           
            pierGeometry[i][0] = String(values[max9 * i + 4]); // Start station
            pierGeometry[i][1] = Number(values[max9 * i + 5]) || 0; // Skew angle
            pierGeometry[i][2] = String(values[max9 * i + 6]); // Cap type
            pierGeometry[i][3] = Number(values[max9 * i + 7]) || 0; // Number of Columns (each column has its own footing and foundation unless no input)
    
            for (let j = 0; j < Number(pierGeometry[i][3]); j++) {
                pierGeometry[i][4 + 5 * j] = String(values[i * max9 + 8 + 5 * j]);  // Column type
                pierGeometry[i][4 + 5 * j + 1] = String(values[i * max9 + 8 + 5 * j + 1]);  // Footing type
                pierGeometry[i][4 + 5 * j + 2] = Number(values[i * max9 + 8 + 5 * j + 2]) || 0;  // Top of footing elevation 
                pierGeometry[i][4 + 5 * j + 3] = String(values[i * max9 + 8 + 5 * j + 3]);  // Foundation type
                pierGeometry[i][4 + 5 * j + 4] = Number(values[i * max9 + 8 + 5 * j + 4]) || 0;  // Foundation length                                             
            }
        } 
    

        setPierNum(pierNum)
        setPierBridge(pierBridge)
        setPierUnitLeft(pierUnitLeft)
        setPierUnitRight(pierUnitRight)
        setPierGeometry(pierGeometry)


        // Pedestals on each pier cap
        const pedestalNum: string[] = []; 
        const pedestalLength: number[] = [];
        const pedestalWidth: number[] = [];  
        const pedestalBrg1: number[] = []; // Number of pedestal in the 1st line of bearings         
        const pedestalBrg2: number[] = []; // Number of pedestal in the 2nd line of bearings  
        const pedestalLongOff1: number[] = []; // Longitudinal offset for the 1st line of bearings         
        const pedestalLongOff2: number[] = []; // Longitudinal offset for the 2nd line of bearings        
        const pedmax = 40; // Assume 40 pedestal maximum per pier cap       
        const pedestalGeometry1: (number | string)[][] = matrix(NPEDESTAL, pedmax, 0); 
        const pedestalGeometry2: (number | string)[][] = matrix(NPEDESTAL, pedmax, 0); 
        const max10 = 7 + pedmax;   
    
        for (let i = 0; i < NPEDESTAL; i++) {   
            const values = VALUE(KL_show, "PEDESTL", max10); // Retrieve values once for efficiency
            
            pedestalNum[i] = String(values[max10 * i]); 
            pedestalLength[i] = Number(values[max10 * i + 1]) || 0; 
            pedestalWidth[i] = Number(values[max10 * i + 2]) || 0; 
            pedestalBrg1[i] = Number(values[max10 * i + 3]) || 0;
            pedestalBrg2[i] = Number(values[max10 * i + 4]) || 0;
            pedestalLongOff1[i] = Number(values[max10 * i + 5]) || 0;
            pedestalLongOff2[i] = Number(values[max10 * i + 6]) || 0;
    
            for (let j = 0; j < pedestalBrg1[i]; j++) {
                pedestalGeometry1[i][3 * j] = Number(values[i * max10 + 7 + 3 * j]) || 0;  // Transverse offset
                pedestalGeometry1[i][3 * j + 1] = Number(values[i * max10 + 7 + 3 * j + 1]) || 0;  // Depth to top of the cap
                pedestalGeometry1[i][3 * j + 2] = String(values[i * max10 + 7 + 3 * j + 2]);  // Bearing type                  
            } 
            for (let j = 0; j < pedestalBrg2[i]; j++) {
                pedestalGeometry2[i][3 * j] = Number(values[i * max10 + 7 + 3 * pedestalBrg1[i] + 3 * j]) || 0;  // Transverse offset
                pedestalGeometry2[i][3 * j + 1] = Number(values[i * max10 + 7 + 3 * pedestalBrg1[i] + 3 * j + 1]) || 0;  // Depth to top of the cap          
                pedestalGeometry2[i][3 * j + 2] = String(values[i * max10 + 7 + 3 * pedestalBrg1[i] + 3 * j + 2]);  // Bearing type 
            } 
        }

        setPedestalNum(pedestalNum)
        setPedestalLength(pedestalLength)
        setPedestalWidth(pedestalWidth)
        setPedestalBrg1(pedestalBrg1)
        setPedestalBrg2(pedestalBrg2)
        setPedestalLongOff1(pedestalLongOff1)
        setPedestalLongOff2(pedestalLongOff2)
        setPedestalGeometry1(pedestalGeometry1)
        setPedestalGeometry2(pedestalGeometry2)




        // Define Structures Units
        label = "STRUCTURAL_UNIT";
        KL_show = FIND_SECTION(KC_show, label);     

        const NUNIT = NINPUT(FIND_SECTION(KC_show, label), "UNIT");   
        setNUNIT(NUNIT)

        // Structural Units
        const unitNum: string[] = []; 
        const unitBridge: string[] = [];        
        const unitGeometry: (number | string)[][] = matrix(NUNIT, 12, 0);  
        const max12 = 13;
    
        for (let i = 0; i < NUNIT; i++) {   
            unitNum[i] = String(VALUE(KL_show, "UNIT", max12)[max12 * i]); 
            unitBridge[i] = String(VALUE(KL_show, "UNIT", max12)[max12 * i + 1]); 
            unitGeometry[i][0] = Number(VALUE(KL_show, "UNIT", max12)[max12 * i + 2]); // Start station
            unitGeometry[i][1] = Number(VALUE(KL_show, "UNIT", max12)[max12 * i + 3]); // End station
            unitGeometry[i][2] = Number(VALUE(KL_show, "UNIT", max12)[max12 * i + 4]); // Left expansion joint width
            unitGeometry[i][3] = Number(VALUE(KL_show, "UNIT", max12)[max12 * i + 5]); // Right expansion joint width
            unitGeometry[i][4] = Number(VALUE(KL_show, "UNIT", max12)[max12 * i + 6]) * deg; // Left skew
            unitGeometry[i][5] = Number(VALUE(KL_show, "UNIT", max12)[max12 * i + 7]) * deg; // Right skew
            unitGeometry[i][6] = String(VALUE(KL_show, "UNIT", max12)[max12 * i + 8]); // Barrier       
            unitGeometry[i][7] = String(VALUE(KL_show, "UNIT", max12)[max12 * i + 9]); // Deck section   
            unitGeometry[i][8] = String(VALUE(KL_show, "UNIT", max12)[max12 * i + 10]); // Span Type
            unitGeometry[i][9] = String(VALUE(KL_show, "UNIT", max12)[max12 * i + 11]); // Girder    
            unitGeometry[i][10] = String(VALUE(KL_show, "UNIT", max12)[max12 * i + 12]); // Haunch                                                  
        }
    
        setUnitNum(unitNum)
        setUnitBridge(unitBridge)
        setUnitGeometry(unitGeometry)


         // Define Sections
         label = "SECTION";
         KL_show = FIND_SECTION(KC_show,label);  

         var NSTRIPESEC = NINPUT(FIND_SECTION(KC_show,label),"STRIPSEC");          
         var NROADSEC = NINPUT(FIND_SECTION(KC_show,label),"ROADSEC");  
         var NMSESEC = NINPUT(FIND_SECTION(KC_show,label),"MSESEC");            
         var NAPPSBSEC = NINPUT(FIND_SECTION(KC_show,label),"APPSBSEC");    
         var NDECKSEC = NINPUT(FIND_SECTION(KC_show,label),"DECKSEC");      
         var NGIRSEC = NINPUT(FIND_SECTION(KC_show,label),"GIRSEC");  
         var NBARRSEC = NINPUT(FIND_SECTION(KC_show,label),"BARRSEC");      
         var NCAPSEC = NINPUT(FIND_SECTION(KC_show,label),"CAPSEC");  
         var NCOLSEC = NINPUT(FIND_SECTION(KC_show,label),"COLSEC");  
         var NFOOTSEC = NINPUT(FIND_SECTION(KC_show,label),"FOOTSEC");                           
         var NPILESEC = NINPUT(FIND_SECTION(KC_show,label),"PILESEC");  

         setNSTRIPESEC(NSTRIPESEC)
         setNROADSEC(NROADSEC)
         setNMSESEC(NMSESEC)
         setNAPPSBSEC(NAPPSBSEC)
         setNDECKSEC(NDECKSEC)
         setNGIRSEC(NGIRSEC)
         setNBARRSEC(NBARRSEC)
         setNCAPSEC(NCAPSEC)
         setNCOLSEC(NCOLSEC)
         setNFOOTSEC(NFOOTSEC)
         setNPILESEC(NPILESEC)

        // Stripe sections
        const stripesecNum: string[] = []; 
        const stripesecDepth: number[] = [];        
        const stripesecWidth: number[] = [];        
    
        for (let i = 0; i < NSTRIPESEC; i++) {   
            const values = VALUE(KL_show, "STRIPSEC", 3); // Retrieve values once for efficiency
            
            stripesecNum[i] = String(values[3 * i]); // Ensure stripesecNum is a string
            stripesecDepth[i] = Number(values[3 * i + 1]) || 0; // Convert to number, default to 0
            stripesecWidth[i] = Number(values[3 * i + 2]) || 0; // Convert to number, default to 0
        } 

        setStripesecNum(stripesecNum)
        setStripesecDepth(stripesecDepth)
        setStripesecWidth(stripesecWidth)

        // Roadway sections
        const roadsecNum: string[] = []; 
        const roadsecDepth: number[] = [];        
        const roadsecWidth: number[] = [];        
        const roadsecShape: string[] = []; // Assuming shape might be a string
        const roadsecShapeDes: string[] = []; // Assuming shape description might be a string
    
        for (let i = 0; i < NROADSEC; i++) {   
            const values = VALUE(KL_show, "ROADSEC", 5); // Retrieve values once for efficiency
    
            roadsecNum[i] = String(values[5 * i]); 
            roadsecDepth[i] = Number(values[5 * i + 1]) || 0; 
            roadsecWidth[i] = Number(values[5 * i + 2]) || 0; 
            roadsecShape[i] = String(values[5 * i + 3]); 
            roadsecShapeDes[i] = String(values[5 * i + 4]);      
        } 
    
        setRoadsecNum(roadsecNum)
        setRoadsecDepth(roadsecDepth)
        setRoadsecWidth(roadsecWidth)
        setRoadsecShape(roadsecShape)
        setRoadsecShapeDes(roadsecShapeDes)

        // MSE Wall sections
        const msesecNum: string[] = []; 
        const msesecCapWidth: number[] = [];            
        const msesecCapDepth: number[] = [];        
        const msesecWallEmbed: number[] = []; 
        const msesecWallThick: number[] = []; 
        const msesecLevingPadWidth: number[] = []; 
        const msesecLevingPadThick: number[] = [];   
        const msesecLevingPadCover: number[] = []; // Assuming this might be a string
    
        const max13 = 8;     
        for (let i = 0; i < NMSESEC; i++) {   
            const values = VALUE(KL_show, "MSESEC", max13); // Retrieve values once for efficiency
            
            msesecNum[i] = String(values[max13 * i]); 
            msesecCapWidth[i] = Number(values[max13 * i + 1]) || 0;     
            msesecCapDepth[i] = Number(values[max13 * i + 2]) || 0; 
            msesecWallEmbed[i] = Number(values[max13 * i + 3]) || 0;  
            msesecWallThick[i] = Number(values[max13 * i + 4]) || 0.5;          
            msesecLevingPadWidth[i] = Number(values[max13 * i + 5]) || 0;  
            msesecLevingPadThick[i] = Number(values[max13 * i + 6]) || 0;
            msesecLevingPadCover[i] = Number(values[max13 * i + 7]);           
        } 
    

        setMsesecNum(msesecNum)
        setMsesecCapWidth(msesecCapWidth)
        setMsesecCapDepth(msesecCapDepth)
        setMsesecWallEmbed(msesecWallEmbed)
        setMsesecWallThick(msesecWallThick)
        setMsesecLevingPadWidth(msesecLevingPadWidth)
        setMsesecLevingPadThick(msesecLevingPadThick)
        setMsesecLevingPadCover(msesecLevingPadCover)

        // Approach slab sections
        const appsbsecNum: string[] = []; 
        const appsbsecDepth: number[] = [];        
        const appsbsecWidth: number[] = []; 
        const appsbsecHaunch: number[] = []; // Assuming this might be a string
        const appsbsecShape: string[] = []; 
        const appsbsecShapeDes: string[] = [];   
    
        const max14 = 6;   
        for (let i = 0; i < NAPPSBSEC; i++) {   
            const values = VALUE(KL_show, "APPSBSEC", max14); // Retrieve values once for efficiency
            
            appsbsecNum[i] = String(values[max14 * i]); 
            appsbsecDepth[i] = Number(values[max14 * i + 1]) || 0; 
            appsbsecWidth[i] = Number(values[max14 * i + 2]) || 0; 
            appsbsecHaunch[i] = Number(values[max14 * i + 3]);
            appsbsecShape[i] = String(values[max14 * i + 4]); 
            appsbsecShapeDes[i] = String(values[max14 * i + 5]);      
        } 

        setAppsbsecNum(appsbsecNum)
        setAppsbsecDepth(appsbsecDepth)
        setAppsbsecWidth(appsbsecWidth)
        setAppsbsecHaunch(appsbsecHaunch)
        setAppsbsecShape(appsbsecShape)
        setAppsbsecShapeDes(appsbsecShapeDes)

        // Deck sections
        const decksecNum: string[] = []; 
        const decksecDepth: number[] = [];        
        const decksecWidth: number[] = [];        
        const decksecShape: string[] = []; // Assuming shape might be a string
        const decksecShapeDes: string[] = []; // Assuming shape description might be a string
    
        for (let i = 0; i < NDECKSEC; i++) {   
            const values = VALUE(KL_show, "DECKSEC", 5); // Retrieve values once for efficiency
            
            decksecNum[i] = String(values[5 * i]); 
            decksecDepth[i] = Number(values[5 * i + 1]) || 0; 
            decksecWidth[i] = Number(values[5 * i + 2]) || 0; 
            decksecShape[i] = String(values[5 * i + 3]); 
            decksecShapeDes[i] = String(values[5 * i + 4]);      
        } 


        setDecksecNum(decksecNum)
        setDecksecDepth(decksecDepth)
        setDecksecWidth(decksecWidth)
        setDecksecShape(decksecShape)
        setDecksecShapeDes(decksecShapeDes)

        // Girder Sections
        const girdersecNum: string[] = []; 
        const girdersecDepth: number[] = [];        
        const girdersecWidth: number[] = [];        
        const girdersecShape: string[] = []; // Assuming shape might be a string
        const girdersecShapeDes: string[] = []; // Assuming shape description might be a string
    
        for (let i = 0; i < NGIRSEC; i++) {   
            const values = VALUE(KL_show, "GIRSEC", 5); // Retrieve values once for efficiency
            
            girdersecNum[i] = String(values[5 * i]); 
            girdersecDepth[i] = Number(values[5 * i + 1]) || 0; 
            girdersecWidth[i] = Number(values[5 * i + 2]) || 0; 
            girdersecShape[i] = String(values[5 * i + 3]); 
            girdersecShapeDes[i] = String(values[5 * i + 4]);      
        }      

        setGirdersecNum(girdersecNum)
        setGirdersecDepth(girdersecDepth)
        setGirdersecWidth(girdersecWidth)
        setGirdersecShape(girdersecShape)
        setGirdersecShapeDes(girdersecShapeDes)

        // Barrier Sections
        const barriersecNum: string[] = []; 
        const barriersecDepth: number[] = [];        
        const barriersecWidth: number[] = []; 
        const barriersecMirror: string[] = []; // Assuming this might be a string
        const barriersecShape: string[] = []; 
        const barriersecShapeDes: string[] = []; 
    
        const max15 = 6;    
        for (let i = 0; i < NBARRSEC; i++) {   
            const values = VALUE(KL_show, "BARRSEC", max15); // Retrieve values once for efficiency
            
            barriersecNum[i] = String(values[max15 * i]); 
            barriersecDepth[i] = Number(values[max15 * i + 1]) || 0; 
            barriersecWidth[i] = Number(values[max15 * i + 2]) || 0; 
            barriersecMirror[i] = String(values[max15 * i + 3]);      
            barriersecShape[i] = String(values[max15 * i + 4]); 
            barriersecShapeDes[i] = String(values[max15 * i + 5]);      
        }      
    
        setBarriersecNum(barriersecNum)
        setBarriersecDepth(barriersecDepth)
        setBarriersecWidth(barriersecWidth)
        setBarriersecMirror(barriersecMirror)
        setBarriersecShape(barriersecShape)
        setBarriersecShapeDes(barriersecShapeDes)


        // Pier Cap Sections (used for End Bent as well)
        const piercapsecNum: string[] = []; 
        const piercapsecDepth: number[] = [];        
        const piercapsecWidth: number[] = [];  
        const piercapsecThick: number[] = [];             
        const piercapsecShape: string[] = []; // Assuming shape might be a string
        const piercapsecShapeDes: string[] = []; // Assuming shape description might be a string
    
        for (let i = 0; i < NCAPSEC; i++) {   
            const values = VALUE(KL_show, "CAPSEC", 6); // Retrieve values once for efficiency
            
            piercapsecNum[i] = String(values[6 * i]); 
            piercapsecWidth[i] = Number(values[6 * i + 1]) || 0; 
            piercapsecDepth[i] = Number(values[6 * i + 2]) || 0; 
            piercapsecThick[i] = Number(values[6 * i + 3]) || 0;     
            piercapsecShape[i] = String(values[6 * i + 4]); 
            piercapsecShapeDes[i] = String(values[6 * i + 5]);      
        }      
    

        setPiercapsecNum(piercapsecNum)
        setPiercapsecWidth(piercapsecWidth)
        setPiercapsecDepth(piercapsecDepth)
        setPiercapsecThick(piercapsecThick)
        setPiercapsecShape(piercapsecShape)
        setPiercapsecShapeDes(piercapsecShapeDes)

        // Pier Column Sections
        const piercolsecNum: string[] = []; 
        const piercolsecDepth: number[] = [];        
        const piercolsecWidth: number[] = [];        
        const piercolsecShape: string[] = []; // Assuming shape might be a string
        const piercolsecShapeDes: string[] = []; // Assuming shape description might be a string
    
        for (let i = 0; i < NCOLSEC; i++) {   
            const values = VALUE(KL_show, "COLSEC", 5); // Retrieve values once for efficiency
            
            piercolsecNum[i] = String(values[5 * i]); 
            piercolsecDepth[i] = Number(values[5 * i + 1]) || 0; 
            piercolsecWidth[i] = Number(values[5 * i + 2]) || 0; 
            piercolsecShape[i] = String(values[5 * i + 3]); 
            piercolsecShapeDes[i] = String(values[5 * i + 4]);      
        }      


        setPiercolsecNum(piercolsecNum)
        setPiercolsecDepth(piercolsecDepth)
        setPiercolsecWidth(piercolsecWidth)
        setPiercolsecShape(piercolsecShape)
        setPiercolsecShapeDes(piercolsecShapeDes)

        // Pier Footing Sections
        const pierfootsecNum: string[] = []; 
        const pierfootsecWidth: number[] = [];        
        const pierfootsecLength: number[] = []; 
        const pierfootsecDepth: number[] = [];             
        const pierfootsecShape: string[] = []; // Assuming shape might be a string
        const pierfootsecShapeDes: string[] = []; // Assuming shape description might be a string
    
        for (let i = 0; i < NFOOTSEC; i++) {   
            const values = VALUE(KL_show, "FOOTSEC", 6); // Retrieve values once for efficiency
            
            pierfootsecNum[i] = String(values[6 * i]); 
            pierfootsecWidth[i] = Number(values[6 * i + 1]) || 0; 
            pierfootsecLength[i] = Number(values[6 * i + 2]) || 0; 
            pierfootsecDepth[i] = Number(values[6 * i + 3]) || 0;      
            pierfootsecShape[i] = String(values[6 * i + 4]); 
            pierfootsecShapeDes[i] = String(values[6 * i + 5]);      
        }      

        setPierfootsecNum(pierfootsecNum)
        setPierfootsecWidth(pierfootsecWidth)
        setPierfootsecLength(pierfootsecLength)
        setPierfootsecDepth(pierfootsecDepth)
        setPierfootsecShape(pierfootsecShape)
        setPierfootsecShapeDes(pierfootsecShapeDes)

        // Pile Sections
        const pilesecNum: string[] = []; 
        const pilesecDepth: number[] = [];        
        const pilesecWidth: number[] = [];        
        const pilesecShape: string[] = []; // Assuming shape might be a string
        const pilesecShapeDes: string[] = []; // Assuming shape description might be a string
    
        for (let i = 0; i < NPILESEC; i++) {   
            const values = VALUE(KL_show, "PILESEC", 5); // Retrieve values once for efficiency
            
            pilesecNum[i] = String(values[5 * i]); 
            pilesecDepth[i] = Number(values[5 * i + 1]) || 0; 
            pilesecWidth[i] = Number(values[5 * i + 2]) || 0; 
            pilesecShape[i] = String(values[5 * i + 3]); 
            pilesecShapeDes[i] = String(values[5 * i + 4]);      
        }      

        setPilesecNum(pilesecNum)
        setPilesecDepth(pilesecDepth)
        setPilesecWidth(pilesecWidth)
        setPilesecShape(pilesecShape)
        setPilesecShapeDes(pilesecShapeDes)
            

        // Read Data for Geometry Inputs
        // Shapes
        label = "SHAPE";
        KL_show = FIND_SECTION(KC_show,label); 

        // Find number of inputs from each section of the input file
        var N_RECTAN = NINPUT(FIND_SECTION(KC_show,label),"RECTAN");   
        var N_CIRCLE = NINPUT(FIND_SECTION(KC_show,label),"CIRCLE");           
        var N_TRAP = NINPUT(FIND_SECTION(KC_show,label),"TRAP"); 
        var N_TRIAN = NINPUT(FIND_SECTION(KC_show,label),"TRIAN");            
        var N_QUAD = NINPUT(FIND_SECTION(KC_show,label),"QUAD");     
        var N_ISHAPE = NINPUT(FIND_SECTION(KC_show,label),"ISHAPE");
        var N_BOX = NINPUT(FIND_SECTION(KC_show,label),"BOX");
        var N_SLOPE = NINPUT(FIND_SECTION(KC_show,label),"SLOPE");     
        var N_CUSTOM = NINPUT(FIND_SECTION(KC_show,label),"CUSTOM");
           

        setN_RECTAN(N_RECTAN)
        setN_CIRCLE(N_CIRCLE)
        setN_TRAP(N_TRAP)
        setN_TRIAN(N_TRIAN)
        setN_QUAD(N_QUAD)
        setN_ISHAPE(N_ISHAPE)
        setN_BOX(N_BOX)
        setN_SLOPE(N_SLOPE)
        setN_CUSTOM(N_CUSTOM)


       // Input rectangular shape information  
       const RECTAN_NAME: string[] = []; // Shape name      
       const RECTAN_WIDTH: number[] = []; // Width
       const RECTAN_DEPTH: number[] = []; // Depth 
       const RECTAN_REF: string[] = []; // Reference location
       const RECTAN_REF_Y: number[] = []; // Y coordinate of the insert point with (0,0) at the reference  
       const RECTAN_REF_Z: number[] = []; // Z coordinate of the insert point with (0,0) at the reference  
   
       for (let i = 0; i < N_RECTAN; i++) {
           const values = VALUE(KL_show, "RECTAN", 6); // Retrieve values once for efficiency
           
           RECTAN_NAME[i] = String(values[6 * i]); // Ensure RECTAN_NAME is a string
   
           // Check if the values are numbers before performing arithmetic operations
           const widthValue = values[6 * i + 1];
           const depthValue = values[6 * i + 2];
           const refYValue = values[6 * i + 4];
           const refZValue = values[6 * i + 5];
   
           RECTAN_WIDTH[i] = typeof widthValue === 'number' ? 
               Number((widthValue * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           RECTAN_DEPTH[i] = typeof depthValue === 'number' ? 
               Number((depthValue * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           RECTAN_REF[i] = String(values[6 * i + 3]); // Ensure RECTAN_REF is a string
   
           RECTAN_REF_Y[i] = typeof refYValue === 'number' ? 
               Number((refYValue * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           RECTAN_REF_Z[i] = typeof refZValue === 'number' ? 
               Number((refZValue * 100).toFixed(2)) : 0; // Default to 0 if not a number
       }
   
       setRECTAN_NAME(RECTAN_NAME)
       setRECTAN_WIDTH(RECTAN_WIDTH)
       setRECTAN_DEPTH(RECTAN_DEPTH)
       setRECTAN_REF(RECTAN_REF)
       setRECTAN_REF_Y(RECTAN_REF_Y)
       setRECTAN_REF_Z(RECTAN_REF_Z)

       // Generate rectangular shape data points
       let shapeRECTAN: BABYLON.Vector3[][] = []; // Declare as a 2D array of BABYLON.Vector3
       for (let i = 0; i < N_RECTAN; i++) {
           let xc0 = 0; 
           let yc0 = 0; 
           let maxZ = RECTAN_WIDTH[i] / 2; 
           let minZ = -RECTAN_WIDTH[i] / 2; 
           let maxY = RECTAN_DEPTH[i] / 2; 
           let minY = -RECTAN_DEPTH[i] / 2;     
           let xc: number, yc: number; // Declare variables for xc and yc
   
           switch (RECTAN_REF[i]) {
               case 'CG':   
                   yc = yc0 + RECTAN_REF_Y[i];
                   xc = xc0 + RECTAN_REF_Z[i];        
                   break;
               case 'TOP':     
                   yc = maxY + RECTAN_REF_Y[i];
                   xc = xc0 + RECTAN_REF_Z[i];       
                   break;
               case 'BOTTOM':       
                   yc = minY + RECTAN_REF_Y[i];
                   xc = xc0 + RECTAN_REF_Z[i];      
                   break;
               case 'LEFT':       
                   yc = yc0 + RECTAN_REF_Y[i];
                   xc = minZ + RECTAN_REF_Z[i];      
                   break;            
               case 'RIGHT':    
                   yc = yc0 + RECTAN_REF_Y[i];
                   xc = maxZ + RECTAN_REF_Z[i];   
                   break;
               default:
                   // Handle unexpected reference type if necessary
                   continue; // Skip to the next iteration if the reference is invalid
           }
   
           shapeRECTAN[i] = [
               new BABYLON.Vector3(-RECTAN_WIDTH[i] / 2 - xc, RECTAN_DEPTH[i] / 2 - yc, 0),
               new BABYLON.Vector3(RECTAN_WIDTH[i] / 2 - xc, RECTAN_DEPTH[i] / 2 - yc, 0),
               new BABYLON.Vector3(RECTAN_WIDTH[i] / 2 - xc, -RECTAN_DEPTH[i] / 2 - yc, 0),
               new BABYLON.Vector3(-RECTAN_WIDTH[i] / 2 - xc, -RECTAN_DEPTH[i] / 2 - yc, 0),
           ];    
           shapeRECTAN[i].push(shapeRECTAN[i][0]); // Close the shape by pushing the first point at the end
       }
   
       setShapeRECTAN(shapeRECTAN)


       // Input circular shape information
       let CIRCLE_NAME: string[] = [];         // Shape name      
       let CIRCLE_RADIUS: number[] = [];       // Radius
       let CIRCLE_REF: string[] = [];          // Reference location
       let CIRCLE_REF_Y: number[] = [];        // Y coordinate of the insert point with (0,0) at the reference  
       let CIRCLE_REF_Z: number[] = [];        // Z coordinate of the insert point with (0,0) at the reference  
   
       for (let i = 0; i < N_CIRCLE; i++) {
           const values = VALUE(KL_show, "CIRCLE", 5); // Get values once to avoid repeated calls
   
           // Ensure the value is assigned correctly based on type
           CIRCLE_NAME[i] = (typeof values[5 * i] === 'string') ? String(values[5 * i]) : ''; // Ensure it's a string
   
           // For radius and references, ensure they are numbers and assign a default value if not
           CIRCLE_RADIUS[i] = (typeof values[5 * i + 1] === 'number') ? 
               Number(((values[5 * i + 1] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           CIRCLE_REF[i] = (typeof values[5 * i + 2] === 'string') ? String(values[5 * i + 2]) : ''; // Ensure it's a string
   
           CIRCLE_REF_Y[i] = (typeof values[5 * i + 3] === 'number') ? 
               Number(((values[5 * i + 3] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           CIRCLE_REF_Z[i] = (typeof values[5 * i + 4] === 'number') ? 
               Number(((values[5 * i + 4] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
       }


       setCIRCLE_NAME(CIRCLE_NAME)
       setCIRCLE_RADIUS(CIRCLE_RADIUS)
       setCIRCLE_REF(CIRCLE_REF)
       setCIRCLE_REF_Y(CIRCLE_REF_Y)
       setCIRCLE_REF_Z(CIRCLE_REF_Z)

       // Input trapezoidal shape information  
       let TRAP_NAME: string[] = [];          // shape name      
       let TRAP_TWIDTH: number[] = [];        // top width
       let TRAP_BWIDTH: number[] = [];        // bottom width     
       let TRAP_DEPTH: number[] = [];         // depth   
       let TRAP_REF: string[] = [];           // reference location
       let TRAP_REF_Y: number[] = [];         // y coordinate of the insert point with (0,0) at the reference  
       let TRAP_REF_Z: number[] = [];         // z coordinate of the insert point with (0,0) at the reference  
   
       for (let i = 0; i < N_TRAP; i++) {
           const values = VALUE(KL_show, "TRAP", 7); // Get values once to avoid repeated calls
   
           // Ensure values are assigned correctly
           TRAP_NAME[i] = (typeof values[7 * i] === 'string') ? String(values[7 * i]) : ''; // Ensure it's a string
   
           // For dimensions, ensure they are numbers and assign a default value if not
           TRAP_TWIDTH[i] = (typeof values[7 * i + 1] === 'number') ? 
               Number(((values[7 * i + 1] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRAP_BWIDTH[i] = (typeof values[7 * i + 2] === 'number') ? 
               Number(((values[7 * i + 2] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRAP_DEPTH[i] = (typeof values[7 * i + 3] === 'number') ? 
               Number(((values[7 * i + 3] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRAP_REF[i] = (typeof values[7 * i + 4] === 'string') ? String(values[7 * i + 4]) : ''; // Ensure it's a string
   
           TRAP_REF_Y[i] = (typeof values[7 * i + 5] === 'number') ? 
               Number(((values[7 * i + 5] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRAP_REF_Z[i] = (typeof values[7 * i + 6] === 'number') ? 
               Number(((values[7 * i + 6] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
       }


       setTRAP_NAME(TRAP_NAME)
       setTRAP_TWIDTH(TRAP_TWIDTH)
       setTRAP_BWIDTH(TRAP_BWIDTH)
       setTRAP_DEPTH(TRAP_DEPTH)
       setTRAP_REF(TRAP_REF)
       setTRAP_REF_Y(TRAP_REF_Y)
       setTRAP_REF_Z(TRAP_REF_Z)


           // Generate trapezoidal shape data points
           // Define the type for shapeTRAP as an array of arrays of BABYLON.Vector3
       let shapeTRAP: BABYLON.Vector3[][] = []; // Array of arrays of BABYLON.Vector3
   
       for (let i = 0; i < N_TRAP; i++) {
           // Centroid to bottom of section
           let xc0 = 0; 
           let yc0 = (TRAP_BWIDTH[i] + 2 * TRAP_TWIDTH[i]) / (3 * (TRAP_BWIDTH[i] + TRAP_TWIDTH[i])) * TRAP_DEPTH[i]; 
           let maxZ = Math.max(TRAP_TWIDTH[i] / 2, TRAP_TWIDTH[i] / 2); 
           let minZ = -Math.max(TRAP_TWIDTH[i] / 2, TRAP_TWIDTH[i] / 2); 
           let maxY = TRAP_DEPTH[i]; 
           let minY = 0;                 
   
           let yc: number; // Declare yc
           let xc: number; // Declare xc
   
           switch (TRAP_REF[i]) {
               case 'CG':   
                   yc = yc0 + TRAP_REF_Y[i];
                   xc = xc0 + TRAP_REF_Z[i];        
                   break;
               case 'TOP':     
                   yc = maxY + TRAP_REF_Y[i];
                   xc = xc0 + TRAP_REF_Z[i];       
                   break;
               case 'BOTTOM':       
                   yc = minY + TRAP_REF_Y[i];
                   xc = xc0 + TRAP_REF_Z[i];      
                   break;
               case 'LEFT':       
                   yc = yc0 + TRAP_REF_Y[i];
                   xc = minZ + TRAP_REF_Z[i];      
                   break;            
               case 'RIGHT':    
                   yc = yc0 + TRAP_REF_Y[i];
                   xc = maxZ + TRAP_REF_Z[i];   
                   break;
               default:
                   yc = 0; // Fallback in case of an unexpected value
                   xc = 0; // Fallback in case of an unexpected value
           }
   
           shapeTRAP[i] = [  
               new BABYLON.Vector3(-TRAP_TWIDTH[i] / 2, TRAP_DEPTH[i] - yc, 0),
               new BABYLON.Vector3(TRAP_TWIDTH[i] / 2,  TRAP_DEPTH[i] - yc, 0),
               new BABYLON.Vector3(TRAP_BWIDTH[i] / 2,  -yc, 0),
               new BABYLON.Vector3(-TRAP_BWIDTH[i] / 2, -yc, 0),
           ];    
   
           shapeTRAP[i].push(shapeTRAP[i][0]); // Close the shape by adding the first vertex again
       }


       setShapeTRAP(shapeTRAP)

       // Input triangular shape information  
       let TRIAN_NAME: string[] = [];         // Shape name      
       let TRIAN_X0: number[] = [];           // x0 coordinate
       let TRIAN_Y0: number[] = [];           // y0 coordinate    
       let TRIAN_X1: number[] = [];           // x1 coordinate
       let TRIAN_Y1: number[] = [];           // y1 coordinate    
       let TRIAN_X2: number[] = [];           // x2 coordinate
       let TRIAN_Y2: number[] = [];           // y2 coordinate 
       let TRIAN_REF: string[] = [];           // Reference location
       let TRIAN_REF_Y: number[] = [];        // Y coordinate of the insert point with (0,0) at the reference  
       let TRIAN_REF_Z: number[] = [];        // Z coordinate of the insert point with (0,0) at the reference          
       for (let i = 0; i < N_TRIAN; i++) {
           const values = VALUE(KL_show, "TRIAN", 10); // Get values once to avoid repeated calls
   
           // Ensure values are assigned correctly
           TRIAN_NAME[i] = (typeof values[10 * i] === 'string') ? String(values[10 * i]) : ''; // Ensure it's a string
   
           // Ensure values are numbers and assign a default value if not
           TRIAN_X0[i] = (typeof values[10 * i + 1] === 'number') ? 
               Number(((values[10 * i + 1] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRIAN_Y0[i] = (typeof values[10 * i + 2] === 'number') ? 
               Number(((values[10 * i + 2] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRIAN_X1[i] = (typeof values[10 * i + 3] === 'number') ? 
               Number(((values[10 * i + 3] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRIAN_Y1[i] = (typeof values[10 * i + 4] === 'number') ? 
               Number(((values[10 * i + 4] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRIAN_X2[i] = (typeof values[10 * i + 5] === 'number') ? 
               Number(((values[10 * i + 5] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRIAN_Y2[i] = (typeof values[10 * i + 6] === 'number') ? 
               Number(((values[10 * i + 6] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRIAN_REF[i] = (typeof values[10 * i + 7] === 'string') ? String(values[10 * i + 7]) : ''; // Ensure it's a string
   
           TRIAN_REF_Y[i] = (typeof values[10 * i + 8] === 'number') ? 
               Number(((values[10 * i + 8] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number
   
           TRIAN_REF_Z[i] = (typeof values[10 * i + 9] === 'number') ? 
               Number(((values[10 * i + 9] as number) * 100).toFixed(2)) : 0; // Default to 0 if not a number                   
       }

 

       setTRIAN_NAME(TRIAN_NAME)
       setTRIAN_X0(TRIAN_X0)
       setTRIAN_Y0(TRIAN_Y0)
       setTRIAN_X1(TRIAN_X1)
       setTRIAN_Y1(TRIAN_Y1)
       setTRIAN_X2(TRIAN_X2)
       setTRIAN_Y2(TRIAN_Y2)
       setTRIAN_REF(TRIAN_REF)
       setTRIAN_REF_Y(TRIAN_REF_Y)
       setTRIAN_REF_Z(TRIAN_REF_Z)


       // Generate triangular shape data points
       let shapeTRIAN: BABYLON.Vector3[][] = []; // Define shapeTRIAN as an array of arrays of BABYLON.Vector3
   
       for (let i = 0; i < N_TRIAN; i++) {
           // Centroid to bottom of section
           const xc0 = (TRIAN_X0[i] + TRIAN_X1[i] + TRIAN_X2[i]) / 3;
           const yc0 = (TRIAN_Y0[i] + TRIAN_Y1[i] + TRIAN_Y2[i]) / 3;
           const maxZ = Math.max(TRIAN_X0[i], TRIAN_X1[i], TRIAN_X2[i]); 
           const minZ = Math.min(TRIAN_X0[i], TRIAN_X1[i], TRIAN_X2[i]); 
           const maxY = Math.max(TRIAN_Y0[i], TRIAN_Y1[i], TRIAN_Y2[i]); 
           const minY = Math.min(TRIAN_Y0[i], TRIAN_Y1[i], TRIAN_Y2[i]); 
   
           let yc: number; // Declare yc
           let xc: number; // Declare xc
   
           switch (TRIAN_REF[i]) {
               case 'CG':   
                   yc = yc0 + TRIAN_REF_Y[i];
                   xc = xc0 + TRIAN_REF_Z[i];        
                   break;
               case 'TOP':     
                   yc = maxY + TRIAN_REF_Y[i];
                   xc = xc0 + TRIAN_REF_Z[i];       
                   break;
               case 'BOTTOM':       
                   yc = minY + TRIAN_REF_Y[i];
                   xc = xc0 + TRIAN_REF_Z[i];      
                   break;
               case 'LEFT':       
                   yc = yc0 + TRIAN_REF_Y[i];
                   xc = minZ + TRIAN_REF_Z[i];      
                   break;            
               case 'RIGHT':    
                   yc = yc0 + TRIAN_REF_Y[i];
                   xc = maxZ + TRIAN_REF_Z[i];   
                   break;
               default:
                   yc = 0; // Default value in case of an unexpected reference
                   xc = 0; // Default value in case of an unexpected reference
           }
   
           shapeTRIAN[i] = [  
               new BABYLON.Vector3(TRIAN_X0[i] - xc, TRIAN_Y0[i] - yc, 0),
               new BABYLON.Vector3(TRIAN_X1[i] - xc, TRIAN_Y1[i] - yc, 0),
               new BABYLON.Vector3(TRIAN_X2[i] - xc, TRIAN_Y2[i] - yc, 0),
           ];    
           shapeTRIAN[i].push(shapeTRIAN[i][0]); // Close the shape by adding the first vertex again
       }

       setShapeTRIAN(shapeTRIAN)

       // Input quadrilateral shape information  
       let QUAD_NAME: string[] = []; // Shape name      
       let QUAD_X0: number[] = [];   // x0 coordinate
       let QUAD_Y0: number[] = [];   // y0 coordinate    
       let QUAD_X1: number[] = [];   // x1 coordinate
       let QUAD_Y1: number[] = [];   // y1 coordinate    
       let QUAD_X2: number[] = [];   // x2 coordinate
       let QUAD_Y2: number[] = [];   // y2 coordinate
       let QUAD_X3: number[] = [];   // x3 coordinate
       let QUAD_Y3: number[] = [];   // y3 coordinate    
       let QUAD_REF: string[] = [];   // Reference location
       let QUAD_REF_Y: number[] = []; // Y coordinate of the insert point with (0,0) at the reference  
       let QUAD_REF_Z: number[] = []; // Z coordinate of the insert point with (0,0) at the reference             
               
   
       for (let i = 0; i < N_QUAD; i++) {
           const values = VALUE(KL_show, "QUAD", 12); // Get values once to avoid repeated calls
   
           // Ensure values are assigned correctly
           QUAD_NAME[i] = (typeof values[12 * i] === 'string') ? String(values[12 * i]) : ''; // Ensure it's a string
   
           // Ensure values are numbers and assign a default value if not
           QUAD_X0[i] = (typeof values[12 * i + 1] === 'number') ? 
               Math.round((values[12 * i + 1] as number) * 100) / 100 : 0; // Round to 2 decimal places
           QUAD_Y0[i] = (typeof values[12 * i + 2] === 'number') ? 
               Math.round((values[12 * i + 2] as number) * 100) / 100 : 0; // Round to 2 decimal places
           QUAD_X1[i] = (typeof values[12 * i + 3] === 'number') ? 
               Math.round((values[12 * i + 3] as number) * 100) / 100 : 0; // Round to 2 decimal places
           QUAD_Y1[i] = (typeof values[12 * i + 4] === 'number') ? 
               Math.round((values[12 * i + 4] as number) * 100) / 100 : 0; // Round to 2 decimal places
           QUAD_X2[i] = (typeof values[12 * i + 5] === 'number') ? 
               Math.round((values[12 * i + 5] as number) * 100) / 100 : 0; // Round to 2 decimal places
           QUAD_Y2[i] = (typeof values[12 * i + 6] === 'number') ? 
               Math.round((values[12 * i + 6] as number) * 100) / 100 : 0; // Round to 2 decimal places
           QUAD_X3[i] = (typeof values[12 * i + 7] === 'number') ? 
               Math.round((values[12 * i + 7] as number) * 100) / 100 : 0; // Round to 2 decimal places
           QUAD_Y3[i] = (typeof values[12 * i + 8] === 'number') ? 
               Math.round((values[12 * i + 8] as number) * 100) / 100 : 0; // Round to 2 decimal places
   
           QUAD_REF[i] = (typeof values[12 * i + 9] === 'string') ? String(values[12 * i + 9]) : ''; // Ensure it's a string
   
           QUAD_REF_Y[i] = (typeof values[12 * i + 10] === 'number') ? 
               Math.round((values[12 * i + 10] as number) * 100) / 100 : 0; // Round to 2 decimal places
           QUAD_REF_Z[i] = (typeof values[12 * i + 11] === 'number') ? 
               Math.round((values[12 * i + 11] as number) * 100) / 100 : 0; // Round to 2 decimal places  
       }


       setQUAD_NAME(QUAD_NAME)
       setQUAD_X0(QUAD_X0)
       setQUAD_Y0(QUAD_Y0)
       setQUAD_X1(QUAD_X1)
       setQUAD_Y1(QUAD_Y1)
       setQUAD_X2(QUAD_X2)
       setQUAD_Y2(QUAD_Y2)
       setQUAD_X3(QUAD_X3)
       setQUAD_Y3(QUAD_Y3)
       setQUAD_REF(QUAD_REF)
       setQUAD_REF_Y(QUAD_REF_Y)
       setQUAD_REF_Z(QUAD_REF_Z)

       // Generate quadrilateral shape data points
       let shapeQUAD: BABYLON.Vector3[][] = []; // Define shapeQUAD as an array of arrays of BABYLON.Vector3
   
       for (let i = 0; i < N_QUAD; i++) {
           // Centroid to bottom of section
           const xc0 = (QUAD_X0[i] + QUAD_X1[i] + QUAD_X2[i] + QUAD_X3[i]) / 4;
           const yc0 = (QUAD_Y0[i] + QUAD_Y1[i] + QUAD_Y2[i] + QUAD_Y3[i]) / 4;
           const maxZ = Math.max(QUAD_X0[i], QUAD_X1[i], QUAD_X2[i], QUAD_X3[i]); 
           const minZ = Math.min(QUAD_X0[i], QUAD_X1[i], QUAD_X2[i], QUAD_X3[i]); 
           const maxY = Math.max(QUAD_Y0[i], QUAD_Y1[i], QUAD_Y2[i], QUAD_Y3[i]); 
           const minY = Math.min(QUAD_Y0[i], QUAD_Y1[i], QUAD_Y2[i], QUAD_Y3[i]); 
   
           let yc: number; // Declare yc
           let xc: number; // Declare xc
   
           switch (QUAD_REF[i]) {
               case 'CG':   
                   yc = yc0 + QUAD_REF_Y[i];
                   xc = xc0 + QUAD_REF_Z[i];        
                   break;
               case 'TOP':     
                   yc = maxY + QUAD_REF_Y[i];
                   xc = xc0 + QUAD_REF_Z[i];       
                   break;
               case 'BOTTOM':       
                   yc = minY + QUAD_REF_Y[i];
                   xc = xc0 + QUAD_REF_Z[i];      
                   break;
               case 'LEFT':       
                   yc = yc0 + QUAD_REF_Y[i];
                   xc = minZ + QUAD_REF_Z[i];      
                   break;            
               case 'RIGHT':    
                   yc = yc0 + QUAD_REF_Y[i];
                   xc = maxZ + QUAD_REF_Z[i];   
                   break;
               default:
                   yc = 0; // Default value in case of an unexpected reference
                   xc = 0; // Default value in case of an unexpected reference
           }
   
           shapeQUAD[i] = [  
               new BABYLON.Vector3(QUAD_X0[i] - xc, QUAD_Y0[i] - yc, 0),
               new BABYLON.Vector3(QUAD_X1[i] - xc, QUAD_Y1[i] - yc, 0),
               new BABYLON.Vector3(QUAD_X2[i] - xc, QUAD_Y2[i] - yc, 0),
               new BABYLON.Vector3(QUAD_X3[i] - xc, QUAD_Y3[i] - yc, 0),      
           ];    
           shapeQUAD[i].push(shapeQUAD[i][0]); // Close the shape by adding the first vertex again
       }

       setShapeQUAD(shapeQUAD)

       // Input I shape information  
       let ISHAPE_NAME: string[] = []; // Shape name      
       let ISHAPE_BTF: number[] = [];  // Width of top flange
       let ISHAPE_TTF: number[] = [];  // Thickness of top flange    
       let ISHAPE_BBF: number[] = [];  // Width of bottom flange
       let ISHAPE_TBF: number[] = [];  // Thickness of bottom flange    
       let ISHAPE_DWEB: number[] = []; // Depth of web
       let ISHAPE_TWEB: number[] = [];  // Thickness of web   
       let ISHAPE_REF: string[] = [];   // Reference location
       let ISHAPE_REF_Y: number[] = []; // Y coordinate of the insert point with (0,0) at the reference  
       let ISHAPE_REF_Z: number[] = []; // Z coordinate of the insert point with (0,0) at the reference     

       for (let i = 0; i < N_ISHAPE; i++) {
           const values = VALUE(KL_show, "ISHAPE", 10); // Get values once to avoid repeated calls
   
           ISHAPE_NAME[i] = (typeof values[10 * i] === 'string') ? String(values[10 * i]) : ''; // Ensure it's a string
   
           // Round values and ensure they're numbers
           ISHAPE_BTF[i] = (typeof values[10 * i + 1] === 'number') ? 
               Math.round((values[10 * i + 1] as number) * 100) / 100 : 0; 
           ISHAPE_TTF[i] = (typeof values[10 * i + 2] === 'number') ? 
               Math.round((values[10 * i + 2] as number) * 100) / 100 : 0; 
           ISHAPE_BBF[i] = (typeof values[10 * i + 3] === 'number') ? 
               Math.round((values[10 * i + 3] as number) * 100) / 100 : 0; 
           ISHAPE_TBF[i] = (typeof values[10 * i + 4] === 'number') ? 
               Math.round((values[10 * i + 4] as number) * 100) / 100 : 0; 
           ISHAPE_DWEB[i] = (typeof values[10 * i + 5] === 'number') ? 
               Math.round((values[10 * i + 5] as number) * 100) / 100 : 0; 
           ISHAPE_TWEB[i] = (typeof values[10 * i + 6] === 'number') ? 
               Math.round((values[10 * i + 6] as number) * 100) / 100 : 0; 
           
           ISHAPE_REF[i] = (typeof values[10 * i + 7] === 'string') ? String(values[10 * i + 7]) : ''; // Ensure it's a string
   
           ISHAPE_REF_Y[i] = (typeof values[10 * i + 8] === 'number') ? 
               Math.round((values[10 * i + 8] as number) * 100) / 100 : 0; 
           ISHAPE_REF_Z[i] = (typeof values[10 * i + 9] === 'number') ? 
               Math.round((values[10 * i + 9] as number) * 100) / 100 : 0;                   
       }
   
       setISHAPE_NAME(ISHAPE_NAME)
       setISHAPE_BTF(ISHAPE_BTF)
       setISHAPE_TTF(ISHAPE_TTF)
       setISHAPE_BBF(ISHAPE_BBF)
       setISHAPE_TBF(ISHAPE_TBF)
       setISHAPE_DWEB(ISHAPE_DWEB)
       setISHAPE_TWEB(ISHAPE_TWEB)
       setISHAPE_REF(ISHAPE_REF)
       setISHAPE_REF_Y(ISHAPE_REF_Y)
       setISHAPE_REF_Z(ISHAPE_REF_Z)

       function areaSection(x: number[], y: number[], n: number): number {
           let area = 0;  
           for (let i = 0; i < n; i++) {
               area += 0.5 * (x[i] * y[i + 1] - x[i + 1] * y[i]);
           }
           return area;
       }
   
       function cxSection(x: number[], y: number[], area: number, n: number): number {
           let cx = 0;  
           for (let i = 0; i < n; i++) {
               cx += (1 / (6 * area)) * (x[i] + x[i + 1]) * (x[i] * y[i + 1] - x[i + 1] * y[i]);
           }
           return cx;
       }
   
       function cySection(x: number[], y: number[], area: number, n: number): number {
           let cy = 0;  
           for (let i = 0; i < n; i++) {
               cy += (1 / (6 * area)) * (y[i] + y[i + 1]) * (x[i] * y[i + 1] - x[i + 1] * y[i]);
           }
           return cy;
       }
   
       function shapePoints(x: number[], y: number[], refx: number, refy: number, n: number): BABYLON.Vector3[] {
           const points: BABYLON.Vector3[] = []; 
           for (let i = 0; i < n; i++) {
               points.push(new BABYLON.Vector3(x[i] - refx, y[i] - refy, 0));
           }
           points.push(new BABYLON.Vector3(x[0] - refx, y[0] - refy, 0));       
           return points;
       }


        // Generate I shape data points
        let shapeISHAPE: BABYLON.Vector3[][] = []; // Declare the type explicitly
    
        for (let i = 0; i < N_ISHAPE; i++) {
            // Centroid to bottom of section
            const x: number[] = [];
            const y: number[] = [];
            
            x[0] = -ISHAPE_BTF[i] / 2; 
            y[0] = 0.0;
            x[1] = ISHAPE_BTF[i] / 2; 
            y[1] = 0.0;
            x[2] = x[1];
            y[2] = y[1] - ISHAPE_TTF[i]; 
            x[3] = 0.5 * ISHAPE_TWEB[i]; 
            y[3] = y[2];
            x[4] = x[3];
            y[4] = y[3] - ISHAPE_DWEB[i]; 
            x[5] = 0.5 * ISHAPE_BBF[i];
            y[5] = y[4];
            x[6] = x[5];
            y[6] = y[5] - ISHAPE_TBF[i];
            x[7] = -x[6];
            y[7] = y[6];
            x[8] = x[7];
            y[8] = y[5];
            x[9] = -x[4];
            y[9] = y[4];
            x[10] = -x[3];
            y[10] = y[3];
            x[11] = -x[2];
            y[11] = y[2];
            x[12] = x[0];
            y[12] = y[0];
    
            const areaIshape = areaSection(x, y, 12);
            const xc0 = cxSection(x, y, areaIshape, 12);
            const yc0 = cySection(x, y, areaIshape, 12); 
            const maxZ = maxValue(x)[0]; 
            const minZ = minValue(x)[0]; 
            const maxY = maxValue(y)[0];  
            const minY = minValue(y)[0];   
    
            // Initialize xc and yc with default values
            let xc: number = 0; 
            let yc: number = 0; 
    
            switch (ISHAPE_REF[i]) {
                case 'CG':   
                    yc = yc0 + ISHAPE_REF_Y[i];
                    xc = xc0 + ISHAPE_REF_Z[i];        
                    break;
                case 'TOP':     
                    yc = maxY + ISHAPE_REF_Y[i];
                    xc = xc0 + ISHAPE_REF_Z[i];       
                    break;
                case 'BOTTOM':       
                    yc = minY + ISHAPE_REF_Y[i];
                    xc = xc0 + ISHAPE_REF_Z[i];      
                    break;
                case 'LEFT':       
                    yc = yc0 + ISHAPE_REF_Y[i];
                    xc = minZ + ISHAPE_REF_Z[i];      
                    break;            
                case 'RIGHT':    
                    yc = yc0 + ISHAPE_REF_Y[i];
                    xc = maxZ + ISHAPE_REF_Z[i];   
                    break;
            }
                    
            shapeISHAPE[i] = shapePoints(x, y, xc, yc, 12);         
        }
    
        setShapeISHAPE(shapeISHAPE)
 
        // Input single-cell box shape information  
        const BOX_maxN: number = 22;   
        let BOX_NAME: string[] = []; // shape name      
        let BOX_depth: number[] = []; // depth of the box girder 
        let BOX_widthGirder: number[] = [];  // Top width of concrete box girder
        let BOX_lengthCantilever: number[] = []; // Cantilever length from tip of the cantilever to the root at web
        let BOX_slopeWeb: number[] = []; // Web slope of box girder
        let BOX_thickWeb: number[] = []; // Web thickness
        let BOX_thickCantilever: number[] = []; // Cantilever thickness
        let BOX_thickTopSlab: number[] = []; // Top flange thickness
        let BOX_thickTopHaunch: number[] = []; // Haunch thickness from top of deck to top of web
        let BOX_thickBotSlab: number[] = []; // Thickness of bottom slab
        let BOX_thickBotHaunch: number[] = []; // Thickness of bottom haunch from top of haunch to bottom of the bottom slabs
        let BOX_widthTopHaunchOut: number[] = []; // Width of the haunch in cantilever
        let BOX_widthTopHaunchIn: number[] = []; // Width of the haunch in the top slab
        let BOX_widthBotHaunch: number[] = []; // Width of the haunch in the bottom slab
        let BOX_REF: string[] = []; // reference location
        let BOX_REF_Y: number[] = []; // y coordinate of the insert point with (0,0) at the reference  
        let BOX_REF_Z: number[] = []; // z coordinate of the insert point with (0,0) at the reference    

        for (let i = 0; i < N_BOX; i++) {
            const values = VALUE(KL_show, "BOX", 17); // Get values once to avoid repeated calls
    
            BOX_NAME[i] = String(values[17 * i]); // Ensure it's a string
    

            const toNumber = (value: any) => {
                return typeof value === 'number' ? Math.round(value * 100) / 100 : 0; // Default to 0 if not a number
            };
    
            BOX_depth[i] = toNumber(values[17 * i + 1]);
            BOX_widthGirder[i] = toNumber(values[17 * i + 2]);
            BOX_lengthCantilever[i] = toNumber(values[17 * i + 3]);
            BOX_slopeWeb[i] = toNumber(values[17 * i + 4]);
            BOX_thickWeb[i] = toNumber(values[17 * i + 5]);
            BOX_thickCantilever[i] = toNumber(values[17 * i + 6]);
            BOX_thickTopSlab[i] = toNumber(values[17 * i + 7]);
            BOX_thickTopHaunch[i] = toNumber(values[17 * i + 8]);
            BOX_thickBotSlab[i] = toNumber(values[17 * i + 9]);
            BOX_thickBotHaunch[i] = toNumber(values[17 * i + 10]);
            BOX_widthTopHaunchOut[i] = toNumber(values[17 * i + 11]);
            BOX_widthTopHaunchIn[i] = toNumber(values[17 * i + 12]);
            BOX_widthBotHaunch[i] = toNumber(values[17 * i + 13]);
            BOX_REF[i] = String(values[17 * i + 14]); // Ensure it's a string
            BOX_REF_Y[i] = toNumber(values[17 * i + 15]);
            BOX_REF_Z[i] = toNumber(values[17 * i + 16]);
        }
    
        setBOX_NAME(BOX_NAME)
        setBOX_depth(BOX_depth)
        setBOX_widthGirder(BOX_widthGirder)
        setBOX_lengthCantilever(BOX_lengthCantilever)
        setBOX_slopeWeb(BOX_slopeWeb)
        setBOX_thickWeb(BOX_thickWeb)
        setBOX_thickCantilever(BOX_thickCantilever)
        setBOX_thickTopSlab(BOX_thickTopSlab)
        setBOX_thickTopHaunch(BOX_thickTopHaunch)
        setBOX_thickBotSlab(BOX_thickBotSlab)
        setBOX_thickBotHaunch(BOX_thickBotHaunch)
        setBOX_widthTopHaunchOut(BOX_widthTopHaunchOut)
        setBOX_widthTopHaunchIn(BOX_widthTopHaunchIn)
        setBOX_widthBotHaunch(BOX_widthBotHaunch)
        setBOX_REF(BOX_REF)
        setBOX_REF_Y(BOX_REF_Y)
        setBOX_REF_Z(BOX_REF_Z)

        // Generate Box shape data points
        var shapeBOX: BABYLON.Vector3[][] = []; // Explicitly define the type for shapeBOX
    
        for (var i = 0; i < N_BOX; i++) {
            var x: number[] = [];
            var y: number[] = [];
    
            var x_point8 = 
                BOX_widthGirder[i] / 2 - BOX_lengthCantilever[i] - 
                BOX_slopeWeb[i] * (BOX_depth[i] - BOX_thickTopHaunch[i] - BOX_thickBotHaunch[i]) - 
                BOX_thickWeb[i] / Math.cos(Math.atan(BOX_slopeWeb[i])) - 
                BOX_widthBotHaunch[i];
    
            var x_point11 = 
                BOX_widthGirder[i] / 2 - BOX_lengthCantilever[i] - 
                BOX_thickWeb[i] / Math.cos(Math.atan(BOX_slopeWeb[i])) - 
                BOX_widthTopHaunchIn[i];
    
            // Fill x and y coordinates
            x[0] = -BOX_widthGirder[i] / 2; 
            y[0] = 0.0;
            x[1] = BOX_widthGirder[i] / 2; 
            y[1] = 0.0;
            x[2] = x[1];
            y[2] = -BOX_thickCantilever[i];
            x[3] = BOX_widthGirder[i] / 2 - (BOX_lengthCantilever[i] - BOX_widthTopHaunchOut[i]);
            y[3] = -BOX_thickCantilever[i];
            x[4] = BOX_widthGirder[i] / 2 - BOX_lengthCantilever[i];
            y[4] = -BOX_thickTopHaunch[i];
            x[5] = x[4] - BOX_slopeWeb[i] * (BOX_depth[i] - BOX_thickTopHaunch[i]);
            y[5] = -BOX_depth[i];
            x[6] = 0;
            y[6] = -BOX_depth[i];
            x[7] = 0;
            y[7] = -BOX_depth[i] + BOX_thickBotSlab[i];
            x[8] = x_point8;
            y[8] = -BOX_depth[i] + BOX_thickBotSlab[i];
            x[9] = x_point8 + BOX_widthBotHaunch[i];
            y[9] = -BOX_depth[i] + BOX_thickBotSlab[i] + (BOX_thickBotHaunch[i] - BOX_thickBotSlab[i]);
            x[10] = BOX_widthGirder[i] / 2 - BOX_lengthCantilever[i] - BOX_thickWeb[i] / Math.cos(Math.atan(BOX_slopeWeb[i]));
            y[10] = -BOX_thickTopHaunch[i]; 
            x[11] = x_point11;
            y[11] = -BOX_thickTopSlab[i];
            x[12] = -x_point11;
            y[12] = -BOX_thickTopSlab[i];
            x[13] = -x_point11 - BOX_widthTopHaunchIn[i];
            y[13] = -BOX_thickTopHaunch[i];
            x[14] = -(x_point8 + BOX_widthBotHaunch[i]);
            y[14] = -BOX_depth[i] + BOX_thickBotSlab[i] + (BOX_thickBotHaunch[i] - BOX_thickBotSlab[i]);
            x[15] = -x_point8;
            y[15] = -BOX_depth[i] + BOX_thickBotSlab[i];
            x[16] = 0;
            y[16] = -BOX_depth[i] + BOX_thickBotSlab[i];
            x[17] = 0;
            y[17] = -BOX_depth[i];
            x[18] = -(BOX_widthGirder[i] / 2 - BOX_lengthCantilever[i] - BOX_slopeWeb[i] * (BOX_depth[i] - BOX_thickTopHaunch[i]));
            y[18] = -BOX_depth[i];
            x[19] = -(BOX_widthGirder[i] / 2 - BOX_lengthCantilever[i]);
            y[19] = -BOX_thickTopHaunch[i];
            x[20] = -(BOX_widthGirder[i] / 2 - (BOX_lengthCantilever[i] - BOX_widthTopHaunchOut[i]));
            y[20] = -BOX_thickCantilever[i];
            x[21] = -BOX_widthGirder[i] / 2;
            y[21] = -BOX_thickCantilever[i];
            x[22] = x[0]; // Closing the shape
            y[22] = y[0];
    
            // Calculate area and centroid
            var areaBOX = areaSection(x, y, BOX_maxN);
            var xc0 = cxSection(x, y, areaBOX, BOX_maxN);
            var yc0 = cySection(x, y, areaBOX, BOX_maxN); 
            var maxZ = maxValue(x)[0]; 
            var minZ = minValue(x)[0]; 
            var maxY = maxValue(y)[0];  
            var minY = minValue(y)[0];  
    
            // Initialize xc and yc to avoid the used-before-assignment error
            let xc: number = 0;
            let yc: number = 0;
    
            // Determine reference point
            switch (BOX_REF[i]) {
                case 'CG':   
                    yc = yc0 + BOX_REF_Y[i];
                    xc = xc0 + BOX_REF_Z[i];        
                    break;
                case 'TOP':     
                    yc = maxY + BOX_REF_Y[i];
                    xc = xc0 + BOX_REF_Z[i];       
                    break;
                case 'BOTTOM':       
                    yc = minY + BOX_REF_Y[i];
                    xc = xc0 + BOX_REF_Z[i];      
                    break;
                case 'LEFT':       
                    yc = yc0 + BOX_REF_Y[i];
                    xc = minZ + BOX_REF_Z[i];      
                    break;            
                case 'RIGHT':    
                    yc = yc0 + BOX_REF_Y[i];
                    xc = maxZ + BOX_REF_Z[i];   
                    break;
            }
    
            // Generate points
            shapeBOX[i] = shapePoints(x, y, xc, yc, BOX_maxN);         
        }

        setShapeBOX(shapeBOX)
 
        // Input SLOPE shape information  
        const SLOPE_maxN = 60;   
        let SLOPE_NAME: string[] = []; // shape name      
        let SLOPE_NUMBER: number[] = []; // Number of data points   
        let SLOPE_THICK: number[] = []; // Thickness of sloped section      
        let SLOPE_SE: number[][] = matrix(SLOPE_maxN, N_SLOPE, 0); // Slope superelevation information  
        let SLOPE_DIST: number[][] = matrix(SLOPE_maxN, N_SLOPE, 0); // Slope distance information       
        let SLOPE_REF: string[] = []; // reference location
        let SLOPE_REF_Y: number[] = []; // y coordinate of the insert point with (0,0) at the reference  
        let SLOPE_REF_Z: number[] = []; // z coordinate of the insert point with (0,0) at the reference  
    

        for (let i = 0; i < N_SLOPE; i++) {
            const slopeValues = VALUE(KL_show, "SLOPE", SLOPE_maxN * 2 + 6); // Get values once to avoid repeated calls
    
            // Ensure proper type conversions
            SLOPE_NAME[i] = String(slopeValues[(SLOPE_maxN * 2 + 6) * i]);
            SLOPE_REF[i] = String(slopeValues[(SLOPE_maxN * 2 + 6) * i + 1]);
            SLOPE_REF_Y[i] = Number(parseFloat(((Math.round(Number(slopeValues[(SLOPE_maxN * 2 + 6) * i + 2]) * 100) / 100)).toFixed(2)));
            SLOPE_REF_Z[i] = Number(parseFloat(((Math.round(Number(slopeValues[(SLOPE_maxN * 2 + 6) * i + 3]) * 100) / 100)).toFixed(2)));    
            SLOPE_NUMBER[i] = Math.round(Number(slopeValues[(SLOPE_maxN * 2 + 6) * i + 4]));  
            SLOPE_THICK[i] = Number(parseFloat(((Math.round(Number(slopeValues[(SLOPE_maxN * 2 + 6) * i + 5]) * 100) / 100)).toFixed(2)));             
    
            for (let j = 0; j < SLOPE_NUMBER[i]; j++) {
                SLOPE_SE[j][i] = Number(parseFloat(((Math.round(Number(slopeValues[(SLOPE_maxN * 2 + 6) * i + 2 * j + 6]) * 100) / 100)).toFixed(2)));
                SLOPE_DIST[j][i] = Number(parseFloat(((Math.round(Number(slopeValues[(SLOPE_maxN * 2 + 6) * i + 2 * j + 7]) * 100) / 100)).toFixed(2)));           
            }                                                               
        } 

        setSLOPE_NAME(SLOPE_NAME)
        setSLOPE_NUMBER(SLOPE_NUMBER)
        setSLOPE_THICK(SLOPE_THICK)
        setSLOPE_SE(SLOPE_SE)
        setSLOPE_DIST(SLOPE_DIST)
        setSLOPE_REF(SLOPE_REF)
        setSLOPE_REF_Y(SLOPE_REF_Y)
        setSLOPE_REF_Z(SLOPE_REF_Z)
        
        // Generate SLOPE shape data points
        let shapeSLOPE: BABYLON.Vector3[][] = []; // Declare the type explicitly
    
        for (let i = 0; i < N_SLOPE; i++) {
            // Centroid to bottom of section
            const x: number[] = [];
            const y: number[] = [];
            
            x[0] = 0; // Define the top left node as (0,0)
            y[0] = 0;
    
            for (let j = 1; j < SLOPE_NUMBER[i] + 1; j++) {
                x[j] = x[j - 1] + SLOPE_DIST[j - 1][i];
                y[j] = y[j - 1] + SLOPE_DIST[j - 1][i] * SLOPE_SE[j - 1][i];         
            }  
            x[SLOPE_NUMBER[i] + 1] = x[SLOPE_NUMBER[i]];
            y[SLOPE_NUMBER[i] + 1] = y[SLOPE_NUMBER[i]] - SLOPE_THICK[i];   
    
            for (let j = SLOPE_NUMBER[i] + 2; j < 2 * SLOPE_NUMBER[i] + 2; j++) {
                x[j] = x[j - 1] - SLOPE_DIST[2 * SLOPE_NUMBER[i] - j + 1][i];
                y[j] = y[j - 1] - SLOPE_DIST[2 * SLOPE_NUMBER[i] - j + 1][i] * SLOPE_SE[2 * SLOPE_NUMBER[i] - j + 1][i];       
            }  
            x[2 * SLOPE_NUMBER[i] + 2] = x[0];
            y[2 * SLOPE_NUMBER[i] + 2] = y[0];   
    
            const areaSLOPE = areaSection(x, y, 2 * SLOPE_NUMBER[i] + 1);
            const xc0 = cxSection(x, y, areaSLOPE, 2 * SLOPE_NUMBER[i] + 1);
            const yc0 = cySection(x, y, areaSLOPE, 2 * SLOPE_NUMBER[i] + 1); 
            
            const maxZ = maxValue(x)[0]; 
            const minZ = minValue(x)[0]; 
            const maxY = maxValue(y)[0];  
            const minY = minValue(y)[0];       
    
            let xc: number = 0; // Declare xc and initialize to a default value
            let yc: number = 0; // Same for yc
    
            switch (SLOPE_REF[i]) {
                case 'CG':   
                    yc = yc0 + SLOPE_REF_Y[i];
                    xc = xc0 + SLOPE_REF_Z[i];        
                    break;
                case 'TOP':     
                    yc = maxY + SLOPE_REF_Y[i];
                    xc = xc0 + SLOPE_REF_Z[i];       
                    break;
                case 'BOTTOM':       
                    yc = minY + SLOPE_REF_Y[i];
                    xc = xc0 + SLOPE_REF_Z[i];      
                    break;
                case 'LEFT':       
                    yc = yc0 + SLOPE_REF_Y[i];
                    xc = minZ + SLOPE_REF_Z[i];      
                    break;            
                case 'RIGHT':    
                    yc = yc0 + SLOPE_REF_Y[i];
                    xc = maxZ + SLOPE_REF_Z[i];   
                    break;
            }
    
            shapeSLOPE[i] = shapePoints(x, y, xc, yc, 2 * SLOPE_NUMBER[i] + 2);         
        }
    
        setShapeSLOPE(shapeSLOPE)

        // Input custom shape information  
        const CUSTOM_maxN = 60;  
        let CUSTOM_NAME: string[] = []; // shape name      
        let CUSTOM_NUMBER: number[] = []; // Number of data points   
        let CUSTOM_X: number[][] = matrix(CUSTOM_maxN, N_CUSTOM, 0);   
        let CUSTOM_Y: number[][] = matrix(CUSTOM_maxN, N_CUSTOM, 0);       
        let CUSTOM_REF: string[] = []; // reference location
        let CUSTOM_REF_Y: number[] = []; // y coordinate of the insert point with (0,0) at the reference  
        let CUSTOM_REF_Z: number[] = []; // z coordinate of the insert point with (0,0) at the reference  

        for (let i = 0; i < N_CUSTOM; i++) {
            const customValues = VALUE(KL_show, "CUSTOM", CUSTOM_maxN * 2 + 5); // Get values once to avoid repeated calls
    
            CUSTOM_NAME[i] = String(customValues[(CUSTOM_maxN * 2 + 5) * i]); // Ensure it's a string
            CUSTOM_REF[i] = String(customValues[(CUSTOM_maxN * 2 + 5) * i + 1]); // Ensure it's a string
    
            // Ensure values are properly cast to numbers
            CUSTOM_REF_Y[i] = Number(parseFloat((Math.round(Number(customValues[(CUSTOM_maxN * 2 + 5) * i + 2]) * 100) / 100).toFixed(2)));               
            CUSTOM_REF_Z[i] = Number(parseFloat((Math.round(Number(customValues[(CUSTOM_maxN * 2 + 5) * i + 3]) * 100) / 100).toFixed(2)));    
            CUSTOM_NUMBER[i] = Number(parseFloat((Math.round(Number(customValues[(CUSTOM_maxN * 2 + 5) * i + 4]) * 100) / 100).toFixed(0)));        
    
            for (let j = 0; j < CUSTOM_NUMBER[i]; j++) {
                CUSTOM_X[j][i] = Number(parseFloat((Math.round(Number(customValues[(CUSTOM_maxN * 2 + 5) * i + 2 * j + 5]) * 100) / 100).toFixed(2)));
                CUSTOM_Y[j][i] = Number(parseFloat((Math.round(Number(customValues[(CUSTOM_maxN * 2 + 5) * i + 2 * j + 6]) * 100) / 100).toFixed(2)));           
            }                                                               
        }

        setCUSTOM_NAME(CUSTOM_NAME)
        setCUSTOM_NUMBER(CUSTOM_NUMBER)
        setCUSTOM_X(CUSTOM_X)
        setCUSTOM_Y(CUSTOM_Y)
        setCUSTOM_REF(CUSTOM_REF)
        setCUSTOM_REF_Y(CUSTOM_REF_Y)
        setCUSTOM_REF_Z(CUSTOM_REF_Z)

        // Generate Custom shape data points
        let shapeCUSTOM: BABYLON.Vector3[][] = []; // Declare the type explicitly
    
        for (let i = 0; i < N_CUSTOM; i++) {
            // Centroid to bottom of section
            const x: number[] = [];
            const y: number[] = [];
    
            for (let j = 0; j < CUSTOM_NUMBER[i]; j++) {
                x[j] = CUSTOM_X[j][i];
                y[j] = CUSTOM_Y[j][i];         
            }  
            x[CUSTOM_NUMBER[i]] = x[0];
            y[CUSTOM_NUMBER[i]] = y[0];   
    
            const areaCUSTOM = areaSection(x, y, CUSTOM_NUMBER[i]);
            const xc0 = cxSection(x, y, areaCUSTOM, CUSTOM_NUMBER[i]);
            const yc0 = cySection(x, y, areaCUSTOM, CUSTOM_NUMBER[i]); 
    
            const maxZ = maxValue(x)[0]; 
            const minZ = minValue(x)[0]; 
            const maxY = maxValue(y)[0];  
            const minY = minValue(y)[0];       
    
            let xc: number = 0; // Declare xc and initialize it to a default value
            let yc: number = 0; // Same for yc
    
            switch (CUSTOM_REF[i]) {
                case 'CG':   
                    yc = yc0 + CUSTOM_REF_Y[i];
                    xc = xc0 + CUSTOM_REF_Z[i];        
                    break;
                case 'TOP':     
                    yc = maxY + CUSTOM_REF_Y[i];
                    xc = xc0 + CUSTOM_REF_Z[i];       
                    break;
                case 'BOTTOM':       
                    yc = minY + CUSTOM_REF_Y[i];
                    xc = xc0 + CUSTOM_REF_Z[i];      
                    break;
                case 'LEFT':       
                    yc = yc0 + CUSTOM_REF_Y[i];
                    xc = minZ + CUSTOM_REF_Z[i];      
                    break;            
                case 'RIGHT':    
                    yc = yc0 + CUSTOM_REF_Y[i];
                    xc = maxZ + CUSTOM_REF_Z[i];   
                    break;
            }                  
    
            shapeCUSTOM[i] = shapePoints(x, y, xc, yc, CUSTOM_NUMBER[i]);         
        }

        setShapeCUSTOM(shapeCUSTOM)

        function sectionShape(shapeName: string): (BABYLON.Vector3 | string | number)[] {    
            let shapeP: (BABYLON.Vector3 | string | number)[] = []; // Declare shapeP with explicit type
    
            for (let i = 0; i < N_RECTAN; i++) { // Rectangular shape      
                if (shapeName === RECTAN_NAME[i]) {
                    shapeP.push(...shapeRECTAN[i]); // Use spread operator to add all elements
                }  
            } 
    
            for (let i = 0; i < N_CIRCLE; i++) { // Circle shape (SPECIAL CASE)     
                if (shapeName === CIRCLE_NAME[i]) {
                    shapeP.push("CIRCLE");             
                    shapeP.push(CIRCLE_RADIUS[i]);
                    shapeP.push(CIRCLE_REF[i]);
                    shapeP.push(CIRCLE_REF_Y[i]);
                    shapeP.push(CIRCLE_REF_Z[i]);
                }  
            } 
    
            for (let i = 0; i < N_TRAP; i++) { // Trapezoidal shape
                if (shapeName === TRAP_NAME[i]) {
                    shapeP = [...shapeTRAP[i]]; // Assign with spread operator
                }          
            }
    
            for (let i = 0; i < N_TRIAN; i++) { // Triangular shape
                if (shapeName === TRIAN_NAME[i]) {
                    shapeP = [...shapeTRIAN[i]]; // Assign with spread operator
                }          
            }
    
            for (let i = 0; i < N_QUAD; i++) { // Quadrilateral shape
                if (shapeName === QUAD_NAME[i]) {
                    shapeP = [...shapeQUAD[i]]; // Assign with spread operator
                }          
            }
    
            for (let i = 0; i < N_ISHAPE; i++) { // I shape
                if (shapeName === ISHAPE_NAME[i]) {
                    shapeP = [...shapeISHAPE[i]]; // Assign with spread operator
                }          
            }
    
            for (let i = 0; i < N_BOX; i++) { // Box shape
                if (shapeName === BOX_NAME[i]) {
                    shapeP = [...shapeBOX[i]]; // Assign with spread operator
                }          
            }
    
            for (let i = 0; i < N_SLOPE; i++) { // SLOPE shape
                if (shapeName === SLOPE_NAME[i]) {
                    shapeP = [...shapeSLOPE[i]]; // Assign with spread operator
                }          
            }
    
            for (let i = 0; i < N_CUSTOM; i++) { // Custom shape
                if (shapeName === CUSTOM_NAME[i]) {
                    shapeP = [...shapeCUSTOM[i]]; // Assign with spread operator
                }          
            }
    
            return shapeP; // Return the array of shape points
        }
    
            // Shape used for rendering only
            var NCAPSHAPE = NINPUT(FIND_SECTION(KC_show,label),"CAP");   
            var NTUBSHAPE = NINPUT(FIND_SECTION(KC_show,label),"TUB");   
            var NPLATESHAPE = NINPUT(FIND_SECTION(KC_show,label),"PLATE");   
            var NAASHTOSHAPE = NINPUT(FIND_SECTION(KC_show,label),"AASHTO");   
            var NFIBSHAPE = NINPUT(FIND_SECTION(KC_show,label),"FIB");  
            var NNPBRG = NINPUT(FIND_SECTION(KC_show,label),"NPPBRG");  
            var NPOTBRG = NINPUT(FIND_SECTION(KC_show,label),"POTBRG");  
            var NDISKBRG = NINPUT(FIND_SECTION(KC_show,label),"DISKBRG");  
    

            setNCAPSHAPE(NCAPSHAPE)
            setNTUBSHAPE(NTUBSHAPE)
            stNPLATESHAPE(NPLATESHAPE)
            setNAASHTOSHAPE(NAASHTOSHAPE)
            setNFIBSHAPE(NFIBSHAPE)
            setNNPBRG(NNPBRG)
            setNPOTBRG(NPOTBRG)
            setNDISKBRG(NDISKBRG)


        // Input cap shape information
        const capShape_name: string[] = []; // shape name    
        const capShape_seg: number[] = []; // Number of segments, cantilever counted as a segment       
        const capShape_width: number[] = []; // cap width  
        const capShape_colwidth: number[] = []; // column width          
        const capShape_depth_tip: number[] = []; // cap depth at tip
        const capShape_vary1: number[] = []; // cap depth at 1/4 point
        const capShape_vary2: number[] = []; // cap depth at 1/2 point
        const capShape_vary3: number[] = []; // cap depth at 3/4 point    
        const capShape_depth_root: number[] = []; // cap depth at root of cantilever
        const capShape_mid1: number[] = []; // cap depth at mid 1/8 point  
        const capShape_mid2: number[] = []; // cap depth at mid 1/4 point
        const capShape_mid3: number[] = []; // cap depth at mid 3/8 point
        const capShape_mid4: number[] = []; // cap depth at mid 1/2 point  
        const capShape_mid5: number[] = []; // cap depth at mid 5/8 point
        const capShape_mid6: number[] = []; // cap depth at mid 3/4 point     
        const capShape_mid7: number[] = []; // cap depth at mid 7/8 point     
        const capShape_vary_length: number[] = []; // cap length for varied depth portion in cantilever
        const capShape_left_length: number[] = []; // cap left cantilever length
        const capShape_mid_length: number[] = []; // cap mid segment length (duplicate when number of segment exceeds 3)     
        const capShape_right_length: number[] = []; // cap right cantilever length 
        

        const max21 = 20;
        for (let i = 0; i < NCAPSHAPE; i++) {
            capShape_name[i] = String(VALUE(KL_show, "CAP", max21)[max21 * i]);
            capShape_seg[i] = Number(VALUE(KL_show, "CAP", max21)[max21 * i + 1]);
    
            for (let j = 2; j <= 19; j++) {
                const value = VALUE(KL_show, "CAP", max21)[max21 * i + j];
                if (typeof value === 'number') {
                    // Only round if the value is a number
                    const roundedValue = Math.round(value * 100) / 100;
                    switch (j) {
                        case 2: capShape_width[i] = roundedValue; break;
                        case 3: capShape_colwidth[i] = roundedValue; break;
                        case 4: capShape_depth_tip[i] = roundedValue; break;
                        case 5: capShape_vary1[i] = roundedValue; break;
                        case 6: capShape_vary2[i] = roundedValue; break;
                        case 7: capShape_vary3[i] = roundedValue; break;
                        case 8: capShape_depth_root[i] = roundedValue; break;
                        case 9: capShape_mid1[i] = roundedValue; break;
                        case 10: capShape_mid2[i] = roundedValue; break;
                        case 11: capShape_mid3[i] = roundedValue; break;
                        case 12: capShape_mid4[i] = roundedValue; break;
                        case 13: capShape_mid5[i] = roundedValue; break;
                        case 14: capShape_mid6[i] = roundedValue; break;
                        case 15: capShape_mid7[i] = roundedValue; break;
                        case 16: capShape_vary_length[i] = roundedValue; break;
                        case 17: capShape_left_length[i] = roundedValue; break;
                        case 18: capShape_mid_length[i] = roundedValue; break;
                        case 19: capShape_right_length[i] = roundedValue; break;
                    }
                } else {
                    console.error('Unexpected value type for cap shape at index', i, j, value);
                    // Handle default assignment if necessary
                }
            }
        }
        setCapShape_name(capShape_name)
        setCapShape_seg(capShape_seg)
        setCapShape_width(capShape_width)
        setCapShape_colwidth(capShape_colwidth)
        setCapShape_depth_tip(capShape_colwidth)
        setCapShape_vary1(capShape_vary1)
        setCapShape_vary2(capShape_vary2)
        setCapShape_vary3(capShape_vary3)
        setCapShape_depth_root(capShape_depth_root)
        setCapShape_mid1(capShape_mid1)
        setCapShape_mid2(capShape_mid2)
        setCapShape_mid3(capShape_mid3)
        setCapShape_mid4(capShape_mid4)
        setCapShape_mid5(capShape_mid5)
        setCapShape_mid6(capShape_mid6)
        setCapShape_mid7(capShape_mid7)
        setCapShape_vary_length(capShape_vary_length)
        setCapShape_left_length(capShape_left_length)
        setCapShape_mid_length(capShape_mid_length)
        setCapShape_right_length(capShape_right_length)

        /*
    
         var shapePierCap_center = []; // Shape points for the center of the cap
         var offset = 0.1; // offset from center of the cap
         for(var i=0; i<NCAPSHAPE; i++){   
         var lengthPierCap = capShape_left_length[i] + capShape_mid_length[i] * (capShape_seg[i]-2) + capShape_right_length[i];
         var shapeCap_center = [
         new BABYLON.Vector3(-lengthPierCap / 2, -1*capShape_depth_tip[i] /2 + offset, 0)
         ];
         if(capShape_vary_length[i] + 1/2*capShape_colwidth[i] < capShape_left_length[i]){
            var diff = capShape_left_length[i] - capShape_vary_length[i] - 1/2*capShape_colwidth[i]; 
            var cant = capShape_vary_length[i];
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + diff, -1*capShape_depth_tip[i]/2 + offset, 0));  
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + diff + cant*1/4, -1*capShape_vary1[i]/2 + offset, 0));
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + diff + cant*2/4, -1*capShape_vary2[i]/2 + offset, 0)); 
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + diff + cant*3/4, -1*capShape_vary3[i]/2 + offset, 0)); 
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + diff + cant, -1*capShape_depth_root[i]/2 + offset, 0));                                           
         } else{
            var cant = capShape_left_length[i] - 1/2*capShape_colwidth[i];
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + cant*1/4, -1*capShape_vary1[i]/2 + offset, 0));
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + cant*2/4, -1*capShape_vary2[i]/2 + offset, 0)); 
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + cant*3/4, -1*capShape_vary3[i]/2 + offset, 0)); 
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + cant, -1*capShape_depth_root[i]/2 + offset, 0));         
         }
    
         var n_mid = capShape_seg[i]-2;
         var dis_right = capShape_mid_length[i] * n_mid / 2;
         var dis_left = -1*dis_right;
         shapeCap_center.push(new BABYLON.Vector3(dis_left, -1*capShape_depth_root[i]/2 + offset, 0));      
         for(var j=0;j<n_mid;j++){
         var midseg = (capShape_mid_length[i] - capShape_colwidth[i]) /8; 
         shapeCap_center.push(new BABYLON.Vector3(dis_left + capShape_colwidth[i]/2, -1*capShape_depth_root[i]/2 + offset, 0)); 
         shapeCap_center.push(new BABYLON.Vector3(dis_left + capShape_colwidth[i]/2+ midseg*1, -1*capShape_mid1[i]/2 + offset , 0));
         shapeCap_center.push(new BABYLON.Vector3(dis_left + capShape_colwidth[i]/2+ midseg*2, -1*capShape_mid2[i]/2 + offset , 0)); 
         shapeCap_center.push(new BABYLON.Vector3(dis_left + capShape_colwidth[i]/2+ midseg*3, -1*capShape_mid3[i]/2 + offset , 0));
         shapeCap_center.push(new BABYLON.Vector3(dis_left + capShape_colwidth[i]/2+ midseg*4, -1*capShape_mid4[i]/2 + offset , 0));
         shapeCap_center.push(new BABYLON.Vector3(dis_left + capShape_colwidth[i]/2+ midseg*5, -1*capShape_mid5[i]/2 + offset , 0));
         shapeCap_center.push(new BABYLON.Vector3(dis_left + capShape_colwidth[i]/2+ midseg*6, -1*capShape_mid6[i]/2 + offset , 0));  
         shapeCap_center.push(new BABYLON.Vector3(dis_left + capShape_colwidth[i]/2+ midseg*7, -1*capShape_mid7[i]/2 + offset , 0));  
         shapeCap_center.push(new BABYLON.Vector3(dis_left + capShape_colwidth[i]/2+ midseg*8, -1*capShape_depth_root[i]/2 + offset, 0));        
         dis_left = dis_left + capShape_mid_length[i];                       
         }
         if(capShape_vary_length[i] + 1/2*capShape_colwidth[i] < capShape_right_length[i]){
            var diff = capShape_right_length[i] - capShape_vary_length[i] - 1/2*capShape_colwidth[i]; 
            shapeCap.push(new BABYLON.Vector3(lengthPierCap / 2 - diff, -1*capShape_depth_tip[i], 0)); 
            var cant = capShape_vary_length[i];
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - diff - cant, -1*capShape_depth_root[i]/2 + offset, 0)); 
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - diff - cant*3/4, -1*capShape_vary3[i]/2 + offset, 0)); 
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - diff - cant*2/4, -1*capShape_vary2[i]/2 + offset, 0));  
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - diff - cant/4, -1*capShape_vary1[i]/2 + offset, 0));                                  
         } else{
            var cant = capShape_right_length[i] - 1/2*capShape_colwidth[i];
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - cant, -1*capShape_depth_root[i]/2 + offset, 0));  
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - cant*3/4, -1*capShape_vary3[i]/2 + offset, 0));  
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - cant*2/4, -1*capShape_vary2[i]/2 + offset, 0));  
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - cant/4, -1*capShape_vary1[i]/2 + offset, 0));         
         }
         // Reverse the points back from right to left
         if(capShape_vary_length[i] + 1/2*capShape_colwidth[i] < capShape_right_length[i]){
            var diff = capShape_right_length[i] - capShape_vary_length[i] - 1/2*capShape_colwidth[i]; 
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - diff, -1*capShape_depth_tip[i], 0)); 
            var cant = capShape_vary_length[i];
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - diff - cant/4, -1*capShape_vary1[i]/2 - offset, 0));  
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - diff - cant*2/4, -1*capShape_vary2[i]/2 - offset, 0));   
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - diff - cant*3/4, -1*capShape_vary3[i]/2 - offset, 0)); 
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - diff - cant, -1*capShape_depth_root[i]/2 - offset, 0));                                       
         } else{
            var cant = capShape_right_length[i] - 1/2*capShape_colwidth[i];
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - cant/4, -1*capShape_vary1[i]/2 - offset, 0));  
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - cant*2/4, -1*capShape_vary2[i]/2 - offset, 0));   
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - cant*3/4, -1*capShape_vary3[i]/2 - offset, 0));  
            shapeCap_center.push(new BABYLON.Vector3(lengthPierCap / 2 - cant, -1*capShape_depth_root[i]/2 - offset, 0));          
         }
         var n_mid = capShape_seg[i]-2;
         var dis_right = capShape_mid_length[i] * n_mid / 2;
         var dis_left = -1*dis_right;
         shapeCap.push(new BABYLON.Vector3(dis_right, -1*capShape_depth_root[i], 0));      
         for(var j=0;j<n_mid;j++){
         var midseg = (capShape_mid_length[i] - capShape_colwidth[i]) /8; 
         shapeCap_center.push(new BABYLON.Vector3(dis_right - capShape_colwidth[i]/2, -1*capShape_depth_root[i]/2 - offset, 0));           
         shapeCap_center.push(new BABYLON.Vector3(dis_right - capShape_colwidth[i]/2- midseg, -1*capShape_mid7[i]/2 - offset , 0));  
         shapeCap_center.push(new BABYLON.Vector3(dis_right - capShape_colwidth[i]/2- midseg*2, -1*capShape_mid6[i]/2 - offset , 0));       
         shapeCap_center.push(new BABYLON.Vector3(dis_right - capShape_colwidth[i]/2- midseg*3, -1*capShape_mid5[i]/2 - offset , 0));
         shapeCap_center.push(new BABYLON.Vector3(dis_right - capShape_colwidth[i]/2- midseg*4, -1*capShape_mid4[i]/2 - offset , 0));
         shapeCap_center.push(new BABYLON.Vector3(dis_right - capShape_colwidth[i]/2- midseg*5, -1*capShape_mid3[i]/2 - offset , 0));
         shapeCap_center.push(new BABYLON.Vector3(dis_right - capShape_colwidth[i]/2- midseg*6, -1*capShape_mid2[i]/2 - offset , 0)); 
         shapeCap_center.push(new BABYLON.Vector3(dis_right - capShape_colwidth[i]/2- midseg*7, -1*capShape_mid1[i]/2 - offset , 0));
         shapeCap_center.push(new BABYLON.Vector3(dis_right - capShape_colwidth[i]/2- midseg*8, -1*capShape_depth_root[i]/2 - offset, 0)); 
         shapeCap_center.push(new BABYLON.Vector3(dis_right - capShape_colwidth[i]- midseg*8, -1*capShape_depth_root[i]/2 - offset, 0));        
         dis_right = dis_right - capShape_mid_length[i];                       
         }
         if(capShape_vary_length[i] + 1/2*capShape_colwidth[i] < capShape_left_length[i]){
            var diff = capShape_left_length[i] - capShape_vary_length[i] - 1/2*capShape_colwidth[i]; 
            var cant = capShape_vary_length[i];
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + diff + cant, -1*capShape_depth_root[i]/2 - offset, 0));         
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + diff + cant*3/4, -1*capShape_vary3[i]/2 - offset, 0)); 
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + diff + cant*2/4, -1*capShape_vary2[i]/2 - offset, 0));        
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + diff + cant*1/4, -1*capShape_vary1[i]/2 - offset, 0));
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + diff, -1*capShape_depth_tip[i]/2 - offset, 0));  
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2, -1*capShape_depth_tip[i]/2 - offset, 0));                                   
         } else{
            var cant = capShape_left_length[i] - 1/2*capShape_colwidth[i];
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + cant, -1*capShape_depth_root[i]/2 - offset, 0));         
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + cant*3/4, -1*capShape_vary3[i]/2 - offset, 0)); 
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + cant*2/4, -1*capShape_vary2[i]/2 - offset, 0));        
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2 + cant*1/4, -1*capShape_vary1[i]/2 - offset, 0));
            shapeCap_center.push(new BABYLON.Vector3(-1*lengthPierCap / 2, -1*capShape_depth_tip[i]/2 - offset, 0)); 
         }
    
         shapeCap_center.push(shapeCap[0]);
         shapePierCap_center[i] = shapeCap_center;
         }
    
         */
       
        // Input Tub shape information 
        const tubShape_name: string[] = []; // shape name    
        const tubShape_topwidth: number[] = []; // top width from centers of the top flanges
        const tubShape_webslope: number[] = []; // web slope
        const tubShape_webdepth: number[] = []; // web depth
        const tubShape_webthick: number[] = []; // web thickness
        const tubShape_botflext: number[] = []; // bottom flange extension
        const tubShape_topflwidth: number[] = []; // top flange width
        const tubShape_topflthick: number[] = []; // top flange thickness  
        const tubShape_botflthick: number[] = []; // bottom flange thickness  
        const tubShape_points: BABYLON.Vector3[][] = []; // points for tub shape (assuming it can be any type)
    
        const max22 = 9;
        for (let i = 0; i < NTUBSHAPE; i++) {
            tubShape_name[i] = String(VALUE(KL_show, "TUB", max22)[max22 * i]); 
            tubShape_topwidth[i] = Number(VALUE(KL_show, "TUB", max22)[max22 * i + 1]) * unit_ratio; 
            tubShape_webslope[i] = Number(VALUE(KL_show, "TUB", max22)[max22 * i + 2]) * unit_ratio; 
            tubShape_webdepth[i] = Number(VALUE(KL_show, "TUB", max22)[max22 * i + 3]) * unit_ratio; 
            tubShape_webthick[i] = Number(VALUE(KL_show, "TUB", max22)[max22 * i + 4]) * unit_ratio; 
            tubShape_botflext[i] = Number(VALUE(KL_show, "TUB", max22)[max22 * i + 5]) * unit_ratio; 
            tubShape_topflwidth[i] = Number(VALUE(KL_show, "TUB", max22)[max22 * i + 6]) * unit_ratio; 
            tubShape_topflthick[i] = Number(VALUE(KL_show, "TUB", max22)[max22 * i + 7]) * unit_ratio;                                          
            tubShape_botflthick[i] = Number(VALUE(KL_show, "TUB", max22)[max22 * i + 8]) * unit_ratio; 
        }
    
        setTubShape_name(tubShape_name)
        setTubShape_topwidth(tubShape_topwidth)
        setTubShape_webslope(tubShape_webslope)
        setTubShape_webdepth(tubShape_webdepth)
        setTubShape_webthick(tubShape_webthick)
        setTubShape_botflext(tubShape_botflext)
        setTubShape_topflwidth(tubShape_topflwidth)
        setTubShape_topflthick(tubShape_topflthick)
        setTubShape_botflthick(tubShape_botflthick)


         for(var i=0; i<NTUBSHAPE; i++){
           var shape_points3 =  shapeTub(tubShape_topwidth[i],tubShape_webslope[i],tubShape_webdepth[i],tubShape_webthick[i],
           tubShape_botflext[i],tubShape_topflwidth[i],tubShape_topflthick[i],tubShape_botflthick[i]);  
           tubShape_points.push(shape_points3);
         }

         setTubShape_points(tubShape_points)

         function shapeTub(
            widthGirder: number,
            slopeWeb: number,
            depthWeb: number,
            thickWeb: number,
            extBotFlange: number,
            widthTopFlange: number,
            thickTopFlange: number,
            thickBotFlange: number
        ): BABYLON.Vector3[] { // Declare the return type as an array of Vector3
            const widthBotFlange = widthGirder - slopeWeb * depthWeb * 2 + extBotFlange * 2;        
            const shape_tub: BABYLON.Vector3[] = [
                new BABYLON.Vector3(-widthGirder / 2 - widthTopFlange / 2, thickTopFlange, 0), // Point #1
                new BABYLON.Vector3(-widthGirder / 2 + widthTopFlange / 2, thickTopFlange, 0), // Point #2
                new BABYLON.Vector3(-widthGirder / 2 + widthTopFlange / 2, 0, 0), // Point #3
                new BABYLON.Vector3(-widthGirder / 2 + thickWeb / Math.cos(Math.atan(slopeWeb)) / 2, 0, 0), // Point #4
                new BABYLON.Vector3(-widthBotFlange / 2 + extBotFlange + thickWeb / Math.cos(Math.atan(slopeWeb)) / 2, -depthWeb, 0), // Point #5
                new BABYLON.Vector3(widthBotFlange / 2 - extBotFlange - thickWeb / Math.cos(Math.atan(slopeWeb)) / 2, -depthWeb, 0), // Point #6
                new BABYLON.Vector3(widthGirder / 2 - thickWeb / Math.cos(Math.atan(slopeWeb)) / 2, 0, 0), // Point #7
                new BABYLON.Vector3(widthGirder / 2 - widthTopFlange / 2, 0, 0), // Point #8
                new BABYLON.Vector3(widthGirder / 2 - widthTopFlange / 2, thickTopFlange, 0), // Point #9
                new BABYLON.Vector3(widthGirder / 2 + widthTopFlange / 2, thickTopFlange, 0), // Point #10
                new BABYLON.Vector3(widthGirder / 2 + widthTopFlange / 2, 0, 0), // Point #11
                new BABYLON.Vector3(widthGirder / 2 + thickWeb / Math.cos(Math.atan(slopeWeb)) / 2, 0, 0), // Point #12
                new BABYLON.Vector3(widthBotFlange / 2 - extBotFlange + thickWeb / Math.cos(Math.atan(slopeWeb)) / 2, -depthWeb, 0), // Point #13
                new BABYLON.Vector3(widthBotFlange / 2, -depthWeb, 0), // Point #14
                new BABYLON.Vector3(widthBotFlange / 2, -depthWeb - thickBotFlange, 0), // Point #15
                new BABYLON.Vector3(-widthBotFlange / 2, -depthWeb - thickBotFlange, 0), // Point #16
                new BABYLON.Vector3(-widthBotFlange / 2, -depthWeb, 0), // Point #17
                new BABYLON.Vector3(-widthBotFlange / 2 + extBotFlange - thickWeb / Math.cos(Math.atan(slopeWeb)) / 2, -depthWeb, 0), // Point #18
                new BABYLON.Vector3(-widthGirder / 2 - thickWeb / Math.cos(Math.atan(slopeWeb)) / 2, 0, 0), // Point #19
                new BABYLON.Vector3(-widthGirder / 2 - widthTopFlange / 2, 0, 0), // Point #20
            ];
            
            return shape_tub;
        }
            
            // Input Plate girder shape information 
        const plateShape_name: string[] = []; // shape name    
        const plateShape_topflwidth: number[] = []; // top flange width
        const plateShape_topflthick: number[] = []; // top flange thickness  
        const plateShape_botflwidth: number[] = []; // bottom flange width
        const plateShape_botflthick: number[] = []; // bottom flange thickness  
        const plateShape_webdepth: number[] = []; // web depth
        const plateShape_webthick: number[] = []; // web thickness
        const plateShape_points: BABYLON.Vector3[][] = []; // points for plate shape

        setPlateShape_name(plateShape_name)
        setPlateShape_topflwidth(plateShape_topflwidth)
        setPlateShape_topflthick(plateShape_topflthick)
        setPlateShape_botflwidth(plateShape_botflwidth)
        setPlateShape_botflthick(plateShape_botflthick)
        setPlateShape_webdepth(plateShape_webdepth)
        setPlateShape_webthick(plateShape_webthick)

        const max23 = 7;
    
        // Assuming NPLATESHAPE is defined elsewhere in your code
        for (let i = 0; i < NPLATESHAPE; i++) {
            plateShape_name[i] = String(VALUE(KL_show, "PLATE", max23)[max23 * i]); 
            plateShape_topflwidth[i] = Number(VALUE(KL_show, "PLATE", max23)[max23 * i + 1]) * unit_ratio; 
            plateShape_topflthick[i] = Number(VALUE(KL_show, "PLATE", max23)[max23 * i + 2]) * unit_ratio; 
            plateShape_botflwidth[i] = Number(VALUE(KL_show, "PLATE", max23)[max23 * i + 3]) * unit_ratio; 
            plateShape_botflthick[i] = Number(VALUE(KL_show, "PLATE", max23)[max23 * i + 4]) * unit_ratio; 
            plateShape_webdepth[i] = Number(VALUE(KL_show, "PLATE", max23)[max23 * i + 5]) * unit_ratio; 
            plateShape_webthick[i] = Number(VALUE(KL_show, "PLATE", max23)[max23 * i + 6]) * unit_ratio; 
        }
    

         for(var i=0; i<NPLATESHAPE; i++){
           var shape_points4 =  shapePlate(plateShape_topflwidth[i],plateShape_topflthick[i],plateShape_botflwidth[i],
                               plateShape_botflthick[i],plateShape_webdepth[i],plateShape_webthick[i]);  
           plateShape_points.push(shape_points4);
         }

         setPlateShape_points(plateShape_points)

         function shapePlate(
            widthTopFlange: number,
            thickTopFlange: number,
            widthBotFlange: number,
            thickBotFlange: number,
            depthWeb: number,
            thickWeb: number
        ): BABYLON.Vector3[] { // Specify the return type
            // Reference top of web
            const shape_plate: BABYLON.Vector3[] = [
                new BABYLON.Vector3(-widthTopFlange / 2, thickTopFlange, 0), // Point #1
                new BABYLON.Vector3(widthTopFlange / 2, thickTopFlange, 0),  // Point #2
                new BABYLON.Vector3(widthTopFlange / 2, 0, 0),               // Point #3
                new BABYLON.Vector3(thickWeb / 2, 0, 0),                     // Point #4
                new BABYLON.Vector3(thickWeb / 2, -depthWeb, 0),            // Point #5
                new BABYLON.Vector3(widthBotFlange / 2, -depthWeb, 0),      // Point #6
                new BABYLON.Vector3(widthBotFlange / 2, -depthWeb - thickBotFlange, 0), // Point #7
                new BABYLON.Vector3(-widthBotFlange / 2, -depthWeb - thickBotFlange, 0), // Point #8
                new BABYLON.Vector3(-widthBotFlange / 2, -depthWeb, 0),     // Point #9
                new BABYLON.Vector3(-thickWeb / 2, -depthWeb, 0),           // Point #10
                new BABYLON.Vector3(-thickWeb / 2, 0, 0),                   // Point #11
                new BABYLON.Vector3(-widthTopFlange / 2, 0, 0),             // Point #12
            ];
            
            return shape_plate; // Return the shape points
        }
            
            // Input AASHTO beam shape information 
        const aashtoShape_name: string[] = []; // shape name   
        const aashtoShape_D1: number[] = [];
        const aashtoShape_D2: number[] = [];
        const aashtoShape_D3: number[] = [];
        const aashtoShape_D4: number[] = [];
        const aashtoShape_D5: number[] = [];
        const aashtoShape_D6: number[] = [];
        const aashtoShape_B1: number[] = [];
        const aashtoShape_B2: number[] = [];
        const aashtoShape_B3: number[] = [];
        const aashtoShape_B4: number[] = [];
        const aashtoShape_points: BABYLON.Vector3[][] = []; // points for AASHTO shape   
        
        const max24 = 11;
    
        for (let i = 0; i < NAASHTOSHAPE; i++) {
            aashtoShape_name[i] = String(VALUE(KL_show, "AASHTO", max24)[max24 * i]); 
            aashtoShape_D1[i] = Number(VALUE(KL_show, "AASHTO", max24)[max24 * i + 1]) * unit_ratio; 
            aashtoShape_D2[i] = Number(VALUE(KL_show, "AASHTO", max24)[max24 * i + 2]) * unit_ratio; 
            aashtoShape_D3[i] = Number(VALUE(KL_show, "AASHTO", max24)[max24 * i + 3]) * unit_ratio; 
            aashtoShape_D4[i] = Number(VALUE(KL_show, "AASHTO", max24)[max24 * i + 4]) * unit_ratio; 
            aashtoShape_D5[i] = Number(VALUE(KL_show, "AASHTO", max24)[max24 * i + 5]) * unit_ratio; 
            aashtoShape_D6[i] = Number(VALUE(KL_show, "AASHTO", max24)[max24 * i + 6]) * unit_ratio; 
            aashtoShape_B1[i] = Number(VALUE(KL_show, "AASHTO", max24)[max24 * i + 7]) * unit_ratio; 
            aashtoShape_B2[i] = Number(VALUE(KL_show, "AASHTO", max24)[max24 * i + 8]) * unit_ratio;                               
            aashtoShape_B3[i] = Number(VALUE(KL_show, "AASHTO", max24)[max24 * i + 9]) * unit_ratio; 
            aashtoShape_B4[i] = Number(VALUE(KL_show, "AASHTO", max24)[max24 * i + 10]) * unit_ratio;                
        }
    
        setAashtoShape_name(aashtoShape_name)
        setAashtoShape_D1(aashtoShape_D1)
        setAashtoShape_D2(aashtoShape_D2)
        setAashtoShape_D3(aashtoShape_D3)
        setAashtoShape_D4(aashtoShape_D4)
        setAashtoShape_D5(aashtoShape_D5)
        setAashtoShape_D6(aashtoShape_D6)
        setAashtoShape_B1(aashtoShape_B1)
        setAashtoShape_B2(aashtoShape_B2)
        setAashtoShape_B3(aashtoShape_B3)
        setAashtoShape_B4(aashtoShape_B4)
        setAashtoShape_B5(aashtoShape_B5)


         for(var i=0; i<NAASHTOSHAPE; i++){
           var shape_points5 =  shapeAASHTO(aashtoShape_D1[i],aashtoShape_D2[i],aashtoShape_D3[i],aashtoShape_D4[i],aashtoShape_D5[i],
                               aashtoShape_D6[i],aashtoShape_B1[i],aashtoShape_B2[i],aashtoShape_B3[i],aashtoShape_B4[i]);  
           aashtoShape_points.push(shape_points5);
         }

         setAashtoShape_points(aashtoShape_points)

         function shapeAASHTO(
            D1: number,
            D2: number,
            D3: number,
            D4: number,
            D5: number,
            D6: number,
            B1: number,
            B2: number,
            B3: number,
            B4: number
        ): BABYLON.Vector3[] { // Specify the return type explicitly
            // Reference mid of top of the beam  
            const shape_aashto: BABYLON.Vector3[] = [
                new BABYLON.Vector3(-B1 / 2, 0, 0), // Point #1
                new BABYLON.Vector3(B1 / 2, 0, 0), // Point #2
                new BABYLON.Vector3(B1 / 2, -D2, 0), // Point #3
                new BABYLON.Vector3(B3 / 2 + B4, -D2 - D3, 0), // Point #4
                new BABYLON.Vector3(B3 / 2, -D2 - D3 - D4, 0), // Point #5
                new BABYLON.Vector3(B3 / 2, -D1 + D5 + D6, 0), // Point #6
                new BABYLON.Vector3(B2 / 2, -D1 + D6, 0), // Point #7
                new BABYLON.Vector3(B2 / 2, -D1, 0), // Point #8
                new BABYLON.Vector3(-B2 / 2, -D1, 0), // Point #9
                new BABYLON.Vector3(-B2 / 2, -D1 + D6, 0), // Point #10
                new BABYLON.Vector3(-B3 / 2, -D1 + D5 + D6, 0), // Point #11
                new BABYLON.Vector3(-B3 / 2, -D2 - D3 - D4, 0), // Point #12
                new BABYLON.Vector3(-B3 / 2 - B4, -D2 - D3, 0), // Point #13
                new BABYLON.Vector3(-B1 / 2, -D2, 0), // Point #14
            ];
            return shape_aashto;
        }
            
            // Input FIB beam shape information 
        const fibShape_name: string[] = []; // shape name   
        const fibShape_D1: number[] = [];
        const fibShape_D2: number[] = [];
        const fibShape_D3: number[] = [];
        const fibShape_D4: number[] = [];
        const fibShape_D5: number[] = [];
        const fibShape_D6: number[] = [];
        const fibShape_B1: number[] = [];
        const fibShape_B2: number[] = [];
        const fibShape_B3: number[] = [];
        const fibShape_B4: number[] = [];
        const fibShape_B5: number[] = [];
        const fibShape_B6: number[] = [];
        const fibShape_B7: number[] = [];
        const fibShape_B8: number[] = [];
        const fibShape_B9: number[] = [];
        const fibShape_points: BABYLON.Vector3[][] = []; // points for fib shape    
        

        const max25 = 16;
    
        for (let i = 0; i < NFIBSHAPE; i++) {
            fibShape_name[i] = String(VALUE(KL_show, "FIB", max25)[max25 * i]); 
            fibShape_D1[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 1]) * unit_ratio; 
            fibShape_D2[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 2]) * unit_ratio; 
            fibShape_D3[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 3]) * unit_ratio; 
            fibShape_D4[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 4]) * unit_ratio; 
            fibShape_D5[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 5]) * unit_ratio; 
            fibShape_D6[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 6]) * unit_ratio; 
            fibShape_B1[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 7]) * unit_ratio; 
            fibShape_B2[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 8]) * unit_ratio;                               
            fibShape_B3[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 9]) * unit_ratio; 
            fibShape_B4[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 10]) * unit_ratio; 
            fibShape_B5[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 11]) * unit_ratio; 
            fibShape_B6[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 12]) * unit_ratio;                               
            fibShape_B7[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 13]) * unit_ratio; 
            fibShape_B8[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 14]) * unit_ratio;      
            fibShape_B9[i] = Number(VALUE(KL_show, "FIB", max25)[max25 * i + 15]) * unit_ratio;  
        }
    
        setFibShape_name(fibShape_name)
        setFibShape_D1(fibShape_D1)
        setFibShape_D2(fibShape_D2)
        setFibShape_D3(fibShape_D3)
        setFibShape_D4(fibShape_D4)
        setFibShape_D5(fibShape_D5)
        setFibShape_D6(fibShape_D6)
        setFibShape_B1(fibShape_B1)
        setFibShape_B2(fibShape_B2)
        setFibShape_B3(fibShape_B3)
        setFibShape_B4(fibShape_B4)
        setFibShape_B5(fibShape_B5)
        setFibShape_B6(fibShape_B6)
        setFibShape_B7(fibShape_B7)
        setFibShape_B8(fibShape_B8)
        setFibShape_B9(fibShape_B9)

         for(var i=0; i<NFIBSHAPE; i++){
           var shape_points6 =  shapeFIB(fibShape_D1[i],fibShape_D2[i],fibShape_D3[i],fibShape_D4[i],fibShape_D5[i],
                               fibShape_D6[i],fibShape_B1[i],fibShape_B2[i],fibShape_B3[i],fibShape_B4[i],fibShape_B5[i],
                               fibShape_B6[i],fibShape_B7[i],fibShape_B8[i],fibShape_B9[i]);  
           fibShape_points.push(shape_points6);
         }

         setFibShape_points(fibShape_points)

         function shapeFIB(
            D1: number,
            D2: number,
            D3: number,
            D4: number,
            D5: number,
            D6: number,
            B1: number,
            B2: number,
            B3: number,
            B4: number,
            B5: number,
            B6: number,
            B7: number,
            B8: number,
            B9: number
        ): BABYLON.Vector3[] { // Specify the return type
            // Reference mid of the top of the beam  
            const shape_fib: BABYLON.Vector3[] = [
                new BABYLON.Vector3(-B1 / 2, 0, 0), // Point #1
                new BABYLON.Vector3(B1 / 2, 0, 0), // Point #2
                new BABYLON.Vector3(B1 / 2, -D2, 0), // Point #3
                new BABYLON.Vector3(B3 / 2 + B4, -D2 - D3, 0), // Point #4
                new BABYLON.Vector3(B3 / 2, -D2 - D3 - D4, 0), // Point #5
                new BABYLON.Vector3(B3 / 2, -D1 + D6 + D5, 0), // Point #6
                new BABYLON.Vector3(B3 / 2 + B5, -D1 + D6 + (D5 * 5) / 6, 0), // Point #7
                new BABYLON.Vector3(B3 / 2 + B6, -D1 + D6 + (D5 * 4) / 6, 0), // Point #8
                new BABYLON.Vector3(B3 / 2 + B7, -D1 + D6 + (D5 * 3) / 6, 0), // Point #9
                new BABYLON.Vector3(B3 / 2 + B8, -D1 + D6 + (D5 * 2) / 6, 0), // Point #10
                new BABYLON.Vector3(B3 / 2 + B9, -D1 + D6 + (D5 * 1) / 6, 0), // Point #11
                new BABYLON.Vector3(B2 / 2, -D1 + D6, 0), // Point #12  
                new BABYLON.Vector3(B2 / 2, -D1, 0), // Point #13
                new BABYLON.Vector3(-B2 / 2, -D1, 0), // Point #14
                new BABYLON.Vector3(-B2 / 2, -D1 + D6, 0), // Point #15
                new BABYLON.Vector3(-B3 / 2 - B9, -D1 + D6 + (D5 * 1) / 6, 0), // Point #16
                new BABYLON.Vector3(-B3 / 2 - B8, -D1 + D6 + (D5 * 2) / 6, 0), // Point #17
                new BABYLON.Vector3(-B3 / 2 - B7, -D1 + D6 + (D5 * 3) / 6, 0), // Point #18
                new BABYLON.Vector3(-B3 / 2 - B6, -D1 + D6 + (D5 * 4) / 6, 0), // Point #19
                new BABYLON.Vector3(-B3 / 2 - B5, -D1 + D6 + (D5 * 5) / 6, 0), // Point #20                                   
                new BABYLON.Vector3(-B3 / 2, -D1 + D6 + D5, 0), // Point #21
                new BABYLON.Vector3(-B3 / 2, -D2 - D3 - D4, 0), // Point #22
                new BABYLON.Vector3(-B3 / 2 - B4, -D2 - D3, 0), // Point #23
                new BABYLON.Vector3(-B1 / 2, -D2, 0), // Point #24
            ];
            return shape_fib;
        }
            
            // Input standard neoprene pad information 
        const nppBrgName: string[] = []; // shape name   
        const nppBrgWidth: number[] = [];
        const nppBrgLength: number[] = [];
        const nppBrgHeight: number[] = [];  

        const max26 = 4;
    
        for (let i = 0; i < NNPBRG; i++) {
            nppBrgName[i] = String(VALUE(KL_show, "NPPBRG", max26)[max26 * i]); 
            nppBrgWidth[i] = Number(VALUE(KL_show, "NPPBRG", max26)[max26 * i + 1]); 
            nppBrgLength[i] = Number(VALUE(KL_show, "NPPBRG", max26)[max26 * i + 2]); 
            nppBrgHeight[i] = Number(VALUE(KL_show, "NPPBRG", max26)[max26 * i + 3]);                
        }
    
        setNppBrgName(nppBrgName)
        setNppBrgWidth(nppBrgWidth)
        setNppBrgLength(nppBrgLength)
        setNppBrgHeight(nppBrgHeight)

        // Coordinates for import based on model  
        // Model Inputs (x, y, z coordinates with points shown as a sphere and with labels)
        label = "MODEL";
        KL_show = FIND_SECTION(KC_show, label); 
        // Find number of inputs from each section of the input file
        const NMODEL = NINPUT(FIND_SECTION(KC_show, label), "MODEL");   
        const NCLINE = NINPUT(FIND_SECTION(KC_show, label), "STRCEN");   

        setNMODEL(NMODEL)
        setNCLINE(NCLINE)

        // Input model information  
        const modelName: string[] = []; // model name 
        const modelShow: string[] = []; // show model in 3D rendering view           
        const modelType: string[] = []; // model type
        const modelCenterLine: string[] = []; // model centerline name
        const modelUnit: string[] = []; // unit name
        const modelSuper: string[] = []; // include superstructure
        const modelSub: string[] = []; // include substructure  
        const modelPointBearing: string[] = []; // include nodes at bearing locations       
        const modelPoint10th: string[] = []; // include nodes at 10th point of the span
        const modelPoint20th: string[] = []; // include nodes at 20th point of the span  
        const modelPointXframe: string[] = []; // include nodes at cross-frame location for steel bridge        
        const modelPointSplice: string[] = []; // include nodes at splice location for steel bridge 
    

        const max = 12;
        for (let i = 0; i < NMODEL; i++) {
            modelName[i] = String(VALUE(KL_show, "MODEL", max)[max * i]); 
            modelShow[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 1]);                  
            modelType[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 2]);
            modelCenterLine[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 3]);     
            modelUnit[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 4]);       
            modelSuper[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 5]); 
            modelSub[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 6]); 
            modelPointBearing[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 7]); 
            modelPoint10th[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 8]); 
            modelPoint20th[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 9]); 
            modelPointXframe[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 10]); 
            modelPointSplice[i] = String(VALUE(KL_show, "MODEL", max)[max * i + 11]);                                       
        }
    
        setModelName(modelName)
        setModelShow(modelShow)
        setModelType(modelType)
        setModelCenterLine(modelCenterLine)
        setModelUnit(modelUnit)
        setModelSuper(modelSuper)
        setModelSub(modelSub)
        setModelPointBearing(modelPointBearing)
        setModelPoint10th(modelPoint10th)
        setModelPoint20th(modelPoint20th)
        setModelPointXframe(modelPointXframe)
        setModelPointSplice(modelPointSplice)

        // Input model centerline information  
        const clineName: string[] = []; // centerline name 
        const clineChord: string[] = []; // if the centerline is chorded
        const clineBrgOffset: number[] = []; // bearing offset along baseline, same at each support
        const clineSec: number[] = []; // Number of sections for the centerline  
        


        const maxSec = 30; // Max number of centerline sections allowed   
        const clineGeometry: number[][] = matrix(NCLINE, 4 * maxSec, 0); // Ensure this matrix is a number array
        const max27 = 4 + maxSec * 4;       
    
        for (let i = 0; i < NCLINE; i++) {
            clineName[i] = String(VALUE(KL_show, "STRCEN", max27)[max27 * i]); 
            clineChord[i] = String(VALUE(KL_show, "STRCEN", max27)[max27 * i + 1]); 
            clineBrgOffset[i] = Number(VALUE(KL_show, "STRCEN", max27)[max27 * i + 2]);       
            clineSec[i] = Number(VALUE(KL_show, "STRCEN", max27)[max27 * i + 3]);       
    
            for (let j = 0; j < clineSec[i]; j++) {
                clineGeometry[i][4 * j] = Number(VALUE(KL_show, "STRCEN", max27)[i * max27 + 4 + 4 * j]);  // begin ratio of distance from begin span to specific girder section
                clineGeometry[i][4 * j + 1] = Number(VALUE(KL_show, "STRCEN", max27)[i * max27 + 4 + 4 * j + 1]);  // end ratio of distance from begin span to specific girder section          
                clineGeometry[i][4 * j + 2] = Number(VALUE(KL_show, "STRCEN", max27)[i * max27 + 4 + 4 * j + 2]);  // offset to baseline          
                clineGeometry[i][4 * j + 3] = Number(VALUE(KL_show, "STRCEN", max27)[i * max27 + 4 + 4 * j + 3]); // distance to top of deck  
            }                                          
        }
    
        setClineName(clineName)
        setClineChord(clineChord)
        setClineBrgOffset(clineBrgOffset)
        setClineSec(clineSec)

        setClineGeometry(clineGeometry)
    
       // Import Reference File Information  
         label = "REFERENCE";
         KL_show = FIND_SECTION(KC_show,label); 
         // Find number of inputs from each section of the input file
         var NREFPOINT = NINPUT(FIND_SECTION(KC_show,label),"RFPOINT");      
         var NTOPO = NINPUT(FIND_SECTION(KC_show,label),"TOPO");   
         var NDTM = NINPUT(FIND_SECTION(KC_show,label),"DTM");
         var NAERIAL = NINPUT(FIND_SECTION(KC_show,label),"AERIAL");
         var NUTILITY = NINPUT(FIND_SECTION(KC_show,label),"UTILITY");             
    
         setNREFPOINT(NREFPOINT)
         setNTOPO(NTOPO)
         setNDTM(NDTM)
         setNAERIAL(NAERIAL)
         setNUTILITY(NUTILITY)

            // Import reference point coordinates
        const refPointName: string[] = []; // Specify as an array of strings
        const refPointX: number[] = []; // Specify as an array of numbers
        const refPointY: number[] = []; // Specify as an array of numbers
        const refPointZ: number[] = []; // Specify as an array of numbers

        const max28 = 4;
    
        for (let i = 0; i < NREFPOINT; i++) {
            refPointName[i] = String(VALUE(KL_show, "RFPOINT", max28)[max28 * i]); // Ensure it's a string
            refPointX[i] = Number(VALUE(KL_show, "RFPOINT", max28)[max28 * i + 1]);
            refPointY[i] = Number(VALUE(KL_show, "RFPOINT", max28)[max28 * i + 2]);
            refPointZ[i] = Number(VALUE(KL_show, "RFPOINT", max28)[max28 * i + 3]);
        }

        setRefPointName(refPointName)
        setRefPointX(refPointX)
        setRefPointY(refPointY)
        setRefPointZ(refPointZ)

        // Import topo files
        const topoName: string[] = []; // Array of strings
        const topoShow: string[] = []; // You can specify a more specific type if known
        const topoRef: string[] = []; // You can specify a more specific type if known
        const topoURL: string[] = []; // Array of strings

        const max29 = 4;
    
        for (let i = 0; i < NTOPO; i++) {
            topoName[i] = String(VALUE(KL_show, "TOPO", max29)[max29 * i]); // Ensure it's a string
            topoShow[i] = String(VALUE(KL_show, "TOPO", max29)[max29 * i + 1]);  
            topoRef[i] = String(VALUE(KL_show, "TOPO", max29)[max29 * i + 2]);                        
            topoURL[i] = String(VALUE(KL_show, "TOPO", max29)[max29 * i + 3]); // Ensure it's a string
        }
    

        // Import DTM files
        const dtmName: string[] = []; // Array of strings
        const dtmShow: string[] = []; // You can specify a more specific type if known
        const dtmRef: string[] = []; // You can specify a more specific type if known
        const dtmURL: string[] = []; // Array of strings
        const max30 = 4;
    
        for (let i = 0; i < NDTM; i++) {
            dtmName[i] = String(VALUE(KL_show, "DTM", max30)[max30 * i]); // Ensure it's a string
            dtmShow[i] = String(VALUE(KL_show, "DTM", max30)[max30 * i + 1]); 
            dtmRef[i] = String(VALUE(KL_show, "DTM", max30)[max30 * i + 2]);                         
            dtmURL[i] = String(VALUE(KL_show, "DTM", max30)[max30 * i + 3]); // Ensure it's a string
        }
    

        setDtmName(dtmName)
        setDtmShow(dtmShow)
        setDtmRef(dtmRef)
        setDtmURL(dtmURL)

        // Import Aerial files
        const aerialName: string[] = [];  // Array of strings for aerial names
        const aerialShow: string[] = [];      // You can specify a more specific type if known
        const aerialRef: string[] = [];       // You can specify a more specific type if known
        const aerialXpix: number[] = [];   // Array of numbers for X pixel values
        const aerialRY: number[] = [];      // Array of numbers for RY values
        const aerialRX: number[] = [];      // Array of numbers for RX values
        const aerialYpix: number[] = [];    // Array of numbers for Y pixel values
        const aerialX: number[] = [];       // Array of numbers for X coordinates
        const aerialY: number[] = [];       // Array of numbers for Y coordinates
        const aerialJPG: string[] = [];     // Array of strings for JPG file paths
    
        const max31 = 10;  // Maximum number of properties per aerial file
        for (let i = 0; i < NAERIAL; i++) {
            aerialName[i] = String(VALUE(KL_show, "AERIAL", max31)[max31 * i]); 
            aerialShow[i] = String(VALUE(KL_show, "AERIAL", max31)[max31 * i + 1]);        
            aerialRef[i] = String(VALUE(KL_show, "AERIAL", max31)[max31 * i + 2]); 
            aerialXpix[i] = Number(VALUE(KL_show, "AERIAL", max31)[max31 * i + 3]);
            aerialRY[i] = Number(VALUE(KL_show, "AERIAL", max31)[max31 * i + 4]); 
            aerialRX[i] = Number(VALUE(KL_show, "AERIAL", max31)[max31 * i + 5]); 
            aerialYpix[i] = Number(VALUE(KL_show, "AERIAL", max31)[max31 * i + 6]);
            aerialX[i] = Number(VALUE(KL_show, "AERIAL", max31)[max31 * i + 7]); 
            aerialY[i] = Number(VALUE(KL_show, "AERIAL", max31)[max31 * i + 8]);                                                             
            aerialJPG[i] = String(VALUE(KL_show, "AERIAL", max31)[max31 * i + 9]); 
        }
    
        setAerialName(aerialName)
        setAerialShow(aerialShow)
        setAerialRef(aerialRef)
        setAerialXpix(aerialXpix)
        setAerialRY(aerialRY)
        setAerialRX(aerialRX)
        setAerialYpix(aerialYpix)
        setAerialX(aerialX)
        setAerialY(aerialY)
        setAerialJPG(aerialJPG)

        // Import Utility files
        const utilityName: string[] = []; // Array for utility names
        const utilityShow: string[] = [];     // Array for utility show values; you can specify a more precise type if known
        const utilityRef: string[] = [];      // Array for utility references; specify a more precise type if known
        const utilityURL: string[] = [];    // Array for utility URLs
    
        const max32 = 4; // Maximum number of properties per utility file
        for (let i = 0; i < NUTILITY; i++) {
            utilityName[i] = String(VALUE(KL_show, "UTILITY", max32)[max32 * i]); 
            utilityShow[i] = String(VALUE(KL_show, "UTILITY", max32)[max32 * i + 1]);
            utilityRef[i] = String(VALUE(KL_show, "UTILITY", max32)[max32 * i + 2]);
            utilityURL[i] = String(VALUE(KL_show, "UTILITY", max32)[max32 * i + 3]); // Ensure it's a string
        }

        setUtilityName(utilityName)
        setUtilityShow(utilityShow)
        setUtilityRef(utilityRef)
        setUtilityURL(utilityURL)

        }
    }, [inputFile]);



   useEffect(() => {
    if(fileReady){



                // Function for finding the row numbers for chapter in a text input file; a chapter is the first-level label     
                function FIND_CHAPTER(SEP: string): number[] {
                    const NN: number[] = [9999, 9999]; // Initialize an array to hold the chapter indices
                
                    for (let i = 0; i < lines.length; i++) {
                        if (lines[i].indexOf(SEP) > -1 && lines[i].indexOf("{") > -1) {
                            NN[0] = i + 1; // Chapter start line index
                            for (let j = i + 1; j < lines.length; j++) {
                                if (lines[j].indexOf("{") > -1) { 
                                    NN[1] = j; // Chapter end line index
                                    break;
                                }
                            }
                            break; // Exit the outer loop after finding the first chapter
                        }
                    }
                
                    return NN;
                }
                

                
                // Basic Functions for Getting Info from the Input File

                // Function for finding the row numbers for an input section in a certain chapter; a session is the 2nd level label   
                function FIND_SECTION(KC: number[], SEP: string): number[] {
                    const NN: number[] = [9999, 9999]; // Initialize an array to hold the section indices
                
                    for (let i = KC[0]; i < KC[1]; i++) {
                        if (lines[i].indexOf(SEP) > -1 && lines[i].indexOf("[") > -1) {
                            NN[0] = i + 1; // Section start line index
                            for (let j = i + 1; j < lines.length; j++) {
                                if (lines[j].indexOf("[") > -1 || lines[j].indexOf("{") > -1) { 
                                    NN[1] = j; // Section end line index
                                    break;
                                }
                            }
                            break; // Exit the outer loop after finding the first section
                        }
                    }
                
                    return NN;
                }
                
                
                // Function for finding the row numbers for an input section with a lable identified    
                function FINDI(KL: number[], SEP: string, n: number): number[] {
                    const NN: number[] = [9999, 9999]; // Initialize an array to hold the indices
                
                    for (let i_line = KL[0]; i_line < KL[1]; i_line++) {
                        if (lines[i_line].indexOf(SEP) > -1) {
                            NN[0] = i_line + 1; // Set the start index
                            NN[1] = NN[0] + n + 1; // Allow one additional comment line
                            break; // Exit after finding the first match
                        }
                    }
                
                    return NN;
                }
                
                // Function for finding the number if input within a range of rows with a lable identified    
                function NINPUT(KL: number[], SEP: string): number {
                    const separator = SEP + "=";    
                    let nLabel = 0;
                
                    for (let i_line = KL[0]; i_line < KL[1]; i_line++) {
                        if (lines[i_line].indexOf(separator) > -1) {
                            nLabel += 1; // Increment the count for each label found
                        }
                    }
                
                    return nLabel;
                }
                
                // Function for reading input values from a data file   
                function VALUE(KL: number[], label0: string, n: number): (string | number)[] {
                    const label = label0 + "=" + ",";
                    const value: (string | number)[] = []; // Array can hold both strings and numbers
                    const charEnd: number[] = [];   
                    let nLine = 0;  
                
                    for (let i = KL[0]; i < KL[1]; i++) {
                        if (i >= lines.length) { break; } // Adjust to avoid going out of bounds
                        if (lines[i].indexOf(label) > -1) {             
                            let nComma = 0; 
                            for (let k = lines[i].indexOf(label) + label.length; k < lines[i].length; k++) {
                                if (lines[i].charAt(k) === ",") {
                                    charEnd[nComma] = k; 
                                    nComma++;
                                }
                            }
                            for (let ii = 0; ii < n; ii++) {
                                let start: number;
                                let end: number;
                                
                                if (ii === 0) { 
                                    start = lines[i].indexOf(label) + label.length;
                                    end = charEnd[0];
                                } else {
                                    start = charEnd[ii - 1] + 1;
                                    end = charEnd[ii];
                                }
                
                                const valueSub = Number(lines[i].substring(start, end));
                                if (isNaN(valueSub)) {
                                    value[ii + nLine * n] = lines[i].substring(start, end);
                                } else {
                                    value[ii + nLine * n] = valueSub;
                                } 
                            }
                            nLine++;
                        }       
                    }
                
                    /*
                    for (let i = 0; i < value.length; i++) {
                        // Check if value[i] is a string before calling string methods
                        if (typeof value[i] === 'string') {
                            // Replace the input "#" for "," to avoid conflict with comma separator
                            if (value[i].indexOf("#") > -1) { 
                                value[i] = value[i].replace(/#/g, ','); // Use regex to replace all occurrences
                            }
                        }
                    } 
                        */
                
                    return value;
                    }
                
            


        // Provide an Updated input file by clicking the "show inputs" button in the "File" pull-down menu
        // Update the original Input file
        function updateInputFile(inputWin: Window) { 
            function updateInput(text: string, format: number) {
                if (format === 1) {    
                    inputWin.document.write("<p>" + text + "</p>");
                    // format block for format 1
                } else if (format === 2) {
                    inputWin.document.write("<p>" + text + "</p>");  
                    // format block for format 2     
                } else if (format === 3) {
                    inputWin.document.write("<p>" + text + "</p>"); 
                    // format block for format 3       
                } else {
                    // Handle unexpected format
                }
            }
        
            // By lines
            for (let line = 0; line < lines.length; line++) {
                updateInput(lines[line], 1);      
            }
        }
        


        // Find index of cap shape based on the name of the pier cap shape 
        function indexCapShape(capshape: string): number | undefined {
            for (let j = 0; j < Number(NCAPSHAPE); j++) {
                if (capShape_name[j] === capshape) {
                    return j; // Return the index if found
                }
            }
            return undefined; // Return undefined if not found
        }
    
        // Find index of tub shape based on the name of the tub shape 
        function indexTubShape(tubshape: string): number | undefined {
            for (let j = 0; j < Number(NTUBSHAPE); j++) {
                if (tubShape_name[j] === tubshape) {
                    return j; // Return the index if found
                }
            }
            return undefined; // Return undefined if not found
        }
    
        // Find index of plate shape based on the name of the plate shape 
        function indexPlateShape(plateshape: string): number | undefined {
            for (let j = 0; j < Number(NPLATESHAPE); j++) {
                if (plateShape_name[j] === plateshape) {
                    return j;
                }
            }   
            return undefined; // Return undefined if not found
        }
    
        // Find index of AASHTO shape based on the name of the shape 
        function indexAASHTOShape(aashtoshape: string): number | undefined {
            for (let j = 0; j < Number(NAASHTOSHAPE); j++) {
                if (aashtoShape_name[j] === aashtoshape) {
                    return j;
                }
            }
            return undefined; // Return undefined if not found
        }
    
        // Find index of FIB shape based on the name of the shape 
        function indexFIBShape(fibshape: string): number | undefined { // Explicitly define the parameter and return type
            for (let j = 0; j < Number(NFIBSHAPE); j++) {
                if (fibShape_name[j] === fibshape) {
                    return j;
                }
            }   
            return undefined; // Return undefined if not found
        }
    
        // Find index of reference point based on the name
        function indexRefPoint(refpoint: string): number | undefined { // Specify refpoint as a string
            for (let j = 0; j < Number(NREFPOINT); j++) {
                if (refPointName[j] === refpoint) {
                    return j; // Return the index if found
                }
            }
            return undefined; // Return undefined if not found
        }

        // Find index of neoprene pad shape based on the name of the shape 
        function indexNPP(npp: string): number | undefined {
            for (let j = 0; j < Number(NNPBRG); j++) {
                if (nppBrgName[j] === npp) {
                    return j;
                }
            }
            return undefined; // return undefined if not found
        }

        // Find index of topo based on the name
        function indexTopo(topo: string): number | undefined { // Specify topo as a string
            for (let j = 0; j < Number(NTOPO); j++) {
                if (topoName[j] === topo) {
                    return j; // Return the index if found
                }
            }
            return undefined; // Return undefined if not found
        }

        // Find index of model centerline based on the name of the centerline
        function indexCLine(cline: string): number | undefined { // Specify cline as a string
            for (let j = 0; j < Number(NCLINE); j++) {
                if (clineName[j] === cline) {
                    return j; // Return the index if found
                }
            }
            return undefined; // Return undefined if not found
        }
    
        // Find index of DTM based on the name
        function indexDtm(dtm: string): number | undefined { // Specify dtm as a string
            for (let j = 0; j < Number(NDTM); j++) {
                if (dtmName[j] === dtm) {
                    return j; // Return the index if found
                }
            }
            return undefined; // Return undefined if not found
        }

        // Find index of aerial based on the name of the aerial
        function indexAerial(aerial: string): number | undefined { // Specify aerial as a string
            for (let j = 0; j < Number(NAERIAL); j++) {
                if (aerialName[j] === aerial) {
                    return j; // Return the index if found
                }
            }
            return undefined; // Return undefined if not found
        }

    
        // Find index of utility based on the name of the utility
        function indexUtility(utility: string): number | undefined { // Specify utility as a string
            for (let j = 0; j < Number(NUTILITY); j++) {
                if (utilityName[j] === utility) {
                    return j; // Return the index if found
                }
            }
            return undefined; // Return undefined if not found
        }



        // Index for unit viewing options
        function indexUnitOn(unit:string){
            for(var j=0;j<Number(NUNITON);j++){
                if(unitOnNum[j] == unit){
                    return j;}
            }   
            }
    
            // Index for main viewing options
            function indexMainOn(main: string): number | undefined { // Specify the type for main
                for (let j = 0; j < Number(NMAINON); j++) {
                    if (mainOnNum[j] === main) {
                        return j; // Return the index if found
                    }
                }
                return undefined; // Return undefined if not found
            }
        
            // Index for horizontal curves
            function indexHcurve(hcurve: string): number | undefined {
                for (let j = 0; j < Number(NHCURVE); j++) {
                    if (hcurveNum[j] === hcurve) {
                        return j; // Return the index if found
                    }
                }
                return undefined; // Return undefined if not found
            }
    
            // Index for SuperElevation curves
            function indexSEcurve(securve: string): number | undefined { // Specify the type for securve
                for (let j = 0; j < Number(NSECURVE); j++) {
                    if (securveNum[j] === securve) {
                        return j; // Return the index if found
                    }
                }
                return undefined; // Return undefined if not found
            }        
    
            // Find index of road based on the name of the road
            function indexRoad(road: string): number | undefined { // Specify the type for road
                for (let j = 0; j < Number(NROAD); j++) {
                    if (roadNum[j] === road) {
                        return j; // Return the index if found
                    }
                }
                return undefined; // Return undefined if not found
            }
    
            function indexAlign(align: string): number | undefined { // Specify the type for align
    
                for (let j = 0; j < Number(NALIGNMENT); j++) {
                    if (alignmentNum[j] === align) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of wall based on the name of the wall
            function indexWall(wall: string): number | undefined { // Specify the type for wall
                for (let j = 0; j < Number(NWALL); j++) {
                    if (wallNum[j] === wall) {
                        return j; // Return the index if found
                    }
                }
                return undefined; // Return undefined if not found
            }
        
            function indexVcurve(vcurve:string){
                for(var j=0;j<Number(NVCURVE);j++){
                    if(vcurveNum[j] == vcurve){
                        return j;}
                }   
            }
        
            // Find index of stripe based on the name of the stripe
            function indexStripe(stripe: string): number | undefined {
                for (let j = 0; j < Number(NSTRIPE); j++) {
                    if (stripeNum[j] === stripe) {
                        return j; // Return the index if found
                    }
                }
                return undefined; // Return undefined if not found
            }
            
            // Find index of barrier based on the name of the barrier
            function indexBarrier(barrier: string): number | undefined {
                for (let j = 0; j < Number(NBARRIER); j++) {
                    if (barrierNum[j] === barrier) {
                        return j; // Return the index if found
                    }
                }
                return undefined; // Return undefined if not found
            }
        
            // Find index of deck based on the name of the deck
            function indexDeck(deck: string): number | undefined {
                for (let j = 0; j < Number(NDECK); j++) {
                    if (deckNum[j] === deck) {
                        return j; // Return the index if found
                    }
                }
                return undefined; // Return undefined if not found
            }
        
            // Find index of haunch section based on the name of the haunch section 
            function indexHaunch(haunch: string): number | undefined {
                for (let j = 0; j < Number(NHAUNCH); j++) {
                    if (haunchNum[j] === haunch) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of bearing section based on the name of the bearing section 
            function indexBearing(bearing: string): number | undefined {
                for (let j = 0; j < Number(NBEARING); j++) {
                    if (bearingNum[j] === bearing) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of approach slab based on the name of the approach slab
            function indexAppSB(approachsb: string): number | undefined {
                for (let j = 0; j < Number(NAPPSLAB); j++) {
                    if (appSBNum[j] === approachsb) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
             // Find index of girder based on the name of the girder
             function indexGirder(girder: string): number | undefined {
                for (let j = 0; j < Number(NGIRDER); j++) {
                    if (girderNum[j] === girder) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
               
            // Find index of beam type based on the name of the beam type
            function indexBeam(beam: string): number | undefined {
                for (let j = 0; j < Number(NBEAM); j++) {
                    if (beamNum[j] === beam) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
                
            // Find index of span based on the name of the span
            function indexSpan(span: string): number | undefined {
                for (let j = 0; j < Number(NSPAN); j++) {
                    if (spanNum[j] === span) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of cap based on the name of the cap
            function indexCap(cap: string): number | undefined {
                for (let j = 0; j < Number(NCAP); j++) {
                    if (capNum[j] === cap) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
    
            // Find index of column based on the name of the column
            function indexCol(col: string): number | undefined {
                for (let j = 0; j < Number(NCOL); j++) {
                    if (columnNum[j] === col) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
            // Find index of footing based on the name of the footing
            function indexFooting(footing: string): number | undefined {
                for (let j = 0; j < Number(NFOOTING); j++) {
                    if (footingNum[j] === footing) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of foundation based on the name of the foundation
            function indexPile(pile: string): number | undefined {
                for (let j = 0; j < Number(NPILE); j++) {
                    if (pileNum[j] === pile) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
    
            // Find index of pier based on the name of the pier
            function indexPier(pier: string): number | undefined {
                for (let j = 0; j < Number(NPIER); j++) {
                    if (pierNum[j] === pier) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
                
            // Find index of pedestal based on the name of the pedestal on each pier
            function indexPestal(pedestal: string): number | undefined {
                for (let j = 0; j < Number(NPEDESTAL); j++) {
                    if (pedestalNum[j] === pedestal) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
                
            // Find index of stripe section based on the name of the stripe section 
            function indexStripeSec(stripesec: string): number | undefined {
                for (let j = 0; j < Number(NSTRIPESEC); j++) {
                    if (stripesecNum[j] === stripesec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of roadway section based on the name of the roadway section 
            function indexRoadSec(roadsec: string): number | undefined {
                for (let j = 0; j < Number(NROADSEC); j++) {
                    if (roadsecNum[j] === roadsec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of MSE section based on the name of the section 
            function indexMSESec(msesec: string): number | undefined {
                for (let j = 0; j < Number(NMSESEC); j++) {
                    if (msesecNum[j] === msesec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of approach slab section based on the name of the approach slab section 
            function indexAppSBSec(appsbsec: string): number | undefined {
                for (let j = 0; j < Number(NAPPSBSEC); j++) {
                    if (appsbsecNum[j] === appsbsec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
        
            // Find index of deck section based on the name of the deck section 
            function indexDeckSec(decksec: string): number | undefined {
                for (let j = 0; j < Number(NDECKSEC); j++) {
                    if (decksecNum[j] === decksec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of girder section based on the name of the girder section 
            function indexGirderSec(girdersec: string): number | undefined {
                for (let j = 0; j < Number(NGIRSEC); j++) {
                    if (girdersecNum[j] === girdersec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of barrier section based on the name of the barrier section 
            function indexBarrierSec(barriersec: string): number | undefined {
                for (let j = 0; j < Number(NBARRSEC); j++) {
                    if (barriersecNum[j] === barriersec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
    
            // Find index of pier cap section based on the name of the pier cap section 
            function indexCapSec(capsec: string): number | undefined {
                for (let j = 0; j < Number(NCAPSEC); j++) {
                    if (piercapsecNum[j] === capsec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
    
        
            // Find index of pier column section based on the name of the pier column section 
            function indexColSec(colsec: string): number | undefined {
                for (let j = 0; j < Number(NCOLSEC); j++) {
                    if (piercolsecNum[j] === colsec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
                
            // Find index of pier footing section based on the name of the pier footing section 
            function indexFootSec(footsec: string): number | undefined {
                for (let j = 0; j < Number(NFOOTSEC); j++) {
                    if (pierfootsecNum[j] === footsec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
    
            // Find index of pile section based on the name of the pile section 
            function indexPileSec(pilesec: string): number | undefined {
                for (let j = 0; j < Number(NPILESEC); j++) {
                    if (pilesecNum[j] === pilesec) {
                        return j; // Return the index if found
                    }
                }   
                return undefined; // Return undefined if not found
            }
        
            // Find index of box shape based on the name of the box shape 
            function indexBoxShape(boxshape: string): number | undefined {
                for (let j = 0; j < Number(N_BOX); j++) {
                    if (BOX_NAME[j] === boxshape) {
                        return j;
                    }
                }
                return undefined; // Return undefined if not found
            }
        

        // Function to return shape points for the Caps
        function slopedShapePierCap(index: number, slope: number): BABYLON.Vector3[] {
            slope = slope * -1;  // Reverse the slope to have the left side higher facing upstream
            const shapeCap: BABYLON.Vector3[] = []; // Define shapeCap with the appropriate type
    
            for (let i = 0; i < Number(NCAPSHAPE); i++) {
                if (index === i) {
                    const lengthPierCap = 
                        capShape_left_length[i] + 
                        capShape_mid_length[i] * (capShape_seg[i] - 2) + 
                        capShape_right_length[i];
                    const width1 = -lengthPierCap / 2;   
                    const width2 = lengthPierCap / 2;  
                    const depth1 = -1 * capShape_depth_tip[i];
    
                    shapeCap.push(new BABYLON.Vector3(width1, width1 * slope, 0));
                    shapeCap.push(new BABYLON.Vector3(width2, width2 * slope, 0));
                    shapeCap.push(new BABYLON.Vector3(width2, depth1 + width2 * slope, 0));
    
                    if (capShape_vary_length[i] + 1 / 2 * capShape_colwidth[i] < capShape_right_length[i]) {
                        const diff = capShape_right_length[i] - capShape_vary_length[i] - 1 / 2 * capShape_colwidth[i]; 
                        const width3 = lengthPierCap / 2 - diff;
                        const depth2 = -1 * capShape_depth_tip[i];
                        shapeCap.push(new BABYLON.Vector3(width3, depth2 + width3 * slope, 0));
    
                        const cant = capShape_vary_length[i];
                        // Create additional points based on varied depths
                        for (let j = 0; j < 8; j++) {
                            const width = lengthPierCap / 2 - diff - cant * (j / 4);
                            const depth = -1 * capShape_vary1[i]; // Modify as needed for each case
                            shapeCap.push(new BABYLON.Vector3(width, depth + width * slope, 0)); 
                        }
                    } else {
                        const cant = capShape_right_length[i] - 1 / 2 * capShape_colwidth[i];
                        // Create points based on a different calculation
                        for (let j = 0; j < 8; j++) {
                            const width = lengthPierCap / 2 - cant * (j / 4);
                            const depth = -1 * capShape_depth_tip[i]; // Modify as needed for each case
                            shapeCap.push(new BABYLON.Vector3(width, depth + width * slope, 0)); 
                        }
                    }
    
                    // Midpoint calculation
                    const n_mid = capShape_seg[i] - 2;
                    let dis_right = capShape_mid_length[i] * n_mid / 2;
                    let dis_left = -dis_right;
                    const depth11 = -1 * capShape_depth_root[i];
                    shapeCap.push(new BABYLON.Vector3(dis_right, depth11 + dis_right * slope, 0));
    
                    for (let j = 0; j < n_mid; j++) {
                        const midseg = (capShape_mid_length[i] - capShape_colwidth[i]) / 8; 
                        const width12 = dis_right - capShape_colwidth[i] / 2;
                        const depth12 = -1 * capShape_depth_root[i];
                        const width13 = dis_right - capShape_colwidth[i] / 2 - midseg;
                        const depth13 = -1 * capShape_mid7[i];
                        const width14 = dis_right - capShape_colwidth[i] / 2 - midseg * 2;
                        const depth14 = -1 * capShape_mid6[i];
                        const width15 = dis_right - capShape_colwidth[i] / 2 - midseg * 3;
                        const depth15 = -1 * capShape_mid5[i];
                        const width16 = dis_right - capShape_colwidth[i] / 2 - midseg * 4;
                        const depth16 = -1 * capShape_mid4[i];
                        const width17 = dis_right - capShape_colwidth[i] / 2 - midseg * 5;
                        const depth17 = -1 * capShape_mid3[i];
                        const width18 = dis_right - capShape_colwidth[i] / 2 - midseg * 6;
                        const depth18 = -1 * capShape_mid2[i];
                        const width19 = dis_right - capShape_colwidth[i] / 2 - midseg * 7;
                        const depth19 = -1 * capShape_mid1[i];
                        const width20 = dis_right - capShape_colwidth[i] / 2 - midseg * 8;
                        const depth20 = -1 * capShape_depth_root[i];
                        const width21 = dis_right - capShape_colwidth[i] - midseg * 8;
                        const depth21 = -1 * capShape_depth_root[i];
    
                        shapeCap.push(new BABYLON.Vector3(width12, depth12 + width12 * slope, 0));           
                        shapeCap.push(new BABYLON.Vector3(width13, depth13 + width13 * slope, 0));  
                        shapeCap.push(new BABYLON.Vector3(width14, depth14 + width14 * slope, 0));       
                        shapeCap.push(new BABYLON.Vector3(width15, depth15 + width15 * slope, 0));
                        shapeCap.push(new BABYLON.Vector3(width16, depth16 + width16 * slope, 0));
                        shapeCap.push(new BABYLON.Vector3(width17, depth17 + width17 * slope, 0));
                        shapeCap.push(new BABYLON.Vector3(width18, depth18 + width18 * slope, 0)); 
                        shapeCap.push(new BABYLON.Vector3(width19, depth19 + width19 * slope, 0));
                        shapeCap.push(new BABYLON.Vector3(width20, depth20 + width20 * slope, 0)); 
                        shapeCap.push(new BABYLON.Vector3(width21, depth21 + width21 * slope, 0));        
                        dis_right -= capShape_mid_length[i];                       
                    }
    
                    if (capShape_vary_length[i] + 1 / 2 * capShape_colwidth[i] < capShape_left_length[i]) {
                        const diff = capShape_left_length[i] - capShape_vary_length[i] - 1 / 2 * capShape_colwidth[i]; 
                        const cant = capShape_vary_length[i];
    
                        const width22 = -1 * lengthPierCap / 2 + diff + cant;
                        const depth22 = -1 * capShape_depth_root[i];
                        const width23 = -1 * lengthPierCap / 2 + diff + cant * 3 / 4;
                        const depth23 = -1 * capShape_vary3[i];
                        const width24 = -1 * lengthPierCap / 2 + diff + cant * 2 / 4;
                        const depth24 = -1 * capShape_vary2[i];        
                        const width25 = -1 * lengthPierCap / 2 + diff + cant * 1 / 4;
                        const depth25 = -1 * capShape_vary1[i];
                        const width26 = -1 * lengthPierCap / 2 + diff;
                        const depth26 = -1 * capShape_depth_tip[i];
                        const width27 = -1 * lengthPierCap / 2;
                        const depth27 = -1 * capShape_depth_tip[i];
    
                        shapeCap.push(new BABYLON.Vector3(width22, depth22 + width22 * slope, 0));         
                        shapeCap.push(new BABYLON.Vector3(width23, depth23 + width23 * slope, 0)); 
                        shapeCap.push(new BABYLON.Vector3(width24, depth24 + width24 * slope, 0));        
                        shapeCap.push(new BABYLON.Vector3(width25, depth25 + width25 * slope, 0));
                        shapeCap.push(new BABYLON.Vector3(width26, depth26 + width26 * slope, 0));  
                        shapeCap.push(new BABYLON.Vector3(width27, depth27 + width27 * slope, 0));
    
                    } else {
                        const cant = capShape_left_length[i] - 1 / 2 * capShape_colwidth[i];
                        const width28 = -1 * lengthPierCap / 2 + cant;
                        const depth28 = -1 * capShape_depth_root[i];
                        const width29 = -1 * lengthPierCap / 2 + cant * 3 / 4;
                        const depth29 = -1 * capShape_vary3[i];
                        const width30 = -1 * lengthPierCap / 2 + cant * 2 / 4;
                        const depth30 = -1 * capShape_vary2[i];
                        const width31 = -1 * lengthPierCap / 2 + cant * 1 / 4;
                        const depth31 = -1 * capShape_vary1[i];
                        const width32 = -1 * lengthPierCap / 2;
                        const depth32 = -1 * capShape_depth_tip[i];
    
                        shapeCap.push(new BABYLON.Vector3(width28, depth28 + depth28 * slope, 0));         
                        shapeCap.push(new BABYLON.Vector3(width29, depth29 + depth29 * slope, 0));
                        shapeCap.push(new BABYLON.Vector3(width30, depth30 + depth30 * slope, 0));        
                        shapeCap.push(new BABYLON.Vector3(width31, depth31 + depth31 * slope, 0));
                        shapeCap.push(new BABYLON.Vector3(width32, depth32 + depth32 * slope, 0)); 
                    }
    
                    shapeCap.push(shapeCap[0]); // Close the shape
                    return shapeCap; // Return the generated shape
                }
            }
            return []; // Return an empty shape if no match is found
        }
    
    

        // Create DTM meshes based on DXF input file
        function showDTM(ref: any, lines: string[], color: any, scene: any): void {
            // Read information from import file
            const KL = findDXFSection("ENTITIES", lines);
            const polyfaceLoc = findDXFPolyfaces(KL, lines);
    
            for (let i = 0; i < polyfaceLoc.length; i++) {
                // Pull coordinates and face information from DXF file 
                const nvertex = Number(lines[polyfaceLoc[i][0] + 22]);
                const nface = Number(lines[polyfaceLoc[i][0] + 24]);
                const vertexXYZ: number[][] = []; // Array to hold vertex coordinates
                const face123: number[][] = []; // Array to hold face indices
    
                for (let j = 0; j < nvertex; j++) {
                    const x = Number(lines[polyfaceLoc[i][0] + 40 + j * 22]);
                    const y = Number(lines[polyfaceLoc[i][0] + 44 + j * 22]); // Switch y and z coordinates
                    const z = Number(lines[polyfaceLoc[i][0] + 42 + j * 22]);
                    vertexXYZ.push([x, y, z]);                             
                }
    
                for (let j = 0; j < nface; j++) {
                    const n1 = Number(lines[polyfaceLoc[i][2][j] + 25]);
                    const n2 = Number(lines[polyfaceLoc[i][2][j] + 23]); // Switch the sequence of the vertex
                    const n3 = Number(lines[polyfaceLoc[i][2][j] + 21]);
                    face123.push([n1, n2, n3]);                             
                }
    
                // Draw triangle meshes
                const dtm_mesh = createDtmMesh(ref, vertexXYZ, face123, color, scene);
            }
        }
        function createDtmMesh(ref: number[], xyz: number[][], faces: number[][], color: any, scene: BABYLON.Scene): BABYLON.Mesh {
            // Let's create a mesh for our element
            const mesh = new BABYLON.Mesh('triangle', scene);
    
            const positions: number[] = [];
            const colors: number[] = [];
            const vertex_color = [0, 1, 0, 1]; // Color in RGBA format
    
            // Insert the mesh by moving to a reference point    
            for (let i = 0; i < xyz.length; i++) {    
                for (let j = 0; j < 3; j++) {
                    positions.push(xyz[i][j] - ref[j]);
                }
                for (let j = 0; j < 4; j++) {
                    colors.push(vertex_color[j]);
                }        
            }
    
            //And the indices, for the points order (need be clockwise to be seen):
            var indices = [];
            for(var i=0;i<faces.length;i++){ 
                for (var j=0;j<3;j++){
                    indices.push((faces[i])[j]-1);
                }                  
            }
    
        // Make a new mesh shaper device.
        const normals: number[] = []; // Explicitly declare the type of normals
        const vertexData = new BABYLON.VertexData();
    
        // Compute normals based on positions and indices
        BABYLON.VertexData.ComputeNormals(positions, indices, normals);  
    
        // Stuff its buffers with your data
        vertexData.positions = positions;
        vertexData.indices = indices;
        vertexData.normals = normals;
        vertexData.colors = colors;
    
        // Use the vertexData object to shape-ify the mesh
        vertexData.applyToMesh(mesh, true);
    
            /******Display custom mesh in wireframe view to show its creation****************/
            var mat = new BABYLON.StandardMaterial("mat", scene);
            //mat.wireframe = true;
            mesh.material = mat;
            mesh.material.backFaceCulling = false;  
    
            return mesh;
        }
        // Create Topo meshes based on DXF input file
        function showTOPO(ref: number[], lines: string[], scene: BABYLON.Scene) {
            // Read information from the import file
            // Pull layer information 
            const KL = findDXFSection("TABLES", lines);
            const dxfLayerLoc = findDXFLayer(KL, lines);
    
            // Example of using the ref parameter
            // You might want to do something like apply the ref to mesh positioning
            // This is just a placeholder for demonstration
    
            // Additional logic to handle the layers would go here
        }
        // Basic Functions for Getting Info from the Input DXF Files
    
        // Function for finding the row numbers for a chapter in a text input file; a chapter is the first-level label     
        function findDXFSection(label: string, lines: string[]): number[] {
            let label1: string = ""; // Initialize label1 with a default value
            
            // Assign the correct label1 based on the input label
            switch (label) {
                case "HEADER":
                    label1 = "CLASSES";
                    break;
                case "CLASSES":
                    label1 = "TABLES";
                    break;
                case "TABLES":
                    label1 = "BLOCKS";
                    break;
                case "BLOCKS":
                    label1 = "ENTITIES";
                    break;
                case "ENTITIES":
                    label1 = "OBJECTS";
                    break;
                case "OBJECTS":
                    label1 = "ACDSDATA";
                    break;
                case "ACDSDATA":
                    label1 = "THUMBNAILIMAGE";
                    break;
                case "THUMBNAILIMAGE":
                    label1 = "EOF";
                    break;
                default:
                    throw new Error("Invalid label provided."); // Handle unexpected labels
            }
    
            const NN: number[] = [9999, 9999];
            for (let i = 0; i < lines.length; i++) {
                if (lines[i].indexOf(label) > -1 && lines[i - 2].indexOf("SECTION") > -1) {
                    NN[0] = i - 2;
                    for (let j = i + 1; j < lines.length; j++) {
                        if ((lines[j].indexOf(label1) > -1 && lines[j - 2].indexOf("SECTION") > -1) || 
                            (lines[j].indexOf(label1) > -1 && label1 === "EOF")) { 
                            NN[1] = j - 3; 
                            break;
                        }
                    }
                }
            }
            return NN;
        }
        function findDXFPolyfaces(KC: number[], lines: string[]): [number, number, number[]][] {
            const polyIndex: [number, number, number[]][] = []; // Array to store polyface mesh indices
            
            for (let i = KC[0]; i < KC[1]; i++) {
                if (lines[i].indexOf("POLYLINE") > -1 && lines[i + 10].indexOf("AcDbPolyFaceMesh") > -1) {
                    const a = i;
                    // Initialize b to a value that signifies "not set"
                    let b: number | undefined; // Use undefined initially
                    
                    // Find end of the polyface mesh
                    for (let j = i + 1; j < KC[1]; j++) {
                        if (lines[j].indexOf("SEQEND") > -1 && lines[j - 1].indexOf("0") > -1) { 
                            b = j + 9; 
                            break;
                        }
                    }
    
                    // Check if b was assigned
                    if (b === undefined) {
                        continue; // Skip this iteration if b was not set
                    }
                    
                    // Find index of face records
                    const c: number[] = [];
                    for (let j = a + 1; j < b; j++) {
                        if (lines[j].indexOf("AcDbFaceRecord") > -1 && lines[j - 1].indexOf("100") > -1) { 
                            const d = j - 11; 
                            c.push(d);
                        }
                    }       
                    polyIndex.push([a, b, c]);    
                }
            }
            return polyIndex;
        }
        // Function for finding the row numbers for each layer in the DXF file 
        function findDXFLayer(KC: number[], lines: string[]): [number, number, any[][]][] {
            const layerIndex: [number, number, any[][]][] = [];    
        
            for (let i = KC[0]; i < KC[1]; i++) {
                if (lines[i].indexOf("LAYER") > -1 && lines[i - 1].indexOf("0") > -1) {
                    const a = i;
                    let b: number | undefined; // Initialize b as undefined
    
                    // Find end of the layer
                    for (let j = i + 1; j < KC[1]; j++) {
                        if ((lines[j].indexOf("LAYER") > -1 && lines[j + 1].indexOf("5") > -1) ||
                            (lines[j].indexOf("ENDTAB") > -1 && lines[j - 1].indexOf("0") > -1)) { 
                            b = j - 1; 
                            break;
                        }
                    } 
    
                    // Check if b was assigned
                    if (b === undefined) {
                        continue; // Skip to the next iteration if b was not set
                    }
    
                    // Find layer information
                    const layerInfo: any[] = [];
                    let name: string | undefined;
                    let on: string | undefined;
                    let freeze = "NO";
                    let lock = "NO";
                    let plot = "1"; 
                    let color: number | undefined;
                    let linetype: string | undefined;
                    let lineweight: number | undefined;
                    let transparancy: number | undefined;
                    let newVPfreeze = "NO";
                    let description: string | undefined;
    
                    for (let j = a + 1; j < b; j++) {
                        if (lines[j].indexOf("2") > -1 && lines[j - 1].indexOf("AcDbLayerTableRecord") > -1) { 
                            name = lines[j + 1];
                        }
                        if (lines[j].indexOf("370") > -1 && lines[j + 2].indexOf("390") > -1) { 
                            lineweight = Number(lines[j + 1]); 
                        }         
                        if (lines[j].indexOf("6") > -1) { 
                            linetype = lines[j + 1];
                        }
                        if (lines[j].indexOf("62") > -1) { 
                            color = Number(lines[j + 1]);
                            on = (color < 0) ? "NO" : "YES";
                            color = Math.abs(color); // Ensure color is positive
                        }
                        if (lines[j].indexOf("1000") > -1 && lines[j - 2].indexOf("1000") > -1) { 
                            description = lines[j + 1];
                        }
                        if (lines[j].indexOf("1071") > -1) { 
                            const trans = Number(lines[j + 1]);
                            transparancy = Math.floor((33554687 - trans) / 2.55) / 100;              
                        }         
                        if (lines[j].indexOf("70") > -1) { 
                            const num = Number(lines[j + 1]);
                            freeze = (num === 0) ? "NO" : (num === 1 || num === 3 || num === 5 || num === 7) ? "YES" : freeze;
                            lock = (num === 4 || num === 5 || num === 6 || num === 7) ? "YES" : "NO";
                            newVPfreeze = (num === 2 || num === 3 || num === 6 || num === 7) ? "YES" : "NO";
                        }  
                        if (lines[j].indexOf("290") > -1) { 
                            plot = lines[j + 1];
                        }
                    } 
    
                    // Add layer information to the index
                    layerInfo.push([name, on, freeze, lock, plot, color, linetype, lineweight, transparancy, newVPfreeze, description]);
                    layerIndex.push([a, b, layerInfo]);    
                }
            }
            return layerIndex;     
        }
    
    
    // Extend the ArcRotateCamera class
    class ExtendedArcRotateCamera extends BABYLON.ArcRotateCamera {
        moveTargetTo(newPos: BABYLON.Vector3, speed: number): void {
            const ease = new BABYLON.CubicEase();
            ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    
            BABYLON.Animation.CreateAndStartAnimation('at5', this, 'target', speed, 120, this.target, newPos, 0, ease);
            BABYLON.Animation.CreateAndStartAnimation('at5', this, 'radius', speed, 240, this.radius, 12, 0, ease);
        }
    }
    
   
    // 2. Object Shape Libriary 
     // 2.1 General Structures Shape
     // Rectangular Shapes
       // Rectangular with level top
       var shapeRectangular = function (width: number, height: number): BABYLON.Vector3[] {
        var shapeRect: BABYLON.Vector3[] = [
            new BABYLON.Vector3(-width / 2, 0, 0),
            new BABYLON.Vector3(width / 2, 0, 0),
            new BABYLON.Vector3(width / 2, -height, 0),    
            new BABYLON.Vector3(-width / 2, -height, 0),
            new BABYLON.Vector3(-width / 2, -0.01, 0),      
        ];
        return shapeRect;
       };
    
       // Rectangular with a slope
       var shapeRectangularSlope = function (width:number,height:number,slope:number){
       var slope = slope * -1;    
       var shapeRect = [
       new BABYLON.Vector3(-width / 2, -width / 2 * slope, 0),
       new BABYLON.Vector3(width / 2, width / 2 * slope, 0),
       new BABYLON.Vector3(width / 2, -height + width / 2 * slope, 0),    
       new BABYLON.Vector3(-width / 2, -height + -width / 2 * slope, 0),
       new BABYLON.Vector3(-width / 2, -0.01 + -width / 2 * slope, 0),      
       ];
       return shapeRect;
       }
    
       // Projected rectangular with a slope
       var shapeRectangularProj = function (width:number,height:number,ang:number,slope:number){
       var slope = slope * -1;           
       var shapeRect = [
       new BABYLON.Vector3(-width / 2, -width / 2 * slope, width/2*Math.tan(ang)),
       new BABYLON.Vector3(width / 2, width / 2 * slope, -width/2*Math.tan(ang)),
       new BABYLON.Vector3(width / 2, -height + width / 2 * slope, -width/2*Math.tan(ang)),    
       new BABYLON.Vector3(-width / 2, -height - width / 2 * slope, width/2*Math.tan(ang)),
       new BABYLON.Vector3(-width / 2, -0.01 - width / 2 * slope, width/2*Math.tan(ang)),      
       ];
       return shapeRect;
       }
       
       // Rotated rectangular
       var shapeRectangularRot = function (width:number,height:number,ang:number){
       var shapeRect = [
       new BABYLON.Vector3(-width / 2*Math.cos(ang) - height/2 *Math.sin(ang) , -width/2 * Math.sin(ang) + height/2 *Math.cos(ang), 0),
       new BABYLON.Vector3(width / 2*Math.cos(ang) - height/2 *Math.sin(ang), width/2 *Math.sin(ang) + height/2*Math.cos(ang), 0),
       new BABYLON.Vector3(width / 2*Math.cos(ang) - (-height/2) *Math.sin(ang), width/2*Math.sin(ang) + (-height/2)*Math.cos(ang), 0),    
       new BABYLON.Vector3(-width / 2*Math.cos(ang) - (-height/2)*Math.sin(ang), (-width/2)*Math.sin(ang) + (-height/2)*Math.cos(ang), 0),
       new BABYLON.Vector3(-width / 2*Math.cos(ang) - (height/2)*Math.sin(ang), (-width/2)*Math.sin(ang) + (height/2-0.01)*Math.cos(ang), 0),      
       ];
       return shapeRect;
       }
    
     // 2.2 Deck
       // Yellow stripe next to barrier
       var shapeStripe = function (width:number,depth:number){
         var shape_stripe = [
         new BABYLON.Vector3(-width/2, 0, 0),
         new BABYLON.Vector3(width/2, 0, 0),
         new BABYLON.Vector3(width/2, -depth, 0),
         new BABYLON.Vector3(-width/2, -depth, 0),
         ];
         shape_stripe.push(shape_stripe[0]);
         return shape_stripe;
       }
    
     // 2.3 Standard Traffic Barrier
       // F32 barrier left
       var shapeFBarrierLeft = function (x:number,y:number){
       var shapeBarrier = [
       new BABYLON.Vector3(0.146 + x, y, 0), // Point #1
       new BABYLON.Vector3(0.146 + x, 1.396 + y, 0), // Point #2
       new BABYLON.Vector3(0.042 + x, 1.5 + y, 0), // Point #3
       new BABYLON.Vector3(0.042 + x, 2.458 + y, 0), // Point #4
       new BABYLON.Vector3(0.083 + x, 2.5 + y, 0), // Point #5
       new BABYLON.Vector3(0.896 + x, 2.5 + y, 0), // Point #6
       new BABYLON.Vector3(0.942 + x, 2.458 + y, 0), // Point #7
       new BABYLON.Vector3(1.101 + x, 1.048 + y, 0), // Point #8
       new BABYLON.Vector3(1.116 + x, 0.959 + y, 0), // Point #9
       new BABYLON.Vector3(1.135 + x, 0.889 + y, 0), // Point #10
       new BABYLON.Vector3(1.164 + x, 0.810 + y, 0), // Point #11
       new BABYLON.Vector3(1.203 + x, 0.732 + y, 0), // Point #12
       new BABYLON.Vector3(1.25 + x, 0.657 + y, 0), // Point #13
       new BABYLON.Vector3(1.542 + x, 0.25 + y, 0), // Point #14
       new BABYLON.Vector3(1.542 + x, y, 0), // Point #15
       ];
       return shapeBarrier;
       }
    
       // F32 barrier right 
       var shapeFBarrierRight = function (x:number,y:number){
       var shapeBarrier = [
       new BABYLON.Vector3(-0.146 + x, y, 0), // Point #1
       new BABYLON.Vector3(-0.146 + x, 1.396 + y, 0), // Point #2
       new BABYLON.Vector3(-0.042 + x, 1.5 + y, 0), // Point #3
       new BABYLON.Vector3(-0.042 + x, 2.458 + y, 0), // Point #4
       new BABYLON.Vector3(-0.083 + x, 2.5 + y, 0), // Point #5
       new BABYLON.Vector3(-0.896 + x, 2.5 + y, 0), // Point #6
       new BABYLON.Vector3(-0.942 + x, 2.458 + y, 0), // Point #7
       new BABYLON.Vector3(-1.101 + x, 1.048 + y, 0), // Point #8
       new BABYLON.Vector3(-1.116 + x, 0.959 + y, 0), // Point #9
       new BABYLON.Vector3(-1.135 + x, 0.889 + y, 0), // Point #10
       new BABYLON.Vector3(-1.164 + x, 0.810 + y, 0), // Point #11
       new BABYLON.Vector3(-1.203 + x, 0.732 + y, 0), // Point #12
       new BABYLON.Vector3(-1.25 + x, 0.657 + y, 0), // Point #13
       new BABYLON.Vector3(-1.542 + x, 0.25 + y, 0), // Point #14
       new BABYLON.Vector3(-1.542 + x, y, 0), // Point #15
       ];
       return shapeBarrier;
       }
    
    
    // 3. Bridge Paths 
       // 3.1 Geometry Curves Functions (by default three vertical/horizontal curves maximum)
     // 3. Bridge Paths 
    // 3.1 Geometry Curves Functions (by default three vertical/horizontal curves maximum)
    
    // Vertical Curve General Functions
    function cPVC(PVI: number, LOC: number): number {
        let cPVC_in = PVI - LOC / 2; 
        return cPVC_in;
    }
    
    function cPVT(PVI: number, LOC: number): number {
        let cPVT_in = PVI + LOC / 2; 
        return cPVT_in;
    }
    
    function cELpvc(ELpvi: number, LeftSlope: number, LOC: number): number {
        let cELpvc_in = ELpvi - LeftSlope * LOC / 2;
        return cELpvc_in;
    }
    // Offset Vertical Curve
    var EL_vc_off = function (Stax: number, PVC: number[], ELpvc: number[], LeftSlope: number[], RightSlope: number[], LOC: number[], off: number): number {
        let cELxoff: number = 0; // Initialize with a default value
    
        if (Stax < PVC[0]) {   
            cELxoff = ELpvc[0] + LeftSlope[0] * (Stax - PVC[0]) + off;
        } else {
            for (var i = 0; i < PVC.length; i++) {
                if (Stax >= PVC[i] && Stax < PVC[i] + LOC[i]) {      
                    var xi = (Stax - PVC[i]) / 100;
                    var Li = LOC[i] / 100;
                    var gi = (LeftSlope[i] - RightSlope[i]) * 100;
                    var yi = (gi * xi ** 2) / (2 * Li);
                    cELxoff = ELpvc[i] + (Stax - PVC[i]) * LeftSlope[i] - yi + off;
                    break; // Exit the loop once the correct segment is found
                }    
    
                if (Stax >= PVC[i] + LOC[i] && Stax < PVC[i + 1]) {        
                    var xi = Stax - (PVC[i] + LOC[i]);
                    cELxoff = ELpvc[i] + LeftSlope[i] * LOC[i] * 0.5 + RightSlope[i] * (0.5 * LOC[i] + xi) + off;
                    break; // Exit the loop once the correct segment is found
                }
    
                if (Stax >= PVC[i] + LOC[i] && i == PVC.length - 1) { 
                    var xi = Stax - (PVC[i] + LOC[i]);
                    cELxoff = ELpvc[i] + LeftSlope[i] * LOC[i] * 0.5 + RightSlope[i] * (0.5 * LOC[i] + xi) + off;
                    break; // Exit the loop once the correct segment is found
                }      
            }
        }
    
        return cELxoff; // cELxoff will always have a value when returned
    };
    
    // Horizontal Curve General Functions
    function x_C(ang: number, R: number, angPC: number): number {
        let x_C_in = 2 * R * Math.sin(ang / 2) * Math.cos(angPC - ang / 2);
        return x_C_in;
    }
    
    function y_C(ang: number, R: number, angPC: number): number {
        let y_C_in = 2 * R * Math.sin(ang / 2) * Math.sin(angPC - ang / 2);
        return y_C_in;
    }
    
    function x_T(x: number, staPT: number, angPC: number): number {
        let x_T_in = (x - staPT) * Math.cos(angPC);
        return x_T_in;
    }
    
    function y_T(x: number, staPT: number, angPC: number): number {
        let y_T_in = (x - staPT) * Math.sin(angPC);
        return y_T_in;
    }
    
    
    // Angle from the tangent direction of the path to x axis at the data point
    var ang_hc = function (Stax: number, yPC1: number, angPC1: number, staPC: number[], R: number[], LOC: number[], deg: number): number {
        var I_x: number[] = [];
        for (var i = 0; i < staPC.length; i++) {
            I_x[i] = (Stax - staPC[i]) * 360 * deg / (2 * Math.PI * R[i]);
        }
    
        var I_LOC: number[] = [];
        for (var i = 0; i < staPC.length; i++) {
            I_LOC[i] = LOC[i] * 360 * deg / (2 * Math.PI * R[i]);
        }
    
        var angPC: number[] = [];
        angPC[0] = angPC1;
    
        for (var i = 0; i < staPC.length; i++) {
            angPC[i + 1] = angPC[i] - I_LOC[i];
        }
    
        let angHC: number; // Declare angHC variable
    
        // Initialize angHC with a default value (optional, can be 0 or another default)
        angHC = angPC[0]; // Default to the initial angle if Stax < staPC[0]
    
        if (Stax < staPC[0]) {
            return angHC; // Return the initial angle directly
        }
    
        for (var i = 0; i < staPC.length; i++) {
            if (Stax >= staPC[i] && Stax < staPC[i] + LOC[i]) {
                angHC = angPC[i] - I_x[i];
                return angHC; // Return the calculated angle immediately
            }
    
            if (Stax >= staPC[i] + LOC[i] && Stax < staPC[i + 1] && i < staPC.length - 1) {
                angHC = angPC[i + 1];
                return angHC; // Return the calculated angle immediately
            }
    
            if (Stax >= staPC[i] + LOC[i] && i == staPC.length - 1) {
                angHC = angPC[i + 1];
                return angHC; // Return the calculated angle immediately
            }
        }
    
        // If none of the conditions are met, you may want to return a default value or handle it accordingly
        return angHC; // Ensure that angHC is returned
    };
    
       // Offset horizontal curve function (+ sign for offset to the left side)
    // Function to calculate x offset
    function x_Off(off: number, angPC: number, ang: number): number {
        let x_Off_in = off * Math.sin(angPC - ang);
        return x_Off_in;
    }
    
    // Function to calculate y offset
    function y_Off(off: number, angPC: number, ang: number): number {
        let y_Off_in = off * Math.cos(angPC - ang);
        return y_Off_in;
    }
    // Function to calculate horizontal curve offset
    var x_hc_off = function (
        Stax: number, 
        xPC1: number, 
        angPC1: number, 
        staPC: number[], 
        R: number[], 
        LOC: number[], 
        deg: number, 
        off: number
    ): number {
        var I_x: number[] = [];
        for (var i = 0; i < staPC.length; i++) {
            I_x[i] = (Stax - staPC[i]) * 360 * deg / (2 * Math.PI * R[i]);
        }  
    
        var I_LOC: number[] = [];
        for (var i = 0; i < staPC.length; i++) {
            I_LOC[i] = LOC[i] * 360 * deg / (2 * Math.PI * R[i]);
        }      
    
        var staPT: number[] = [];
        for (var i = 0; i < staPC.length; i++) {
            staPT[i] = staPC[i] + LOC[i];
        }       
    
        var angPC: number[] = [];
        angPC[0] = angPC1;      
        for (var i = 0; i < staPC.length; i++) {
            angPC[i + 1] = angPC[i] - I_LOC[i];            
        } 
    
        var xPT: number[] = [];
        var xPC: number[] = [];
        xPC[0] = xPC1;
        xPT[0] = xPC[0] + x_C(I_LOC[0], R[0], angPC[0]);        
    
        for (var i = 0; i < staPC.length; i++) {
            if (i > 0) {
                xPC[i] = xPT[i - 1] + x_T(staPC[i], staPT[i - 1], angPC[i]);    
                xPT[i] = xPC[i] + x_C(I_LOC[i], R[i], angPC[i]);
            } 
        }  
    
        let xHCoff: number = 0; // Initialize xHCoff with a default value
    
        if (Stax < staPC[0]) {
            xHCoff = xPC[0] - (staPC[0] - Stax) * Math.cos(angPC[0]) - x_Off(off, angPC[0], 0);
            return xHCoff; // Return immediately if Stax is before the first station point
        }
    
        for (var i = 0; i < staPC.length; i++) {    
            if (Stax >= staPC[i] && Stax < staPC[i] + LOC[i]) {             
                xHCoff = xPC[i] + x_C(I_x[i], R[i], angPC[i]) - x_Off(off, angPC[i], I_x[i]); 
                return xHCoff; // Return immediately for the calculated angle
            }
            if (Stax >= staPC[i] + LOC[i] && Stax < staPC[i + 1] && i < staPC.length - 1) {                                      
                xHCoff = xPT[i] + x_T(Stax, staPT[i], angPC[i + 1]) - x_Off(off, angPC[i + 1], 0); 
                return xHCoff; // Return immediately for the calculated angle
            }
            if (Stax >= staPC[i] + LOC[i] && i == staPC.length - 1) {                                      
                xHCoff = xPT[i] + x_T(Stax, staPT[i], angPC[i + 1]) - x_Off(off, angPC[i + 1], 0); 
                return xHCoff; // Return immediately for the calculated angle
            }
        }
    
        // If none of the conditions are met, return the last calculated value
        return xHCoff; 
    };
    
    // Function to calculate vertical curve offset
    var y_hc_off = function (
        Stax: number, 
        yPC1: number, 
        angPC1: number, 
        staPC: number[], 
        R: number[], 
        LOC: number[], 
        deg: number, 
        off: number
    ): number {
        var I_x: number[] = [];
        for (var i = 0; i < staPC.length; i++) {
            I_x[i] = (Stax - staPC[i]) * 360 * deg / (2 * Math.PI * R[i]);
        }  
    
        var I_LOC: number[] = [];
        for (var i = 0; i < staPC.length; i++) {
            I_LOC[i] = LOC[i] * 360 * deg / (2 * Math.PI * R[i]);
        }  
    
        var staPT: number[] = [];
        for (var i = 0; i < staPC.length; i++) {
            staPT[i] = staPC[i] + LOC[i];
        }    
    
        var angPC: number[] = [];
        angPC[0] = angPC1;      
        for (var i = 0; i < staPC.length; i++) {
            angPC[i + 1] = angPC[i] - I_LOC[i];            
        }
    
        var yPT: number[] = [];
        var yPC: number[] = [];
        yPC[0] = yPC1;
        yPT[0] = yPC[0] + y_C(I_LOC[0], R[0], angPC[0]);   
    
        for (var i = 0; i < staPC.length; i++) {
            if (i > 0) {
                yPC[i] = yPT[i - 1] + y_T(staPC[i], staPT[i - 1], angPC[i]);    
                yPT[i] = yPC[i] + y_C(I_LOC[i], R[i], angPC[i]);
            } 
        }  
    
        let yHCoff: number; // Declare yHCoff
    
        // Initialize yHCoff with a default value to avoid unassigned usage
        yHCoff = 0; // Default value or adjust as needed
    
        if (Stax < staPC[0]) {   
            yHCoff = yPC[0] - (staPC[0] - Stax) * Math.sin(angPC[0]) + y_Off(off, angPC[0], 0); 
            return yHCoff; // Return immediately if Stax is before the first station point
        }
    
        for (var i = 0; i < staPC.length; i++) {    
            if (Stax >= staPC[i] && Stax < staPC[i] + LOC[i]) {             
                yHCoff = yPC[i] + y_C(I_x[i], R[i], angPC[i]) + y_Off(off, angPC[i], I_x[i]);   
                return yHCoff; // Return immediately for the calculated angle
            }   
            if (Stax >= staPC[i] + LOC[i] && Stax < staPC[i + 1] && i < staPC.length - 1) {                                      
                yHCoff = yPT[i] + y_T(Stax, staPT[i], angPC[i + 1]) + y_Off(off, angPC[i + 1], 0); 
                return yHCoff; // Return immediately for the calculated angle
            } 
            if (Stax >= staPC[i] + LOC[i] && i == staPC.length - 1) {                                      
                yHCoff = yPT[i] + y_T(Stax, staPT[i], angPC[i + 1]) + y_Off(off, angPC[i + 1], 0); 
                return yHCoff; // Return immediately for the calculated angle
            } 
        }
    
        // If we reach this point, yHCoff has not been assigned a value through conditions
        return yHCoff; // Ensure yHCoff is returned
    };
    
    
    // Function to calculate super elevation based on station
    var superElevation = function (sta: number, superElev: number[][]): number | undefined {
        if (!Array.isArray(superElev) || superElev.length === 0 || !Array.isArray(superElev[0])) {
            return 0; 
        }
    
        let supere = 0;
    
        if (sta < superElev[0][0]) {
            supere = superElev[0][1];
        } else if (sta >= superElev[0][0] && sta < superElev[superElev.length - 1][0]) {
            for (var i = 0; i < superElev.length - 1; i++) {
                if (sta >= superElev[i][0] && sta < superElev[i + 1][0]) {
                    supere =
                        ((superElev[i + 1][1] - superElev[i][1]) / 
                         (superElev[i + 1][0] - superElev[i][0])) * 
                        (sta - superElev[i][0]) + 
                        superElev[i][1];
                }
            }
        } else if (sta >= superElev[superElev.length - 1][0]) {
            supere = superElev[superElev.length - 1][1];
        }
    
        return supere;
    };
    

    
    // Extend the Mesh class
    class ExtendedMesh extends BABYLON.Mesh {
        private _rq: BABYLON.Quaternion;
    
        constructor(name: string, scene: BABYLON.Scene) {
            super(name, scene);
            // Initialize _rq to a default value
            this._rq = BABYLON.Quaternion.Identity(); // or any default quaternion you prefer
        }
    
        rotateAroundPivot(pivotPoint: BABYLON.Vector3, axis: BABYLON.Vector3, angle: number): void {
            if (!this.rotationQuaternion) {
                this._rq = BABYLON.Quaternion.RotationYawPitchRoll(this.rotation.y, this.rotation.x, this.rotation.z);
            }
    
            const _p = new BABYLON.Quaternion(this.position.x - pivotPoint.x, this.position.y - pivotPoint.y, this.position.z - pivotPoint.z, 0);
            axis.normalize();
            const _q = BABYLON.Quaternion.RotationAxis(axis, angle); // form quaternion rotation
            const _qinv = BABYLON.Quaternion.Inverse(_q);
            const _pdash = _q.multiply(_p).multiply(_qinv);
    
            this.position = new BABYLON.Vector3(
                pivotPoint.x + _pdash.x,
                pivotPoint.y + _pdash.y,
                pivotPoint.z + _pdash.z
            );
    
            this.rotationQuaternion = this._rq.multiply(_q);
            this._rq = this.rotationQuaternion;
        }
    
        scaleFromPivot(pivotPoint: BABYLON.Vector3, sx: number, sy: number, sz: number): void {
            const _sx = sx / this.scaling.x;
            const _sy = sy / this.scaling.y;
            const _sz = sz / this.scaling.z;
    
            this.scaling = new BABYLON.Vector3(sx, sy, sz);
    
            this.position = new BABYLON.Vector3(
                pivotPoint.x + sx * (this.position.x - pivotPoint.x),
                pivotPoint.y + sy * (this.position.y - pivotPoint.y),
                pivotPoint.z + sz * (this.position.z - pivotPoint.z)
            );
        }
    }	
    

        const { current: canvas } = reactCanvas;
        if (!canvas) return;
        const engine = new Engine(canvas, true); // Antialiasing enabled by default
        const scene1 = new Scene(engine);
        setScene(scene1);
        //scene1.useRightHandedSystem = true; // use right handed  - Disable for 3D rendering view
        scene1.getAnimationRatio();

        // 1.1 View Controls
        /******************************************************************************************************************************/

        // Camera Control "0" for standard camera for model view and "1" for follow camera with cars on the structures
        // Ensure flowON is defined correctly, either as a string or an array element
        var cameraView = 0;


        //console.log('cameraView',cameraView)

        // Create and position a free camera
        var camera = new BABYLON.ArcRotateCamera("Camera", 1, 1.2, 1500, new BABYLON.Vector3(15, 0, 0), scene1);
        camera.upperBetaLimit = 1.30;
        camera.setTarget(Vector3.Zero());
        camera.attachControl(canvas, true);

        camera.wheelPrecision = Number(zoomSpeed);    
        camera._panningMouseButton = 0;
        camera.panningInertia = 0.8;   
        camera.panningSensibility = 5;
        // Removed the line below as it does not exist on ArcRotateCamera
        // camera.buttons = [2, 1, 0];
        camera.maxZ = 100000;  
        camera.minZ = 0.1;  
        camera.lowerRadiusLimit = 10;
        camera.upperRadiusLimit = 100000;

        // Follow Camera
        // This creates and initially positions a follow camera 	
        var followCamera = new BABYLON.FollowCamera("FollowCam", new BABYLON.Vector3(0, 100, 0), scene1);
        //The goal distance of camera from target
        followCamera.radius = 30;
        // The goal height of camera above local origin (centre) of target
        followCamera.heightOffset = 40;
        // The goal rotation of camera around local origin (centre) of target in x y plane
        followCamera.rotationOffset = 0;
        //Acceleration of camera in moving from current to goal position
        followCamera.cameraAcceleration = 0.005
        //The speed at which acceleration is halted 
        followCamera.maxCameraSpeed = 10
        //camera.target is set after the target's creation
        // This attaches the camera to the canvas
        //followCamera.attachControl(canvas, true);
        let showCars;
        // Control which camera is active in the scene
        if (cameraView == 0) {
        scene1.activeCamera = camera;
        camera.attachControl(canvas);    
        camera.wheelPrecision = Number(zoomSpeed); //Mouse wheel speed      
        camera._panningMouseButton = 0;
        camera.panningInertia = 0.8;   
        camera.panningSensibility = 5;
        // camera.buttons = [2, 1, 0];
        camera.maxZ = 100000;  
        camera.minZ = 0.1;  
        camera.lowerRadiusLimit = 10;
        camera.upperRadiusLimit = 100000;

        } else {
        scene1.activeCamera = followCamera;
        showCars = 1;  
        };
    

        // Set up the light (using DirectionalLight for shadow generation)
        var light = new BABYLON.DirectionalLight("dir01", new BABYLON.Vector3(0, -1, -1), scene1);
        light.diffuse = new BABYLON.Color3(1, 1, 1);
        light.specular = new BABYLON.Color3(1, 1, 1);

        // Shadow generator setup
        var shadowGenerator = new BABYLON.ShadowGenerator(1024, light); // Use DirectionalLight here
        shadowGenerator.frustumEdgeFalloff = 1.0;

        // Check if the shadow map exists before accessing its properties
        const shadowMap = shadowGenerator.getShadowMap();
        if (shadowMap) {
            shadowMap.refreshRate = BABYLON.RenderTargetTexture.REFRESHRATE_RENDER_ONCE;
        }

        // Set the other properties safely
        light.autoUpdateExtends = false;
        shadowGenerator.bias = 0.00001;


        // 1.4 Axis for global coordinates
        var showAxis_3D = function(size: number) {        
            var makeTextPlane = function(text: string, color: string, size: number) { 
                var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", 50, scene1, true);
                dynamicTexture.hasAlpha = true;
                dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color, "transparent", true);

                var plane = BABYLON.Mesh.CreatePlane("TextPlane", size, scene1, true);
                var material = new BABYLON.StandardMaterial("TextPlaneMaterial", scene1);
                material.backFaceCulling = false;
                material.diffuseTexture = dynamicTexture;
                plane.material = material;
                return plane;
            };
        
            var axisX = BABYLON.Mesh.CreateLines("axisX", [
                new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(size, 0, 0),
                new BABYLON.Vector3(size * 0.95, 0.05 * size, 0), 
                new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, -0.05 * size, 0)
            ], scene1, false); // Set updatable to false
            axisX.color = new BABYLON.Color3(1, 0, 0);

            var xChar = makeTextPlane("X", "red", size / 3);
            xChar.position = new BABYLON.Vector3(0.9 * size, 0.2 * size, 0);
            xChar.billboardMode = 7;

            var axisY = BABYLON.Mesh.CreateLines("axisY", [
                new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, size, 0),
                new BABYLON.Vector3(-0.05 * size, size * 0.95, 0), 
                new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3(0.05 * size, size * 0.95, 0)
            ], scene1, false); // Set updatable to false

            axisY.color = new BABYLON.Color3(0, 1, 0);
            var yChar = makeTextPlane("Y", "green", size / 3);
            yChar.position = new BABYLON.Vector3(0, 1.2 * size, -0.05 * size);
            yChar.billboardMode = 7;

            var axisZ = BABYLON.Mesh.CreateLines("axisZ", [
                new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, size),
                new BABYLON.Vector3(0, -0.05 * size, size * 0.95),
                new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3(0, 0.05 * size, size * 0.95)
            ], scene1, false); // Set updatable to false
            axisZ.color = new BABYLON.Color3(0, 0, 1);
            
            var zChar = makeTextPlane("Z", "blue", size / 3);
            zChar.position = new BABYLON.Vector3(0, 0.2 * size, 0.9 * size);
            zChar.billboardMode = 7;
        };

        // Call the function
        showAxis_3D(50);


        var assetsManager = new BABYLON.AssetsManager(scene1);
        assetsManager.useDefaultLoadingScreen = false;
    /*
        // Load DTM files
        var dtmTasks = [];
        for (var i = 0; i < NDTM; i++) {
            dtmTasks[i] = assetsManager.addTextFileTask(dtmName[i], dtmURL[i]); //Error here
            var ref = dtmRef[i];
            var index_ref = indexRefPoint(ref); // This may return undefined
        
            // Check if index_ref is defined
            if (index_ref !== undefined) {
                var x = refPointX[index_ref];
                var y = refPointY[index_ref];
                var z = refPointZ[index_ref];
                var ref_Point = [x, y, z];
        
                if (dtmShow[i] == "YES" || dtmShow[i] == "Yes" || dtmShow[i] == "yes") {   
                    dtmTasks[i].onSuccess = function(task) {
                        var data = task.text;      
                        var data_lines = data.split('\n');      
                        showDTM(ref_Point, data_lines, "green", scene1);
                    };
                }
            } else {
                console.warn(`Reference point for DTM ${i} is undefined.`);
            }
        }


        // Load Aerial Pictures
        var aerialTasks = [];
        for (var i = 0; i < NAERIAL; i++) {     
            aerialTasks[i] = assetsManager.addTextureTask(aerialName[i], aerialJPG[i]); //Error here
            if (aerialShow[i] == "YES" || aerialShow[i] == "Yes" || aerialShow[i] == "yes") {               
                aerialTasks[i].onSuccess = function(task) {                             
                    var plane = BABYLON.MeshBuilder.CreatePlane("plane", {height: 5000, width: 5000, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, scene1);
                    plane.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);  
                    var material = new BABYLON.StandardMaterial("mat", scene1) as BABYLON.StandardMaterial; // Cast to StandardMaterial
                    material.diffuseTexture = task.texture;  
                    plane.material = material; // Assign the material to the plane
                    
                    for (var j = 0; j < NAERIAL; j++) {
                        // Compare the URL of the texture
                        if (task.texture.url === aerialJPG[j]) {  //Error here
                            var ref = aerialRef[j];
                            var index_ref = indexRefPoint(ref);
                            // Check if index_ref is defined
                            if (index_ref !== undefined) {
                                var x = refPointX[index_ref];
                                var y = refPointY[index_ref];
                                var z = refPointZ[index_ref];
                                var x_coord = aerialX[j] - x;
                                var y_coord = -y;
                                var z_coord = aerialY[j] - z;                  
                                plane.position.y = y_coord;
                                plane.position.x = x_coord + 2500; // Move from left corner to center of the picture
                                plane.position.z = z_coord - 2500; // Move from top corner to center of the picture
                            } else {
                                console.warn(`Index reference for aerial ${j} is undefined.`);
                            }
                        }    
                    }
                };
            }
        }
        // Load Topo files
        var topoTasks = [];
        for (var i = 0; i < NTOPO; i++) {
            topoTasks[i] = assetsManager.addTextFileTask(topoName[i], topoURL[i]); //error here
            var ref = topoRef[i];
            var index_ref = indexRefPoint(ref); // This may return undefined

            // Check if index_ref is defined
            if (index_ref !== undefined) {
                var x = refPointX[index_ref];
                var y = refPointY[index_ref];
                var z = refPointZ[index_ref];
                var ref_Point = [x, y, z];

                if (topoShow[i] === "YES" || topoShow[i] === "Yes" || topoShow[i] === "yes") {   
                    topoTasks[i].onSuccess = function(task) {
                        var data = task.text;      
                        var data_lines = data.split('\n');      
                        showTOPO(ref_Point, data_lines, scene1);
                    };
                }
            } else {
                console.warn(`Index reference for TOPO ${i} is undefined.`);
            }
        }
*/
        assetsManager.onProgress = function(remainingCount, totalCount, lastFinishedTask) {
            engine.loadingUIText = 'We are loading external files. ' + remainingCount + ' out of ' + totalCount + ' files still need to be loaded.';
        };

        assetsManager.onFinish = function (tasks) {
            engine.runRenderLoop(function () {
                scene1.render();
            });
        };
        
        assetsManager.load();

        // Ground Elevation 
        var groundEev = 0.0;

        var square;


        // Create Ground  
        if (groundtextON === "NO") {       
            square = BABYLON.MeshBuilder.CreateDisc("square", {radius: 40000}, scene1);
            square.rotation.x = Math.PI / 2;
            square.position = new BABYLON.Vector3(0, 0, 0);   
            var groundMaterial = new BABYLON.StandardMaterial("ground material", scene1);
            groundMaterial.alpha = 0.75;
            groundMaterial.diffuseColor = new BABYLON.Color3(0.5, 0.5, 1.0);
            square.material = groundMaterial;

        } else if (groundtextON === "YES") {    
            square = BABYLON.MeshBuilder.CreateDisc("square", {radius: 40000}, scene1);
            square.rotation.x = Math.PI / 2;
            square.position = new BABYLON.Vector3(0, 0, 0);         
        }

        // Create the custom procedural texture
        var customProcText = new BABYLON.CustomProceduralTexture("customtext", "./textures/customProceduralTextures/land", 1024, scene1);

        // Create the custom material
        var customMaterial = new BABYLON.StandardMaterial("custommat", scene1);
        customMaterial.ambientTexture = customProcText; // Set the ambient texture
        customMaterial.alpha = 0.75; // Set the alpha value

        // Check if square is defined before assigning the custom material
        if (square) {
            square.material = customMaterial; // Assign the custom material to the square
            square.receiveShadows = true; // Enable shadows
        } else {
            console.warn("Square was not created; check the value of groundtextON.");
        }



        // 1.6 Sky with a procedural texture
        var skybox = BABYLON.Mesh.CreateSphere("skybox", 100, 100000, scene1);
        var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene1);
        skyboxMaterial.backFaceCulling = false;

        // Assign the skybox material if needed
        skybox.material = skyboxMaterial;
        //Replace the code below for HTML File
        skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("textures/TropicalSunnyDay", scene1);
        //	skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("{% static 'babylon/textures/TropicalSunnyDay' %}", scene1);
        //Replace the code above for HTML File
        skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
        skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
        skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        skyboxMaterial.disableLighting = true;
        skybox.material = skyboxMaterial;


        if (skyON === "NO") {
            removeSky();
        } // Close for showing objects
        

        function showSkyScene(){
            removeSky();    
            var skybox = BABYLON.Mesh.CreateSphere("skybox", 100, 80000, scene1);
            var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene1);
            skyboxMaterial.backFaceCulling = false;
         
         //Replace the code below for HTML File
            skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("textures/TropicalSunnyDay", scene1);
         //	skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("{% static 'babylon/textures/TropicalSunnyDay' %}", scene1);
         //Replace the code above for HTML File
            skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
            skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
            skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
            skyboxMaterial.disableLighting = true;
            skybox.material = skyboxMaterial;
            }
         
            function removeSky() {
             var skybox = scene1.getMeshByID("skybox"); // Get the skybox mesh
             if (skybox) { // Check if skybox is not null
                 skybox.dispose(); // Safely call dispose on the skybox
             } else {
                 console.warn("Skybox does not exist.");
             }
         }
         

         const name = "myMaterial";

         // Marble material
         var marbleMaterial = new BABYLON.StandardMaterial("deck", scene1);
         var marbleTexture = new MarbleProceduralTexture("marble", 512, scene1);
         marbleTexture.numberOfTilesHeight = 5; // Corrected property
         marbleTexture.numberOfTilesWidth = 5;  // Corrected property
         marbleMaterial.ambientTexture = marbleTexture;
     
         // Grass material
         var grassMaterial = new BABYLON.StandardMaterial(name + "bawl", scene1);
         var grassTexture = new GrassProceduralTexture(name + "textbawl", 256, scene1);
         grassMaterial.ambientTexture = grassTexture;
     
         // Road material
         var roadMaterial = new BABYLON.StandardMaterial("road", scene1);
         var roadMaterialpt = new RoadProceduralTexture("customtext", 512, scene1);
         roadMaterial.diffuseTexture = roadMaterialpt;
     
         // Wood material
         var woodMaterial = new BABYLON.StandardMaterial(name, scene1);
         var woodTexture = new WoodProceduralTexture(name + "text", 1024, scene1);
         woodTexture.ampScale = 50.0;
         woodMaterial.diffuseTexture = woodTexture;
     
        var mat = new BABYLON.StandardMaterial("mat1", scene1);
        mat.alpha = 1.0;
        mat.diffuseColor = new BABYLON.Color3(0.5, 0.5, 1.0);
        mat.backFaceCulling = false;
     
        var concreteMaterial = new BABYLON.StandardMaterial("concrete", scene1);
        concreteMaterial.alpha = 1.0;
        // Vlaues for R (red), G (green) and B (blue); use a value from 0 to 255, then devided by 255
        // Note the value is reverse from RGB color number for light grey rgb(212, 212, 212)      
        concreteMaterial.diffuseColor = new BABYLON.Color3(0.663, 0.663, 0.663); 
        concreteMaterial.specularColor = new BABYLON.Color3(0.663, 0.663, 0.663);     
        concreteMaterial.ambientColor = new BABYLON.Color3(0.663, 0.663, 0.663);
        concreteMaterial.emissiveColor = new BABYLON.Color3(0.663, 0.663, 0.663);
        concreteMaterial.backFaceCulling = true;
     
        var concreteDeckMaterial = new BABYLON.StandardMaterial("concrete", scene1);
        concreteDeckMaterial.alpha = 1.0;
        // Vlaues for R (red), G (green) and B (blue); use a value from 0 to 255, then devided by 255    
        concreteDeckMaterial.diffuseColor = new BABYLON.Color3(0.4, 0.4, 0.4); 
        concreteDeckMaterial.specularColor = new BABYLON.Color3(0.4, 0.4, 0.4);     
        concreteDeckMaterial.ambientColor = new BABYLON.Color3(0.4, 0.4, 0.4);
        concreteDeckMaterial.emissiveColor = new BABYLON.Color3(0.4, 0.4, 0.4);
        concreteDeckMaterial.backFaceCulling = true;
     
        var concreteGirderMaterial = new BABYLON.StandardMaterial("concrete", scene1);
        concreteDeckMaterial.alpha = 1.0;
        // Vlaues for R (red), G (green) and B (blue); use a value from 0 to 255, then devided by 255    
        concreteGirderMaterial.diffuseColor = new BABYLON.Color3(0.4, 0.4, 0.4); 
        concreteGirderMaterial.specularColor = new BABYLON.Color3(0.4, 0.4, 0.4);     
        concreteGirderMaterial.ambientColor = new BABYLON.Color3(0.4, 0.4, 0.4);
        concreteGirderMaterial.emissiveColor = new BABYLON.Color3(0.4, 0.4, 0.4);
        concreteGirderMaterial.backFaceCulling = true;
     
        var steelGirderMaterial = new BABYLON.StandardMaterial("concrete", scene1);
        concreteMaterial.alpha = 1.0;
        // Vlaues for R (red), G (green) and B (blue); use a value from 0 to 255, then devided by 255
        // Rust 71.8% red, 25.5% green and 5.5% blue 
        steelGirderMaterial.diffuseColor = new BABYLON.Color3(0.718, 0.255, 0.055); 
        steelGirderMaterial.specularColor = new BABYLON.Color3(0.718, 0.255, 0.055);     
        steelGirderMaterial.ambientColor = new BABYLON.Color3(0.718, 0.255, 0.055);
        steelGirderMaterial.emissiveColor = new BABYLON.Color3(0.718, 0.255, 0.055);
        steelGirderMaterial.backFaceCulling = true;
     
        var MSEWallMaterial = new BABYLON.StandardMaterial("MSE Wall", scene1);
        MSEWallMaterial.alpha = 1.0;
        // Vlaues for R (red), G (green) and B (blue); use a value from 0 to 255, then devided by 255     
        MSEWallMaterial.diffuseColor = new BABYLON.Color3(0.827, 0.827, 0.827); 
        MSEWallMaterial.specularColor = new BABYLON.Color3(0.827, 0.827, 0.827);     
        MSEWallMaterial.ambientColor = new BABYLON.Color3(0.827, 0.827, 0.827);
        MSEWallMaterial.emissiveColor = new BABYLON.Color3(0.663, 0.663, 0.663);
        MSEWallMaterial.backFaceCulling = false;
     
        var yellowMaterial = new BABYLON.StandardMaterial("yellow", scene1);
        yellowMaterial.alpha = 1.0;  
        yellowMaterial.diffuseColor = new BABYLON.Color3(1, 1, 0); 
        yellowMaterial.specularColor = new BABYLON.Color3(1, 1, 0);     
        yellowMaterial.ambientColor = new BABYLON.Color3(1, 1, 0);
        yellowMaterial.emissiveColor = new BABYLON.Color3(1, 1, 0);
        yellowMaterial.backFaceCulling = false;
     
        var whiteMaterial = new BABYLON.StandardMaterial("white", scene1);
        whiteMaterial.alpha = 1.0;  
        whiteMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1); 
        whiteMaterial.specularColor = new BABYLON.Color3(1, 1, 1);     
        whiteMaterial.ambientColor = new BABYLON.Color3(1, 1, 1);
        whiteMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
        whiteMaterial.backFaceCulling = false;
     
        var redMaterial = new BABYLON.StandardMaterial("red", scene1);
        redMaterial.alpha = 1.0;  
        redMaterial.diffuseColor = new BABYLON.Color3(1, 0, 0); 
        redMaterial.specularColor = new BABYLON.Color3(1, 0, 0);     
        redMaterial.ambientColor = new BABYLON.Color3(1, 0, 0);
        redMaterial.emissiveColor = new BABYLON.Color3(1, 0, 0);
        redMaterial.backFaceCulling = false;
     
        var blueMaterial = new BABYLON.StandardMaterial("blue", scene1);
        blueMaterial.alpha = 1.0;  
        blueMaterial.diffuseColor = new BABYLON.Color3(0, 0, 1); 
        blueMaterial.specularColor = new BABYLON.Color3(0, 0, 1);     
        blueMaterial.ambientColor = new BABYLON.Color3(0, 0, 1);
        blueMaterial.emissiveColor = new BABYLON.Color3(0, 0, 1);
        blueMaterial.backFaceCulling = false;
     

        // Create a box and store it in the useRef
        boxRef.current = MeshBuilder.CreateBox("box", { size: 2 }, scene1);
        if (boxRef.current) {
            boxRef.current.position.y = 1; // Move the box upward 1/2 its height
        }

        // Create a ground
        MeshBuilder.CreateGround("ground", { width: 6, height: 6 }, scene1);


        // Structral unit information for each main road
        var staUnit_main = [];
        for(var ii=0;ii<Number(NMAIN);ii++){
            var bridge_temp = mainBridgeNum[ii];
            var staUnit_temp = [];
            for(var i=0;i<Number(NUNIT);i++){
                var unit_name = unitNum[i];
                if(unitBridge[i] == bridge_temp){
                var temp = [];
                for(var j=0;j<12;j++){ // Include unit geometry for columns 1-12
                    temp[j] = unitGeometry[i][j];  // Information for start and end stations, expansion joints widths and skews at both ends
                }
                var temp1 = [];
                for(var j=0;j<Number(NSPAN);j++){
                var span_unit = spanUnit[j];
                if(span_unit == unit_name){
                    temp1.push(spanNum[j]); 
                }
                }

                var temp2 = [];
                for(var j=0;j<Number(NPIER);j++){
                var left_unit = pierUnitLeft[j];
                var right_unit = pierUnitRight[j];
                if(left_unit == unit_name || right_unit == unit_name){
                    temp2.push(pierNum[j]); 
                }
                }

                temp.push(temp1); //Include span information for 12th column
                temp.push(unit_name); // Inlcue unit name for 13th column
                temp.push(temp2); // Inlcue pier information for 14th column         
                staUnit_temp.push(temp);
                }
            } 
            staUnit_main[ii] = staUnit_temp;
        }


        // Assuming mainNum and stripeNum are defined as arrays of strings
        var stripe_main: string[][] = []; // Declare stripe_main as an array of arrays of strings

        for (var ii = 0; ii < Number(NMAIN); ii++) {
            var main_temp = mainNum[ii];
            var stripe_temp: string[] = []; // Declare stripe_temp as an array of strings
            for (var i = 0; i < Number(NSTRIPE); i++) {
                var stripe_name = stripeNum[i];         
                if (stripeMain[i] === main_temp) {
                    stripe_temp.push(stripe_name);
                }
            } 
            stripe_main[ii] = stripe_temp; // Assign the array of stripe names to the corresponding main road
        }

        // Assuming the following are defined and are arrays of numbers
        var widthApproachSlab: number[] = [];  // Declare as an array of numbers
        var thickApproachSlab: number[] = [];  // Declare as an array of numbers
        var thickAppHaunch: number[] = [];      // Declare as an array of numbers

        for (var i = 0; i < Number(NAPPSLAB); i++) {
            var appseg = appSBSeg[i];
            if (appseg === 1) {
                var appsec = appSBLongSec[i];
                var indexSec = indexAppSBSec(appsec);
                
                // Check if indexSec is defined
                if (indexSec !== undefined) {
                    if (appsbsecShapeDes[indexSec] === "Rectangular") {
                        thickApproachSlab.push(appsbsecDepth[indexSec]); // Push depth
                        widthApproachSlab.push(appsbsecWidth[indexSec]); // Push width
                        thickAppHaunch.push(appsbsecHaunch[indexSec]);   // Push haunch thickness
                    } else {
                        // Handle approach slab inputs with slope breaks
                    }
                } else {
                    console.warn(`Section "${appsec}" not found in approach slab sections.`);
                    // Optionally handle the case where the section is not found
                }
            }
        }

        // Create models for main roads 
        for(var ii=0;ii<Number(NMAIN);ii++){
            var main_name = mainNum[ii];
            var index_main = indexMainOn(main_name); 

            if (index_main !== undefined) {
                if (mainOnOff[index_main] === 'NO') { 
                    continue; 
                }
            } else {
                console.warn(`Index for main "${main_name}" not found.`);
                // Optionally handle the case where the index is not found
            }

        // 3.2 Project Limits
        // Bridge Start/End Stations
        var staPjBegin = mainBegStation[ii];
        var staPjEnd = mainEndStation[ii];
        var staBrBegin = mainBridgeBenStation[ii];  //Defined at FFBW
        var staBrEnd = mainBridgeEndStation[ii];    // Defined at FFBW
        var staApproachSlab1 = mainBridgeBenApproachSB[ii]; //Station for Begin Approach Slab 1 
        var staApproachSlab2 = mainBridgeEndApproachSB[ii]; //Station for End Approach Slab 2 
        var staUnit = staUnit_main[ii];   // Expansion joint is defined from the edge of the girder to cl pier at intermediate pier locations


        // 3.3 Vertical Curves Definitions 
        const align: string = mainAlign[ii];

        // Convert align to a number if it is a string representation of a number
        const index: number | undefined = indexAlign(align); 
        var indexV;
        var indexH;
        var indexSE;
        var halign;
        var valign;
        var sealign;
        const hCurveData: number[][] = [];
        const vcStaPvi: number[] = [];
        const vcLen: number[] = [];
        const vcEL_Pvi: number[] = []; 
        const vcSlpLeft: number[] = [];
        const vcSlpRight: number[] = [];     
        const vcStaPvc: number[] = [];
        const vcEL_Pvc: number[] = [];

        // Proceed only if index is a valid number
        if (index !== undefined) { 
            halign = String(alignmentCurves[index][0]);
            valign = String(alignmentCurves[index][1]);
            sealign = String(alignmentCurves[index][2]);
            // Convert valign to string if necessary for indexVcurve
            indexV = indexVcurve(valign.toString());
            indexH = indexHcurve(halign);
            indexSE= indexSEcurve(sealign); 

            
            if (indexH !== undefined) { // Check if indexH is defined before using it
                for (let i = 0; i < hcurveSeg[indexH]; i++) {
                    const curve_temp: number[] = [];   
                    curve_temp[0] = hcurveCircular[indexH][3 * i];
                    curve_temp[1] = hcurveCircular[indexH][3 * i + 1];   
                    curve_temp[2] = hcurveCircular[indexH][3 * i + 2];         
                    hCurveData.push(curve_temp);                                               
                }
            } else {
                console.warn(`Invalid horizontal curve index for align "${halign}" at index ${index}.`);
            }

            const vCurveData: number[][] = [];
            if (indexV !== undefined && vcurveType[indexV] === 1) {     
                for (let i = 0; i < vcurveSeg[indexV]; i++) {
                    const curve_temp: number[] = [];   
                    curve_temp[0] = vcurveCircular[indexV][5 * i];
                    curve_temp[1] = vcurveCircular[indexV][5 * i + 1];   
                    curve_temp[2] = vcurveCircular[indexV][5 * i + 2];
                    curve_temp[3] = vcurveCircular[indexV][5 * i + 3];
                    curve_temp[4] = vcurveCircular[indexV][5 * i + 4];          
                    vCurveData.push(curve_temp);                                               
                }
            }

            var seCurveData: number[][] = [];
            var superElev = seCurveData;
            if (indexSE !== undefined) {
                for (let i = 0; i < securveSeg[indexSE]; i++) {
                    const curve_temp: number[] = [];   
                    curve_temp[0] = securvePairs[indexSE][2 * i];
                    curve_temp[1] = securvePairs[indexSE][2 * i + 1];            
                    seCurveData.push(curve_temp);                                               
                }
            }

            if (indexV !== undefined && vcurveType[indexV] === 1) {     
                for (let i = 0; i < vcurveSeg[indexV]; i++) {
                    vcStaPvi[i] = vCurveData[i][0];   
                    vcLen[i] = vCurveData[i][1];  
                    vcEL_Pvi[i] = vCurveData[i][2];   
                    vcSlpLeft[i] = vCurveData[i][3];  
                    vcSlpRight[i] = vCurveData[i][4];  
                    vcStaPvc[i] = cPVC(vcStaPvi[i], vcLen[i]);
                    vcEL_Pvc[i] = cELpvc(vcEL_Pvi[i], vcSlpLeft[i], vcLen[i]);                                                    
                }
            }
        } else {
            console.warn(`Invalid alignment value at index ${ii}: ${align}`);
        }


        // Define variable types explicitly
        let xPC1 = 0;  // Assuming xPC1 should be a number
        let yPC1 = 0;  // Assuming yPC1 should be a number
        let angPC1 = 0; // Assuming angPC1 should be a number
        let staPC: number[] = []; 
        let hcLOC: number[] = []; 
        let hcR: number[] = []; // Note: define R as positive for crest curve and negative for sag curve
        let xAdjust: number;  // Assuming xPC1 should be a number
        let yAdjust: number; 

        if (indexH !== undefined) { 
            for (let i = 0; i < hcurveSeg[indexH]; i++) {
                const curve_temp: number[] = [];   
                curve_temp[0] = hcurveCircular[indexH][3 * i];
                curve_temp[1] = hcurveCircular[indexH][3 * i + 1];   
                curve_temp[2] = hcurveCircular[indexH][3 * i + 2];         
                hCurveData.push(curve_temp);                                               
            }

            // Now use indexH safely
            xPC1 = hcurveXPC[indexH];
            yPC1 = hcurveYPC[indexH];
            angPC1 = hcurveANG[indexH]; 
            
            for (let i = 0; i < hcurveSeg[indexH]; i++) {
                staPC[i] = hCurveData[i][0];
                hcLOC[i] = hCurveData[i][1];
                hcR[i] = hCurveData[i][2];                                                 
            }

            //******* Adjust the environment to fit to center the view to the main bridge  ______________________________________________
            const staBrMid = (staBrEnd + staBrBegin) / 2;
            const xBrMid = x_hc_off(staBrMid, xPC1, angPC1, staPC, hcR, hcLOC, deg, 0);
            const yBrMid = y_hc_off(staBrMid, yPC1, angPC1, staPC, hcR, hcLOC, deg, 0);
            const xBrBegin = x_hc_off(staBrBegin, xPC1, angPC1, staPC, hcR, hcLOC, deg, 0);
            const yBrBegin = y_hc_off(staBrBegin, yPC1, angPC1, staPC, hcR, hcLOC, deg, 0);
            const xBrEnd = x_hc_off(staBrEnd, xPC1, angPC1, staPC, hcR, hcLOC, deg, 0);
            const yBrEnd = y_hc_off(staBrEnd, yPC1, angPC1, staPC, hcR, hcLOC, deg, 0);
            
            xAdjust = 0.5 * (xBrMid + 0.5 * (xBrBegin + xBrEnd));
            yAdjust = 0.5 * (yBrMid + 0.5 * (yBrBegin + yBrEnd));

        // Set up new camera target  
        var cameraTarget = BABYLON.Mesh.CreateSphere("sphere1", 16, 0.001, scene1);
                cameraTarget.position.y = groundEev;
                cameraTarget.position.x = xAdjust;
                cameraTarget.position.z = yAdjust;   
        //  camera.setTarget(cameraTarget);

        // Move the light to the new target position
                light.position.x = xAdjust +200;
                light.position.z = yAdjust + 200; 
                light.position.y = groundEev + 400; 

                if (square) { // Check if square is defined
                    square.position.x = xAdjust;
                    square.position.z = yAdjust; 
                    square.position.y = groundEev; // Ensure groundEev is defined as well
                } else {
                    console.error("Error: 'square' is not defined. Ensure ground creation logic executed successfully.");
                }

        // Move the sky to the new target position
                if(skyON == "YES"){
                skybox.position.x = xAdjust;
                skybox.position.z = yAdjust; 
                }; // close for showing object

        } else {
            console.warn(`Invalid horizontal curve index for align "${halign}" at index ${index}.`);
        }


        //******* Adjust environment to fit to center the view to the main bridge  ______________________________________________

        // 3.4 Combine horizontal and vertical curves for the 3D baseline/path of the bridge
        // Bridge path function
        var pathComponent = function (staBegin: number, staEnd: number, spacing: number, hoff: number, zoff: number): BABYLON.Vector3[] {
            var myPath: BABYLON.Vector3[] = []; // Declare myPath as an array of BABYLON.Vector3
            const n_vcPoint: number = Math.floor((staEnd - staBegin) / spacing);
        
            for (let i = 0; i < n_vcPoint; i++) {
                const sta_i: number = staBegin + i * spacing;
                const x_hci: number = x_hc_off(sta_i, xPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);        
                const y_hci: number = y_hc_off(sta_i, yPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
                let EL_vci: number = EL_vc_off(sta_i, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, zoff);
                // Ensure superElevation returns a number
                const elevationAdjustment = superElevation(sta_i, superElev) || 0; // Use 0 as default if undefined
                EL_vci += elevationAdjustment * hoff;
                
                myPath.push(new BABYLON.Vector3(x_hci, EL_vci, y_hci));
            }

            if (n_vcPoint * spacing <= staEnd - staBegin) {
                const x_hcEnd: number = x_hc_off(staEnd, xPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);   
                const y_hcEnd: number = y_hc_off(staEnd, yPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);   
                let EL_vcEnd: number = EL_vc_off(staEnd, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, zoff);
                
                // Use 0 as default if undefined
                const elevationAdjustmentEnd = superElevation(staEnd, superElev) || 0; 
                EL_vcEnd += elevationAdjustmentEnd * hoff;    
                
                myPath.push(new BABYLON.Vector3(x_hcEnd, EL_vcEnd, y_hcEnd));
            }    

            var myPath1 = new BABYLON.Curve3(myPath);
            var pathComp = myPath1.getPoints();
            return pathComp;
        };

        // Path for baseline
        // Define the pathBaseLine function with explicit types
        var pathBaseLine = function (staBegin: number, staEnd: number, hoff: number, zoff: number): BABYLON.Vector3[] {
            var myBase: BABYLON.Vector3[] = []; // Declare myBase as an array of BABYLON.Vector3
            const n_vcPoint: number = (Math.ceil(staEnd / 100) * 100 - Math.floor(staBegin / 100) * 100) / 100;
            
            for (let i = 0; i < n_vcPoint; i++) {
                const sta_i: number = Math.ceil(staBegin / 100) * 100 + i * 100;
                const x_hci: number = x_hc_off(sta_i, xPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);   
                const y_hci: number = y_hc_off(sta_i, yPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);   
                const EL_vci: number = EL_vc_off(sta_i, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, zoff);
                
                myBase.push(new BABYLON.Vector3(x_hci, EL_vci, y_hci));
            } 
            
            var myBase1 = new BABYLON.Curve3(myBase);
            var baseComp = myBase1.getPoints();
            return baseComp; // Return an array of BABYLON.Vector3
        }


        // Angle for baseline
        // Define the baseAngle function with explicit types
        var baseAngle = function (staBegin: number, staEnd: number, deg: number): number[] {
            var myBase: number[] = []; // Declare myBase as an array of numbers
            const n_vcPoint: number = (Math.ceil(staEnd / 100) * 100 - Math.floor(staBegin / 100) * 100) / 100;

            for (let i = 0; i < n_vcPoint; i++) {
                const sta_i: number = Math.ceil(staBegin / 100) * 100 + i * 100;
                const ang_hci: number = ang_hc(sta_i, xPC1, angPC1, staPC, hcR, hcLOC, deg);         
                myBase.push(ang_hci + 90 * deg);
            }  

            return myBase; // Return an array of numbers
        }

        //Tangent angle from z axis clockwise
        // Define the pathTangentZ function with explicit types
        var pathTangentZ = function (staBegin: number, staEnd: number, spacing: number): number[] {
            var myTan: number[] = []; // Declare myTan as an array of numbers
            const n_vcPoint: number = Math.floor((staEnd - staBegin) / spacing);
            
            for (let i = 0; i < n_vcPoint; i++) {
                const sta_i: number = staBegin + i * spacing;
                const ang_hci: number = ang_hc(sta_i, xPC1, angPC1, staPC, hcR, hcLOC, deg);     
                myTan.push(180 * deg - ang_hci);
            }

            // Check if we need to add the tangent at the end point
            if (n_vcPoint * spacing <= staEnd - staBegin) {
                const ang_hcEnd: number = ang_hc(staEnd, xPC1, angPC1, staPC, hcR, hcLOC, deg);     
                myTan.push(180 * deg - ang_hcEnd);
            }    

            return myTan; // Return an array of numbers
        }

        // Path for substructure components

        function x_DimOff(sta_off: number, ang: number): number {
            const x_DimOff_in: number = -sta_off * Math.sin(ang); 
            return x_DimOff_in;
        }

        function y_DimOff(sta_off: number, ang: number): number {
            const y_DimOff_in: number = -sta_off * Math.cos(ang); 
            return y_DimOff_in;
        }


        // Substructure path function
        // Along the station direction
        var pathSubComponent = function (
            length: number, 
            spacing: number, 
            x_Base: number, 
            y_Base: number, 
            z_Base: number, 
            ang_Base: number, 
            sta_off: number, 
            extra: number, // Specify type even if it's not used
            z_off: number
        ): BABYLON.Vector3[] { // Specify the return type
            var pathSub: BABYLON.Vector3[] = []; // Declare pathSub as an array of Vector3
            const n_subPoint: number = Math.floor(length / spacing);
            
            for (var i = 0; i < n_subPoint; i++) {
                var sta_i: number = i * spacing;
                var x_Sub: number = x_Base - x_DimOff(sta_off, ang_Base) - x_DimOff(sta_i, ang_Base);
                var y_Sub: number = y_Base + y_DimOff(sta_off, ang_Base) + y_DimOff(sta_i, ang_Base);
                var z_Sub: number = z_Base + z_off;
                pathSub.push(new BABYLON.Vector3(x_Sub, z_Sub, y_Sub));
            }
            
            if (n_subPoint * spacing <= length) {
                var sta_end: number = n_subPoint * spacing;
                var x_Sub: number = x_Base - x_DimOff(sta_off, ang_Base) - x_DimOff(sta_end, ang_Base);
                var y_Sub: number = y_Base + y_DimOff(sta_off, ang_Base) + y_DimOff(sta_end, ang_Base);
                var z_Sub: number = z_Base + z_off;
                pathSub.push(new BABYLON.Vector3(x_Sub, z_Sub, y_Sub));
            }  
            
            var path_sub: BABYLON.Curve3 = new BABYLON.Curve3(pathSub);
            var pathSubComp: BABYLON.Vector3[] = path_sub.getPoints();
            return pathSubComp;
        };

        // In vertical direction with station offset
        var pathSubVertComponent = function (
            length: number, 
            spacing: number, 
            x_Base: number, 
            y_Base: number, 
            z_Base: number, 
            ang_Base: number, 
            sta_off: number, 
            z_off: number
        ): BABYLON.Vector3[] { // Specify the return type as an array of Vector3
            var pathSub: BABYLON.Vector3[] = []; // Declare pathSub as an array of Vector3
            const n_subPoint: number = Math.floor(length / spacing);
            
            for (var i = 0; i < n_subPoint; i++) {
                var z_i: number = i * spacing;
                var x_Sub: number = x_Base - x_DimOff(sta_off, ang_Base);
                var y_Sub: number = y_Base + y_DimOff(sta_off, ang_Base);
                var z_Sub: number = z_Base + z_off - z_i;
                pathSub.push(new BABYLON.Vector3(x_Sub, z_Sub, y_Sub));
            }
            
            if (n_subPoint * spacing <= length) {
                var z_end: number = n_subPoint * spacing;
                var x_Sub: number = x_Base - x_DimOff(sta_off, ang_Base);
                var y_Sub: number = y_Base + y_DimOff(sta_off, ang_Base);
                var z_Sub: number = z_Base + z_off - z_end;
                pathSub.push(new BABYLON.Vector3(x_Sub, z_Sub, y_Sub));
            }  
            
            var path_sub: BABYLON.Curve3 = new BABYLON.Curve3(pathSub);
            var pathSubComp: BABYLON.Vector3[] = path_sub.getPoints();
            return pathSubComp;
        };
        // Bridge baseline paths
        var pathBase = pathComponent(staPjBegin, staPjEnd, 10, 0, 0); 
        var pathBaseStick = pathBaseLine(staPjBegin, staPjEnd, 0, 0);
        var pathBaseAngle = baseAngle(staPjBegin, staPjEnd, deg);
        var pathUnit: BABYLON.Vector3[][] = []; // Declare pathUnit as an array of arrays of Vector3
        var lengthSkew = 50; // transition length for skew angle at both ends of the unit
        var stepSkew = 10; // segment length for each transition 
        var nSkew = Math.floor(lengthSkew / stepSkew); // number of segments for skew transition

        for (let i = 0; i < staUnit.length; i++) {
            const [start, end, skew1, skew2, condition1, condition2] = staUnit[i];

            // Ensure the values used for calculations are numbers
            const startNum = Number(start);
            const endNum = Number(end);
            const skew1Num = Number(skew1);
            const skew2Num = Number(skew2);
            
            if (condition1 !== 0 && condition2 !== 0) {
                pathUnit[i] = pathComponent(startNum + skew1Num + lengthSkew, endNum - skew2Num - lengthSkew, 4, 0, 0);
            } else if (condition1 !== 0 && condition2 === 0) {
                pathUnit[i] = pathComponent(startNum + skew1Num + lengthSkew, endNum - skew2Num, 4, 0, 0);
            } else if (condition1 === 0 && condition2 !== 0) {
                pathUnit[i] = pathComponent(startNum + skew1Num, endNum - skew2Num - lengthSkew, 4, 0, 0);    
            } else {
                pathUnit[i] = pathComponent(startNum + skew1Num, endNum - skew2Num, 4, 0, 0);    
            }
        }

        // Stripe Line Paths
        let lanePath: BABYLON.Vector3[][] = []; // Declare lanePath as an array of arrays of Vector3
        if (superstructureON === "YES" && laneON === "YES") {
            var stripe_step = 10; // Define stripe point spacing by default
            
            for (var i = 0; i < (stripe_main[ii]).length; i++) {
                var stripe_name = (stripe_main[ii])[i];
                var index_stripe = indexStripe(stripe_name);

                // Check if index_stripe is defined before using it
                if (index_stripe !== undefined) {
                    var begin_sta: number = Number(stripeStaBegin[index_stripe]); // Ensure it's a number
                    var end_sta: number = Number(stripeStaEnd[index_stripe]); // Ensure it's a number
                    var begin_skew = stripeSkewBegin[index_stripe];
                    var end_skew = stripeSkewEnd[index_stripe];
                    var stripe_seg: number = stripeSeg[index_stripe];
                    var lane_path: BABYLON.Vector3[] = []; // Declare lane_path as an array of Vector3
                    
                    if (stripe_seg === 1) {
                        var stripe_offset: number = Number(stripeGeometry[index_stripe][2]); // Ensure stripe_offset is a number
                        var lane_pathj: BABYLON.Vector3[] = pathComponent(begin_sta, end_sta, stripe_step, stripe_offset, 0.25); 
                        lane_path = lane_path.concat(lane_pathj);               
                    } else {
                        for (var j = 0; j < stripe_seg; j++) {
                            var sta_begj: number = Number(stripeGeometry[index_stripe][3 * j + 0]); // Ensure it's a number
                            var sta_endj: number = Number(stripeGeometry[index_stripe][3 * j + 1]); // Ensure it's a number
                            var stripe_offset: number = Number(stripeGeometry[index_stripe][3 * j + 2]); // Ensure stripe_offset is a number
                            var lane_pathj: BABYLON.Vector3[] = pathComponent(sta_begj, sta_endj, stripe_step, stripe_offset, 0.25);  
                            lane_path = lane_path.concat(lane_pathj); 
                        }
                    }     
                    lanePath.push(lane_path);
                } else {
                    console.warn(`Stripe "${stripe_name}" not found.`);
                }
            }
        }

        // 4. Create Bridge Components
        // 4.1 Baseline  
        if (baselineON == "YES"){
            showBaseLineScene();
        } //Close for showing objects

        function showBaseLineScene(){
        // removeBaseLine();
        var shapeBase = shapeRectangular(0.5,0.25);

        var baseLine = BABYLON.Mesh.ExtrudeShape("baseline"+ii, shapeBase, pathBase, 1, 0, 0, scene1);
        baseLine.material = whiteMaterial;

        // Stick and station numbers for 100 feet, Upsation in black and downstation in red
        var makeTextPlane = function(text: string, color: string, size: number, index: number) {
            var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", 50, scene1, true);
            dynamicTexture.hasAlpha = true;
            dynamicTexture.drawText(text, 5, 14, "bold 14px Arial", color, "transparent", true);
            
            var plane = BABYLON.Mesh.CreatePlane("TextBaseLine" + index + ii, size, scene1, true);
            var material = new BABYLON.StandardMaterial("TextPlaneMaterial", scene1); // Create StandardMaterial
            material.backFaceCulling = false;
            material.diffuseTexture = dynamicTexture;
            material.specularColor = new BABYLON.Color3(0, 0, 0); // Set specular color
            
            plane.material = material; // Assign the material to the plane

            return plane;
        };

        var shapeBaseStick = shapeRectangular(1, 0.5);
        for (i = 0; i < pathBaseStick.length; i++) {
            var x_Base = pathBaseStick[i].x;
            var y_Base = pathBaseStick[i].z; 
            var z_Base = pathBaseStick[i].y; 
            var ang_Base = pathBaseAngle[i] + 90 * deg;
            
            var sta_i = Math.ceil(staPjBegin / 100) * 100 + i * 100;
            
            // Convert sta_i to string
            var staChar = makeTextPlane(String(sta_i), "red", 20, i);
            staChar.position = new BABYLON.Vector3(x_Base, z_Base, y_Base);
            staChar.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
            
            var pathStick = pathSubComponent(3.0, 1, x_Base, y_Base, z_Base, ang_Base, 0, 0, 0);
            var baseLineStick = BABYLON.Mesh.ExtrudeShape("baselinestick" + i + ii, shapeBaseStick, pathStick, 1, 0, 0, scene1);
            baseLineStick.material = whiteMaterial;
        }
    }

        function removeBaseLine() {
            for (var jj = 0; jj < Number(NMAIN); jj++) {
                const baselineMesh = scene1.getMeshByID("baseline" + jj);
                if (baselineMesh) {
                    baselineMesh.dispose();  
                }  

                for (var i = 0; i < pathBaseStick.length; i++) {
                    const textMesh = scene1.getMeshByID("TextBaseLine" + i + jj);
                    if (textMesh) {
                        textMesh.dispose();  
                    }

                    const baseLineStickMesh = scene1.getMeshByID("baselinestick" + i + jj);
                    if (baseLineStickMesh) {
                        baseLineStickMesh.dispose();  
                    }
                }
            } 
        }

        // skew is defined as positive in anticlockwise direction
        var deckSkewPath = function (sta: number, thickness: number, width: number, ang: number, offset: number): BABYLON.Vector3[] {
            var x_baseLeft = x_hc_off(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg, width / 2 + offset);
            var y_baseLeft = y_hc_off(sta, yPC1, angPC1, staPC, hcR, hcLOC, deg, width / 2 + offset);
            var x_baseRight = x_hc_off(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg, -width / 2 + offset);
            var y_baseRight = y_hc_off(sta, yPC1, angPC1, staPC, hcR, hcLOC, deg, -width / 2 + offset);
            var z_base = EL_vc_off(sta, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, 0);
            var angBase = ang_hc(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg) + 90 * deg; 

            var x_left = x_baseLeft - x_DimOff((-width / 2 + offset) * Math.tan(ang), angBase);
            var y_left = y_baseLeft + y_DimOff((-width / 2 + offset) * Math.tan(ang), angBase);
            var z_left = z_base + (superElevation(sta, superElev) || 0) * (width / 2 + offset); // Provide default value of 0
            
            var x_right = x_baseRight - x_DimOff((width / 2 + offset) * Math.tan(ang), angBase);
            var y_right = y_baseRight + y_DimOff((width / 2 + offset) * Math.tan(ang), angBase);
            var z_right = z_base + (superElevation(sta, superElev) || 0) * (-width / 2 + offset); // Provide default value of 0

            const myDeckPath = [
                new BABYLON.Vector3(x_left, z_left, y_left),  // Point #1
                new BABYLON.Vector3(x_right, z_right, y_right), // Point #2
                new BABYLON.Vector3(x_right, z_right - thickness, y_right), // Point #3
                new BABYLON.Vector3(x_left, z_left - thickness, y_left) // Point #4   
            ];
            return myDeckPath;
        };

        // skew is defined as positive in anticlockwise direction
        var roadSkewPath = function (sta: number, thickness: number, width: number, ang: number, offset: number): BABYLON.Vector3[] {
            // Calculate base positions
            var x_baseLeft = x_hc_off(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg, width / 2 + offset);
            var y_baseLeft = y_hc_off(sta, yPC1, angPC1, staPC, hcR, hcLOC, deg, width / 2 + offset);
            var x_baseRight = x_hc_off(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg, -width / 2 + offset);
            var y_baseRight = y_hc_off(sta, yPC1, angPC1, staPC, hcR, hcLOC, deg, -width / 2 + offset);
            var z_base = EL_vc_off(sta, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, 0);
            var angBase = ang_hc(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg) + 90 * deg; 
            
            // Calculate skewed path points
            var x_left = x_baseLeft - x_DimOff((-width / 2 + offset) * Math.tan(ang), angBase);
            var y_left = y_baseLeft + y_DimOff((-width / 2 + offset) * Math.tan(ang), angBase);
            var z_left = z_base + (superElevation(sta, superElev) || 0) * (width / 2 + offset); // Use default value
            
            var x_right = x_baseRight - x_DimOff((width / 2 + offset) * Math.tan(ang), angBase);
            var y_right = y_baseRight + y_DimOff((width / 2 + offset) * Math.tan(ang), angBase);
            var z_right = z_base + (superElevation(sta, superElev) || 0) * (-width / 2 + offset); // Use default value

            // Create path points
            var myPath = [
                new BABYLON.Vector3(x_left, z_left, y_left),  // Point #1
                new BABYLON.Vector3(x_right, z_right, y_right), // Point #2
                new BABYLON.Vector3(x_right, z_right - thickness, y_right), // Point #3
                new BABYLON.Vector3(x_left, z_left - thickness, y_left) // Point #4   
            ];

            return myPath;
        };

        // 4.2 Approach roadway/walls for the main roads only
        var spacing = 4; // spacing of deck/girder/roadway points betweent the skews  
        if (approadON == "YES"){
                showApproachRoadScene();
        }

        function showApproachRoadScene() {
            const bridge_approach1 = mainBridgeApproach1[ii];
            // Approach roadway 1
            let widthRoadApp1;
            let thickRoadApp1;
            let staRoad1Begin;
            let staRoad1End;
            let ribbonPath: BABYLON.Vector3[][] = [];
            let n_vcPoint;
            let appSkewBeg1; 
            let appSkewEnd1; 
            let road_seg1;
            let road_sec1_1; 
            let depth_approad1;
            let width_approad1;

            if (bridge_approach1 !== "NONE") {
                const index_app1 = indexRoad(bridge_approach1);
                
                // Ensure index_app1 is defined
                if (index_app1 === undefined) {
                    console.warn(`Index for road "${bridge_approach1}" not found.`);
                    return; // Exit if index is not valid
                }
                
                appSkewBeg1 = roadMainSkew1[index_app1]; 
                appSkewEnd1 = roadMainSkew2[index_app1]; 
                road_seg1 = roadSeg[index_app1];
                road_sec1_1 = roadGeometry[index_app1][1]; 
                depth_approad1 = 0;
                width_approad1 = 0;
                
                if (road_seg1 === 1) {
                    // Check if road_sec1_1 is a string before passing it to indexRoadSec
                    if (typeof road_sec1_1 === "string") {
                        const index_roadsec1 = indexRoadSec(road_sec1_1);
                        
                        // Ensure index_roadsec1 is defined
                        if (index_roadsec1 === undefined) {
                            console.warn(`Index for road section "${road_sec1_1}" not found.`);
                            return; // Exit if index is not valid
                        }
                        
                        const road1_shape = roadsecShapeDes[index_roadsec1];
                        
                        // Ensure road1_shape is a string before comparing
                        if (typeof road1_shape === "string" && road1_shape === "Rectangular") {        
                            depth_approad1 = roadsecDepth[index_roadsec1]; // Ensure this is a number
                            width_approad1 = roadsecWidth[index_roadsec1]; // Ensure this is a number
                        }
                    } else {
                        console.warn(`Expected road_sec1_1 to be a string, but got ${typeof road_sec1_1}`);
                    }
                }
                

        // Approach roadway 1
        widthRoadApp1 = width_approad1;   // width of the approach roadway
        thickRoadApp1 = depth_approad1;   // thickness of roadway pavement
        staRoad1Begin = staPjBegin;
        staRoad1End = staApproachSlab1;  
        n_vcPoint = Math.floor((staRoad1End - staRoad1Begin) / spacing);

        for (let j = 0; j < n_vcPoint; j++) {
            const staj: number = staRoad1Begin + j * spacing;
            const skewj: number = appSkewBeg1 + (appSkewEnd1 - appSkewBeg1) / n_vcPoint * j;
            
            // Call deckSkewPath which returns an array of Vector3
            const appPathj: BABYLON.Vector3[] = deckSkewPath(staj, thickRoadApp1, widthRoadApp1, skewj, 0);
            // Add each point in appPathj to ribbonPath
            ribbonPath.push(appPathj); 
        }

            const myPaths0: BABYLON.Vector3[][] = [ribbonPath[0]];
            const myPaths1: BABYLON.Vector3[][] = [ribbonPath[ribbonPath.length - 1]];

            const ribbon0 = BABYLON.MeshBuilder.CreateRibbon("roadapp1 begin face"   + ii, { 
                pathArray: myPaths0,
                updatable: true, 
                closePath: false 
            }, scene1);
            ribbon0.material = roadMaterial;   

           // shadowGenerator.getShadowMap()?.renderList?.push(ribbon0); // Use optional chaining here

            const ribbon1 = BABYLON.MeshBuilder.CreateRibbon("roadapp1 end face"  + ii, { 
                pathArray: myPaths1,
                updatable: true, 
                closePath: false 
            }, scene1);
            ribbon1.material = roadMaterial;          

            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon1); // Use optional chaining here

            const ribbon = BABYLON.MeshBuilder.CreateRibbon("roadapp1" + ii, { 
                pathArray: ribbonPath,
                sideOrientation: BABYLON.Mesh.DOUBLESIDE, 
                updatable: true, 
                closePath: true 
            }, scene1);
            
            ribbon.material = roadMaterial;       
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon); // Use optional chaining here

            }

            const bridge_approach2 = mainBridgeApproach2[ii];
            let appSkewBeg2; 
            let appSkewEnd2; 
            let road_seg2;
            let road_sec2_1;   
            let depth_approad2;
            let width_approad2;       

            let widthRoadApp2;   // width of the approach roadway
            let thickRoadApp2;   // thickness of roadway pavement
            let staRoad2Begin;
            let staRoad2End;  


            if (bridge_approach2 !== "NONE") {    
                const index_app2 = indexRoad(bridge_approach2);
                
                // Ensure index_app2 is defined
                if (index_app2 === undefined) {
                    console.warn(`Index for road "${bridge_approach2}" not found.`);
                    return; // Exit if index is not valid
                }

                appSkewBeg2 = roadMainSkew1[index_app2]; 
                appSkewEnd2 = roadMainSkew2[index_app2]; 
                road_seg2 = roadSeg[index_app2];
                road_sec2_1 = roadGeometry[index_app2][1];   
                depth_approad2 = 0;
                width_approad2 = 0;       

                if (typeof road_sec2_1 === 'string') {
                    const index_roadsec2 = indexRoadSec(road_sec2_1);
                    
                    // Ensure index_roadsec2 is defined
                    if (index_roadsec2 === undefined) {
                        console.warn(`Index for road section "${road_sec2_1}" not found.`);
                        return; // Exit if index is not valid
                    }
                
                    const road2_shape = roadsecShapeDes[index_roadsec2];
                    if (road2_shape === "Rectangular") {        
                        depth_approad2 = roadsecDepth[index_roadsec2];
                        width_approad2 = roadsecWidth[index_roadsec2]; 
                    }
                } else {
                    console.warn(`road_sec2_1 is not a string: ${road_sec2_1}`);
                }
                
                if (mainType[ii] === "BRIDGE"){ 
                // Approach roadway 2
                widthRoadApp2 = width_approad2;   // width of the approach roadway
                thickRoadApp2 = depth_approad2;   // thickness of roadway pavement
                staRoad2Begin = staApproachSlab2;
                staRoad2End = staPjEnd;  

                let ribbonPath: BABYLON.Vector3[][] = [];
                let n_vcPoint;
                n_vcPoint = Math.floor((staRoad2End - staRoad2Begin) / spacing);


                for (let j = 0; j < n_vcPoint; j++) {
                    const staj: number = staRoad2Begin + j * spacing;
                    const skewj: number = appSkewBeg2 + (appSkewEnd2 - appSkewBeg2) / n_vcPoint * j;
                
                    const appPathj: BABYLON.Vector3[] = deckSkewPath(staj, thickRoadApp2, widthRoadApp2, skewj, 0);
                    ribbonPath.push(appPathj); 
                }
                
                const myPaths0: BABYLON.Vector3[][] = [ribbonPath[0]];
                const myPaths1: BABYLON.Vector3[][] = [ribbonPath[ribbonPath.length - 1]];
                
                // Create ribbon for the beginning face
                const ribbon0 = BABYLON.Mesh.CreateRibbon("roadapp2 begin face" + ii, myPaths0, true, false, 0, scene1);
                ribbon0.material = roadMaterial;   
                //shadowGenerator.getShadowMap()?.renderList?.push(ribbon0);
                
                // Create ribbon for the end face
                const ribbon1 = BABYLON.Mesh.CreateRibbon("roadapp2 end face" + ii, myPaths1, true, false, 0, scene1);
                ribbon1.material = roadMaterial;          
               // shadowGenerator.getShadowMap()?.renderList?.push(ribbon1);
                
                // Create the main ribbon with the complete path
                const ribbon = BABYLON.MeshBuilder.CreateRibbon("roadapp2" + ii, { pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
                ribbon.material = roadMaterial;       
               // shadowGenerator.getShadowMap()?.renderList?.push(ribbon);

             }
                
            }

            // Calculate offsets for barriers
            let sta_off1L_B;
            let sta_off1L_E;
            let sta_off1R_B;
            let sta_off1R_E;
        // Ensure widthRoadApp1 and appSkewBeg1 are defined before calculations
        if (widthRoadApp1 !== undefined && appSkewBeg1 !== undefined && appSkewEnd1 !== undefined) {
            sta_off1L_B = -widthRoadApp1 / 2 * Math.tan(appSkewBeg1);
            sta_off1L_E = -widthRoadApp1 / 2 * Math.tan(appSkewEnd1);
            sta_off1R_B = widthRoadApp1 / 2 * Math.tan(appSkewBeg1);
            sta_off1R_E = widthRoadApp1 / 2 * Math.tan(appSkewEnd1);
        } else {
            console.warn("Width or skew values for approach 1 are undefined.");
        }

        let sta_off2L_B;
        let sta_off2L_E;
        let sta_off2R_B;
        let sta_off2R_E;

        if (mainType[ii] === "BRIDGE"){ 
            // Ensure widthRoadApp2 and appSkewBeg2 are defined before calculations
            if (widthRoadApp2 !== undefined && appSkewBeg2 !== undefined && appSkewEnd2 !== undefined) {
                sta_off2L_B = -widthRoadApp2 / 2 * Math.tan(appSkewBeg2);
                sta_off2L_E = -widthRoadApp2 / 2 * Math.tan(appSkewEnd2);
                sta_off2R_B = widthRoadApp2 / 2 * Math.tan(appSkewBeg2);
                sta_off2R_E = widthRoadApp2 / 2 * Math.tan(appSkewEnd2);
            } else {
                console.warn("Width or skew values for approach 2 are undefined.");
            }
        }
        
            // Approach roadway Barrier 1
            if (bridge_approach1 !== "NONE") {
                const staRoad1B = staPjBegin;
                const staRoad1E = staApproachSlab1;
            
                // Check if widthRoadApp1 and sta_off1L_B are defined
                if (widthRoadApp1 !== undefined && sta_off1L_B !== undefined && sta_off1L_E !== undefined && sta_off1R_B !== undefined && sta_off1R_E !== undefined) {
                    const pathRoad1L = pathComponent(staRoad1B + sta_off1L_B, staRoad1E + sta_off1L_E, 2, 0.0, 0.0);
                    const pathRoad1R = pathComponent(staRoad1B + sta_off1R_B, staRoad1E + sta_off1R_E, 2, 0.0, 0.0);
                    
                    const shapeApproachFBarrierL = shapeFBarrierLeft(-widthRoadApp1 / 2, 0);
                    const barrierAPP1Left = BABYLON.MeshBuilder.ExtrudeShape("barrierapp1L" + ii, { shape: shapeApproachFBarrierL, path: pathRoad1L, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL }, scene1);
                    barrierAPP1Left.material = concreteMaterial;
                   // shadowGenerator.getShadowMap()?.renderList?.push(barrierAPP1Left);
            
                    const shapeApproachFBarrierR = shapeFBarrierRight(widthRoadApp1 / 2, 0);
                    const barrierApp1Right = BABYLON.MeshBuilder.ExtrudeShape("barrierapp1R" + ii, { shape: shapeApproachFBarrierR, path: pathRoad1R, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL }, scene1);
                    barrierApp1Right.material = concreteMaterial;
                   // shadowGenerator.getShadowMap()?.renderList?.push(barrierApp1Right);
                } else {
                    console.warn("Width or offset values for approach 1 are undefined. Cannot create barriers.");
                }
            }
            
            // Approach roadway Barrier 2
            if (bridge_approach2 !== "NONE") {
                const staRoad2B = staApproachSlab2;
                const staRoad2E = staPjEnd;
            
                // Check if widthRoadApp2 and offsets are defined
                if (widthRoadApp2 !== undefined && sta_off2L_B !== undefined && sta_off2L_E !== undefined && sta_off2R_B !== undefined && sta_off2R_E !== undefined) {
                    const pathRoad2L = pathComponent(staRoad2B + sta_off2L_B, staRoad2E + sta_off2L_E, 2, 0.0, 0.0);
                    const pathRoad2R = pathComponent(staRoad2B + sta_off2R_B, staRoad2E + sta_off2R_E, 2, 0.0, 0.0);
            
                    const shapeApproachFBarrierL = shapeFBarrierLeft(-widthRoadApp2 / 2, 0);
                    const barrierAPP2Left = BABYLON.MeshBuilder.ExtrudeShape("barrierapp2L" + ii, { shape: shapeApproachFBarrierL, path: pathRoad2L, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL }, scene1);
                    barrierAPP2Left.material = concreteMaterial;
                    //shadowGenerator.getShadowMap()?.renderList?.push(barrierAPP2Left);
            
                    const shapeApproachFBarrierR = shapeFBarrierRight(widthRoadApp2 / 2, 0);
                    const barrierApp2Right = BABYLON.MeshBuilder.ExtrudeShape("barrierapp2R" + ii, { shape: shapeApproachFBarrierR, path: pathRoad2R, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL }, scene1);
                    barrierApp2Right.material = concreteMaterial;
                   // shadowGenerator.getShadowMap()?.renderList?.push(barrierApp2Right);
                } else {
                    console.warn("Width or offset values for approach 2 are undefined. Cannot create barriers.");
                }
            }
            
        }

        const widthDeck: number[] = [];   // width of the deck
        const thickDeck: number[] = [];   // thickness of the deck
        const thickHaunch: number = 0.25;
        const widthDeck_varied: number[][] = [];   // varied width of the deck
        const offsetDeck_varied: number[][] = [];   // varied offset of deck to baseline    
        const thickDeck_varied: number[][] = [];   // varied thickness of the deck
        const deck_sta_begin: number[][] = [];
        const deck_sta_end: number[][] = [];

        for (let i = 0; i < staUnit.length; i++) {  
            const deck_unit = String(staUnit[i][7]);
            const index_deck = Number(indexDeck(deck_unit));
            const deck_seg = deckSec[index_deck];
            const widthDeck_temp: number[] = []; 
            const offsetDeck_temp: number[] = [];         
            const thickDeck_temp: number[] = [];   
            const deck_sta_begin_temp: number[] = [];
            const deck_sta_end_temp: number[] = [];    

            const staBegin = staUnit[i][0];
            const staEnd = staUnit[i][1];

            if (deck_seg === 1) {
                const deck_sec = String(deckGeometry[index_deck][3]);
                const deck_offset = Number(deckGeometry[index_deck][2]);
                const index_decksec = Number(indexDeckSec(deck_sec));
                const shape_des = decksecShapeDes[index_decksec];
                offsetDeck_temp.push(deck_offset);        
                if (shape_des === "Rectangular") {
                    widthDeck[i] = decksecWidth[index_decksec];
                    thickDeck[i] = decksecDepth[index_decksec];  
                    widthDeck_temp.push(decksecWidth[index_decksec]);
                    thickDeck_temp.push(decksecDepth[index_decksec]);                             
                } else {
                    // Non-rectangular shape
                }
                deck_sta_begin_temp.push(Number(staBegin));
                deck_sta_end_temp.push(Number(staEnd));        
            } else {
                for (let j = 0; j < deck_seg; j++) {
                    const factorBegin = Number(deckGeometry[index_deck][4 * j + 0]);
                    const factorEnd = Number(deckGeometry[index_deck][4 * j + 1]);
                    deck_sta_begin_temp.push(Number(staBegin) + (Number(staEnd) - Number(staBegin)) * factorBegin);
                    deck_sta_end_temp.push(Number(staBegin) + (Number(staEnd) - Number(staBegin)) * factorEnd); 
                    const deck_sec = deckGeometry[index_deck][4 * j + 3];
                    const deck_offset = deckGeometry[index_deck][4 * j + 2];          
                    const index_decksec = indexDeckSec(String(deck_sec));
                    const shape_des = decksecShapeDes[Number(index_decksec)];
                    offsetDeck_temp.push(Number(deck_offset));           
                    if (shape_des === "Rectangular") {
                        widthDeck_temp.push(decksecWidth[Number(index_decksec)]);
                        thickDeck_temp.push(decksecDepth[Number(index_decksec)]);                  
                    } else {
                        // Non-rectangular shape
                    }
                }
                widthDeck[i] = Math.max.apply(null, widthDeck_temp);
                thickDeck[i] = Math.max.apply(null, thickDeck_temp);
            }
            widthDeck_varied[i] = widthDeck_temp;
            offsetDeck_varied[i] = offsetDeck_temp;        
            thickDeck_varied[i] = thickDeck_temp;  
            deck_sta_begin[i] = deck_sta_begin_temp;
            deck_sta_end[i] = deck_sta_end_temp; 
        }

        function deckWidthVaried(sta: number, unit_id: number): number {
            let deck_width = 0;
            if (widthDeck_varied[unit_id].length === 1) {
                deck_width = widthDeck_varied[unit_id][0];
            }
            if (widthDeck_varied[unit_id].length !== 1) {
                for (let i = 0; i < widthDeck_varied[unit_id].length; i++) {
                    if (deck_sta_begin[unit_id][i] <= sta && sta <= deck_sta_end[unit_id][i]) {
                        deck_width = widthDeck_varied[unit_id][i];
                    } else if (
                        deck_sta_end[unit_id][i - 1] < sta &&
                        sta <= deck_sta_begin[unit_id][i]
                    ) {
                        deck_width =
                            widthDeck_varied[unit_id][i - 1] +
                            ((widthDeck_varied[unit_id][i] -
                                widthDeck_varied[unit_id][i - 1]) /
                                (deck_sta_begin[unit_id][i] - deck_sta_end[unit_id][i - 1])) *
                                (sta - deck_sta_end[unit_id][i - 1]);
                    } else if (deck_sta_begin[unit_id][0] > sta) {
                        deck_width = widthDeck_varied[unit_id][0];
                    } else if (
                        deck_sta_end[unit_id][
                            widthDeck_varied[unit_id].length - 1
                        ] < sta
                    ) {
                        deck_width =
                            widthDeck_varied[unit_id][
                                widthDeck_varied[unit_id].length - 1
                            ];
                    }
                }
            }
            return deck_width;
        }


        function deckOffsetVaried(sta: number, unit_id: number): number {
            let deck_offset = 0;

            // Check if the unit_id exists and offsetDeck_varied is defined
            if (unit_id < offsetDeck_varied.length && offsetDeck_varied[unit_id]) {
                if (offsetDeck_varied[unit_id].length === 1) {
                    deck_offset = offsetDeck_varied[unit_id][0];
                } else {
                    for (let i = 0; i < offsetDeck_varied[unit_id].length; i++) {
                        if (deck_sta_begin[unit_id][i] <= sta && sta <= deck_sta_end[unit_id][i]) {
                            deck_offset = offsetDeck_varied[unit_id][i];
                        } else if (deck_sta_end[unit_id][i - 1] < sta && sta <= deck_sta_begin[unit_id][i]) {
                            deck_offset = offsetDeck_varied[unit_id][i - 1] + 
                                (offsetDeck_varied[unit_id][i] - offsetDeck_varied[unit_id][i - 1]) / 
                                (deck_sta_begin[unit_id][i] - deck_sta_end[unit_id][i - 1]) * 
                                (sta - deck_sta_end[unit_id][i - 1]);
                        } else if (deck_sta_begin[unit_id][0] > sta) {
                            deck_offset = offsetDeck_varied[unit_id][0];
                        } else if (deck_sta_end[unit_id][offsetDeck_varied[unit_id].length - 1] < sta) {
                            deck_offset = offsetDeck_varied[unit_id][offsetDeck_varied[unit_id].length - 1];
                        }
                    }
                }
            } else {
                console.warn(`Unit ID ${unit_id} is out of range or offsetDeck_varied is not defined.`);
            }

            return deck_offset;
        }

        function deckThickVaried(sta: number, unit_id: number): number {
            let deck_thick = 0;

            // Ensure unit_id is within the bounds of thickDeck_varied
            if (unit_id < thickDeck_varied.length && thickDeck_varied[unit_id]) {
                if (thickDeck_varied[unit_id].length === 1) {
                    deck_thick = thickDeck_varied[unit_id][0];
                } else {
                    for (let i = 0; i < thickDeck_varied[unit_id].length; i++) {
                        if (deck_sta_begin[unit_id][i] <= sta && sta <= deck_sta_end[unit_id][i]) {
                            deck_thick = thickDeck_varied[unit_id][i];
                        } else if (deck_sta_end[unit_id][i - 1] < sta && sta <= deck_sta_begin[unit_id][i]) {
                            deck_thick = thickDeck_varied[unit_id][i - 1] + 
                                (thickDeck_varied[unit_id][i] - thickDeck_varied[unit_id][i - 1]) / 
                                (deck_sta_begin[unit_id][i] - deck_sta_end[unit_id][i - 1]) * 
                                (sta - deck_sta_end[unit_id][i - 1]);
                        } else if (deck_sta_begin[unit_id][0] > sta) {
                            deck_thick = thickDeck_varied[unit_id][0];
                        } else if (deck_sta_end[unit_id][thickDeck_varied[unit_id].length - 1] < sta) {
                            deck_thick = thickDeck_varied[unit_id][thickDeck_varied[unit_id].length - 1];
                        }
                    }
                }
            } else {
                console.warn(`Unit ID ${unit_id} is out of range or thickDeck_varied is not defined.`);
            }

            return deck_thick;
        }

        //
        // 1) Adjust the declarations to 3D instead of 4D for offsets
        //
        const offsetGirder_varied: number[][][] = []; 
        const secGirder_varied: BABYLON.Vector3[][][][] = []; 
        const girder_sta_begin: number[][][] = [];
        const girder_sta_end: number[][][] = [];

        let index_girder_group: number[] = [];

        for (let i = 0; i < staUnit.length; i++) {
            const girder_group = staUnit[i][9]; // string
            index_girder_group = [];

            // Find matching girder groups
            for (let j = 0; j < Number(NGIRDER); j++) {
                if (girderGroup[j] === girder_group) {
                    index_girder_group.push(j);
                }
            }

            //
            // 2) offsetGirder_temp is now "number[][]" -> 
            //    first index is girder_id (within this unit),
            //    second index is segment index
            //
            const offsetGirder_temp: number[][] = [];
            const secGirder_temp: BABYLON.Vector3[][][] = [];  
            const girder_sta_begin_temp: number[][] = [];
            const girder_sta_end_temp: number[][] = [];

            for (let jj = 0; jj < index_girder_group.length; jj++) {
                const indexjj = index_girder_group[jj];
                const girder_type = girderType[indexjj];
                const girder_seg = girderSec[indexjj];

                // Prepare the arrays
                offsetGirder_temp[jj] = [];   // <--- now a 1D array of numbers
                secGirder_temp[jj] = [];     // array of segments, each segment has Vector3[]
                girder_sta_begin_temp[jj] = [];
                girder_sta_end_temp[jj] = [];

                if (girder_seg === 1) {
                    // Only one segment
                    const girder_sec = String(girderGeometry[indexjj][3]);
                    const index_sec = Number(indexGirderSec(girder_sec));
                    const girder_shape = girdersecShape[index_sec];
                    const girder_shapeDes = girdersecShapeDes[index_sec];

                    let shape_points7: BABYLON.Vector3[] = [];
                    if (girder_shapeDes === "Tub") {
                        const index_shape = Number(indexTubShape(girder_shape));
                        shape_points7 = tubShape_points[index_shape];
                    } else if (girder_shapeDes === "Plate") {
                        const index_shape = Number(indexPlateShape(girder_shape));
                        shape_points7 = plateShape_points[index_shape];
                    } else if (girder_shapeDes === "AASHTO") {
                        const index_shape = Number(indexAASHTOShape(girder_shape));
                        shape_points7 = aashtoShape_points[index_shape];
                    } else if (girder_shapeDes === "FIB") {
                        const index_shape = Number(indexFIBShape(girder_shape));
                        shape_points7 = fibShape_points[index_shape];
                    } else if (girder_shapeDes === "Box") {
                        const index_shape = Number(indexBoxShape(girder_shape));
                        shape_points7 = shapeBOX[index_shape];
                    }

                    const girder_offset = Number(girderGeometry[indexjj][2]);

                    //
                    // 3) Push a single number, not [girder_offset]
                    //
                    offsetGirder_temp[jj].push(girder_offset);

                    secGirder_temp[jj].push(shape_points7);
                    girder_sta_begin_temp[jj].push(Number(staUnit[i][0]));
                    girder_sta_end_temp[jj].push(Number(staUnit[i][1]));
                } else {
                    // Multiple segments
                    for (let k = 0; k < girder_seg; k++) {
                        girder_sta_begin_temp[jj].push(
                            Number(staUnit[i][0]) +
                                (Number(staUnit[i][1]) - Number(staUnit[i][0])) *
                                Number(girderGeometry[indexjj][4 * k + 0])
                        );
                        girder_sta_end_temp[jj].push(
                            Number(staUnit[i][0]) +
                                (Number(staUnit[i][1]) - Number(staUnit[i][0])) *
                                Number(girderGeometry[indexjj][4 * k + 1])
                        );

                        const girder_sec = String(girderGeometry[indexjj][4 * k + 3]);
                        const index_sec = Number(indexGirderSec(girder_sec));
                        const girder_shape = girdersecShape[index_sec];
                        const girder_shapeDes = girdersecShapeDes[index_sec];

                        let shape_points8: BABYLON.Vector3[] = [];
                        if (girder_shapeDes === "Tub") {
                            const index_shape = Number(indexTubShape(girder_shape));
                            shape_points8 = tubShape_points[index_shape];
                        } else if (girder_shapeDes === "Plate") {
                            // ... etc.
                            shape_points8 = [];
                        }

                        const girder_offset = Number(girderGeometry[indexjj][4 * k + 2]);

                        //
                        // 3) Push a single number, not [girder_offset]
                        //
                        offsetGirder_temp[jj].push(girder_offset);

                        secGirder_temp[jj].push(shape_points8);
                    }
                }
            }

            // Finally, assign them into the global arrays
            offsetGirder_varied[i] = offsetGirder_temp;
            secGirder_varied[i] = secGirder_temp;
            girder_sta_begin[i] = girder_sta_begin_temp;
            girder_sta_end[i] = girder_sta_end_temp;
        }

        // Helper to coerce values into number[] form
        function toNumberArray(value: number | number[] | undefined): number[] {
            if (Array.isArray(value)) {
                return value;
            } else if (typeof value === 'number') {
                return [value];
            } else {
                return [];
            }
        }

        /**
         * Returns the offset for a given station (sta), unit_id, girder_id.
         * Interpolates if `sta` is between two sub-segments.
         */
        function hcoffVaried(sta: number, unit_id: number, girder_id: number): number {
            let hc_off = 0;

            // offsetGirder is now a number[] (1D array) for each segment
            const offsetGirder: number[] = toNumberArray(offsetGirder_varied[unit_id]?.[girder_id]);
            const girderStaBegin: number[] = toNumberArray(girder_sta_begin[unit_id]?.[girder_id]);
            const girderStaEnd: number[] = toNumberArray(girder_sta_end[unit_id]?.[girder_id]);

            // If only one offset, return it directly
            if (offsetGirder.length === 1) {
                hc_off = offsetGirder[0];
                return hc_off;
            }

            // Handle multiple segments
            if (offsetGirder.length > 1) {
                for (let j = 0; j < offsetGirder.length; j++) {
                    // Check if `sta` is within the current segment range
                    if (girderStaBegin[j] <= sta && sta <= girderStaEnd[j]) {
                        hc_off = offsetGirder[j];
                        break;
                    }

                    // Check if `sta` is in the gap between segments => interpolate
                    if (
                        j > 0 &&
                        girderStaEnd[j - 1] < sta &&
                        sta <= girderStaBegin[j]
                    ) {
                        const previousOffset = offsetGirder[j - 1];
                        const currentOffset = offsetGirder[j];
                        const previousEnd = girderStaEnd[j - 1];
                        const currentBegin = girderStaBegin[j];

                        hc_off =
                            previousOffset +
                            ((currentOffset - previousOffset) / (currentBegin - previousEnd)) *
                            (sta - previousEnd);
                        break;
                    }
                }
            }

            return hc_off;
        }

        /**
         * Returns the girder cross-section points (Vector3[])
         * for a given station, unit_id, and girder_id.
         */

        function pointsVaried(sta: number, unit_id: number, girder_id: number): BABYLON.Vector3[] {
            let points: BABYLON.Vector3[] = [];

            // "shapes" = array of segments, each segment is an array of Vector3
            const shapes = secGirder_varied[unit_id]?.[girder_id] || [];

            // Convert station arrays safely
            const girderStaBegin = toNumberArray(girder_sta_begin[unit_id]?.[girder_id]);
            const girderStaEnd = toNumberArray(girder_sta_end[unit_id]?.[girder_id]);

            // If only one segment, return that segment's points
            if (shapes.length === 1) {
                points = shapes[0];
                return points;
            }

            // Handle multiple segments
            for (let j = 0; j < shapes.length; j++) {
                const segPoints = shapes[j]; // array of Vector3 for j-th segment

                // If sta is within the j-th segment
                if (girderStaBegin[j] <= sta && sta <= girderStaEnd[j]) {
                    points = segPoints;
                    break;
                }

                // If sta is in a gap => interpolate between shapes j-1 and j
                if (j > 0 && girderStaEnd[j - 1] < sta && sta <= girderStaBegin[j]) {
                    const points1 = shapes[j - 1];
                    const points2 = shapes[j];

                    if (points1.length > 0 && points2.length > 0) {
                        const ratio =
                            (sta - girderStaEnd[j - 1]) /
                            (girderStaBegin[j] - girderStaEnd[j - 1]);

                        // Interpolate ALL points, one-to-one
                        const minLen = Math.min(points1.length, points2.length);
                        const interpolatedShape: BABYLON.Vector3[] = [];

                        for (let k = 0; k < minLen; k++) {
                            const p1 = points1[k];
                            const p2 = points2[k];
                            const interpolatedPoint = new BABYLON.Vector3(
                                p1.x + (p2.x - p1.x) * ratio,
                                p1.y + (p2.y - p1.y) * ratio,
                                p1.z + (p2.z - p1.z) * ratio
                            );
                            interpolatedShape.push(interpolatedPoint);
                        }

                        // If one shape is longer than the other, you can decide how to handle the leftover points.
                        // For simplicity, let's just copy them from points2 if needed:
                        if (points2.length > minLen) {
                            for (let k = minLen; k < points2.length; k++) {
                                // This might not be a 'true' interpolation, but you might choose to do something else
                                interpolatedShape.push(points2[k]);
                            }
                        }

                        points = interpolatedShape;
                    }
                    break;
                }

                // If sta is before the first segment
                if (j === 0 && girderStaBegin[j] > sta) {
                    points = segPoints;
                    break;
                }

                // If sta is after the last segment
                if (j === shapes.length - 1 && girderStaEnd[j] < sta) {
                    points = segPoints;
                    break;
                }
            }

            return points;
        }

        type SuperElevationFunc = (sta: number, superElev: number[][]) => number | undefined;

        const girderSkewPath = function (
            sta: number,
            girder_shape: BABYLON.Vector3[],
            ang: number,
            hc_off: number,
            vcOff: number
        ): BABYLON.Vector3[] {
            const z_base = EL_vc_off(sta, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, vcOff);
            const angBase = ang_hc(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg) + 90 * deg;
            const path: BABYLON.Vector3[] = [];

            for (let j = 0; j < girder_shape.length; j++) {
                const x = girder_shape[j].x;
                const y = girder_shape[j].y;
                const z = girder_shape[j].z;

                const xp =
                    x_hc_off(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off - x) -
                    x_DimOff((x - hc_off) * Math.tan(ang), angBase);
                const yp =
                    y + z_base + (superElevation(sta, superElev) || 0) * hc_off;
                const zp =
                    y_hc_off(sta, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off - x) +
                    y_DimOff((x - hc_off) * Math.tan(ang), angBase);

                path.push(new BABYLON.Vector3(xp, yp, zp));
            }

            return path;
        };

        const boxGirderSkewPath = function (
            sta: number,
            girder_shape: BABYLON.Vector3[],
            ang: number,
            hc_off: number,
            vcOff: number
        ): BABYLON.Vector3[] {
            const z_base = EL_vc_off(sta, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, vcOff);
            const angBase = ang_hc(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg) + 90 * deg;
            const path: BABYLON.Vector3[] = [];

            for (let j = 0; j < girder_shape.length; j++) {
                const x = girder_shape[j].x;
                const y = girder_shape[j].y;
                const z = girder_shape[j].z;

                const xp =
                    x_hc_off(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off - x) -
                    x_DimOff((x - hc_off) * Math.tan(ang), angBase);
                const yp =
                    y + z_base + (superElevation(sta, superElev) || 0) * (hc_off - x);
                const zp =
                    y_hc_off(sta, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off - x) +
                    y_DimOff((x - hc_off) * Math.tan(ang), angBase);

                path.push(new BABYLON.Vector3(xp, yp, zp));
            }

            return path;
        };

        const beamSkewPath = function (
            sta_beg: number,
            sta_end: number,
            hc_off_beg: number,
            hc_off_end: number,
            ang_beg: number,
            ang_end: number,
            girder_shape: BABYLON.Vector3[],
            vcOff: number
        ): BABYLON.Vector3[][] {
            const z_base_beg = EL_vc_off(sta_beg, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, vcOff);
            const angBase_beg = ang_hc(sta_beg, xPC1, angPC1, staPC, hcR, hcLOC, deg) + 90 * deg;
            const z_base_end = EL_vc_off(sta_end, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, vcOff);
            const angBase_end = ang_hc(sta_end, xPC1, angPC1, staPC, hcR, hcLOC, deg) + 90 * deg;

            const path_beg: BABYLON.Vector3[] = [];
            for (let j = 0; j < girder_shape.length; j++) {
                const x = girder_shape[j].x;
                const y = girder_shape[j].y;
                const z = girder_shape[j].z;

                const xp =
                    x_hc_off(sta_beg, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_beg - x) -
                    x_DimOff((x - hc_off_beg) * Math.tan(ang_beg), angBase_beg);
                const yp =
                    y + z_base_beg + (superElevation(sta_beg, superElev) || 0) * hc_off_beg;
                const zp =
                    y_hc_off(sta_beg, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_beg - x) +
                    y_DimOff((x - hc_off_beg) * Math.tan(ang_beg), angBase_beg);

                path_beg.push(new BABYLON.Vector3(xp, yp, zp));
            }

            const path_end: BABYLON.Vector3[] = [];
            for (let j = 0; j < girder_shape.length; j++) {
                const x = girder_shape[j].x;
                const y = girder_shape[j].y;
                const z = girder_shape[j].z;

                const xp =
                    x_hc_off(sta_end, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_end - x) -
                    x_DimOff((x - hc_off_end) * Math.tan(ang_end), angBase_end);
                const yp =
                    y + z_base_end + (superElevation(sta_end, superElev) || 0) * hc_off_end;
                const zp =
                    y_hc_off(sta_end, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_end - x) +
                    y_DimOff((x - hc_off_end) * Math.tan(ang_end), angBase_end);

                path_end.push(new BABYLON.Vector3(xp, yp, zp));
            }

            return [path_beg, path_end];
        };

        // 4.3 Bridge Superstructure
        if (superstructureON === "YES") {
    // Deck    
    if (deckON == "YES"){ 
        for(var i=0; i < staUnit.length; i++){ 
        var unit_name = String((staUnit[i])[13]);
        const index_unit = indexUnitOn(unit_name) || 0;
        var show_deck = unitON[index_unit][1];
        if(show_deck == "YES"){  

        if (Number(staUnit[i][4]) != 0 && Number(staUnit[i][5]) != 0){
        //Skewed deck near the begin of the unit
         var sta0 = Number(staUnit[i][0]) + Number(staUnit[i][2]); 
         var ang0 = Number(staUnit[i][4]);  
         var ribbonPath = [];
         for(var j= 0; j < nSkew + 1; j++){ 
         var staj = sta0 + j*stepSkew;
         var angj = ang0 - j * Number(staUnit[i][4]) / nSkew;
         var width_deckj = deckWidthVaried(staj,i);
         var offset_deckj = deckOffsetVaried(staj,i);     
         var depth_deckj = deckThickVaried(staj,i);
         var skewdeckPathj = deckSkewPath(staj,depth_deckj,width_deckj,angj,offset_deckj);
             ribbonPath[j] = skewdeckPathj;  
         }
    
        //Deck betweent the skews of the unit     
         var staBegin = Number(staUnit[i][0]) + Number(staUnit[i][2]) + lengthSkew;
         var staEnd = Number(staUnit[i][1]) - Number(staUnit[i][3]) - lengthSkew
         var n_vcPoint = Math.floor((staEnd - staBegin)/spacing);
         for (var j = 1; j < n_vcPoint-1; j++) {
         var staj = staBegin + j * spacing;
         var width_deckj = deckWidthVaried(staj,i);
         var offset_deckj = deckOffsetVaried(staj,i);      
         var depth_deckj = deckThickVaried(staj,i);     
         var deckPathj = deckSkewPath(staj,depth_deckj,width_deckj,0,offset_deckj);
             ribbonPath.push(deckPathj); 
          }
    
        //Skewed deck near the end of the unit     
         var sta0 = Number(staUnit[i][1]) - Number(staUnit[i][3]) - lengthSkew; 
         var ang0 = 0;  
         for(var j=0; j < nSkew + 1; j++){ 
         var staj = sta0 + j*stepSkew;
         var angj = ang0 + j * Number(staUnit[i][5]) / nSkew;
         var width_deckj = deckWidthVaried(staj,i);
         var offset_deckj = deckOffsetVaried(staj,i);     
         var depth_deckj = deckThickVaried(staj,i);      
         var skewdeckPathj = deckSkewPath(staj,depth_deckj,width_deckj,angj,offset_deckj);
             ribbonPath.push(skewdeckPathj);     
         }
    
        var myPaths0 = [ribbonPath[0]];
        var myPaths1 = [ribbonPath[ribbonPath.length-1]];    
        var ribbon0 = BABYLON.MeshBuilder.CreateRibbon("begin face" + ii,{pathArray: myPaths0,closeArray: true,closePath: false,offset: 0,},scene1);         
            ribbon0.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon0);
        var ribbon1 = BABYLON.MeshBuilder.CreateRibbon("end face" + ii, {pathArray: myPaths1,closeArray: true,closePath: false,offset: 0,},scene1);        
            ribbon1.material = concreteDeckMaterial;        
           // shadowGenerator.getShadowMap()?.renderList?.push(ribbon1);
        var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon"+ii, {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
            ribbon.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
    
           } else if (Number(staUnit[i][4]) != 0 && Number(staUnit[i][5]) == 0){
    
        //Skewed deck near the begin of the unit
         var sta0 = Number(staUnit[i][0]) + Number(staUnit[i][2]); 
         var ang0 = Number(staUnit[i][4]);  
         var ribbonPath = [];
         for(var j= 0; j < nSkew + 1; j++){ 
         var staj = sta0 + j*stepSkew;
         var angj = ang0 - j * Number(staUnit[i][4]) / nSkew;
         var width_deckj = deckWidthVaried(staj,i);
         var offset_deckj = deckOffsetVaried(staj,i);       
         var depth_deckj = deckThickVaried(staj,i);       
         var skewdeckPathj = deckSkewPath(staj,depth_deckj,width_deckj,angj,offset_deckj);
             ribbonPath[j] = skewdeckPathj;  
         }
    
        //Deck betweent the skews of the unit     
    
         var staBegin = Number(staUnit[i][0]) + Number(staUnit[i][2]) + lengthSkew;
         var staEnd = Number(staUnit[i][1]) - Number(staUnit[i][3]);
         var n_vcPoint = Math.floor((staEnd - staBegin)/spacing);
         for (var j = 1; j < n_vcPoint; j++) {
         var staj = staBegin + j * spacing;
         var width_deckj = deckWidthVaried(staj,i);
         var offset_deckj = deckOffsetVaried(staj,i);      
         var depth_deckj = deckThickVaried(staj,i);        
         var deckPathj = deckSkewPath(staj,depth_deckj,width_deckj,0,offset_deckj);
             ribbonPath.push(deckPathj); 
         }
         if (n_vcPoint * spacing  <= staEnd - staBegin){
            var width_deckj = deckWidthVaried(staEnd,i);
            var offset_deckj = deckOffsetVaried(staEnd,i);         
            var depth_deckj = deckThickVaried(staEnd,i);          
            var deckPathj = deckSkewPath(staEnd,depth_deckj,width_deckj,0,offset_deckj);
            ribbonPath.push(deckPathj); 
         }  
         
        var myPaths0 = [ribbonPath[0]];
        var myPaths1 = [ribbonPath[ribbonPath.length-1]];    
        var ribbon0 = BABYLON.MeshBuilder.CreateRibbon("begin face" + ii,{pathArray: myPaths0,closeArray: true,closePath: false,offset: 0,},scene1);        
            ribbon0.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon0);
        var ribbon1 = BABYLON.MeshBuilder.CreateRibbon("end face" + ii, {pathArray: myPaths1,closeArray: true,closePath: false,offset: 0,},scene1);
            ribbon1.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon1);
        var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon"+ii, {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
            ribbon.material = concreteDeckMaterial;        
           // shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
    
          } else if (Number(staUnit[i][4]) == 0 && Number(staUnit[i][5]) != 0){
    
        //Deck betweent the skews of the unit     
    
         var ribbonPath = [];
         var staBegin = Number(staUnit[i][0]) + Number(staUnit[i][2]);
         var staEnd = Number(staUnit[i][1]) - Number(staUnit[i][3]) - lengthSkew
         var n_vcPoint = Math.floor((staEnd - staBegin)/spacing);
         for (var j = 0; j < n_vcPoint-1; j++) {
         var staj = staBegin + j * spacing;
         var width_deckj = deckWidthVaried(staj,i);
         var offset_deckj = deckOffsetVaried(staj,i);     
         var depth_deckj = deckThickVaried(staj,i);     
         var deckPathj = deckSkewPath(staj,depth_deckj,width_deckj,0,offset_deckj);
             ribbonPath[j] = deckPathj;  
         }
    
        //Skewed deck near the end of the unit     
         var sta0 = Number(staUnit[i][1]) - Number(staUnit[i][3]) - lengthSkew; 
         var ang0 = 0;  
         for(var j=0; j < nSkew + 1; j++){ 
         var staj = sta0 + j*stepSkew;
         var angj = ang0 + j * Number(staUnit[i][5]) / nSkew;
         var width_deckj = deckWidthVaried(staj,i);
         var offset_deckj = deckOffsetVaried(staj,i);        
         var depth_deckj = deckThickVaried(staj,i);      
         var skewdeckPathj = deckSkewPath(staj,depth_deckj,width_deckj,angj,offset_deckj);
             ribbonPath.push(skewdeckPathj);     
         }
    
        var myPaths0 = [ribbonPath[0]];
        var myPaths1 = [ribbonPath[ribbonPath.length-1]];    
        var ribbon0 = BABYLON.MeshBuilder.CreateRibbon("begin face" + ii,{pathArray: myPaths0,closeArray: true,closePath: false,offset: 0,},scene1);
            ribbon0.material = concreteDeckMaterial;        
           // shadowGenerator.getShadowMap()?.renderList?.push(ribbon0);
        var ribbon1 = BABYLON.MeshBuilder.CreateRibbon("end face" + ii, {pathArray: myPaths1,closeArray: true,closePath: false,offset: 0,},scene1);
            ribbon1.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon1);
        var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon"+ii, {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
            ribbon.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
    
          }  else {  
    
        //Deck betweent the skews of the unit     
         var ribbonPath = [];
         var staBegin = Number(staUnit[i][0]) + Number(staUnit[i][2]);
         var staEnd = Number(staUnit[i][1]) - Number(staUnit[i][3]);
         var n_vcPoint = Math.floor((staEnd - staBegin)/spacing);
         for (var j = 0; j < n_vcPoint; j++) {
         var staj = staBegin + j * spacing;
         var width_deckj = deckWidthVaried(staj,i);
         var offset_deckj = deckOffsetVaried(staj,i);      
         var depth_deckj = deckThickVaried(staj,i);      
         const deckPathj00 = deckSkewPath(staj,depth_deckj,width_deckj,0,offset_deckj);
             ribbonPath[j] = deckPathj00;  
         }
         if (n_vcPoint * spacing  <= staEnd - staBegin){
            var width_deckj = deckWidthVaried(staEnd,i);
            var offset_deckj = deckOffsetVaried(staEnd,i);          
            var depth_deckj = deckThickVaried(staEnd,i);          
            var deckPathj = deckSkewPath(staEnd,depth_deckj,width_deckj,0,offset_deckj);
            ribbonPath.push(deckPathj); 
          }  
         
        var myPaths0 = [ribbonPath[0]];
        var myPaths1 = [ribbonPath[ribbonPath.length-1]];    
        var ribbon0 = BABYLON.MeshBuilder.CreateRibbon("begin face" + ii,{pathArray: myPaths0,closeArray: true,closePath: false,offset: 0,},scene1);
            ribbon0.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon0);
        var ribbon1 = BABYLON.MeshBuilder.CreateRibbon("end face" + ii, {pathArray: myPaths1,closeArray: true,closePath: false,offset: 0,},scene1);
            ribbon1.material = concreteDeckMaterial;        
           // shadowGenerator.getShadowMap()?.renderList?.push(ribbon1);
        var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon"+ii, {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
            ribbon.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
    
          };
    
        }; // close for showing objects
        } // close for "for" loop
        }; // Close for showing objects 
              
        
        // Function for rendering logic with shadowGenerator

            if (girderON === "YES") {
                for (let i = 0; i < staUnit.length; i++) {
                    const unitName = String(staUnit[i][13]); 
                    const indexUnit = indexUnitOn(unitName);

                    const defaultGirderMaterial = new BABYLON.StandardMaterial("white", scene1);
                    let girderMaterial: BABYLON.Material = defaultGirderMaterial;
                    const span_type = staUnit[i][8] as string;

                    if (indexUnit !== undefined) {
                        const showGirder = unitON[indexUnit]?.[2];

                if (showGirder === "YES") {
                    if (span_type === "CURVED") {
                        const girderGroupName = staUnit[i][9] as string;
                        const indexGirderGroup: number[] = [];

                        for (let j = 0; j < Number(NGIRDER); j++) {
                            if (girderGroup[j] === girderGroupName) {
                                indexGirderGroup.push(j);
                            }
                        }

                        for(var jj = 0; jj< index_girder_group.length;jj++){
                            var girder_type = girderType[index_girder_group[jj]];
                            var girder_sec = girderSec[index_girder_group[jj]];
                            var dist_to_deck = girderToDeck[index_girder_group[jj]];
                            let vc_off = 0;
                            switch(girder_type) {
                                case "STEELPL": 
                                    // Steel plate girder  
                                    vc_off = -dist_to_deck; //Vertical offset from top of the deck to top girder web
                                    girderMaterial = steelGirderMaterial;               
                                break;
                                case "STEELTUB": 
                                    // Steel tub girder  
                                    vc_off = -dist_to_deck; //Vertical offset from top of the deck to to girder web
                                    girderMaterial = steelGirderMaterial;               
                                break;
                                case "CONCTUB": 
                                    // Concrete tub girder
                                    vc_off = -dist_to_deck; //Vertical offset from top of the deck to top of girder
                                    girderMaterial = concreteGirderMaterial;             
                                break;
                                case "CONCBOX": 
                                    // Single cell concrete box girder
                                    vc_off = 0; //Vertical offset from top of the deck to girder work point
                                    girderMaterial = concreteGirderMaterial;  
                                    setShowSuperDeck(false); // Not showing deck for concrete box girder  
                                break;
                                case "FLATSLAB": 
                                    // Concrete flat slab
                                    vc_off = -dist_to_deck; //Vertical offset from top of the deck to top of slab
                                    girderMaterial = concreteGirderMaterial;             
                                break;
                            }
                    
                            if (Number(staUnit[i][4]) != 0 && Number(staUnit[i][5]) != 0){
                            //Skewed girder near the begin of the unit
                                var sta0 = Number(staUnit[i][0]) + Number(staUnit[i][2]); 
                                var ang0 = Number(staUnit[i][4]);  
                                var ribbonPath = [];
                                for(var j= 0; j < nSkew + 1; j++){ 
                                var staj = sta0 + j*stepSkew;
                                let angj = ang0 - j * Number(staUnit[i][4]) / nSkew;
                                var hcOffj = hcoffVaried(staj,i,jj);
                                var shapej = pointsVaried(staj,i,jj); 
                                if(girder_type == "CONCBOX"){
                                var skewGirderPathj = boxGirderSkewPath(staj,shapej,angj,hcOffj,vc_off);
                                } else{
                                var skewGirderPathj = girderSkewPath(staj,shapej,angj,hcOffj,vc_off);
                                }
                                    ribbonPath[j] = skewGirderPathj;  
                                }
                    
                            //Girder betweent the skews of the unit     
                    
                            var staBegin = Number(staUnit[i][0]) + Number(staUnit[i][2]) + lengthSkew;
                            var staEnd = Number(staUnit[i][1]) - Number(staUnit[i][3]) - lengthSkew
                            var n_vcPoint = Math.floor((staEnd - staBegin)/spacing);
                            for (var j = 1; j < n_vcPoint-1; j++) {
                            var staj = staBegin + j * spacing;
                            let angj = ang0 - j * Number(staUnit[i][4]) / nSkew;
                            var hcOffj = hcoffVaried(staj,i,jj); 
                            var shapej = pointsVaried(staj,i,jj); 
                                if(girder_type == "CONCBOX"){
                                var girderPathj = boxGirderSkewPath(staj,shapej,angj,hcOffj,vc_off);
                                } else{
                                var girderPathj = girderSkewPath(staj,shapej,angj,hcOffj,vc_off);
                                }                 
                                ribbonPath.push(girderPathj); 
                            }
                    
                            //Skewed girder near the end of the unit     
                            var sta0 = Number(staUnit[i][1]) - Number(staUnit[i][3]) - lengthSkew; 
                            var ang0 = 0;  
                            for(var j=0; j < nSkew + 1; j++){ 
                            var staj = sta0 + j*stepSkew;
                            angj = ang0 + j * Number(staUnit[i][5]) / nSkew;
                            var hcOffj = hcoffVaried(staj,i,jj); 
                            var shapej = pointsVaried(staj,i,jj);  
                                if(girder_type == "CONCBOX"){
                                var skewGirderPathj = boxGirderSkewPath(staj,shapej,angj,hcOffj,vc_off);
                                } else{
                                var skewGirderPathj = girderSkewPath(staj,shapej,angj,hcOffj,vc_off);
                                }               
                                ribbonPath.push(skewGirderPathj);     
                            }

                            var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon", {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
                                ribbon.layerMask = 1;  
                                ribbon.material = girderMaterial;        
                                //shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
                    
                                } else if (Number(staUnit[i][4]) != 0 && Number(staUnit[i][5]) == 0){
                    
                            //Skewed girder near the begin of the unit
                            var sta0 = Number(staUnit[i][0]) + Number(staUnit[i][2]); 
                            var ang0 = Number(staUnit[i][4]);  
                            var ribbonPath = [];
                            for(var j= 0; j < nSkew + 1; j++){ 
                            var staj = sta0 + j*stepSkew;
                            angj = ang0 - j * Number(staUnit[i][4]) / nSkew;
                            var hcOffj = hcoffVaried(staj,i,jj);  
                            var shapej = pointsVaried(staj,i,jj);  
                                if(girder_type == "CONCBOX"){
                                var skewGirderPathj = boxGirderSkewPath(staj,shapej,angj,hcOffj,vc_off);
                                } else{
                                var skewGirderPathj = girderSkewPath(staj,shapej,angj,hcOffj,vc_off);
                                }                
                                ribbonPath[j] = skewGirderPathj;  
                            }
                    
                            //Girder betweent the skews of the unit     
                    
                            var staBegin = Number(staUnit[i][0]) + Number(staUnit[i][2]) + lengthSkew;
                            var staEnd = Number(staUnit[i][1]) - Number(staUnit[i][3]);
                            var n_vcPoint = Math.floor((staEnd - staBegin)/spacing);
                            for (var j = 1; j < n_vcPoint; j++) {
                            var staj = staBegin + j * spacing;
                            var hcOffj = hcoffVaried(staj,i,jj);  
                            var shapej = pointsVaried(staj,i,jj);  
                                if(girder_type == "CONCBOX"){
                                var girderPathj = boxGirderSkewPath(staj,shapej,0,hcOffj,vc_off);
                                } else{
                                var girderPathj = girderSkewPath(staj,shapej,0,hcOffj,vc_off);
                                }               
                                ribbonPath.push(girderPathj); 
                            }
                            if (n_vcPoint * spacing  < staEnd - staBegin){
                                var hcOffj = hcoffVaried(staEnd,i,jj); 
                                var shapej = pointsVaried(staEnd,i,jj); 
                                if(girder_type == "CONCBOX"){
                                var girderPathj = boxGirderSkewPath(staEnd,shapej,0,hcOffj,vc_off);
                                } else{
                                var girderPathj = girderSkewPath(staEnd,shapej,0,hcOffj,vc_off);
                                }                           
                                ribbonPath.push(girderPathj); 
                            }     
                            

                            var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon", {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
                                ribbon.layerMask = 1;  
                                ribbon.material = girderMaterial;                 
                               // shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
                    
                            } else if (Number(staUnit[i][4]) == 0 && Number(staUnit[i][5]) != 0){
                    
                            //Girder betweent the skews of the unit     
                    
                            var ribbonPath = [];
                            var staBegin = Number(staUnit[i][0]) + Number(staUnit[i][2]);
                            var staEnd = Number(staUnit[i][1]) - Number(staUnit[i][3]) - lengthSkew
                            var n_vcPoint = Math.floor((staEnd - staBegin)/spacing);
                            for (var j = 0; j < n_vcPoint-1; j++) {
                            var staj = staBegin + j * spacing;
                            var hcOffj = hcoffVaried(staj,i,jj);
                            var shapej = pointsVaried(staj,i,jj);   
                                if(girder_type == "CONCBOX"){
                                var girderPathj = boxGirderSkewPath(staj,shapej,0,hcOffj,vc_off);
                                } else{
                                var girderPathj = girderSkewPath(staj,shapej,0,hcOffj,vc_off);
                                }                 
                                ribbonPath[j] = girderPathj;  
                            }
                    
                            //Skewed girder near the end of the unit     
                            var sta0 = Number(staUnit[i][1]) - Number(staUnit[i][3]) - lengthSkew; 
                            var ang0 = 0;  
                            for(var j=0; j < nSkew + 1; j++){ 
                            var staj = sta0 + j*stepSkew;
                            angj = ang0 + j * Number(staUnit[i][5]) / nSkew;
                            var hcOffj = hcoffVaried(staj,i,jj);  
                            var shapej = pointsVaried(staj,i,jj);  
                                if(girder_type == "CONCBOX"){
                                var skewGirderPathj = boxGirderSkewPath(staj,shapej,angj,hcOffj,vc_off);
                                } else{
                                var skewGirderPathj = girderSkewPath(staj,shapej,angj,hcOffj,vc_off);
                                }                
                                ribbonPath.push(skewGirderPathj);     
                            }


                            var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon", {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
                                ribbon.layerMask = 1;  
                                ribbon.material = girderMaterial;                
                                //shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
                    
                            }  else {  
                    
                            //Girder between the skews of the unit     
                            var ribbonPath = [];
                            var staBegin = Number(staUnit[i][0]) + Number(staUnit[i][2]);
                            var staEnd = Number(staUnit[i][1]) - Number(staUnit[i][3]);
                            var n_vcPoint = Math.floor((staEnd - staBegin)/spacing);
                            for (var j = 0; j < n_vcPoint; j++) {
                            var staj = staBegin + j * spacing;
                            var hcOffj = hcoffVaried(staj,i,jj); 
                            var shapej = pointsVaried(staj,i,jj);  

                                if(girder_type == "CONCBOX"){
                                var girderPathj = boxGirderSkewPath(staj,shapej,0,hcOffj,vc_off);
                                } else{
                                var girderPathj = girderSkewPath(staj,shapej,0,hcOffj,vc_off);
                                }                   
                                ribbonPath[j] = girderPathj;  
                            }
                            if (n_vcPoint * spacing  < staEnd - staBegin){
                                var hcOffj = hcoffVaried(staEnd,i,jj); 
                                var shapej = pointsVaried(staEnd,i,jj);  
                                if(girder_type == "CONCBOX"){
                                var girderPathj = boxGirderSkewPath(staEnd,shapej,0,hcOffj,vc_off);
                                } else{
                                var girderPathj = girderSkewPath(staEnd,shapej,0,hcOffj,vc_off);
                                }                                  
                                ribbonPath.push(girderPathj); 
                            }  


                            var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon", {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
                                ribbon.layerMask = 1;  
                                ribbon.material = girderMaterial;                 
                                //shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
                            };
                            }; // Close for multiple girders
                            
                    }

                    if(span_type === "CHORDED"){ 
                        const spans = staUnit[i][12] as any[];
                        var index_span_group = [];
                        for(var j=0;j<spans.length;j++){
                          var index_span = Number(indexSpan(spans[j])); 
                          index_span_group.push(index_span); 
                        }
                       for(var jj = 0; jj< index_span_group.length;jj++){
                         var index_span = index_span_group[jj];  
                         var sta_beg = spanGeometry[index_span][0];
                         var sta_end = spanGeometry[index_span][1]; 
                         var skew_beg = Number(spanGeometry[index_span][2]);  
                         var skew_end = Number(spanGeometry[index_span][3]);               
                         var beam_group = spanGeometry[index_span][4]; 
                         var index_beam_group = [];
                         for(var j=0;j<Number(NBEAM);j++){
                          if(beamGroup[j] == beam_group){
                              index_beam_group.push(j);
                          }
                         }        
                         for(var kk = 0; kk< index_beam_group.length;kk++){  
                           var index_beam = index_beam_group[kk];         
                           var beam_type = beamType[index_beam];
                           var beam_sec = beamSec[index_beam];
                           var dist_to_deck = beamDistToDeck[index_beam]; 
                           var base_offset_beg = beamBLOffBeg[index_beam];
                           var base_offset_end = beamBLOffEnd[index_beam];
                           var long_offset_beg = beamLongOffBeg[index_beam];
                           var long_offset_end = beamLongOffEnd[index_beam];          
                           var brg_offset_beg = beamBrgOffBeg[index_beam];
                           var brg_offset_end = beamBrgOffEnd[index_beam]; 
                           let vc_off = 0;  
                           switch(beam_type) {
                             case "CONCBM": 
                               // Concrete prestressed I beams
                               vc_off = -dist_to_deck; //Vertical offset from top of the deck to top of beam
                               girderMaterial = concreteGirderMaterial;             
                             break;
                             case "CONCTUB": 
                               // Concrete U girder
                               vc_off = -dist_to_deck; //Vertical offset from top of the deck to top of beam
                               girderMaterial = concreteGirderMaterial;       
                             break;
                            }

                            
                            var index_sec = Number(indexGirderSec(beam_sec));
                            var girder_shape = girdersecShape[index_sec];
                            var girder_shapeDes = girdersecShapeDes[index_sec];

                            let ShapePoints9: Vector3[] = [];
                            if(girder_shapeDes == "AASHTO"){
                             var index_shape = Number(indexAASHTOShape(girder_shape));
                             ShapePoints9 = aashtoShape_points[index_shape];
                            }
                            if(girder_shapeDes == "FIB"){
                             var index_shape = Number(indexFIBShape(girder_shape));
                             ShapePoints9 = fibShape_points[index_shape];
                            }

                            var staBeg = Number(sta_beg) + Number(long_offset_beg);
                            var staEnd = Number(sta_end) - Number(long_offset_end);
                            var skewGirderPathEnds = beamSkewPath(staBeg, staEnd,base_offset_beg,base_offset_end,skew_beg,skew_end,ShapePoints9,vc_off);
                            var x1 = [];
                            var y1 = [];
                            var z1 = [];
                            var x2 = [];
                            var y2 = [];
                            var z2 = [];
                            for(var j=0;j<(skewGirderPathEnds[0]).length;j++){
                                x1.push((skewGirderPathEnds[0])[j].x);
                                y1.push((skewGirderPathEnds[0])[j].y); 
                                z1.push((skewGirderPathEnds[0])[j].z);  
                                x2.push((skewGirderPathEnds[1])[j].x);
                                y2.push((skewGirderPathEnds[1])[j].y); 
                                z2.push((skewGirderPathEnds[1])[j].z);                                                    
                            }

                            var ribbonPath = [];
                            var n_vcPoint = Math.floor((staEnd - staBeg)/spacing);
                            for (var j = 0; j < n_vcPoint; j++) {
                              var staj = staBeg + j * spacing;
                              var beamPathj = [];           
                              for(var m=0;m<(skewGirderPathEnds[0]).length;m++){                   
                                var points_x = x1[m] + (x2[m] - x1[m]) / (staEnd - staBeg) * (staj - staBeg);
                                var points_y = y1[m] + (y2[m] - y1[m]) / (staEnd - staBeg) * (staj - staBeg);
                                var points_z = z1[m] + (z2[m] - z1[m]) / (staEnd - staBeg) * (staj - staBeg);               
                                beamPathj.push(new BABYLON.Vector3(points_x,points_y,points_z));  
                              }    
                              ribbonPath[j] = beamPathj;  
                            }
                            if (n_vcPoint * spacing  < staEnd - staBeg){
                             ribbonPath.push(skewGirderPathEnds[1]); 
                            }  

                            var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon", {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
                            ribbon.layerMask = 1;  
                            ribbon.material = girderMaterial;                  
                            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
                 
                        }; // Close for multiple beams
                       }
                     }
                    }

                  }

                }

            }
        

        var approach_slab1 = mainBridgeApproachSB1[ii];
        var index_appSB1 = Number(indexAppSB(approach_slab1));
        var appSBSkewBeg1 = appSBSkewBeg[index_appSB1];
        var appSBSkewEnd1 = appSBSkewEnd[index_appSB1];
        var width_appSB1 = widthApproachSlab[index_appSB1];
        var thick_appSB1 = thickApproachSlab[index_appSB1];
        var thick_appHaunch1 = thickAppHaunch[index_appSB1];
        var appSBBarrier1 = appSBBar[index_appSB1];   
        var indexBarrier1 = Number(indexBarrier(appSBBarrier1));
        var numBarrier1 =  barrierCount[indexBarrier1];
    
        var approach_slab2 = mainBridgeApproachSB2[ii];
        var index_appSB2 = Number(indexAppSB(approach_slab2));
        var appSBSkewBeg2 = appSBSkewBeg[index_appSB2];
        var appSBSkewEnd2 = appSBSkewEnd[index_appSB2]; 
        var width_appSB2 = widthApproachSlab[index_appSB2];
        var thick_appSB2 = thickApproachSlab[index_appSB2];
        var thick_appHaunch2 = thickAppHaunch[index_appSB2];      
        var appSBBarrier2 = appSBBar[index_appSB2]; 
        var indexBarrier2 = Number(indexBarrier(appSBBarrier2));    
        var numBarrier2 =  barrierCount[indexBarrier2];
    
        // Approach Slab #1    
        if(approadON == "YES"){   
         var ribbonPath = [];
         var staBegin = staApproachSlab1;
         var staEnd = staBrBegin;
         var n_vcPoint = Math.floor((staEnd - staBegin)/spacing);
         for (var j = 0; j < n_vcPoint; j++) {
         var staj = staBegin + j * spacing;
         var skewj = appSBSkewBeg1 + (appSBSkewEnd1 - appSBSkewBeg1)/ n_vcPoint *j;
         var deckPathj = deckSkewPath(staj,thick_appSB1,width_appSB1,skewj,0);
             ribbonPath[j] = deckPathj;  
         }
         if (n_vcPoint * spacing  <= staEnd - staBegin){
            var deckPathj = deckSkewPath(staEnd,thick_appSB1,width_appSB1,appSBSkewEnd1,0);
            ribbonPath.push(deckPathj); 
          }  
         

        var myPaths0 = [ribbonPath[0]];
        var myPaths1 = [ribbonPath[ribbonPath.length-1]];    
        var ribbon0 = BABYLON.MeshBuilder.CreateRibbon("begin face" + ii, { pathArray: myPaths0, updatable: true,closePath: false}, scene1);
            ribbon0.layerMask = 1;  
            ribbon0.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon0);
        var ribbon1 = BABYLON.MeshBuilder.CreateRibbon("end face" + ii, { pathArray: myPaths1, updatable: true,closePath: false}, scene1);        
            ribbon1.layerMask = 1;  
            ribbon1.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon1);
            try {
                var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon" + ii, {
                    pathArray: ribbonPath,
                    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                    updatable: true,
                    closePath: true
                }, scene1);
                ribbon.layerMask = 1;
                ribbon.material = concreteDeckMaterial;
                //shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
            } catch (error) {
                console.error("Error creating ribbon:", error, "Ribbon Path:", ribbonPath);
            }
            
        }
    
        // Approach Slab #2
        if(approadON == "YES"){   
         var ribbonPath = [];
         var staBegin = staBrEnd;
         var staEnd = staApproachSlab2;
         var n_vcPoint = Math.floor((staEnd - staBegin)/spacing);
         for (var j = 0; j < n_vcPoint; j++) {
         var staj = staBegin + j * spacing;
         var skewj = appSBSkewBeg2 + (appSBSkewEnd2 - appSBSkewBeg2)/ n_vcPoint *j;   
         var deckPathj = deckSkewPath(staj,thick_appSB2,width_appSB2,skewj,0);
             ribbonPath[j] = deckPathj;  
         }
         if (n_vcPoint * spacing  <= staEnd - staBegin){
            var deckPathj = deckSkewPath(staEnd,thick_appSB2,width_appSB2,appSBSkewEnd2,0);
            ribbonPath.push(deckPathj); 
          }  
         
        var myPaths0 = [ribbonPath[0]];
        var myPaths1 = [ribbonPath[ribbonPath.length-1]];   
        var ribbon0 = BABYLON.MeshBuilder.CreateRibbon("begin face" + ii, { pathArray: myPaths0, updatable: true,closePath: false}, scene1);
            ribbon0.layerMask = 1;  
            ribbon0.material = concreteDeckMaterial;        
            //shadowGenerator.getShadowMap()?.renderList?.push(ribbon0);
        var ribbon1 = BABYLON.MeshBuilder.CreateRibbon("end face" + ii, { pathArray: myPaths1, updatable: true,closePath: false}, scene1);
            ribbon1.layerMask = 1;  
            ribbon1.material = concreteDeckMaterial;        
           // shadowGenerator.getShadowMap()?.renderList?.push(ribbon1);

            try {
                var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon" + ii, {
                    pathArray: ribbonPath,
                    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                    updatable: true,
                    closePath: true
                }, scene1);
                ribbon.layerMask = 1;
                ribbon.material = concreteDeckMaterial;
                //shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
            } catch (error) {
                console.error("Error creating ribbon:", error, "Ribbon Path:", ribbonPath);
            }

        }
    
        // Function to generate the left-side barrier path
        function pathComponentBarrierLT(
            staBegin: number,
            staEnd: number,
            spacing: number,
            unitId: number,
            zoff: number
        ): BABYLON.Vector3[] {
            const myPath: BABYLON.Vector3[] = [];
            const nVcPoint = Math.floor((staEnd - staBegin) / spacing);

            for (let i = 0; i < nVcPoint; i++) {
                const staI = staBegin + i * spacing;
                const widthDeckI = deckWidthVaried(staI, unitId);
                const offsetDeckI = deckOffsetVaried(staI, unitId);
                const hoff = widthDeckI / 2 + offsetDeckI;

                const xHcI = x_hc_off(staI, xPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
                const yHcI = y_hc_off(staI, yPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
                let elVcI = EL_vc_off(staI, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, zoff);
                elVcI += superElevation(staI, superElev) || 0 * hoff;
 
                myPath.push(new BABYLON.Vector3(xHcI, elVcI, yHcI));
            }

            if (nVcPoint * spacing <= staEnd - staBegin) {
                const widthDeckEnd = deckWidthVaried(staEnd, unitId);
                const offsetDeckEnd = deckOffsetVaried(staEnd, unitId);
                const hoff = widthDeckEnd / 2 + offsetDeckEnd;

                const xHcEnd = x_hc_off(staEnd, xPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
                const yHcEnd = y_hc_off(staEnd, yPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
                let elVcEnd = EL_vc_off(staEnd, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, zoff);
                elVcEnd += superElevation(staEnd, superElev) || 0 * hoff;

                myPath.push(new BABYLON.Vector3(xHcEnd, elVcEnd, yHcEnd));
            }

            return new BABYLON.Curve3(myPath).getPoints();
        }

        // Function to generate the right-side barrier path
        function pathComponentBarrierRT(
            staBegin: number,
            staEnd: number,
            spacing: number,
            unitId: number,
            zoff: number
        ): BABYLON.Vector3[] {
            const myPath: BABYLON.Vector3[] = [];
            const nVcPoint = Math.floor((staEnd - staBegin) / spacing);

            for (let i = 0; i < nVcPoint; i++) {
                const staI = staBegin + i * spacing;
                const widthDeckI = deckWidthVaried(staI, unitId);
                const offsetDeckI = deckOffsetVaried(staI, unitId);
                const hoff = -widthDeckI / 2 + offsetDeckI;

                const xHcI = x_hc_off(staI, xPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
                const yHcI = y_hc_off(staI, yPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
                let elVcI = EL_vc_off(staI, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, zoff);
                elVcI += superElevation(staI, superElev) || 0 * hoff;

                myPath.push(new BABYLON.Vector3(xHcI, elVcI, yHcI));
            }

            if (nVcPoint * spacing <= staEnd - staBegin) {
                const widthDeckEnd = deckWidthVaried(staEnd, unitId);
                const offsetDeckEnd = deckOffsetVaried(staEnd, unitId);
                const hoff = -widthDeckEnd / 2 + offsetDeckEnd;

                const xHcEnd = x_hc_off(staEnd, xPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
                const yHcEnd = y_hc_off(staEnd, yPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
                let elVcEnd = EL_vc_off(staEnd, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, zoff);
                elVcEnd += superElevation(staEnd, superElev) || 0 * hoff;

                myPath.push(new BABYLON.Vector3(xHcEnd, elVcEnd, yHcEnd));
            }

            return new BABYLON.Curve3(myPath).getPoints();
        }


        // Main processing loop for barriers
        const pathUnitBarrierL: BABYLON.Vector3[][] = [];
        const pathUnitBarrierR: BABYLON.Vector3[][] = [];

        for (let i = 0; i < staUnit.length; i++) {
            const widthDeckBegin = deckWidthVaried(Number(staUnit[i][0]) + Number(staUnit[i][2]), i);
            const widthDeckEnd = deckWidthVaried(Number(staUnit[i][1]) - Number(staUnit[i][3]), i);
            const offsetDeckBegin = deckOffsetVaried(Number(staUnit[i][0]) + Number(staUnit[i][2]), i);
            const offsetDeckEnd = deckOffsetVaried(Number(staUnit[i][1]) - Number(staUnit[i][3]), i);

            const offsetLTBegin = -widthDeckBegin / 2 + offsetDeckBegin;
            const offsetRTBegin = widthDeckBegin / 2 + offsetDeckBegin;
            const offsetLTEnd = -widthDeckEnd / 2 + offsetDeckEnd;
            const offsetRTEnd = widthDeckEnd / 2 + offsetDeckEnd;

            if (Number(staUnit[i][4]) !== 0 && Number(staUnit[i][5]) !== 0) {
                pathUnitBarrierL[i] = pathComponentBarrierLT(
                    Number(staUnit[i][0]) + Number(staUnit[i][2]) + offsetLTBegin * Math.tan(Number(staUnit[i][4])),
                    Number(staUnit[i][1]) - Number(staUnit[i][3]) + offsetLTEnd * Math.tan(Number(staUnit[i][5])),
                    4,
                    i,
                    0
                );
                pathUnitBarrierR[i] = pathComponentBarrierRT(
                    Number(staUnit[i][0]) + Number(staUnit[i][2]) + offsetRTBegin * Math.tan(Number(staUnit[i][4])),
                    Number(staUnit[i][1]) - Number(staUnit[i][3]) + offsetRTEnd * Math.tan(Number(staUnit[i][5])),
                    4,
                    i,
                    0
                );
            } else if (Number(staUnit[i][4]) !== 0 && Number(staUnit[i][5]) === 0) {
                pathUnitBarrierL[i] = pathComponentBarrierLT(
                    Number(staUnit[i][0]) + Number(staUnit[i][2]) + offsetLTBegin * Math.tan(Number(staUnit[i][4])),
                    Number(staUnit[i][1]) - Number(staUnit[i][3]),
                    4,
                    i,
                    0
                );
                pathUnitBarrierR[i] = pathComponentBarrierRT(
                    Number(staUnit[i][0]) + Number(staUnit[i][2]) + offsetRTBegin * Math.tan(Number(staUnit[i][4])),
                    Number(staUnit[i][1]) - Number(staUnit[i][3]),
                    4,
                    i,
                    0
                );
            } else if (Number(staUnit[i][4]) === 0 && Number(staUnit[i][5]) !== 0) {
                pathUnitBarrierL[i] = pathComponentBarrierLT(
                    Number(staUnit[i][0]) + Number(staUnit[i][2]),
                    Number(staUnit[i][1]) - Number(staUnit[i][3]) + offsetLTEnd * Math.tan(Number(staUnit[i][5])),
                    4,
                    i,
                    0
                );
                pathUnitBarrierR[i] = pathComponentBarrierRT(
                    Number(staUnit[i][0]) + Number(staUnit[i][2]),
                    Number(staUnit[i][1]) - Number(staUnit[i][3]) + offsetRTEnd * Math.tan(Number(staUnit[i][5])),
                    4,
                    i,
                    0
                );
            } else {
                pathUnitBarrierL[i] = pathComponentBarrierLT(
                    Number(staUnit[i][0]) + Number(staUnit[i][2]),
                    Number(staUnit[i][1]) - Number(staUnit[i][3]),
                    4,
                    i,
                    0
                );
                pathUnitBarrierR[i] = pathComponentBarrierRT(
                    Number(staUnit[i][0]) + Number(staUnit[i][2]),
                    Number(staUnit[i][1]) - Number(staUnit[i][3]),
                    4,
                    i,
                    0
                );
            }
        }


    // Barriers
    if(barrierON == "YES") {
        // Barriers on the bridge    
        for(var i=0; i < staUnit.length; i++){  
        var unit_name= String((staUnit[i])[13]);
        var index_unit = Number(indexUnitOn(unit_name));
        var show_barrier = unitON[index_unit][0];            
        if(show_barrier == "YES"){  
        var barrier_unit = String(staUnit[i][6]);
        var index_barrier = Number(indexBarrier(barrier_unit));
        var barrier_deck_left = barrierDeckLeft[index_barrier];
        var barrier_deck_left_sec = barrierDeckLeftSec[index_barrier];
        var barrier_deck_right = barrierDeckRight[index_barrier];
        var barrier_deck_right_sec = barrierDeckRightSec[index_barrier];
        var index_sec_left = Number(indexBarrierSec(barrier_deck_left_sec));
        var index_sec_right = Number(indexBarrierSec(barrier_deck_right_sec));
        var numBarrier = barrierCount[index_barrier];
        
        // Left barrier at edge of deck
        if(barrier_deck_left == "YES"){

          if(barriersecShapeDes[index_sec_left] == "Standard"){
            let shapeDeckBarrierL: BABYLON.Vector3[] = [];
          if(barriersecShape[index_sec_left] == "F32"){
            if(barriersecMirror[index_sec_left] == "NO"){
            shapeDeckBarrierL = shapeFBarrierLeft(0,0);
            } else{
            shapeDeckBarrierL = shapeFBarrierRight(0,0);            
            }
          }


          shapeDeckBarrierL.push(shapeDeckBarrierL[0]);
          var barrierLeft = BABYLON.MeshBuilder.ExtrudeShape("barrierLeft" + ii, {shape: shapeDeckBarrierL, path: pathUnitBarrierL[i], sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
          barrierLeft.layerMask = 1;  
          barrierLeft.material = concreteMaterial;
          //shadowGenerator.getShadowMap()?.renderList?.push(barrierLeft);
          } else{
              // Non-standard barrier section
          }
        }
    
        // Right barrier at edge of deck
        if(barrier_deck_right == "YES"){
          if(barriersecShapeDes[index_sec_right] == "Standard"){
            let shapeDeckBarrierR: BABYLON.Vector3[] = [];
          if(barriersecShape[index_sec_right] == "F32"){
            if(barriersecMirror[index_sec_right] == "NO"){
            shapeDeckBarrierR = shapeFBarrierLeft(0,0);
            } else{
            shapeDeckBarrierR = shapeFBarrierRight(0,0);            
            }
          }
          shapeDeckBarrierR.push(shapeDeckBarrierR[0]);
          var barrierRight = BABYLON.MeshBuilder.ExtrudeShape("barrierRight" + ii, {shape: shapeDeckBarrierR, path: pathUnitBarrierR[i], sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1); 
          barrierRight.layerMask = 1;  
          barrierRight.material = concreteMaterial;
          //shadowGenerator.getShadowMap()?.renderList?.push(barrierRight);
          } else{
              // Non-standard barrier section
          }
        }

        // Barriers parallel to baseline      
        for(var j=0;j<numBarrier;j++){
          var barrierOffset = Number(barrierGeometry[index_barrier][2*j]);  
          var barrierSecj = String(barrierGeometry[index_barrier][2*j+1]);
          
          var index_secj = Number(indexBarrierSec(barrierSecj));
          if(barriersecShapeDes[index_secj] == "Standard"){
          var sta_off_B = -barrierOffset * Math.tan(Number(staUnit[i][4]));
          var sta_off_E = -barrierOffset * Math.tan(Number(staUnit[i][5]));
          var pathOffsetBarrier = pathComponent (Number(staUnit[i][0])+sta_off_B,Number(staUnit[i][1])+sta_off_E,0.25,Number(barrierOffset),0);
    
          let shapeOffsetBarrier: BABYLON.Vector3[] = [];

          if(barriersecShape[index_secj] == "F32"){
            if(barriersecMirror[index_secj] == "NO"){
            shapeOffsetBarrier = shapeFBarrierLeft(0,0);
            } else{
            shapeOffsetBarrier = shapeFBarrierRight(0,0);            
            }
          }
          shapeOffsetBarrier.push(shapeOffsetBarrier[0]);
          var barrierOffset1 = BABYLON.MeshBuilder.ExtrudeShape("barrierOffset" + i + j + ii, {shape: shapeOffsetBarrier, path: pathOffsetBarrier, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
          barrierOffset1.layerMask = 1;  
          barrierOffset1.material = concreteMaterial;

          //shadowGenerator.getShadowMap()?.renderList?.push(barrierOffset1);

          } else{
              // Non-standard barrier section
          }
         }
    
        }; // Close for showing objects     
        }
    
    
        // Barriers on the approach slabs
        if(approadON == "YES"){
        // Barrier on approach slab 1       
        for(var i=0;i<numBarrier1;i++){
    
          var barrierOffset = Number(barrierGeometry[indexBarrier1][2*i]);  
          var barrierSeci = String(barrierGeometry[indexBarrier1][2*i+1]);
          var index_seci = Number(indexBarrierSec(barrierSeci));
    
          if(barriersecShapeDes[index_seci] == "Standard"){
          var sta_off1_B = -barrierOffset * Math.tan(appSBSkewBeg1);
          var sta_off1_E = -barrierOffset * Math.tan(appSBSkewEnd1);
          var pathApproachBarrier1 = pathComponent (staApproachSlab1+sta_off1_B,staBrBegin+sta_off1_E,0.25,barrierOffset,0);
    

          let shapeAppBarrier: BABYLON.Vector3[] = [];
          if(barriersecShape[index_seci] == "F32"){
            if(barriersecMirror[index_seci] == "NO"){
            shapeAppBarrier = shapeFBarrierLeft(0,0);
            } else{
            shapeAppBarrier = shapeFBarrierRight(0,0);            
            }
          }
          shapeAppBarrier.push(shapeAppBarrier[0]);
    
          var barrierApp1 = BABYLON.MeshBuilder.ExtrudeShape("barrierApp1" + i + ii, {shape: shapeAppBarrier, path: pathApproachBarrier1, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
          barrierApp1.layerMask = 1;  
          barrierApp1.material = concreteMaterial;
          
          //shadowGenerator.getShadowMap()!.renderList!.push(barrierApp1);
          } else{
              // Non-standard barrier section
          }
         }
    
        // Barrier on approach slab 2 
        for(var i=0;i<numBarrier2;i++){
          var barrierOffset = Number(barrierGeometry[indexBarrier2][2*i]);  
          var barrierSeci = String(barrierGeometry[indexBarrier2][2*i+1]);
          var index_seci = Number(indexBarrierSec(barrierSeci));
    
          if(barriersecShapeDes[index_seci] == "Standard"){
          var sta_off2_B = -barrierOffset * Math.tan(appSBSkewBeg2);
          var sta_off2_E = -barrierOffset * Math.tan(appSBSkewEnd2);
          var pathApproachBarrier2 = pathComponent (staBrEnd+sta_off2_B,staApproachSlab2+sta_off2_E,0.25,barrierOffset,0);
    

          let shapeAppBarrier: BABYLON.Vector3[] = [];
          if(barriersecShape[index_seci] == "F32"){
            if(barriersecMirror[index_seci] == "NO"){
            shapeAppBarrier = shapeFBarrierLeft(0,0);
            } else{
            shapeAppBarrier = shapeFBarrierRight(0,0);            
            }
          }
          shapeAppBarrier.push(shapeAppBarrier[0]);
    
          var barrierApp2 = BABYLON.MeshBuilder.ExtrudeShape("barrierAppSB2" + i + ii, {shape: shapeAppBarrier, path: pathApproachBarrier2, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
          barrierApp2.layerMask = 1;  
          barrierApp2.material = concreteMaterial;
          
          //shadowGenerator.getShadowMap()?.renderList?.push(barrierApp2);
          } else{
              // Non-standard barrier section
          }
         }
        }

        // Barriers on the approach slabs
        if (approadON === "YES") {
            // Barrier on approach slab 1
            if (indexBarrier1 !== undefined && barrierGeometry[indexBarrier1]) {
                for (let i = 0; i < numBarrier1; i++) {
                    var barrierOffset = Number(barrierGeometry[indexBarrier1][2*i]);  
                    var barrierSeci = String(barrierGeometry[indexBarrier1][2*i+1]);
                    const indexSeci = indexBarrierSec(barrierSeci);

                    if (indexSeci !== undefined && barriersecShapeDes[indexSeci] === "Standard") {
                        const staOff1B = -barrierOffset * Math.tan(appSBSkewBeg1);
                        const staOff1E = -barrierOffset * Math.tan(appSBSkewEnd1);
                        const pathApproachBarrier1 = pathComponent(
                            staApproachSlab1 + staOff1B,
                            staBrBegin + staOff1E,
                            0.25,
                            barrierOffset,
                            0
                        );

                        const shapeAppBarrier =
                            barriersecShape[indexSeci] === "F32"
                                ? barriersecMirror[indexSeci] === "NO"
                                    ? shapeFBarrierLeft(0, 0)
                                    : shapeFBarrierRight(0, 0)
                                : [];
                        shapeAppBarrier.push(shapeAppBarrier[0]); // Close the shape loop

                        const barrierApp1 = BABYLON.MeshBuilder.ExtrudeShape(
                            `barrierApp1${i}`,
                            {
                                shape: shapeAppBarrier,
                                path: pathApproachBarrier1,
                                sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                cap: BABYLON.Mesh.CAP_ALL,
                            },
                            scene
                        );
                        barrierApp1.material = concreteMaterial;
                        shadowGenerator.getShadowMap()?.renderList?.push(barrierApp1);
                    }
                }
            }

            // Barrier on approach slab 2
            if (indexBarrier2 !== undefined && barrierGeometry[indexBarrier2]) {
                for (let i = 0; i < numBarrier2; i++) {
                    var barrierOffset = Number(barrierGeometry[indexBarrier2][2*i]);  
                    var barrierSeci = String(barrierGeometry[indexBarrier2][2*i+1]);
                    const indexSeci = indexBarrierSec(barrierSeci);

                    if (indexSeci !== undefined && barriersecShapeDes[indexSeci] === "Standard") {
                        const staOff2B = -barrierOffset * Math.tan(appSBSkewBeg2);
                        const staOff2E = -barrierOffset * Math.tan(appSBSkewEnd2);
                        const pathApproachBarrier2 = pathComponent(
                            staBrEnd + staOff2B,
                            staApproachSlab2 + staOff2E,
                            0.25,
                            barrierOffset,
                            0
                        );

                        const shapeAppBarrier =
                            barriersecShape[indexSeci] === "F32"
                                ? barriersecMirror[indexSeci] === "NO"
                                    ? shapeFBarrierLeft(0, 0)
                                    : shapeFBarrierRight(0, 0)
                                : [];
                        shapeAppBarrier.push(shapeAppBarrier[0]); // Close the shape loop

                        const barrierApp2 = BABYLON.MeshBuilder.ExtrudeShape(
                            `barrierAppSB2${i}`,
                            {
                                shape: shapeAppBarrier,
                                path: pathApproachBarrier2,
                                sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                cap: BABYLON.Mesh.CAP_ALL,
                            },
                            scene
                        );
                        barrierApp2.material = concreteMaterial;
                        shadowGenerator.getShadowMap()?.renderList?.push(barrierApp2);
                    }
                }
            }
        }

      }
    }


        // Functions
        if (laneON === "YES") { // Check if laneON is true
            showLaneScene();
        }

        function showLaneScene() {
            removeLane(); // Placeholder for your lane removal logic
        
            if (!stripe_main || stripe_main.length === 0) {
                console.error("stripe_main is undefined or empty");
                return;
            }
        
            if (!lanePath || lanePath.length === 0) {
                console.error("lanePath is undefined or empty");
                return;
            }
        
            for (let i = 0; i < stripe_main[ii].length; i++) {
                const stripeColor = "WHITE"; // Example placeholder, replace with logic
        
                // Check if lanePath[i] exists before using it
                if (!lanePath[i]) {
                    console.warn(`lanePath[${i}] is undefined or null`);
                    continue; // Skip to the next iteration
                }
        
                if (stripeColor === "WHITE") {
                    try {
                        const stripe = BABYLON.MeshBuilder.CreateDashedLines(
                            `stripeDashed${i}`,
                            { points: lanePath[i], dashNb: 400 },
                            scene1
                        );
                        stripe.edgesColor = new BABYLON.Color4(1, 1, 1, 1);
                    } catch (error) {
                        console.error(`Error creating stripe at index ${i}:`, error);
                    }
                }
            }
        }
        


        function removeLane() {
            const stripeMesh = scene1.getMeshByID("stripe");
            if (stripeMesh) {
                stripeMesh.dispose();
            }
        }


    // 4.4 Bridge Substructure
    // 4.4.1 Intermediate Piers   

        // Variables for substructure rendering
        const staIntPier: [number, number][] = [];
        const capIntPier_main: string[] = [];
        const colIntPier_main: string[][] = [];
        const footerIntPier_main: [number, number][][] = [];
        const pileIntPier_main: [number, number][][] = [];

        const bridge = mainBridgeNum[0]; // Example selection
        for (let i = 0; i < Number(NPIER); i++) {
            if (pierBridge[i] === bridge) {
                staIntPier.push([pierGeometry[i][0] as number, pierGeometry[i][1] as number]);
                capIntPier_main.push(pierGeometry[i][2] as string);
                const numCol = pierGeometry[i][3] as number;
                const colTemp: string[] = [];
                const footTemp: [number, number][] = [];
                const pileTemp: [number, number][] = [];
                for (let j = 0; j < numCol; j++) {
                    colTemp.push(pierGeometry[i][4 + 5 * j] as string);
                    footTemp.push([
                        pierGeometry[i][4 + 5 * j + 1] as number,
                        pierGeometry[i][4 + 5 * j + 2] as number,
                    ]);
                    pileTemp.push([
                        pierGeometry[i][4 + 5 * j + 3] as number,
                        pierGeometry[i][4 + 5 * j + 4] as number,
                    ]);
                }
                colIntPier_main.push(colTemp);
                footerIntPier_main.push(footTemp);
                pileIntPier_main.push(pileTemp);
            }
        }

        // Baseline coordinates
        const xyzIntPier: BABYLON.Vector3[] = [];
        for (let i = 0; i < staIntPier.length; i++) {
            sta_i = staIntPier[i][0];
            const x_hci = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,0);
            const y_hci = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,0);
            const EL_vci = EL_vc_off(sta_i,vcStaPvc,vcEL_Pvc,vcSlpLeft,vcSlpRight,vcLen,0);
            xyzIntPier.push(new BABYLON.Vector3(x_hci,EL_vci,y_hci));
        }

        //Function for new x, y and z coordinates with an offset on the baseline
        function pointHoffBase(sta:number,staOff:number,hoff:number){
            var point;
            sta = sta + staOff;
            const x_hc = x_hc_off(sta,xPC1,angPC1,staPC,hcR,hcLOC,deg,hoff);
            const  y_hc = y_hc_off(sta,yPC1,angPC1,staPC,hcR,hcLOC,deg,hoff);
            const EL_vc = EL_vc_off(sta,vcStaPvc,vcEL_Pvc,vcSlpLeft,vcSlpRight,vcLen,0);
            point = new BABYLON.Vector3(x_hc,EL_vc,y_hc);
            return point;
        }    

        // Baseline tangent angles at the piers
        var angIntPier = [];  
        for (let i = 0; i < staIntPier.length; i++) {
            sta_i = staIntPier[i][0];
            const ang_hci = ang_hc(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg);     
            angIntPier.push(ang_hci);
        }

        // 4.4.2 Create End Bents at Begin and End of Bridge
        var staEndBent = [   
            staBrBegin, // End Bent #1 FFBW
            staBrEnd, // End Bent #2 FFBW
            ];
   
        let skewEndBent: number[] = [];
        let pileEndBent: any[] = [];
        let widthBackwall: number[] = [];
        let thickBackwall: number[] = [];
        let heightBackwall: number[] = [];
        let slopeBackwall: number[] = [];
        let widthCheekWall: number[] = [];
        let thickCheekWall: number[] = [];
        let heightCheekWall: number[] = [];
        let lengthBentCap: number[] = [];
        let widthBentCap: number[] = [];
        let depthBentCap: number[] = [];
        let slopeBentCap: number[] = [];
        let longOffCap: number[] = [];
        let tranOffCap: number[] = [];
        let eb_pedestal: string[] = [];
        let longOffPile: number[] = [];
        let tranOffPile: number[] = [];


        for (var i = 0; i < Number(NENDBENT); i++) {
            if (endbentBridge[i] === bridge) {
                // Process Backwall Details
                var endbent_backwall = endbentBW[i];
                var endbent_skew = endbentSkew[i];
                skewEndBent.push(endbent_skew);

                var index_backwall = Number(indexCap(endbent_backwall));
                slopeBackwall.push(capSlope[index_backwall]);

                if (capSeg[index_backwall] === 1) {
                    var cap_section = capLongSec[index_backwall];
                    var index_capsec = Number(indexCapSec(cap_section));
                    var shapeDesc = piercapsecShapeDes[index_capsec];

                    if (shapeDesc === "Rectangular" || shapeDesc.toLowerCase() === "rectangular") {
                        widthBackwall.push(piercapsecWidth[index_capsec]);
                        thickBackwall.push(piercapsecThick[index_capsec]);
                        heightBackwall.push(piercapsecDepth[index_capsec]);
                    } else {
                        // Handle other shapes if needed
                    }
                }

                // Process Cheek Wall Details
                widthCheekWall.push(endbentCheekW[i]);
                thickCheekWall.push(endbentCheekT[i]);
                heightCheekWall.push(endbentCheekH[i]);

                // Process Cap Details
                var endbent_cap = endbentCap[i];
                var index_cap = Number(indexCap(endbent_cap));

                eb_pedestal.push(capPedestal[index_cap]);
                slopeBentCap.push(capSlope[index_cap]);
                longOffCap.push(capLongOff[index_cap]);
                tranOffCap.push(capTranOff[index_cap]);

                if (capSeg[index_cap] === 1) {
                    var cap_section = capLongSec[index_cap];
                    var index_capsec = Number(indexCapSec(cap_section));
                    var shapeDesc = piercapsecShapeDes[index_capsec];

                    if (shapeDesc === "Rectangular" || shapeDesc.toLowerCase() === "rectangular") {
                        lengthBentCap.push(piercapsecWidth[index_capsec]);
                        widthBentCap.push(piercapsecThick[index_capsec]);
                        depthBentCap.push(piercapsecDepth[index_capsec]);
                    } else {
                        // Handle other shapes if needed
                    }
                }

                // Process Pile Details
                var endbent_pile = endbentPile[i];
                var pileLength = endbentPileLength[i];
                var index_pile = Number(indexPile(endbent_pile));

                var pile_layout = pileGeometry[index_pile][0];
                var pile_embed = Number(pileEmbed[index_pile]);
                var pile_section = pileSection[index_pile];

                longOffPile.push(pileLongOff[index_pile]);
                tranOffPile.push(pileTranOff[index_pile]);

                if (pile_layout === "1") {
                    // Rectangular Pile Layout

                    var rowLongEBPile = pileGeometry[index_pile][1];  // Number of rows along the baseline
                    var rowTransEBPile = pileGeometry[index_pile][2]; // Number of rows perpendicular to the baseline
                    var spacingLongEBPile = pileGeometry[index_pile][3]; // Spacing along the baseline
                    var spacingTransEBPile = pileGeometry[index_pile][4]; // Spacing perpendicular to the baseline                  
                    pileEndBent.push([
                        rowLongEBPile,
                        rowTransEBPile,
                        spacingLongEBPile,
                        spacingTransEBPile,
                        pileLength,
                        pile_section,
                        pile_embed
                    ]); 

                } else {
                    // Handle irregular pile layouts if required
                }
            }
        }


        // Calculate coordinates
        let xyzEndBent: BABYLON.Vector3[] = [];
        for (let i = 0; i < staEndBent.length; i++) {
            const sta_i = staEndBent[i]; // Current station
            const hoff = tranOffCap[i]; // Horizontal offset
            const x_hci = x_hc_off(sta_i, xPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
            const y_hci = y_hc_off(sta_i, yPC1, angPC1, staPC, hcR, hcLOC, deg, hoff);
            const EL_vci = EL_vc_off(sta_i, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, 0);

            // Push calculated Vector3 into the array
            xyzEndBent.push(new BABYLON.Vector3(x_hci, EL_vci, y_hci));
        }

        // Explicitly type angEndBent as an array of numbers
        let angEndBent: number[] = [];

        // Iterate through staEndBent to calculate tangent angles
        for (let i = 0; i < staEndBent.length; i++) {
            const sta_i = staEndBent[i]; // Current station
            const ang_hci = ang_hc(sta_i, xPC1, angPC1, staPC, hcR, hcLOC, deg);

            // Push calculated angle into the array
            angEndBent.push(ang_hci);
        }

        if (substructureON == "YES") {

        // Creat Pier Cap, Column and Footing for each pier 
        for (let i = 0; i < staIntPier.length; i++) {

            // Pier Cap 
            var sta_base = Number(staIntPier[i][0]);
            var x_Base = xyzIntPier[i].x;
            var y_Base = xyzIntPier[i].z; 
            var z_Base = xyzIntPier[i].y; 
            var skew_angle = staIntPier[i][1];
            var ang_Base = angIntPier[i] + 90 * deg + skew_angle; // For cap and columns
            var ang_Base0 = angIntPier[i] + 90 * deg; // For pedestals and piles only
        

            if(capON == "YES") {   
            for (var j = 0; j < staUnit.length; j++) {   
                var unit_name = String((staUnit[j])[13]);
                const index_unit = Number(indexUnitOn(unit_name)); 
                var show_cap = unitON[index_unit][7]; 

            if ( (Number(staUnit[j][0]) <= staIntPier[i][0] && Number(staIntPier[i][0]) <= Number(staUnit[j][1])) && show_cap == "YES"){
                var cap_type = capIntPier_main[i];
                var index_cap = Number(indexCap(cap_type));
                var cap_slope = capSlope[index_cap];
                var cap_pedestal = capPedestal[index_cap];
                var dist_to_deck = Number(capTopDist[index_cap]);
                var cap_long_off = Number(capLongOff[index_cap]);          
                var cap_trans_off = Number(capTranOff[index_cap]);
                var point_base = pointHoffBase(sta_base,cap_long_off,cap_trans_off)
                var x_Base_cap = point_base.x;
                var y_Base_cap = point_base.z; 
                var z_Base_cap = point_base.y;

                // Calculate base point for each pedestal
                var x_BasePed1 = [];
                var y_BasePed1 = [];
                var z_BasePed1 = [];
                var z_offPed1 = [];
                var x_BasePed2 = [];
                var y_BasePed2 = [];
                var z_BasePed2 = [];
                var z_offPed2 = []; 
                var brg_type1 = [];
                var brg_length1 = [];
                var brg_width1 = [];
                var brg_height1 = [];
                var brg_diameter1 = [];
                var brg_type2 = [];
                var brg_length2 = [];
                var brg_width2 = [];
                var brg_height2 = []; 
                var brg_diameter2 = [];         
                if(cap_pedestal != "NONE"){         
                var index_ped = Number(indexPestal(cap_pedestal));
                var ped_length = pedestalLength[index_ped];
                var ped_width = pedestalWidth[index_ped]; 
                var ped_brg1 = pedestalBrg1[index_ped];
                var ped_brg2 = pedestalBrg2[index_ped];
                var ped_longoff1 = pedestalLongOff1[index_ped];
                var ped_longoff2 = pedestalLongOff2[index_ped]; 
                var shapePedestal = shapeRectangular(ped_width,0.5);  

                if(ped_brg1 > 0.0){
                    for(var k =0; k<ped_brg1; k++){
                    var ped_long1 = Number(cap_long_off) + Number(ped_longoff1);
                    var ped_tran1 = Number(pedestalGeometry1[index_ped][3*k]);
                    var ped_deck1 = Number(pedestalGeometry1[index_ped][3*k+1]);  
                    var ped_bearing1 = String(pedestalGeometry1[index_ped][3*k+2]);
                    const index_bearing = Number(indexBearing(ped_bearing1)); 
                    var brg_type = bearingType[index_bearing];
                    var brg_standard = bearingStandard[index_bearing];
                    let brg_length = 0; // Default initialization
                    let brg_width = 0;
                    let brg_height = 0;
                    let brg_diameter = 0;

                    if(brg_standard == "CUSTOM"){
                        brg_length = bearingLength[index_bearing];
                        brg_width = bearingWidth[index_bearing];
                        brg_height = bearingHeight[index_bearing];
                    }
                    if(brg_type == "CIRCPAD"){
                        brg_diameter = bearingLength[index_bearing];
                        brg_height = bearingHeight[index_bearing];
                    }                 
                    if(brg_type == "RECTPAD"){
                        var index_rectpad = Number(indexNPP(brg_standard));
                        brg_length = nppBrgLength[index_rectpad];
                        brg_width = nppBrgWidth[index_rectpad];
                        brg_height = nppBrgHeight[index_rectpad];
                    }
                    var point_basek = pointHoffBase(sta_base,ped_long1,ped_tran1);
                    x_BasePed1[k] = point_basek.x;
                    y_BasePed1[k] = point_basek.z; 
                    z_BasePed1[k] = point_basek.y;  
                    z_offPed1[k] = -1*ped_deck1;
                    brg_type1[k] = brg_type;
                    brg_length1[k] = brg_length;
                    brg_width1[k] = brg_width;
                    brg_height1[k] = brg_height;
                    brg_diameter1[k] = brg_diameter;                 
                    }

                    // Create pedestals for 1st bearing line
                    for (let k = 0; k < ped_brg1; k++) {
                        const sta_off = -1 * ped_length / 2;
                        const z_off = z_offPed1[k];
                        const pathPedestal = pathSubComponent(ped_length, 0.2, x_BasePed1[k], y_BasePed1[k], z_BasePed1[k], ang_Base0, sta_off, 0, z_off);

                        const CapPedestali1 = BABYLON.MeshBuilder.ExtrudeShape(
                            `CapPedi1${ii}${k}`,
                            {
                                shape: shapePedestal,
                                path: pathPedestal,
                                sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                cap: BABYLON.Mesh.CAP_ALL,
                            },
                            scene1
                        );

                        CapPedestali1.material = concreteMaterial;


                        if (shadowMap) {
                            // Ensure renderList is initialized
                            if (!shadowMap.renderList) {
                                shadowMap.renderList = [];
                            }
                            shadowMap.renderList.push(CapPedestali1);
                        } else {
                            console.error("Shadow map is null or undefined.");
                        }
                        

                        CapPedestali1.setPivotPoint(new BABYLON.Vector3(x_Base_cap, z_Base_cap, y_Base_cap));
                        CapPedestali1.rotation.y = -staIntPier[i][1];
                    }


                    // Create bearings for the 1st bearing line
                    for (let k = 0; k < ped_brg1; k++) {
                        const sta_brgoff = -1 * brg_length1[k] / 2;
                        const z_brgoff = z_offPed1[k] + brg_height1[k];
                        let CapBearingi1: BABYLON.Mesh | undefined; // Declare CapBearingi1 to avoid "used before assigned" error

                        if (brg_type1[k] === "RECTPAD") {
                            // Create rectangular bearing
                            const shapeBearing = shapeRectangular(brg_width1[k], brg_height1[k]); // Ensure this function exists
                            const pathBearing = pathSubComponent(
                                brg_length1[k],
                                0.2,
                                x_BasePed1[k],
                                y_BasePed1[k],
                                z_BasePed1[k],
                                ang_Base0,
                                sta_brgoff,
                                0,
                                z_brgoff
                            );
                            CapBearingi1 = BABYLON.MeshBuilder.ExtrudeShape(
                                `CapBrgi1_${ii}_${k}`,
                                {
                                    shape: shapeBearing,
                                    path: pathBearing,
                                    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                    cap: BABYLON.Mesh.CAP_ALL,
                                },
                                scene1
                            );
                        } else if (brg_type1[k] === "CIRCPAD") {
                            // Create circular bearing
                            const pathBearing = pathSubVertComponent( // Ensure this function is implemented
                                brg_height1[k],
                                0.05,
                                x_BasePed1[k],
                                y_BasePed1[k],
                                z_BasePed1[k],
                                ang_Base0,
                                0,
                                z_brgoff
                            );
                            CapBearingi1 = BABYLON.MeshBuilder.CreateTube(
                                `CapBrgi1_${ii}_${k}`,
                                {
                                    path: pathBearing,
                                    radius: brg_diameter1[k] / 2,
                                    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                    cap: BABYLON.Mesh.CAP_ALL,
                                },
                                scene1
                            );
                        }

                    if (CapBearingi1) {
                        // Assign material to the bearing
                        if (typeof blueMaterial === "undefined") {
                            console.error("blueMaterial is not defined.");
                            continue; // Skip iteration if blueMaterial is not defined
                        }
                        CapBearingi1.material = blueMaterial;
                        shadowGenerator.getShadowMap()?.renderList?.push(CapBearingi1);
                        // Set pivot point and rotation
                        CapBearingi1.setPivotPoint(new BABYLON.Vector3(x_Base_cap, z_Base_cap, y_Base_cap));
                        CapBearingi1.rotation.y = -staIntPier[i][1];
                    } else {
                        console.warn(`CapBearingi1 not created for index ${k}.`);
                    }
                }

                }


                    if (ped_brg2 > 0.0) {
                        for (let k = 0; k < ped_brg2; k++) {
                            const ped_long2 = Number(cap_long_off) + Number(ped_longoff2);
                            const ped_tran2 = Number(pedestalGeometry2[index_ped][3 * k]);
                            const ped_deck2 = Number(pedestalGeometry2[index_ped][3 * k + 1]);
                            const ped_bearing2 = pedestalGeometry2[index_ped][3 * k + 2].toString(); // Convert to string if necessary

                            const index_bearing = Number(indexBearing(ped_bearing2));
                            const brg_type = bearingType[index_bearing];
                            const brg_standard = bearingStandard[index_bearing];
                            const nppBrgWidth: number[] = [10, 12, 14];

                            let brg_length: number | undefined;
                            let brg_width: number | undefined;
                            let brg_height: number | undefined;
                            let brg_diameter: number | undefined;

                            if (brg_standard === "CUSTOM") {
                                brg_length = bearingLength[index_bearing];
                                brg_width = bearingWidth[index_bearing];
                                brg_height = bearingHeight[index_bearing];
                            } else if (brg_type === "CIRCPAD") {
                                brg_diameter = bearingLength[index_bearing];
                                brg_height = bearingHeight[index_bearing];
                            } else if (brg_type === "RECTPAD") {
                                const index_rectpad = Number(indexNPP(brg_standard));
                                brg_length = nppBrgLength[index_rectpad];
                                brg_width = nppBrgWidth[index_rectpad];
                                brg_height = nppBrgHeight[index_rectpad];
                            } else {
                                console.warn(`Unknown bearing type: ${brg_type}`);
                            }

                            const point_basek = pointHoffBase(sta_base, ped_long2, ped_tran2);

                            // Ensure values are not undefined before pushing into arrays
                            if (point_basek && brg_height !== undefined) {
                                x_BasePed2[k] = point_basek.x;
                                y_BasePed2[k] = point_basek.z;
                                z_BasePed2[k] = point_basek.y;
                                z_offPed2[k] = -1 * ped_deck2;

                                brg_type2[k] = brg_type;
                                brg_length2[k] = brg_length ?? 0; // Default to 0 if undefined
                                brg_width2[k] = brg_width ?? 0; // Default to 0 if undefined
                                brg_height2[k] = brg_height ?? 0; // Default to 0 if undefined
                                brg_diameter2[k] = brg_diameter ?? 0; // Default to 0 if undefined
                            } else {
                                console.error(`Invalid data for pedestal at index ${k}`);
                            }
                        }
                    }



                    // Create pedestals for 2nd bearing line
                    for (let k = 0; k < ped_brg2; k++) {
                        const sta_off = -1 * ped_length / 2;
                        const z_off = z_offPed2[k];
                        const pathPedestal = pathSubComponent(ped_length, 0.2, x_BasePed2[k], y_BasePed2[k], z_BasePed2[k], ang_Base0, sta_off, 0, z_off);
                        const CapPedestali2 = BABYLON.MeshBuilder.ExtrudeShape(`CapPedi2${ii}${k}`, {
                            shape: shapePedestal,
                            path: pathPedestal,
                            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                            cap: BABYLON.Mesh.CAP_ALL,
                        }, scene1);

                        CapPedestali2.material = concreteMaterial;

                        shadowGenerator.getShadowMap()?.renderList?.push(CapPedestali2);

                        CapPedestali2.setPivotPoint(new BABYLON.Vector3(x_Base_cap, z_Base_cap, y_Base_cap));
                        CapPedestali2.rotation.y = -staIntPier[i][1];
                    }

                    // Create bearings for 2nd bearing line
                    for (let k = 0; k < ped_brg2; k++) {
                        const sta_brgoff = -1 * brg_length2[k] / 2;
                        const z_brgoff = z_offPed2[k] + brg_height2[k];
                        let CapBearingi2: BABYLON.Mesh;

                        if (brg_type2[k] === "RECTPAD") {
                            const shapeBearing = shapeRectangular(brg_width2[k], brg_height2[k]);
                            const pathBearing = pathSubComponent(brg_length2[k], 0.2, x_BasePed2[k], y_BasePed2[k], z_BasePed2[k], ang_Base0, sta_brgoff, 0, z_brgoff);
                            CapBearingi2 = BABYLON.MeshBuilder.ExtrudeShape(`CapBrgi2${ii}${k}`, {
                                shape: shapeBearing,
                                path: pathBearing,
                                sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                cap: BABYLON.Mesh.CAP_ALL,
                            }, scene1);
                        } else if (brg_type2[k] === "CIRCPAD") {
                            const pathBearing = pathSubVertComponent(brg_height2[k], 0.05, x_BasePed2[k], y_BasePed2[k], z_BasePed2[k], ang_Base0, 0, z_brgoff);
                            CapBearingi2 = BABYLON.MeshBuilder.CreateTube(`CapBrgi2${ii}${k}`, {
                                path: pathBearing,
                                radius: brg_diameter2[k] / 2,
                                sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                cap: BABYLON.Mesh.CAP_ALL,
                            }, scene1);
                        } else {
                            throw new Error("Unsupported bearing type");
                        }

                        CapBearingi2.material = blueMaterial;
                        shadowGenerator.getShadowMap()?.renderList?.push(CapBearingi2);
                        CapBearingi2.setPivotPoint(new BABYLON.Vector3(x_Base_cap, z_Base_cap, y_Base_cap));
                        CapBearingi2.rotation.y = -staIntPier[i][1];
                    }

                }

                if (Number(capSeg[index_cap]) === 1) {
                    const cap_section = capLongSec[index_cap];
                    const index_capsec = Number(indexCapSec(cap_section));
                    const shape_capsec = piercapsecShape[index_capsec];
                    const widthPierCap = piercapsecThick[index_capsec];
                
                    let shapePierCap: BABYLON.Vector3[];
                    if (piercapsecShapeDes[index_capsec] === "Cap" || piercapsecShapeDes[index_capsec] === "CAP") {
                        const index_capshape = Number(indexCapShape(shape_capsec));
                        shapePierCap = slopedShapePierCap(index_capshape, cap_slope);
                    } else {
                        throw new Error("Unsupported pier cap shape");
                    }
                
                    const sta_off = -widthPierCap / 2;
                    const z_off = -dist_to_deck;
                    const pathPierCap = pathSubComponent(widthPierCap, 0.5, x_Base_cap, y_Base_cap, z_Base_cap, ang_Base, sta_off, 0, z_off);
                    const CapPieri = BABYLON.MeshBuilder.ExtrudeShape("CapPieri" + ii, {
                        shape: shapePierCap,
                        path: pathPierCap,
                        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                        cap: BABYLON.Mesh.CAP_ALL,
                    }, scene1);
                
                    CapPieri.material = concreteMaterial;
                    shadowGenerator.getShadowMap()?.renderList?.push(CapPieri);

                }
                
            };
            }
            }; //Close for showing objects
                    
                    // Pier Column 
                    var widthPierCol = 0;
                    var depthPierCol = 0;  
                    var depthPierCapRoot = capShape_depth_root[ii];

                    if (columnON === "YES") {
                        for (let j = 0; j < staUnit.length; j++) {
                            const unit_name = String(staUnit[j][13]);
                            const index_unit_local = Number(indexUnitOn(unit_name));
                            const show_column = unitON[index_unit_local][8];
                            if (Number(staUnit[j][0]) <= Number(staIntPier[i][0]) && Number(staIntPier[i][0]) <= Number(staUnit[j][1]) && show_column === "YES") {
                                for (let k = 0; k < colIntPier_main[i].length; k++) {
                                    const col_type = colIntPier_main[i][k];
                                    const index_col = Number(indexCol(col_type));
                                    const sta_off = Number(columnLongOff[index_col]);
                                    const h_off = Number(columnTranOff[index_col]);
                                    const z_off = -1 * Number(columnTopDist[index_col]);
                                    const sta_i = Number(staIntPier[i][0]);


                                    const x_BaseCol = x_hc_off(sta_i, xPC1, angPC1, staPC, hcR, hcLOC, deg, h_off);
                                    const y_BaseCol = y_hc_off(sta_i, yPC1, angPC1, staPC, hcR, hcLOC, deg, h_off);
                                    const colLength = z_Base + z_off - Number(footerIntPier_main[i][k][1]);
                    
                                    if (Number(columnSeg[index_col]) === 2 && colGeometry[index_col][1] === colGeometry[index_col][3]) {
                                        const col_section = String(colGeometry[index_col][1]);
                                        const index_sec = Number(indexColSec(col_section));
                                        const sec_shapeDes = piercolsecShapeDes[index_sec];
                                        if (sec_shapeDes === "Rectangular") {
                                            widthPierCol = Number(piercolsecWidth[index_sec]);
                                            depthPierCol = Number(piercolsecDepth[index_sec]);
                                            const shapePierCol = shapeRectangularRot(depthPierCol, widthPierCol, ang_Base - Number(staIntPier[i][1]));

                                            const pathPierCol = pathSubVertComponent(colLength, 1.0, x_BaseCol, y_BaseCol, z_Base, ang_Base, sta_off, z_off);
                                            
                                            const ColPieri = BABYLON.MeshBuilder.ExtrudeShape(`ColPier${ii}`, {
                                                shape: shapePierCol,
                                                path: pathPierCol,
                                                sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                                cap: BABYLON.Mesh.CAP_ALL,
                                            }, scene1);
                    
                                            const x_Base0 = xyzIntPier[i].x;
                                            const y_Base0 = xyzIntPier[i].z;
                                            const z_Base0 = xyzIntPier[i].y;
                    
                                            ColPieri.setPivotPoint(new BABYLON.Vector3(x_Base0, z_Base0, y_Base0));
                                            ColPieri.rotation.y = -staIntPier[i][1];
                    
                                            ColPieri.material = concreteMaterial;
                                            shadowGenerator.getShadowMap()?.renderList?.push(ColPieri);
                                        }
                                    }
                                  }
                                }
                              }
                            }
                    


                            // Pier Footing
                            if (footingON === "YES") {
                                for (let j = 0; j < staUnit.length; j++) {
                                    const unit_name = String(staUnit[j][13]);
                                    const index_unit_local = Number(indexUnitOn(unit_name));
                                    const show_footing = unitON[index_unit_local][9];

                                    if (Number(staUnit[j][0]) <= Number(staIntPier[i][0]) && Number(staIntPier[i][0]) <= Number(staUnit[j][1]) && show_footing === "YES") {
                                        for (let k = 0; k < footerIntPier_main[i].length; k++) {
                                            const footer = String(footerIntPier_main[i][k][0]);
                                            const footerEV = footerIntPier_main[i][k][1];
                                            const index_foot = Number(indexFooting(footer));

                                            const sec_dir = footingDirection[index_foot];
                                            if (sec_dir === "LONG") {
                                                const foot_section = String(footingGeometry[index_foot][0]);
                                                const index_sec = Number(indexFootSec(foot_section));
                                                const sec_shapeDes = pierfootsecShapeDes[index_sec];

                                                if (sec_shapeDes === "Rectangular") {
                                                    const widthPierFooting = Number(pierfootsecWidth[index_sec]);
                                                    const depthPierFooting = Number(pierfootsecDepth[index_sec]);
                                                    const lengthPierFooting = Number(pierfootsecLength[index_sec]);

                                                    const sta_off = -lengthPierFooting / 2;
                                                    const z_off_local = -1 * (z_Base - footerEV);

                                                    const pathPierFooting = pathSubComponent(
                                                        lengthPierFooting,
                                                        1.0,
                                                        x_Base,
                                                        y_Base,
                                                        z_Base,
                                                        ang_Base,
                                                        sta_off,
                                                        0,
                                                        z_off_local
                                                    );
                                                    const shapePierFooting = shapeRectangular(widthPierFooting, depthPierFooting);

                                                    const FootingPieri = BABYLON.MeshBuilder.ExtrudeShape(
                                                        `FootingPieri${ii}`,
                                                        {
                                                            shape: shapePierFooting,
                                                            path: pathPierFooting,
                                                            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                                            cap: BABYLON.Mesh.CAP_ALL,
                                                        },
                                                        scene1
                                                    );

                                                    FootingPieri.material = concreteMaterial;
                                                    shadowGenerator.getShadowMap()?.renderList?.push(FootingPieri);
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        // Pier Piles
                        if (pileON === "YES") {
                            for (let jj = 0; jj < staUnit.length; jj++) {
                                const unit_name = String(staUnit[jj][13]);
                                const index_unit = Number(indexUnitOn(unit_name));
                                const show_pile = unitON[index_unit][10];
                                if (Number(staUnit[jj][0]) <= Number(staIntPier[i][0]) && Number(staIntPier[i][0]) <= Number(staUnit[jj][1]) && show_pile === "YES") {
                                    for (let kk = 0; kk < footerIntPier_main[i].length; kk++) {
                                        const col_type = colIntPier_main[i][kk];
                                        const index_col = Number(indexCol(col_type));
                                        const sta_col_off = Number(columnLongOff[index_col]);
                                        const h_col_off = Number(columnTranOff[index_col]);

                                        const footer = String(footerIntPier_main[i][kk][0]);
                                        const footerEV = footerIntPier_main[i][kk][1];
                                        const index_foot = Number(indexFooting(footer));
                                        const sec_dir = footingDirection[index_foot];

                                        let depthPierFooting = 0;

                                        if (sec_dir === "LONG") {
                                            const foot_section = String(footingGeometry[index_foot][0]);
                                            const index_sec = Number(indexFootSec(foot_section));
                                            const sec_shapeDes = pierfootsecShapeDes[index_sec];

                                            if (sec_shapeDes === "Rectangular") {
                                                depthPierFooting = pierfootsecDepth[index_sec];
                                            }
                                        }

                                        const pile = String(pileIntPier_main[i][kk][0]);
                                        const pileLength = pileIntPier_main[i][kk][1];
                                        const index_pile = Number(indexPile(pile));
                                        const pile_footing = pileFooting[index_pile];
                                        const pile_longoff = pileLongOff[index_pile];
                                        const pile_tranoff = pileTranOff[index_pile];
                                        const pile_embed = Number(pileEmbed[index_pile]);
                                        const pile_layout = Number(pileGeometry[index_pile][0]);
                                        const pile_section = String(pileSection[index_pile]);
                                        const index_pilesec = Number(indexPileSec(pile_section));
                                        const pile_shape = pilesecShapeDes[index_pilesec];

                                        let width_pile = 0;
                                        let diameter_pile = 0;

                                        if (pile_shape === "Rectangular") {
                                            width_pile = pilesecWidth[index_pilesec];
                                        } else if (pile_shape === "Circular") {
                                            diameter_pile = pilesecWidth[index_pilesec];
                                        }

                                        const z_off = -1 * (z_Base - footerEV + depthPierFooting - pile_embed);

                                        if (pile_layout === 1) {
                                            // Rectangular pile layout
                                            const widthPierPile = width_pile;
                                            const rowLongPierPile = Number(pileGeometry[index_pile][1]);
                                            const rowTransPierPile = Number(pileGeometry[index_pile][2]);
                                            const spacingLongPierPile = Number(pileGeometry[index_pile][3]);
                                            const spacingTransPierPile = Number(pileGeometry[index_pile][4]);
                                            const lengthPierPile = pileLength;
                                            const sta_i = staIntPier[i][0];
                                            const x_Base0 = xyzIntPier[i].x;
                                            const y_Base0 = xyzIntPier[i].z;
                                            const z_Base0 = xyzIntPier[i].y;

                                            for (let j = 0; j < rowLongPierPile; j++) {
                                                for (let k = 0; k < rowTransPierPile; k++) {
                                                    const hoff =
                                                        (rowTransPierPile - 1) * spacingTransPierPile / 2 -
                                                        k * spacingTransPierPile +
                                                        h_col_off +
                                                        pile_tranoff;

                                                    const x_BasePile = x_hc_off(
                                                        sta_i,
                                                        xPC1,
                                                        angPC1,
                                                        staPC,
                                                        hcR,
                                                        hcLOC,
                                                        deg,
                                                        hoff
                                                    );

                                                    const y_BasePile = y_hc_off(
                                                        sta_i,
                                                        yPC1,
                                                        angPC1,
                                                        staPC,
                                                        hcR,
                                                        hcLOC,
                                                        deg,
                                                        hoff
                                                    );

                                                    const sta_off =
                                                        -1 * (rowLongPierPile - 1) * spacingLongPierPile / 2 +
                                                        j * spacingLongPierPile +
                                                        sta_col_off +
                                                        pile_longoff;

                                                    const pathPierPile = pathSubVertComponent(
                                                        lengthPierPile,
                                                        1.0,
                                                        x_BasePile,
                                                        y_BasePile,
                                                        z_Base0,
                                                        ang_Base0,
                                                        sta_off,
                                                        z_off
                                                    );


                                                    let pilePieri;

                                                    if (pile_shape === "Rectangular") {
                                                        const shapePierPile = shapeRectangularRot(widthPierPile, widthPierPile, 0);
                                                        pilePieri = BABYLON.MeshBuilder.ExtrudeShape(
                                                            `pilePieri${i}_${j}_${k}`,
                                                            {
                                                                shape: shapePierPile,
                                                                path: pathPierPile,
                                                                sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                                                cap: BABYLON.Mesh.CAP_ALL,
                                                            },
                                                            scene1
                                                        );
                                                    } else if (pile_shape === "Circular") {
                                                        pilePieri = BABYLON.MeshBuilder.CreateTube(
                                                            `pilePieri${i}_${j}_${k}`,
                                                            {
                                                                path: pathPierPile,
                                                                radius: diameter_pile / 2,
                                                                sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                                                cap: BABYLON.Mesh.CAP_ALL,
                                                            },
                                                            scene1
                                                        );
                                                    }

                                                    if(pilePieri){
                                                        pilePieri.material = concreteMaterial;
                                                        shadowGenerator.getShadowMap()?.renderList?.push(pilePieri);
                                                        pilePieri.setPivotPoint(new BABYLON.Vector3(x_Base0, z_Base0, y_Base0));
                                                        pilePieri.rotation.y = -staIntPier[i][1];
                                                    }

                                                }
                                            }
                                        } else {
                                            // Handle irregular pile layout
                                            console.warn("Irregular pile layout is not implemented");
                                        }
                                    }
                                }
                            }
                        }

                        }



                    for (let i = 0; i < staEndBent.length; i++) {
                        var x_Base = xyzEndBent[i].x;
                        var y_Base = xyzEndBent[i].z; 
                        var z_Base = xyzEndBent[i].y; 
                        var ang_Base = angEndBent[i] + 90 * deg; 

                    if (capON === "YES") {
                        for (let j = 0; j < staUnit.length; j++) {
                            const unit_name = String(staUnit[j][13]); 
                            const index_unit = Number(indexUnitOn(unit_name));
                            if (
                                Number(staUnit[j][0]) <= Number(staEndBent[i]) &&
                                Number(staEndBent[i]) <= Number(staUnit[j][1])
                            ) {
                                if (unitON[index_unit] && unitON[index_unit].length > 4) {
                                    const show_backwall = unitON[index_unit][4];
                    
                                    if (show_backwall === "YES") {
                                        const sta_off = i !== 1 ? -thickBackwall[i] : 0;
                                        const z_off = -thickApproachSlab[i] - thickAppHaunch[i];
                                        const pathBentBackwall = pathSubComponent(thickBackwall[i],0.25, x_Base,y_Base,z_Base,ang_Base,sta_off,0,z_off);
                                        const shapeBackwall = shapeRectangularProj(widthBackwall[i],heightBackwall[i],-1 * skewEndBent[i],slopeBackwall[i]);

                                        const backwallBent = BABYLON.MeshBuilder.ExtrudeShape(`backwallBent${i}`, {shape: shapeBackwall,path: pathBentBackwall,
                                            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                                            cap: BABYLON.Mesh.CAP_ALL,
                                        }, scene1);
                    
                                        backwallBent.material = concreteMaterial;
                                        shadowGenerator.getShadowMap()?.renderList?.push(backwallBent);
                                    }
                                } else {
                                    console.error(`Invalid or missing data in unitON for index: ${index_unit}`);
                                }
                            }
 
                    
            // End Bent Caps
            let CapBearingi1: BABYLON.Mesh | null = null;
            let shapePedestal: BABYLON.Vector3[]; 
            var show_ebcap = unitON[index_unit][5]; 


            if(show_ebcap == "YES"){
            if (i != 1){ var sta_off = -thickBackwall[i]; } else { var sta_off = -(widthBentCap[i]/Math.cos(Math.abs(skewEndBent[i])) - thickBackwall[i]);}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i];
            // Pedestal
                // Calculate base point for each pedestal
                var x_BasePed1 = [];
                var y_BasePed1 = [];
                var z_BasePed1 = [];
                var z_offPed1 = [];
                var x_BasePed2 = [];
                var y_BasePed2 = [];
                var z_BasePed2 = [];
                var z_offPed2 = [];
                var brg_type1 = [];
                var brg_length1 = [];
                var brg_width1 = [];
                var brg_height1 = [];
                var brg_diameter1 = [];
                var brg_type2 = [];
                var brg_length2 = [];
                var brg_width2 = [];
                var brg_height2 = []; 
                var brg_diameter2 = [];   

                if(eb_pedestal[i] != "NONE"){   
                var index_ped = Number(indexPestal(eb_pedestal[i]));
                var ped_length = pedestalLength[index_ped];
                var ped_width = pedestalWidth[index_ped]; 
                var ped_brg1 = pedestalBrg1[index_ped];
                var ped_brg2 = pedestalBrg2[index_ped];
                var ped_longoff1 = pedestalLongOff1[index_ped];
                var ped_longoff2 = pedestalLongOff2[index_ped]; 
                shapePedestal = shapeRectangular(ped_width,0.5);   // Assume 0.5 for showing pedestals   

                let brg_length = 0;
                let brg_width = 0;
                let brg_height = 0;
                let brg_diameter = 0;
                
                if(ped_brg1 > 0.0){
                    for(var k =0; k<ped_brg1; k++){
                    var ped_long1 = Number(longOffCap[i]) + Number(ped_longoff1);
                    var ped_tran1 = Number(pedestalGeometry1[index_ped][3*k]);
                    var ped_deck1 = Number(pedestalGeometry1[index_ped][3*k+1]);  
                    var ped_bearing1 = String(pedestalGeometry1[index_ped][3*k+2]);
                    var index_bearing = Number(indexBearing(ped_bearing1));
                    var brg_type = bearingType[index_bearing];
                    var brg_standard = bearingStandard[index_bearing];
                    if(brg_standard == "CUSTOM"){
                        brg_length = bearingLength[index_bearing];
                        brg_width = bearingWidth[index_bearing];
                        brg_height = bearingHeight[index_bearing];
                    }
                    if(brg_type == "CIRCPAD"){
                        brg_diameter = bearingLength[index_bearing];
                        brg_height = bearingHeight[index_bearing];
                    }  
                    if(brg_type == "RECTPAD"){
                        index_rectpad = Number(indexNPP(brg_standard));
                        brg_length = nppBrgLength[index_rectpad];
                        brg_width = nppBrgWidth[index_rectpad];
                        brg_height = nppBrgHeight[index_rectpad];
                    }  

                    var point_basek = pointHoffBase(Number(staEndBent[i]),ped_long1,ped_tran1);
                    x_BasePed1[k] = point_basek.x;
                    y_BasePed1[k] = point_basek.z; 
                    z_BasePed1[k] = point_basek.y;  
                    z_offPed1[k] = -1*ped_deck1;
                    brg_type1[k] = brg_type;               
                    brg_length1[k] = brg_length;
                    brg_width1[k] = brg_width;
                    brg_height1[k] = brg_height;  
                    brg_diameter1[k] = brg_diameter;             
                    }

                    // Create pedestals for 1st bearing line
                    for(var k =0; k<ped_brg1; k++){
                    var sta_pedoff = -1*ped_length /2;
                    var z_pedoff = z_offPed1[k];  
                    var pathPedestal = pathSubComponent(ped_length,0.2,x_BasePed1[k],y_BasePed1[k],z_BasePed1[k],ang_Base,sta_pedoff,0,z_pedoff);
                    var CapPedestali1 = BABYLON.MeshBuilder.ExtrudeShape("EBCapPedi1" + ii + i + k, {shape: shapePedestal, path: pathPedestal,sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);  
                    CapPedestali1.material = concreteMaterial;
                    shadowGenerator.getShadowMap()?.renderList?.push(CapPedestali1);
                    CapPedestali1.setPivotPoint(new BABYLON.Vector3(x_Base, z_Base, y_Base));
                    CapPedestali1.rotation.y = -skewEndBent[i];               
                    }
                    // Create bearings for 1st bearing line
                    for(var k =0; k<ped_brg1; k++){
                    var sta_brgoff = -1*brg_length1[k] /2;
                    var z_brgoff = z_offPed1[k] + brg_height1[k]; 
                    let CapBearingi1;
            
                    if(brg_type1[k] == "RECTPAD"){               
                        var shapeBearing = shapeRectangular(brg_width1[k],brg_height1[k]);               
                        var pathBearing = pathSubComponent(brg_length1[k],0.2,x_BasePed1[k],y_BasePed1[k],z_BasePed1[k],ang_Base,sta_brgoff,0,z_brgoff);
                        CapBearingi1 = BABYLON.MeshBuilder.ExtrudeShape("EBCapBrgi1" + ii + k, {shape: shapeBearing, path: pathBearing,sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);  
                    }
                    
                    if(brg_type1[k] == "CIRCPAD"){
                    var pathBearing = pathSubVertComponent(brg_height1[k],0.05,x_BasePed1[k],y_BasePed1[k],z_BasePed1[k],ang_Base,0,z_brgoff);                 
                    CapBearingi1 = BABYLON.MeshBuilder.CreateTube("EBCapBrgi1" + ii + k, {path: pathBearing, radius: brg_diameter1[k] / 2, sideOrientation: BABYLON.Mesh.DOUBLESIDE,cap: BABYLON.Mesh.CAP_ALL}, scene1);            
                    }  
                                

                    if(CapBearingi1){
                        CapBearingi1.material = blueMaterial;
                        shadowGenerator.getShadowMap()?.renderList?.push(CapBearingi1);
                        CapBearingi1.setPivotPoint(new BABYLON.Vector3(x_Base, z_Base, y_Base));
                        CapBearingi1.rotation.y = -skewEndBent[i];    
                    }
            
                    }
                }


                if(ped_brg2 > 0.0){
                    for(var k =0; k<ped_brg2; k++){
                    var ped_long2 = Number(longOffCap[i]) + Number(ped_longoff2);
                    var ped_tran2 = Number(pedestalGeometry2[index_ped][3*k]); 
                    var ped_deck2 = Number(pedestalGeometry2[index_ped][3*k+1]);  
                    var ped_bearing2 = String(pedestalGeometry2[index_ped][3*k+2]);
                    var index_bearing = Number(indexBearing(ped_bearing2));
                    var brg_type = bearingType[index_bearing];
                    var brg_standard = bearingStandard[index_bearing];
                    if(brg_standard == "CUSTOM"){
                        brg_length = bearingLength[index_bearing];
                        brg_width = bearingWidth[index_bearing];
                        brg_height = bearingHeight[index_bearing];
                    }
                    if(brg_type == "CIRCPAD"){
                        brg_diameter = bearingLength[index_bearing];
                        brg_height = bearingHeight[index_bearing];
                    }                
                    if(brg_type == "RECTPAD"){
                        index_rectpad = Number(indexNPP(brg_standard));
                        brg_length = nppBrgLength[index_rectpad];
                        brg_width = nppBrgWidth[index_rectpad];
                        brg_height = nppBrgHeight[index_rectpad];
                    }                                                 
                    var point_basek = pointHoffBase(Number(staEndBent[i]),ped_long2,ped_tran2);
                    x_BasePed2[k] = point_basek.x;
                    y_BasePed2[k] = point_basek.z; 
                    z_BasePed2[k] = point_basek.y;
                    z_offPed2[k] = -1*ped_deck2; 
                    brg_type2[k] = brg_type;               
                    brg_length2[k] = brg_length;
                    brg_width2[k] = brg_width;
                    brg_height2[k] = brg_height;
                    brg_diameter2[k] = brg_diameter;                                                                  
                    }
                }
                
                    // Create pedestals for 2nd bearing line
                    for(var k =0; k<ped_brg2; k++){
                    var sta_pedoff = -1*ped_length /2;
                    var z_pedoff = z_offPed2[k]; 
                    var pathPedestal = pathSubComponent(ped_length,0.2,x_BasePed2[k],y_BasePed2[k],z_BasePed2[k],ang_Base,sta_pedoff,0,z_pedoff);
                    var CapPedestali2 = BABYLON.MeshBuilder.ExtrudeShape("EBCapPedi2" + ii + i + k, {shape: shapePedestal, path: pathPedestal,sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);  
                    CapPedestali2.material = concreteMaterial;
                    shadowGenerator.getShadowMap()?.renderList?.push(CapPedestali2);
                    CapPedestali2.setPivotPoint(new BABYLON.Vector3(x_Base, z_Base, y_Base));
                    CapPedestali2.rotation.y = -skewEndBent[i];                    
                    } 

                    // Create bearings for 2nd bearing line
                    for(var k =0; k<ped_brg2; k++){
                    var sta_brgoff = -1*brg_length2[k] /2;
                    var z_brgoff = z_offPed2[k] + brg_height2[k]; 
                    let CapBearingi2;
                    if(brg_type2[k] == "RECTPAD"){               
                        var shapeBearing = shapeRectangular(brg_width2[k],brg_height2[k]);                 
                        var pathBearing = pathSubComponent(brg_length2[k],0.2,x_BasePed2[k],y_BasePed2[k],z_BasePed2[k],ang_Base,sta_brgoff,0,z_brgoff);
                        CapBearingi2 = BABYLON.MeshBuilder.ExtrudeShape("EBCapBrgi2" + ii + k, {shape: shapeBearing, path: pathBearing,sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);  
                    }
                    
                    if(brg_type2[k] == "CIRCPAD"){
                    var pathBearing = pathSubVertComponent(brg_height2[k],0.05,x_BasePed2[k],y_BasePed2[k],z_BasePed2[k],ang_Base,0,z_brgoff);                 
                    CapBearingi2 = BABYLON.MeshBuilder.CreateTube("EBCapBrgi2" + ii + k, {path: pathBearing, radius: brg_diameter2[k] / 2, sideOrientation: BABYLON.Mesh.DOUBLESIDE,cap: BABYLON.Mesh.CAP_ALL}, scene1);            
                    } 
                    if(CapBearingi2){
                        CapBearingi2.material = blueMaterial;
                        shadowGenerator.getShadowMap()?.renderList?.push(CapBearingi2);
                        CapBearingi2.setPivotPoint(new BABYLON.Vector3(x_Base, z_Base, y_Base));
                        CapBearingi2.rotation.y = -skewEndBent[i];   
                    }                
           
                    }                        
                }

            // Cap  
            var pathBentCap = pathSubComponent(widthBentCap[i]/Math.cos(Math.abs(skewEndBent[i])),0.25,x_Base,y_Base,z_Base,ang_Base,sta_off,0,z_off);
            var shapeBentCap = shapeRectangularProj(lengthBentCap[i],depthBentCap[i],-1*skewEndBent[i],slopeBentCap[i]);        

            var capBent = BABYLON.MeshBuilder.ExtrudeShape("capBent" + ii, {shape: shapeBentCap, path: pathBentCap, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
            capBent.material = concreteMaterial;
            shadowGenerator.getShadowMap()?.renderList?.push(capBent);   

            // End Bent Cheekwall
            var sta_i = staEndBent[i];
            var hoffL = lengthBentCap[i] /2 - thickCheekWall[i] / 2 + tranOffCap[i];
            var hoffR = -lengthBentCap[i] /2 + thickCheekWall[i] /2 + tranOffCap[i]; 

            var x_BaseCheekL = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,hoffL);
            var y_BaseCheekL = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,hoffL);
            var x_BaseCheekR = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,hoffR);
            var y_BaseCheekR = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,hoffR);

            // Left Cheekwall
            if (i !== 1){ var sta_off = (lengthBentCap[i]-thickBackwall[i])/2 *Math.tan(-1*skewEndBent[i]);} else { var sta_off = -(widthBentCap[i] - thickBackwall[i])+ (lengthBentCap[i]-thickBackwall[i])/2 *Math.tan(-1*skewEndBent[i]);}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] + heightCheekWall[i] + lengthBentCap[i] /2 * slopeBentCap[i];  
            var pathCheekWall1 = pathSubComponent(widthBentCap[i] - thickBackwall[i],0.25,x_BaseCheekL,y_BaseCheekL,z_Base,ang_Base,sta_off,0,z_off);
            var shapeCheekWall1 = shapeRectangularProj(thickCheekWall[i],heightCheekWall[i],-1*skewEndBent[i],0);
            var cheekWall1 = BABYLON.MeshBuilder.ExtrudeShape("cheekWall1" + ii, {shape: shapeCheekWall1, path: pathCheekWall1, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
            cheekWall1.material = concreteMaterial;
            shadowGenerator.getShadowMap()?.renderList?.push(cheekWall1);      

            // Right Cheekwall
            if (i !== 1){ var sta_off = -(lengthBentCap[i]-thickBackwall[i])/2 *Math.tan(-1*skewEndBent[i]);} else { var sta_off = -(widthBentCap[i] - thickBackwall[i]) - (lengthBentCap[i]-thickBackwall[i]) /2 *Math.tan(-1*skewEndBent[i]);}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] + heightCheekWall[i] - lengthBentCap[i] /2 * slopeBentCap[i];   
            var pathCheekWall2 = pathSubComponent(widthBentCap[i] - thickBackwall[i],0.25,x_BaseCheekR,y_BaseCheekR,z_Base,ang_Base,sta_off,0,z_off);
            var shapeCheekWall2 = shapeRectangularProj(thickCheekWall[i],heightCheekWall[i],-1*skewEndBent[i],0);
            var cheekWall2 = BABYLON.MeshBuilder.ExtrudeShape("cheekWall2" + ii, {shape: shapeCheekWall2, path: pathCheekWall2, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
            cheekWall2.material = concreteMaterial;
            shadowGenerator.getShadowMap()?.renderList?.push(cheekWall2); 
                } // show cap 


                // Ensure proper type checking and comparisons
                if (pileON === "YES") {

                        const index_unit = Number(indexUnitOn(unit_name)); // Cast as needed
                        const show_pile = unitON[index_unit][6];

                        if (
                            Number(staUnit[j][0]) <= staEndBent[i] &&
                            staEndBent[i] <= Number(staUnit[j][1]) &&
                            show_pile === "YES"
                        ) {
                            const pileOffset = longOffPile[i] + longOffCap[i];
                            var pile_section = String(pileEndBent[i][5]);
                            var pile_embed = Number(pileEndBent[i][6]);
                            const index_pilesec = Number(indexPileSec(pile_section));
                            const pile_shape = pilesecShapeDes[index_pilesec];
                            let diameter_pile: number = 0;
                            let widthBentPile: number = 0;
                            var embeddBentPile = pile_embed;

                            if (pile_shape === "Rectangular") {
                                widthBentPile = pilesecWidth[index_pilesec] || 0;
                            }
                            if (pile_shape === "Circular") {
                                diameter_pile = pilesecWidth[index_pilesec] || 0;
                            }

                            const rowLongBentPile = pileEndBent[i][0];
                            const rowTransBentPile = pileEndBent[i][1];
                            const spacingLongBentPile = pileEndBent[i][2];
                            const spacingTransBentPile = pileEndBent[i][3];
                            const lengthBentPile = pileEndBent[i][4];

                            for (let j = 0; j < rowLongBentPile; j++) {
                                for (let k = 0; k < rowTransBentPile; k++) {
                                    const hoff =
                                        (rowTransBentPile - 1) * spacingTransBentPile / 2 -
                                        k * spacingTransBentPile;
                                    const x_BasePile = x_hc_off(staEndBent[i],xPC1,angPC1,staPC,hcR,hcLOC,deg,hoff);
                                    const y_BasePile = y_hc_off(staEndBent[i],yPC1,angPC1,staPC,hcR,hcLOC,deg,hoff);
                                    const sta_off = pileOffset - widthBentPile / 2 -(rowLongBentPile - 1) * spacingLongBentPile / 2 + j * spacingLongBentPile;
                                    const z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] + embeddBentPile;

                                    const pathBentPile = pathSubVertComponent(lengthBentPile,1.0,x_BasePile,y_BasePile,z_Base,ang_Base,sta_off,z_off);

                                    const shapeBentPile = shapeRectangularRot(widthBentPile,widthBentPile,0);

                                    const pileBenti =
                                        pile_shape === "Rectangular"
                                            ? BABYLON.MeshBuilder.ExtrudeShape(
                                                `pileBenti${j}`,
                                                {
                                                    shape: shapeBentPile,
                                                    path: pathBentPile,
                                                    sideOrientation:
                                                        BABYLON.Mesh.DOUBLESIDE,
                                                    cap: BABYLON.Mesh.CAP_ALL,
                                                },
                                                scene1
                                            )
                                            : BABYLON.MeshBuilder.CreateTube(
                                                `pileBenti${j}`,
                                                {
                                                    path: pathBentPile,
                                                    radius: diameter_pile / 2,
                                                    sideOrientation:
                                                        BABYLON.Mesh.DOUBLESIDE,
                                                    cap: BABYLON.Mesh.CAP_ALL,
                                                },
                                                scene1
                                            );

                                    pileBenti.material = concreteMaterial;
                                    shadowGenerator.getShadowMap()?.renderList?.push(pileBenti);
                                    pileBenti.setPivotPoint(
                                        new BABYLON.Vector3(x_Base, z_Base, y_Base)
                                    );
                                    pileBenti.rotation.y = -1 * skewEndBent[i];
                                   }
                                 }
                                }
                            
                              }

                            }
                        }

                    }

                }
                            

        // Retaining Walls
        let appLength = [
            staBrBegin - thickBackwall[0] - staPjBegin, // Approach Ramp #1
            staPjEnd - thickBackwall[1] - staBrEnd // Approach Ramp #2
        ];

        let appRoadName = [
            mainBridgeApproach1[ii], // Approach Ramp #1
            mainBridgeApproach2[ii] // Approach Ramp #2
        ];


        if (wallON === "YES") {
            showWallScene();
        }

        function showWallScene(){
            removeWall();  

        let app_type = mainAppType[ii];
        let app_sec = mainAppSec[ii];
        let dis_mse = mainDistMSE[ii];
        let wall_thickness = 0.5;
        let wall_cap_width = 0;    
        let wall_cap_depth = 0; 
        let wall_embed = 0;
        let wall_leveling_width = 0;
        let wall_leveling_thick = 0;
        let wall_leveling_cover = 0;

        if (app_type !== "NONE") {
            if (app_type === "MSE") {
                const index_mse = Number(indexMSESec(app_sec));
                wall_thickness = msesecWallThick[index_mse];
                wall_cap_width = msesecCapWidth[index_mse];    
                wall_cap_depth = msesecCapDepth[index_mse]; 
                wall_embed = msesecWallEmbed[index_mse];  
                wall_leveling_width = msesecLevingPadWidth[index_mse];  
                wall_leveling_thick = msesecLevingPadThick[index_mse]; 
                wall_leveling_cover = Number(msesecLevingPadCover[index_mse]);       
            } else {
                // Handle other app types
            }
        }

        // Retaining Walls at the end bents    
        if (mainType[ii] === "BRIDGE"){ 
            for (var i = 0; i < staEndBent.length; i++) {                
            var x_Base = xyzEndBent[i].x;
            var y_Base = xyzEndBent[i].z; 
            var z_Base = xyzEndBent[i].y; 
            var ang_Base = angEndBent[i] + 90 * deg; 
            var sta_i = staEndBent[i];

            // MSE Wall Coping 
            var distWall = dis_mse; // Distance from FFBW to Front face MSE wall
            var thickWall = wall_thickness; // Thickness of MSE wall
            var depthWallCoping = wall_cap_depth; // Height of MSE wall coping
            var widthWallCoping = wall_cap_width; // Width of MSE wall coping out of MSE wall panel 
            var embeddWallCoping = wall_embed; // Embeddment of MSE wall into the wall coping
            var widthWallLeveling = wall_leveling_width; // width of MSE wall leveling pad
            var thickWallLeveling = wall_leveling_thick; // thickness of MSE Wall leveling pad
            var soilCoverWall= wall_leveling_cover;  // Soil cover for the MSE wall leveling pad
            //Coping on the front1
            if (i !== 1){ var sta_off = distWall-thickWall;} else { var sta_off = -distWall;}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] + depthWallCoping - embeddWallCoping;  
            var pathWallCopingFront1 = pathSubComponent(thickWall,0.5/12,x_Base,y_Base,z_Base,ang_Base,sta_off,0,z_off);
            var shapeCopingWallFront1 = shapeRectangularProj(lengthBentCap[i],depthWallCoping - embeddWallCoping,-1*skewEndBent[i],0);

            if (shapeCopingWallFront1 && pathWallCopingFront1) {
                try {
                    const copingWallFront1 = BABYLON.MeshBuilder.ExtrudeShape(
                        "copingwallfront1" + i + ii,
                        {
                            shape: shapeCopingWallFront1,
                            path: pathWallCopingFront1,
                            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                            cap: BABYLON.Mesh.CAP_ALL
                        },
                        scene1
                    );
                    copingWallFront1.material = concreteMaterial;
                    shadowGenerator.getShadowMap()?.renderList?.push(copingWallFront1);
                } catch (error) {
                    console.error("Error during copingWallFront1 creation:", error);
                }
            } 
            
            
            //Coping on the front2
            if (i !== 1){ var sta_off = distWall;} else { var sta_off = -distWall -(widthWallCoping - thickWall);}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] + depthWallCoping - embeddWallCoping;  
            var pathWallCopingFront2 = pathSubComponent((widthWallCoping - thickWall),0.5/12,x_Base,y_Base,z_Base,ang_Base,sta_off,0,z_off);
            var shapeCopingWallFront2 = shapeRectangularProj(lengthBentCap[i],depthWallCoping,-1*skewEndBent[i],0);
            if (shapeCopingWallFront2 && pathWallCopingFront2) {
                try {
                    const copingWallFront2 = BABYLON.MeshBuilder.ExtrudeShape(
                        "copingwallfront2" + i + ii,
                        {
                            shape: shapeCopingWallFront2,
                            path: pathWallCopingFront2,
                            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                            cap: BABYLON.Mesh.CAP_ALL
                        },
                        scene1
                    );
                    copingWallFront2.material = concreteMaterial;
                    shadowGenerator.getShadowMap()?.renderList?.push(copingWallFront2);
                } catch (error) {
                    console.error("Error during copingWallFront1 creation:", error);
                }
            } 
                        
            //Coping on the left side
            var hoffL1 = lengthBentCap[i] /2 - (widthWallCoping - thickWall) - thickWall / 2;
            var hoffL2 = lengthBentCap[i] /2 - (widthWallCoping - thickWall) /2;   
            var hoffR1 = -lengthBentCap[i] /2 + (widthWallCoping - thickWall) + thickWall / 2;
            var hoffR2 = -lengthBentCap[i] /2 + (widthWallCoping - thickWall) / 2;              
            var x_BaseCopingL1 = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,hoffL1);
            var y_BaseCopingL1 = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,hoffL1);
            var x_BaseCopingL2 = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,hoffL2);
            var y_BaseCopingL2 = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,hoffL2);
            var x_BaseCopingR1 = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,hoffR1);
            var y_BaseCopingR1 = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,hoffR1);
            var x_BaseCopingR2 = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,hoffR2);
            var y_BaseCopingR2 = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,hoffR2);
            // Left Side Coping 1
            var sta_offL1 = hoffL1*Math.tan(-1*skewEndBent[i]);  
            var sta_offL2 = hoffL2*Math.tan(-1*skewEndBent[i]);
            var sta_offR1 = hoffR1*Math.tan(-1*skewEndBent[i]);  
            var sta_offR2 = hoffR2*Math.tan(-1*skewEndBent[i]);                      
            if (i !== 1){ var sta_off = widthBentCap[i] - thickBackwall[i] + sta_offL1;} else { var sta_off = -distWall + sta_offL1;}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] + depthWallCoping - embeddWallCoping;  
            var pathWallCopingSideL1 = pathSubComponent((distWall - (widthBentCap[i]-thickBackwall[i])),0.25,x_BaseCopingL1,y_BaseCopingL1,z_Base,ang_Base,sta_off,0,z_off);
            var shapeCopingWallSideL1 = shapeRectangular(thickWall,depthWallCoping - embeddWallCoping);
           
            if (shapeCopingWallSideL1 && pathWallCopingSideL1) {
                try {
                    const copingWallSideL1 = BABYLON.MeshBuilder.ExtrudeShape(
                        "copingwallsideL1" + i + ii,
                        {
                            shape: shapeCopingWallSideL1,
                            path: pathWallCopingSideL1,
                            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                            cap: BABYLON.Mesh.CAP_ALL
                        },
                        scene1
                    );
                    copingWallSideL1.material = concreteMaterial;
                    shadowGenerator.getShadowMap()?.renderList?.push(copingWallSideL1);
                } catch (error) {
                    console.error("Error during copingWallFront1 creation:", error);
                }
            }            
            

            // Left Side Coping 2
            if (i !== 1){ var sta_off = widthBentCap[i] - thickBackwall[i] + sta_offL2;} else { var sta_off = -distWall + sta_offL2;}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] + depthWallCoping - embeddWallCoping;  
            var pathWallCopingSideL2 = pathSubComponent((distWall - (widthBentCap[i]-thickBackwall[i])),0.25,x_BaseCopingL2,y_BaseCopingL2,z_Base,ang_Base,sta_off,0,z_off);
            var shapeCopingWallSideL2 = shapeRectangular(widthWallCoping - thickWall,depthWallCoping);
 
            if (shapeCopingWallSideL2 && pathWallCopingSideL2) {
                try {
                    const copingWallSideL2 = BABYLON.MeshBuilder.ExtrudeShape(
                        "copingwallsideL2" + i + ii,
                        {
                            shape: shapeCopingWallSideL2,
                            path: pathWallCopingSideL2,
                            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                            cap: BABYLON.Mesh.CAP_ALL
                        },
                        scene1
                    );
                    copingWallSideL2.material = concreteMaterial;
                    shadowGenerator.getShadowMap()?.renderList?.push(copingWallSideL2);
                } catch (error) {
                    console.error("Error during copingWallFront1 creation:", error);
                }
            } 

            // Right Side Coping 1
            if (i !== 1){ var sta_off = widthBentCap[i] - thickBackwall[i] + sta_offR1;} else { var sta_off = -distWall + sta_offR1;}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] + depthWallCoping - embeddWallCoping;  
            var pathWallCopingSideR1 = pathSubComponent((distWall - (widthBentCap[i]-thickBackwall[i])),0.25,x_BaseCopingR1,y_BaseCopingR1,z_Base,ang_Base,sta_off,0,z_off);
            var shapeCopingWallSideR1 = shapeRectangular(thickWall,depthWallCoping - embeddWallCoping);
 

            if (shapeCopingWallSideR1 && pathWallCopingSideR1) {
                try {
                    const copingWallSideR1 = BABYLON.MeshBuilder.ExtrudeShape(
                        "copingwallsideR1" + i + ii,
                        {
                            shape: shapeCopingWallSideR1,
                            path: pathWallCopingSideR1,
                            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                            cap: BABYLON.Mesh.CAP_ALL
                        },
                        scene1
                    );
                    copingWallSideR1.material = concreteMaterial;
                    shadowGenerator.getShadowMap()?.renderList?.push(copingWallSideR1);
                } catch (error) {
                    console.error("Error during copingWallFront1 creation:", error);
                }
            } 

            // Right Side Coping 2
            if (i !== 1){ var sta_off = widthBentCap[i] - thickBackwall[i] + sta_offR2;} else { var sta_off = -distWall + sta_offR2;}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] + depthWallCoping - embeddWallCoping;  
            var pathWallCopingSideR2 = pathSubComponent((distWall - (widthBentCap[i]-thickBackwall[i])),0.25,x_BaseCopingR2,y_BaseCopingR2,z_Base,ang_Base,sta_off,0,z_off);
            var shapeCopingWallSideR2 = shapeRectangular(widthWallCoping - thickWall,depthWallCoping);
             
            if (shapeCopingWallSideR2 && pathWallCopingSideR2) {
                try {
                    const copingWallSideR2 = BABYLON.MeshBuilder.ExtrudeShape(
                        "copingwallsideR2" + i + ii,
                        {
                            shape: shapeCopingWallSideR2,
                            path: pathWallCopingSideR2,
                            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                            cap: BABYLON.Mesh.CAP_ALL
                        },
                        scene1
                    );
                    copingWallSideR2.material = concreteMaterial;
                    shadowGenerator.getShadowMap()?.renderList?.push(copingWallSideR2);
                } catch (error) {
                    console.error("Error during copingWallFront1 creation:", error);
                }
            } 

            // MSE Walls
            var wallHeightFront = z_Base -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] + soilCoverWall - groundEev; 

            //Walls on the front under the bridge
            if (i !== 1){ var sta_off = distWall-thickWall/2;} else { var sta_off = -distWall + thickWall/2 ;}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i];  
            var z_base = EL_vc_off(sta_i,vcStaPvc,vcEL_Pvc,vcSlpLeft,vcSlpRight,vcLen,z_off);
            var angBase = ang_hc(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg) + 90 * deg;
            var activeWallShape = shapeRectangular(thickWall,wallHeightFront);

            var frontWallPath = function(staOff:number,hc_off:number) {
            var path = [];
            for (var i = 0; i < activeWallShape.length; i++) {
                var x = activeWallShape[i].x;
                var y = activeWallShape[i].y;
                var z = activeWallShape[i].z;
                var xp = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,hc_off) - x_DimOff(staOff + x,angBase);
                var zp  = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,hc_off) + y_DimOff(staOff + x,angBase);
                var yp = y + z_base;       
                path.push(new BABYLON.Vector3(xp,yp,zp))
                }
            return path;
            };
            
            var ribbonPath = [];
            var hcoffL = (lengthBentCap[i] - 2 * (widthWallCoping-thickWall))/2;
            var hcoffR = -(lengthBentCap[i] - 2 * (widthWallCoping-thickWall))/2;   
            if (i !== 1){ var sta_offL = hcoffL*Math.tan(-1*skewEndBent[i]);} else { var sta_offL = hcoffL*Math.tan(-1*skewEndBent[i]);}
            if (i !== 1){ var sta_offR = hcoffR*Math.tan(-1*skewEndBent[i]);} else { var sta_offR = hcoffR*Math.tan(-1*skewEndBent[i]);}
            var frontWallPathL = frontWallPath(sta_off+sta_offL,hcoffL);
            var frontWallPathR = frontWallPath(sta_off+sta_offR,hcoffR); 
            ribbonPath.push(frontWallPathL); 
            ribbonPath.push(frontWallPathR); 

            var ribbon = BABYLON.MeshBuilder.CreateRibbon("msewall"+i+ii, {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
            ribbon.material = MSEWallMaterial;        
            shadowGenerator.getShadowMap()?.renderList?.push(ribbon);

            // Wall Leveling Pad
            if (i !== 1){ var sta_off = distWall-thickWall /2 - widthWallLeveling /2;} else { var sta_off = -distWall + thickWall /2 - widthWallLeveling /2;}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] - wallHeightFront;  
            var pathWallFrontLeveling = pathSubComponent(widthWallLeveling,0.5/12,x_Base,y_Base,z_Base,ang_Base,sta_off,0,z_off);
            var shapeWallFrontLeveling = shapeRectangularProj(lengthBentCap[i] - 2 * (widthWallCoping-thickWall),thickWallLeveling,-1*skewEndBent[i],0);
           
           
            if (shapeWallFrontLeveling && pathWallFrontLeveling) {
                try {
                    const wallFrontLeveling = BABYLON.MeshBuilder.ExtrudeShape(
                        "wallFrontLeveling" + i + ii,
                        {
                            shape: shapeWallFrontLeveling,
                            path: pathWallFrontLeveling,
                            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                            cap: BABYLON.Mesh.CAP_ALL
                        },
                        scene1
                    );
                    wallFrontLeveling.material = concreteMaterial;
                    shadowGenerator.getShadowMap()?.renderList?.push(wallFrontLeveling);
                } catch (error) {
                    console.error("Error during wallFrontLeveling creation:", error);
                }
            } else {
                console.error("Invalid shape or path for wallFrontLeveling.", {
                    shape: shapeWallFrontLeveling,
                    path: pathWallFrontLeveling
                });
            }            
            
            //Walls on the Sides under the bridge
            var hoffL = lengthBentCap[i] /2 - (widthWallCoping - thickWall) - thickWall / 2;
            var hoffR = -lengthBentCap[i] /2 + (widthWallCoping - thickWall) + thickWall / 2;
            var x_BaseWallSideL = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,hoffL);
            var y_BaseWallSideL = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,hoffL);
            var x_BaseWallSideR = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,hoffR);
            var y_BaseWallSideR = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,hoffR);
            var wallHeightFront = z_Base -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] + soilCoverWall -groundEev;
            var wallLength = distWall + thickBackwall[i] - thickWall;
            var sta_offL = hoffL*Math.tan(-1*skewEndBent[i]);  
            var sta_offR = hoffR*Math.tan(-1*skewEndBent[i]);

            if (i !== 1){ var sta_off = -thickBackwall[i];} else { var sta_off = -distWall + thickWall;}
            if (i !== 1){ var sta_offEnd = -thickBackwall[i] + wallLength;} else { var sta_offEnd = -distWall + thickWall + wallLength;}

            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i];  
            var sideWallPath = function(hc_off:number,sta_off:number) {
            var path = [];
            for (var i = 0; i < activeWallShape.length; i++) {
                var x = activeWallShape[i].x;
                var y = activeWallShape[i].y;
                var z = activeWallShape[i].z;
                var xp = x_hc_off(sta_i,xPC1,angPC1,staPC,hcR,hcLOC,deg,hc_off - x) - x_DimOff(sta_off,angBase);
                var zp  = y_hc_off(sta_i,yPC1,angPC1,staPC,hcR,hcLOC,deg,hc_off - x) + y_DimOff(sta_off,angBase);
                var yp = y + z_base;       
                path.push(new BABYLON.Vector3(xp,yp,zp))
                }
                return path;
                };
                
                var ribbonPathL = [];
                var ribbonPathR = [];
                var sideWallPathL1 = sideWallPath(hcoffL,sta_off + sta_offL);
                var sideWallPathL2 = sideWallPath(hcoffL,sta_offEnd + sta_offL);      
                ribbonPathL.push(sideWallPathL1); 
                ribbonPathL.push(sideWallPathL2);        
                var sideWallPathR1 = sideWallPath(hcoffR,sta_off + sta_offR);
                var sideWallPathR2 = sideWallPath(hcoffR,sta_offEnd + sta_offR);      
                ribbonPathR.push(sideWallPathR1); 
                ribbonPathR.push(sideWallPathR2);   

            var ribbon = BABYLON.MeshBuilder.CreateRibbon("mseleft"+i+ii, {pathArray: ribbonPathL, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
            ribbon.material = MSEWallMaterial;        
            shadowGenerator.getShadowMap()?.renderList?.push(ribbon);
            var ribbon = BABYLON.MeshBuilder.CreateRibbon("mseright"+i+ii, {pathArray: ribbonPathR, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
            ribbon.material = MSEWallMaterial;        
            shadowGenerator.getShadowMap()?.renderList?.push(ribbon);

            //Wall Leveling Pad on the Left Side under the bridge 
            if (i !== 1){ var sta_off = -thickBackwall[i] +sta_offL;} else { var sta_off = -distWall + thickWall+sta_offL;}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] - wallHeightFront;  
            var pathLevelingSideL = pathSubComponent(distWall + thickBackwall[i] - thickWall,3/12,x_BaseWallSideL,y_BaseWallSideL,z_Base,ang_Base,sta_off,0,z_off);
            var shapeLevelingSideL = shapeRectangular(widthWallLeveling,thickWallLeveling);
            var levelingSideL = BABYLON.MeshBuilder.ExtrudeShape("wallleftleveling"+i+ii, {shape: shapeLevelingSideL, path: pathLevelingSideL, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
            levelingSideL.material = concreteMaterial;
            shadowGenerator.getShadowMap()?.renderList?.push(levelingSideL);

            //Wall Leveling Pad on the Right Side under the bridge       
            if (i !== 1){ var sta_off = -thickBackwall[i]+sta_offR;} else { var sta_off = -distWall + thickWall+sta_offR;}
            var z_off = -thickApproachSlab[i] - thickAppHaunch[i] - heightBackwall[i] - depthBentCap[i] - wallHeightFront;    
            var pathLevelingSideR = pathSubComponent(distWall + thickBackwall[i] - thickWall,3/12,x_BaseWallSideR,y_BaseWallSideR,z_Base,ang_Base,sta_off,0,z_off);
            var shapeLevelingSideR = shapeRectangular(widthWallLeveling,thickWallLeveling);
            var levelingSideR = BABYLON.MeshBuilder.ExtrudeShape("wallrightleveling"+i+ii, {shape: shapeLevelingSideR, path: pathLevelingSideR, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
            levelingSideR.material = concreteMaterial;
            shadowGenerator.getShadowMap()?.renderList?.push(levelingSideR);
        }
    }


        function getWallTopPoint(sta: number,hc_off: number, z_off: number): BABYLON.Vector3 {
            const z_base = EL_vc_off(sta, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, z_off );
            // The horizontal angle
            const angBase = ang_hc(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg) + 90 * deg;
            const topElev = z_base;
            // XZ in plan
            const xPos = x_hc_off(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off);
            const zPos = y_hc_off(sta, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off );
            return new BABYLON.Vector3(xPos, topElev, zPos);
        }
        
        function getWallBottomPoint(sta: number,hc_off: number): BABYLON.Vector3 {
            const xPos = x_hc_off(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off);
            const zPos = y_hc_off(sta, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off );
            const botElev = groundEev - soilCoverWall;
            return new BABYLON.Vector3(xPos, botElev, zPos);
        }

        function buildSingleFaceRibbon(name: string,stationBegin: number,stationEnd: number,stepLength: number,hc_off: number, z_off: number): BABYLON.Mesh {
            const wallLen = stationEnd - stationBegin;
            const nDiv = Math.floor(wallLen / stepLength);
        
            const topLine: BABYLON.Vector3[] = [];
            const bottomLine: BABYLON.Vector3[] = [];
        
            for (let i = 0; i <= nDiv; i++) {
                const sta_i = (i < nDiv)? stationBegin + i * stepLength : stationEnd; 
        
                // Build top/bottom points
                const ptTop = getWallTopPoint(sta_i, hc_off, z_off);
                const ptBot = getWallBottomPoint(sta_i, hc_off);
        
                topLine.push(ptTop);
                bottomLine.push(ptBot);
            }
        
            const pathArray = [ topLine, bottomLine ];
            // Create a single ribbon for these lines
            const ribbon = BABYLON.MeshBuilder.CreateRibbon(name, {pathArray: pathArray,sideOrientation: BABYLON.Mesh.DOUBLESIDE,closePath: false,}, scene1);
            return ribbon;
        }
        
        function buildWallWithThickness(nameBase: string,stationBegin: number,stationEnd: number,stepLength: number,
                hc_off: number,z_off: number,wallThickness: number,outwardSign: number): { front: BABYLON.Mesh, back: BABYLON.Mesh } {
            // The "front face" is at offset = hc_off
            // The "back face" is offset further by 'wallThickness' in the direction normal to alignment.
            // The sign depends on which side is outward vs. inward.
            const frontName = nameBase + "_front";
            const backName  = nameBase + "_back";
        
            const frontMesh = buildSingleFaceRibbon(frontName,stationBegin,stationEnd,stepLength,hc_off,z_off);
            const backMesh = buildSingleFaceRibbon(backName,stationBegin,stationEnd,stepLength,hc_off + outwardSign * wallThickness,z_off);

            return { front: frontMesh, back: backMesh };
        }

        // Approach Road Retaining Walls        
        for (var j = 0; j < appLength.length; j++) {
            if (j !== 1){ 
                var staWallBegin = staPjBegin;
                var staWallEnd = staBrBegin - thickBackwall[0];
                } else { 
                var staWallBegin = staBrEnd + thickBackwall[1];
                var staWallEnd = staPjEnd;
                }
            var roadname = appRoadName[j];
            var wall_left;
            var wall_right;

            for(var i=0;i<Number(NWALL);i++){
                if(wallRoad[i] == roadname && wallMainSide[i] == "LT"){wall_left = wallNum[i];}
                if(wallRoad[i] == roadname && wallMainSide[i] == "RT"){wall_right = wallNum[i];}
            }

            var index_wall_left = Number(indexWall(String(wall_left)));
            var wall_type_left =  wallMainType[index_wall_left];
            var wall_seg_left = wallSeg[index_wall_left];

            var index_wall_right = Number(indexWall(String(wall_right)));
            var wall_type_right =  wallMainType[index_wall_right];
            var wall_seg_right = wallSeg[index_wall_right];

            if(wall_type_left == "MSE" && wall_type_right == "MSE"){
                if(wall_seg_left == 1 && wall_seg_right == 1){
                var wall_sec_left =  String(wallGeometry[index_wall_left][1]);
                var wall_sec_right =  String(wallGeometry[index_wall_right][1]);
                var index_sec_left = Number(indexMSESec(wall_sec_left));
                var index_sec_right = Number(indexMSESec(wall_sec_right));
                var copingWidthLeft = msesecCapWidth[index_sec_left];
                var copingWidthRight = msesecCapWidth[index_sec_right];            
                var copingDepthLeft = msesecCapDepth[index_sec_left];
                var copingDepthRight = msesecCapDepth[index_sec_right];    
                var wallEmbedLeft = msesecWallEmbed[index_sec_left];
                var wallEmbedRight = msesecWallEmbed[index_sec_right];  
                var wallThickLeft = msesecWallThick[index_sec_left];
                var wallThickRight = msesecWallThick[index_sec_right];               
                var levelingWidthLeft = msesecLevingPadWidth[index_sec_left];
                var levelingWidthRight = msesecLevingPadWidth[index_sec_right];   
                var levelingThickLeft = msesecLevingPadThick[index_sec_left];
                var levelingThickRight = msesecLevingPadThick[index_sec_right];   
                var levelingCoverLeft = msesecLevingPadCover[index_sec_left];
                var levelingCoverRight = msesecLevingPadCover[index_sec_right];  

                var bridge_approach1 = mainBridgeApproach1[ii];
                var index_app1 = Number(indexRoad(bridge_approach1));
                var appSkewBeg1 = roadMainSkew1[index_app1]; 
                var appSkewEnd1 = roadMainSkew2[index_app1]; 
                var appWidth1 = roadWidth[index_app1]; 
                var bridge_approach2 = mainBridgeApproach2[ii];
                var index_app2 = Number(indexRoad(bridge_approach2));
                var appSkewBeg2 = roadMainSkew1[index_app2]; 
                var appSkewEnd2 = roadMainSkew2[index_app2];             
                var appWidth2 = roadWidth[index_app2]; 

                //Approach Ramp Wall Coping on the Left Side
                if (j !== 1){ 
                    var hoff = appWidth1 /2 - copingWidthLeft /2;
                    var sta_offL_B = -hoff * Math.tan(appSkewBeg1);
                    var sta_offL_E = -hoff * Math.tan(appSkewEnd1);
                } else { 
                    var hoff = appWidth2 /2 - copingWidthLeft /2;                
                    var sta_offL_B = -hoff * Math.tan(appSkewBeg2);
                    var sta_offL_E = -hoff * Math.tan(appSkewEnd2);
                }            
                var pathCopingAppL = pathComponent(staWallBegin + sta_offL_B,staWallEnd + sta_offL_E,5,hoff,0); 
                var shapeCopingAppL = shapeRectangular(copingWidthLeft,copingDepthLeft);
                var copingAppL = BABYLON.MeshBuilder.ExtrudeShape("approachcopingleft"+j+ii, {shape: shapeCopingAppL, path: pathCopingAppL, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
                copingAppL.material = concreteMaterial;
                shadowGenerator.getShadowMap()?.renderList?.push(copingAppL);

                //Approach Ramp Wall Coping on the Right Side 
                if (j !== 1){ 
                    var hoff = -appWidth1 /2 + copingWidthRight /2;                
                    var sta_offR_B = -hoff * Math.tan(appSkewBeg1);
                    var sta_offR_E = -hoff * Math.tan(appSkewEnd1);
                } else { 
                    var hoff = -appWidth2 /2 + copingWidthRight /2;                
                    var sta_offR_B = -hoff * Math.tan(appSkewBeg2);
                    var sta_offR_E = -hoff * Math.tan(appSkewEnd2);
                }                      
                var pathCopingAppR = pathComponent(staWallBegin + sta_offR_B,staWallEnd + sta_offR_E,5,hoff,0); 
                var shapeCopingAppR = shapeRectangular(copingWidthRight,copingDepthRight);
                var copingAppR = BABYLON.MeshBuilder.ExtrudeShape("approachcopingright"+j+ii, {shape: shapeCopingAppR, path: pathCopingAppR, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
                copingAppR.material = concreteMaterial;
                shadowGenerator.getShadowMap()?.renderList?.push(copingAppR);

                //Approach Ramp Walls
                let staWallBeginL;
                let staWallEndL;
                let staWallBeginR;
                let staWallEndR;
                let wall_lengthL;
                let wall_lengthR;
                const stepLength = 5;   
                var zoffL = -copingDepthLeft + wallEmbedLeft;
                var zoffR = -copingDepthRight + wallEmbedRight;

                if (j !== 1){ 
                    var hoffL = appWidth1 /2 - copingWidthLeft /2;                
                    staWallBeginL = staWallBegin - hoffL * Math.tan(appSkewBeg1);
                    staWallEndL = staWallEnd - hoffL * Math.tan(appSkewEnd1);
                    wall_lengthL = staWallEndL - staWallBeginL;
                } else { 
                    var hoffL = appWidth2 /2 - copingWidthLeft /2;                
                    staWallBeginL = staWallBegin - hoffL * Math.tan(appSkewBeg2);
                    staWallEndL = staWallEnd - hoffL * Math.tan(appSkewEnd2);
                    wall_lengthL = staWallEndL - staWallBeginL;                
                } 

                if (j !== 1){ 
                    var hoffR = -appWidth1 /2 + copingWidthRight /2;                   
                    staWallBeginR = staWallBegin - hoffR * Math.tan(appSkewBeg1);
                    staWallEndR = staWallEnd - hoffR * Math.tan(appSkewEnd1);
                    wall_lengthR = staWallEndR - staWallBeginR;
                } else { 
                    var hoffR = -appWidth2 /2 + copingWidthRight /2;                   
                    staWallBeginR = staWallBegin - hoffR * Math.tan(appSkewBeg2);
                    staWallEndR = staWallEnd - hoffR * Math.tan(appSkewEnd2);
                    wall_lengthR = staWallEndR - staWallBeginR;                
                } 

            // Left wall
            if (wall_type_left === "MSE" && wall_seg_left === 1) {
                const outwardSignLeft = 1; // or -1, depending on your geometry
                const leftWall = buildWallWithThickness("approachLeftWall_" + j + "_" + ii,staWallBeginL,staWallEndL,stepLength,hoffL,zoffL,
                    wallThickLeft,outwardSignLeft);
            
                // The result is an object with { front, back }
                leftWall.front.material = MSEWallMaterial;
                leftWall.back.material  = MSEWallMaterial;
            
                // Add to shadow generator
                shadowGenerator.getShadowMap()?.renderList?.push(leftWall.front);
                shadowGenerator.getShadowMap()?.renderList?.push(leftWall.back);
            }
            
            // Similarly for the right side:
            if (wall_type_right === "MSE" && wall_seg_right === 1) {            
                const outwardSignRight = -1; // or +1, etc.
                const rightWall = buildWallWithThickness("approachRightWall_" + j + "_" + ii,staWallBeginR,staWallEndR,stepLength,hoffR,zoffR,
                    wallThickRight,outwardSignRight);
            
                rightWall.front.material = MSEWallMaterial;
                rightWall.back.material  = MSEWallMaterial;
            
                // Shadows
                shadowGenerator.getShadowMap()?.renderList?.push(rightWall.front);
                shadowGenerator.getShadowMap()?.renderList?.push(rightWall.back);
            }
            
                //Approach Ramp Wall Leveling Pads
                var padPath = function(sta:number,width:number,thick:number,hc_off:number,vc_off:number) {
                var z_base = EL_vc_off(sta,vcStaPvc,vcEL_Pvc,vcSlpLeft,vcSlpRight,vcLen,vc_off);
                var angBase = ang_hc(sta,xPC1,angPC1,staPC,hcR,hcLOC,deg) + 90 * deg;
                var path = [];
                var wallHeight = z_base + soilCoverWall - groundEev;
                var activePadShape = shapeRectangular(width,thick);
                for (var i = 0; i < activePadShape.length; i++) {
                    var x = activePadShape[i].x;
                    var y = activePadShape[i].y;
                    var z = activePadShape[i].z;
                    var xp = x_hc_off(sta,xPC1,angPC1,staPC,hcR,hcLOC,deg,hc_off - x);
                    var yp = y + z_base - wallHeight;
                    var zp  = y_hc_off(sta,yPC1,angPC1,staPC,hcR,hcLOC,deg,hc_off - x);
                    path.push(new BABYLON.Vector3(xp,yp,zp))
                    }
                    return path;
                    };

                // Left Pad
                const n_staPointL = Math.floor(wall_lengthL/stepLength);  
                var ribbonPathL = []; 
                for (var i = 0; i < n_staPointL; i++) {
                    var sta_i = staWallBeginL + i * stepLength;    
                    var padPathLj = padPath(sta_i,levelingWidthLeft,levelingThickLeft,hoffL,zoffL);     
                    ribbonPathL.push(padPathLj); 
                    }
                if (n_staPointL * stepLength  < wall_lengthL){
                    var padPathLj = padPath(staWallEndL,levelingWidthLeft,levelingThickLeft,hoffL,zoffL);
                    ribbonPathL.push(padPathLj); 
                    } 

                var ribbon = BABYLON.MeshBuilder.CreateRibbon("approachleftleveling"+j+ii, {pathArray: ribbonPathL, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
                ribbon.material = concreteMaterial;        
                shadowGenerator.getShadowMap()?.renderList?.push(ribbon);

                // Right Pad
                var ribbonPathR = []; 
                const n_staPointR = Math.floor(wall_lengthR/stepLength);   
                for (var i = 0; i < n_staPointR; i++) {
                    var sta_i = staWallBeginR + i * stepLength;    
                    var padPathRj = padPath(sta_i,levelingWidthRight,levelingThickRight,hoffR,zoffR);        
                    ribbonPathR.push(padPathRj); 
                    }
                if (n_staPointR * stepLength  < wall_lengthR){
                    var padPathRj = padPath(staWallEndR,levelingWidthRight,levelingThickRight,hoffR,zoffR); 
                    ribbonPathR.push(padPathRj); 
                    } 

                var ribbon = BABYLON.MeshBuilder.CreateRibbon("approachrightleveling"+j+ii, {pathArray: ribbonPathR, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
                ribbon.material = concreteMaterial;        
                shadowGenerator.getShadowMap()?.renderList?.push(ribbon);

                } else{
                // for conditions not having constant MSE section 
                }
            } else{
                // for condition not having both sides MSE walls
            }
        }

    }


        function removeWall() {
            for (let i = 0; i < staEndBent.length; i++) {
            const meshIDs = [
                `copingwallfront1${i}`,
                `copingwallfront2${i}`,
                `copingwallsideL1${i}`,
                `copingwallsideL2${i}`,
                `copingwallsideR1${i}`,
                `copingwallsideR2${i}`,
                `msewall${i}`,
                `wallfrontleveling${i}`,
                `mseleft${i}`,
                `mseright${i}`,
                `mseleftleveling${i}`,
                `mserightleveling${i}`,
            ];
        
            meshIDs.forEach((id) => {
                const mesh = scene1.getMeshByID(id);
                if (mesh) {
                mesh.dispose();
                }
            });
            }
        
            for (let j = 0; j < appLength.length; j++) {
            const meshIDs = [
                `approachcopingleft${j}`,
                `approachcopingright${j}`,
                `approachleft${j}`,
                `approachright${j}`,
                `approachleftleveling${j}`,
                `approachrightleveling${j}`,
            ];
        
            meshIDs.forEach((id) => {
                const mesh = scene1.getMeshByID(id);
                if (mesh) {
                mesh.dispose();
                }
            });
            }
        }
        
    // Generate model node coordinates information
    // Functions for structural centerline paths (for model coordinates generation)

        type ClineGeometry = number[][];

        const offsetCline_varied: number[][][] = [];
        const distCline_varied: number[][][] = [];
        const cline_sta_begin: number[][][] = [];
        const cline_sta_end: number[][][] = [];
        

        for (let i = 0; i < staUnit.length; i++) {
            const offsetCline_temp: number[][] = [];
            const distCline_temp: number[][] = [];
            const cline_sta_begin_temp: number[][] = [];
            const cline_sta_end_temp: number[][] = [];
        
            for (let jj = 0; jj < clineChord.length; jj++) {
            const cline_chord = clineChord[jj];
            const cline_seg = clineSec[jj];
        
            offsetCline_temp[jj] = [];
            distCline_temp[jj] = [];
            cline_sta_begin_temp[jj] = [];
            cline_sta_end_temp[jj] = [];
        
            if (cline_seg === 1) {
                const cline_offset = clineGeometry[jj][2];
                const cline_dist = clineGeometry[jj][3];
                offsetCline_temp[jj].push(cline_offset);
                distCline_temp[jj].push(cline_dist);
                cline_sta_begin_temp[jj].push(Number(staUnit[i][0]));
                cline_sta_end_temp[jj].push(Number(staUnit[i][1]));
            } else {
                for (let j = 0; j < cline_seg; j++) {
                const sta_begin = Number(staUnit[i][0]) + (Number(staUnit[i][1]) - Number(staUnit[i][0])) * clineGeometry[jj][4 * j + 0];
                const sta_end = Number(staUnit[i][0]) + (Number(staUnit[i][1]) - Number(staUnit[i][0])) * clineGeometry[jj][4 * j + 1];
                const cline_offset = clineGeometry[jj][4 * j + 2];
                const cline_dist = clineGeometry[jj][4 * j + 3];
        
                cline_sta_begin_temp[jj].push(sta_begin);
                cline_sta_end_temp[jj].push(sta_end);
                offsetCline_temp[jj].push(cline_offset);
                distCline_temp[jj].push(cline_dist);
                }
            }
            }
        
            offsetCline_varied[i] = offsetCline_temp;
            distCline_varied[i] = distCline_temp;
            cline_sta_begin[i] = cline_sta_begin_temp;
            cline_sta_end[i] = cline_sta_end_temp;
        }
        


        function clineOffVaried(sta: number, unit_id: number, cline_id: number): number {
            let hc_off = 0;
        
            // If there is only one offset
            if ((offsetCline_varied[unit_id][cline_id]).length === 1) {
            hc_off = offsetCline_varied[unit_id][cline_id][0];
            }
        
            // For multiple offsets
            if ((offsetCline_varied[unit_id][cline_id]).length !== 1) {
            for (let j = 0; j < offsetCline_varied[unit_id][cline_id].length; j++) {
                if (
                cline_sta_begin[unit_id][cline_id][j] <= sta &&
                sta <= cline_sta_end[unit_id][cline_id][j]
                ) {
                hc_off = offsetCline_varied[unit_id][cline_id][j];
                } else if (
                cline_sta_end[unit_id][cline_id][j - 1] < sta &&
                sta <= cline_sta_begin[unit_id][cline_id][j]
                ) {
                hc_off =
                    offsetCline_varied[unit_id][cline_id][j - 1] +
                    ((offsetCline_varied[unit_id][cline_id][j] -
                    offsetCline_varied[unit_id][cline_id][j - 1]) /
                    (cline_sta_begin[unit_id][cline_id][j] -
                        cline_sta_end[unit_id][cline_id][j - 1])) *
                    (sta - cline_sta_end[unit_id][cline_id][j - 1]);
                } else if (cline_sta_begin[unit_id][cline_id][0] > sta) {
                hc_off = offsetCline_varied[unit_id][cline_id][0];
                } else if (
                cline_sta_end[unit_id][cline_id][
                    offsetCline_varied[unit_id][cline_id].length - 1
                ] < sta
                ) {
                hc_off =
                    offsetCline_varied[unit_id][cline_id][
                    offsetCline_varied[unit_id][cline_id].length - 1
                    ];
                }
            }
            }
        
            return hc_off;
        }
        

        function clineDistVaried(sta: number, unit_id: number, cline_id: number): number {
            let dist_off = 0;
        
            // If there is only one distance offset
            if (distCline_varied[unit_id][cline_id].length === 1) {
            dist_off = distCline_varied[unit_id][cline_id][0];
            }
        
            // If there are multiple distance offsets
            if (distCline_varied[unit_id][cline_id].length !== 1) {
            for (let j = 0; j < distCline_varied[unit_id][cline_id].length; j++) {
                if (
                cline_sta_begin[unit_id][cline_id][j] <= sta &&
                sta <= cline_sta_end[unit_id][cline_id][j]
                ) {
                dist_off = distCline_varied[unit_id][cline_id][j];
                } else if (
                cline_sta_end[unit_id][cline_id][j - 1] < sta &&
                sta <= cline_sta_begin[unit_id][cline_id][j]
                ) {
                dist_off =
                    distCline_varied[unit_id][cline_id][j - 1] +
                    ((distCline_varied[unit_id][cline_id][j] -
                    distCline_varied[unit_id][cline_id][j - 1]) /
                    (cline_sta_begin[unit_id][cline_id][j] -
                        cline_sta_end[unit_id][cline_id][j - 1])) *
                    (sta - cline_sta_end[unit_id][cline_id][j - 1]);
                } else if (cline_sta_begin[unit_id][cline_id][0] > sta) {
                dist_off = distCline_varied[unit_id][cline_id][0];
                } else if (
                cline_sta_end[unit_id][cline_id][
                    distCline_varied[unit_id][cline_id].length - 1
                ] < sta
                ) {
                dist_off =
                    distCline_varied[unit_id][cline_id][
                    distCline_varied[unit_id][cline_id].length - 1
                    ];
                }
            }
            }
        
            return dist_off;
        }
        
        const clineSkewPath = (
            sta: number,
            cline_shape: { x: number; y: number; z: number }[],
            ang: number,
            hc_off: number,
            vcOff: number
        ): [BABYLON.Vector3[], BABYLON.Vector3] => {
            const z_base = EL_vc_off(sta, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, vcOff);
            const angBase = ang_hc(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg) + 90 * deg;
            const path0: BABYLON.Vector3[] = [];
            let x = 0;
            let y = 0;
            let z = 0;
        
            for (let j = 0; j < cline_shape.length; j++) {
                x = cline_shape[j].x;
                y = cline_shape[j].y;
                z = cline_shape[j].z;
        
                const xp = x_hc_off(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off - x) - x_DimOff((x - hc_off) * Math.tan(ang), angBase);
                const yp = y + z_base + (superElevation(sta, superElev) || 0) * hc_off;
                const zp = y_hc_off(sta, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off - x) + y_DimOff((x - hc_off) * Math.tan(ang), angBase);
        
                path0.push(new BABYLON.Vector3(xp, yp, zp));
            }
        
            const x_p = x_hc_off(sta, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off - x) - x_DimOff((x - hc_off) * Math.tan(ang), angBase);
            const y_p = y + z_base + (superElevation(sta, superElev) || 0) * hc_off;
            const z_p = y_hc_off(sta, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off - x) + y_DimOff((x - hc_off) * Math.tan(ang), angBase);
        
            const point = new BABYLON.Vector3(x_p, y_p, z_p);
            const path: [BABYLON.Vector3[], BABYLON.Vector3] = [path0, point];
        
            return path;
        };
        
            function clineChordSkewPath(
                sta_beg: number,
                sta_end: number,
                hc_off_beg: number,
                hc_off_end: number,
                ang_beg: number,
                ang_end: number,
                cline_shape: { x: number; y: number; z: number }[],
                vcOff: number
            ): BABYLON.Vector3[][] {
                const z_base_beg = EL_vc_off(sta_beg, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, vcOff);
                const angBase_beg = ang_hc(sta_beg, xPC1, angPC1, staPC, hcR, hcLOC, deg) + 90 * deg;
                const z_base_end = EL_vc_off(sta_end, vcStaPvc, vcEL_Pvc, vcSlpLeft, vcSlpRight, vcLen, vcOff);
                const angBase_end = ang_hc(sta_end, xPC1, angPC1, staPC, hcR, hcLOC, deg) + 90 * deg;
            
                const path_beg: BABYLON.Vector3[] = [];
                for (let j = 0; j < cline_shape.length; j++) {
                const { x, y, z } = cline_shape[j];
                const xp = x_hc_off(sta_beg, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_beg - x) -
                    x_DimOff((x - hc_off_beg) * Math.tan(ang_beg), angBase_beg);
                const yp = y + z_base_beg + (superElevation(sta_beg, superElev) || 0) * hc_off_beg;
                const zp = y_hc_off(sta_beg, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_beg - x) +
                    y_DimOff((x - hc_off_beg) * Math.tan(ang_beg), angBase_beg);
                path_beg.push(new BABYLON.Vector3(xp, yp, zp));
                }
            
                const x_beg = x_hc_off(sta_beg, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_beg) -
                x_DimOff(-hc_off_beg * Math.tan(ang_beg), angBase_beg);
                const y_beg = z_base_beg + (superElevation(sta_beg, superElev) || 0) * hc_off_beg;
                const z_beg = y_hc_off(sta_beg, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_beg) +
                y_DimOff(-hc_off_beg * Math.tan(ang_beg), angBase_beg);
                const point_beg = new BABYLON.Vector3(x_beg, y_beg, z_beg);
            
                const path_end: BABYLON.Vector3[] = [];
                for (let j = 0; j < cline_shape.length; j++) {
                const { x, y, z } = cline_shape[j];
                const xp = x_hc_off(sta_end, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_end - x) -
                    x_DimOff((x - hc_off_end) * Math.tan(ang_end), angBase_end);
                const yp = y + z_base_end + (superElevation(sta_end, superElev) || 0) * hc_off_end;
                const zp = y_hc_off(sta_end, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_end - x) +
                    y_DimOff((x - hc_off_end) * Math.tan(ang_end), angBase_end);
                path_end.push(new BABYLON.Vector3(xp, yp, zp));
                }
            
                const x_end = x_hc_off(sta_end, xPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_end) -
                x_DimOff(-hc_off_end * Math.tan(ang_end), angBase_end);
                const y_end = z_base_end + (superElevation(sta_end, superElev) || 0) * hc_off_end;
                const z_end = y_hc_off(sta_end, yPC1, angPC1, staPC, hcR, hcLOC, deg, hc_off_end) +
                y_DimOff(-hc_off_end * Math.tan(ang_end), angBase_end);
                const point_end = new BABYLON.Vector3(x_end, y_end, z_end);
            
                const data: [BABYLON.Vector3[], BABYLON.Vector3[], [BABYLON.Vector3],[BABYLON.Vector3]] = [path_beg, path_end, [point_beg], [point_end]];
                return data;
            }
            


            const makeTextPlaneNode = function (
                text: number,
                color: string,
                size: number,
                index: number
            ): BABYLON.Mesh {
                const dynamicTexture = new BABYLON.DynamicTexture(
                "DynamicTexture",
                20,
                scene1,
                true
                );
                dynamicTexture.hasAlpha = true;
                dynamicTexture.drawText(
                String(text),
                5,
                10,
                "bold 14px Arial",
                color,
                "transparent",
                true
                );
                const plane = BABYLON.Mesh.CreatePlane(
                "TextBaseLine" + index,
                size,
                scene1,
                true
                );
                plane.material = new BABYLON.StandardMaterial("TextPlaneMaterial", scene1);
                plane.material.backFaceCulling = false;
               // plane.material.specularColor = new BABYLON.Color3(0, 0, 0);
               // plane.material.diffuseTexture = dynamicTexture;
                return plane;
            };
            

            const NMODEL = 10; // Replace 10 with the appropriate number

            for(var i=0; i<NMODEL; i++){

            var model_name = modelName[i];
            var model_type = modelType[i];
            var model_unit = modelUnit[i];
            var model_show = String(modelShow[i]);
            var model_super = String(modelSuper[i]);
            var model_sub = String(modelSub[i]);
            var model_bearing = modelPointBearing[i];
            var model_10th = String(modelPoint10th[i]);
            var model_20th = String(modelPoint20th[i]);
            var model_xframe = modelPointXframe[i];
            var model_splice = modelPointSplice[i];
            var model_cline = modelCenterLine[i];
            var index_cline = Number(indexCLine(model_cline));
            var cline_chord = clineChord[index_cline];
            var cline_brg_offset = clineBrgOffset[index_cline];
            if(model_show == "YES"){
                concreteMaterial.alpha = 0.3;
                concreteGirderMaterial.alpha = 0.3;
                concreteGirderMaterial.alpha = 0.3;                      
                }

                
            var cline_dist = 10;
            var cline_offset = 10;   
            // Points in each span 
            var nClinePoint = 2; // Minimum one element for two points
            if (model_10th === "YES"){nClinePoint = 11;} 
            if (model_20th === "YES"){nClinePoint = 21;} 
            var nodesImport = []; // variable to save model coordinates information

            var shape_points10 = shapeRectangular(1,1);
            if (cline_brg_offset > 0){ nClinePoint = nClinePoint + 2;}
            if(model_type == "SPINE"){
                for(var j=0; j < staUnit.length; j++){ 
                var index_unit = j;
                var unit_name9 = (staUnit[j])[13];
                if(model_unit == unit_name9){
                    var tempCoord = [];
                    var nodeNum = 0; 
                    // Superstructure 
                    if(model_super == "YES"){
                    var spans = staUnit[j][12] as string[];
                    var index_span_group = [];
                    for(var k=0;k<spans.length;k++){
                        var index_span = Number(indexSpan(spans[k])); 
                        index_span_group.push(index_span); 
                    }
                    for(var jj = 0; jj< index_span_group.length;jj++){
                        var index_span = index_span_group[jj]; 
                        var sta_beg = spanGeometry[index_span][0];
                        var sta_end = spanGeometry[index_span][1]; 
                        var skew_beg = Number(spanGeometry[index_span][2]);  
                        var skew_end = Number(spanGeometry[index_span][3]);               
                        var long_offset_beg = cline_brg_offset;
                        var long_offset_end = cline_brg_offset; 
                        let dist_to_deck = 2; // Assume 2 ft and adjust later
                        var vc_off = -dist_to_deck; //Vertical offset from top of the deck to centerline
                        var clineMaterial = redMaterial;      
                        var staBeg = Number(sta_beg);
                        var staEnd = Number(sta_end);
                        var base_offset_beg = clineOffVaried(staBeg,index_unit,index_cline);
                        var base_offset_end = clineOffVaried(staEnd,index_unit,index_cline);
                        var skewClinePathEnds = clineChordSkewPath(staBeg, staEnd,base_offset_beg,base_offset_end,skew_beg,skew_end,shape_points10,vc_off);
                        var x1 = [];
                        var y1 = [];
                        var z1 = [];
                        var x2 = [];
                        var y2 = [];
                        var z2 = [];
                        for(var j3=0;j3<(skewClinePathEnds[0]).length;j3++){
                            x1.push((skewClinePathEnds[0])[j3].x);
                            y1.push((skewClinePathEnds[0])[j3].y); 
                            z1.push((skewClinePathEnds[0])[j3].z);  
                            x2.push((skewClinePathEnds[1])[j3].x);
                            y2.push((skewClinePathEnds[1])[j3].y); 
                            z2.push((skewClinePathEnds[1])[j3].z);                                                    
                        }
                        var x_beg = (skewClinePathEnds[2][0]).x;
                        var y_beg = (skewClinePathEnds[2][0]).y;
                        var z_beg = (skewClinePathEnds[2][0]).z;
                        var x_end = (skewClinePathEnds[3][0]).x;
                        var y_end = (skewClinePathEnds[3][0]).y;
                        var z_end = (skewClinePathEnds[3][0]).z;
                        var ribbonPath = [];
                        var spacing = (staEnd - staBeg) / (nClinePoint-1);
                        if (cline_brg_offset > 0){ spacing = (staEnd - staBeg) / (nClinePoint-3);}   
                
                        for (var k = 0; k < nClinePoint; k++) {                           
                            var stak = staBeg + k * spacing;
                            var angk = skew_beg + (skew_end - skew_beg) /(staEnd - staBeg) * k * spacing;
                            var offk = base_offset_beg + (base_offset_end - base_offset_beg) /(staEnd - staBeg) * k * spacing;
                            if (cline_brg_offset > 0 && k == 1){
                                stak = staBeg + cline_brg_offset;
                                angk = skew_beg + (skew_end - skew_beg) /(staEnd - staBeg) * cline_brg_offset;
                                offk = base_offset_beg + (base_offset_end - base_offset_beg) /(staEnd - staBeg) * cline_brg_offset;                         
                                }
                            if (cline_brg_offset > 0 && (k > 1 && k < nClinePoint -2)){
                                stak = staBeg + (k-1) * spacing;
                                angk = skew_beg + (skew_end - skew_beg) /(staEnd - staBeg) * (k-1) * spacing;
                                offk = base_offset_beg + (base_offset_end - base_offset_beg) /(staEnd - staBeg) * (k-1) * spacing;                           
                                }
                            if (cline_brg_offset > 0 && k == nClinePoint -2){
                                stak = staEnd - cline_brg_offset;
                                angk = skew_end - (skew_end - skew_beg) /(staEnd - staBeg) * cline_brg_offset;
                                offk = base_offset_end - (base_offset_end - base_offset_beg) /(staEnd - staBeg) * cline_brg_offset;                           
                                } 
                            if (cline_brg_offset > 0 && k == nClinePoint -1){
                                stak = staEnd;
                                angk = skew_end;
                                offk = base_offset_end; 
                                } 
                            var clinePathk = [];           

                            let point_x;
                            let point_y;
                            let point_z;

                            if(cline_chord == "YES"){  
                            for(var m=0;m<(skewClinePathEnds[0]).length;m++){                   
                                var points_x = x1[m] + (x2[m] - x1[m]) / (staEnd - staBeg) * (stak - staBeg);
                                var points_y = y1[m] + (y2[m] - y1[m]) / (staEnd - staBeg) * (stak - staBeg);
                                var points_z = z1[m] + (z2[m] - z1[m]) / (staEnd - staBeg) * (stak - staBeg);                                                                                 
                                clinePathk.push(new BABYLON.Vector3(points_x,points_y,points_z));  
                            }    
                            ribbonPath[k] = clinePathk; 
                            point_x = x_beg + (x_end - x_beg) / (staEnd - staBeg) * (stak - staBeg);
                            point_y = y_beg + (y_end - y_beg) / (staEnd - staBeg) * (stak - staBeg);
                            point_z = z_beg + (z_end - z_beg) / (staEnd - staBeg) * (stak - staBeg); 
                            }

                            if(cline_chord == "NO"){  
                            var skewClinePathCurve = clineSkewPath(stak,shape_points10, angk, offk,vc_off);
                            for(var m=0;m<(skewClinePathCurve[0]).length;m++){ 
                                var points_x = (skewClinePathCurve[0])[m].x;
                                var points_y = (skewClinePathCurve[0])[m].y;
                                var points_z = (skewClinePathCurve[0])[m].z;                                                                                
                                clinePathk.push(new BABYLON.Vector3(points_x,points_y,points_z));  
                            }    
                            ribbonPath[k] = clinePathk; 
                            point_x = (skewClinePathCurve[1]).x;
                            point_y = (skewClinePathCurve[1]).y;
                            point_z = (skewClinePathCurve[1]).z;
                            }


                            if(jj == 0 || (jj > 0 && k > 0)){
                            var node_point = new BABYLON.Vector3(point_x,point_y,point_z); 
                            tempCoord.push(node_point); 
                            // Create nodal points
                            var box = BABYLON.MeshBuilder.CreateBox("jj", {height: 2, width: 2, depth: 2});  
                            box.material = blueMaterial; 
                            box.position = node_point; 
                            nodeNum = nodeNum + 1;  
                            // Create nodeal labels
                            var nodeChar = makeTextPlaneNode(nodeNum, "red", 10,k);
                            nodeChar.position = new BABYLON.Vector3(Number(point_x),Number(point_y)-8,Number(point_z)); 
                            nodeChar.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
                            }
                        }
                        var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon", {pathArray: ribbonPath, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closePath: true }, scene1);
                        ribbon.material = clineMaterial;        
                    }
                    nodesImport[i] = tempCoord;   
                    }

                    if(model_sub == "YES"){
                    var piers = staUnit[j][14] as string[];
                    var index_pier_group = [];
                    for(var k=0;k<piers.length;k++){
                        var index_pier = Number(indexPier(piers[k])); 
                        index_pier_group.push(index_pier); 
                    }
                    for(var jj = 0; jj< index_pier_group.length;jj++){
                        var index_pier = Number(index_pier_group[jj]); 
                        var sta_pier = pierGeometry[index_pier][0];
                        var skew_pier = pierGeometry[index_pier][1]; 
                        var cap_pier = pierGeometry[index_pier][2];  
                        var column_pier = pierGeometry[index_pier][3];     

                        // Nodes and elements for the cap
                        var cap_type = capIntPier_main[index_pier];
                        var index_cap =  Number(indexCap(cap_type));
                        var dist_to_deck = Number(capTopDist[index_cap]);
                        if(capSeg[index_cap] == 1){
                            var cap_section = capLongSec[index_cap]; 
                            var index_capsec = Number(indexCapSec(cap_section));
                            var shape_capsec = piercapsecShape[index_capsec];
                            var widthPierCap = piercapsecThick[index_capsec]; 
                            var shapePierCap;
                            if(piercapsecShapeDes[index_capsec] == "Cap" || piercapsecShapeDes[index_capsec] == "CAP"){
                                var index_capshape = Number(indexCapShape(shape_capsec));
                                shapePierCap = slopedShapePierCap(index_capshape,0); 
                            } else{
                                // Cap is with a differrent shape other than "CAP" 
                            }
                            var sta_off =  -widthPierCap /2;
                            var z_off = -dist_to_deck;  
                            //var pathPierCap = pathSubComponent(widthPierCap,0.5,x_Base,y_Base,z_Base,ang_Base,sta_off,0,z_off);
                            //var CapPieri = BABYLON.MeshBuilder.ExtrudeShape("CapPieri" + ii, {shape: shapePierCap, path: pathPierCap,sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);        
                        } else{
                            // Create cap based on section in transverse direction to be added later
                        }

        // Section above needs be modified

                    }

                    }

                }
                }
            }                  
            } 

        // Show model nodes and lables
        for(var i=0; i<NMODEL; i++){
            if (String(modelShow[i]) == "YES"){

            }                                
        } 


        //Environment
        // River
        if(riverON == "YES"){
            showRiverScene();
        }
        function showRiverScene(){
        removeRiver();    
        var staRiver = 16000; // Station for the center line of the river
        var skewRiver = 0 * deg; //Skew angle of the river relative to bridge baseline, positive in anticlockwise
        var widthRiver = 1000; //Width of the river
        var x_BaseRiver = x_hc_off(staRiver,xPC1,angPC1,staPC,hcR,hcLOC,deg,0.0);
        var y_BaseRiver = y_hc_off(staRiver,yPC1,angPC1,staPC,hcR,hcLOC,deg,0.0);
        var z_BaseRiver = groundEev-20;
        var ang_BaseRiver = ang_hc(staRiver,xPC1,angPC1,staPC,hcR,hcLOC,deg) + 90 * deg + skewRiver; 

        // Ground

        var groundMaterial = new BABYLON.StandardMaterial("groundMaterial", scene1);
        // Replace the following code for HTML File
        groundMaterial.diffuseTexture = new Texture("textures/ground.jpg", scene1);
        //	groundMaterial.diffuseTexture = new BABYLON.Texture("{% static 'babylon/textures/ground.jpg' %}", scene1);

        // Replace the above code for HTML File

        if (groundMaterial.diffuseTexture instanceof Texture) {
            groundMaterial.diffuseTexture.uScale = 4;
            groundMaterial.diffuseTexture.vScale = 4;
        }
        
            
        var ground = BABYLON.Mesh.CreateGround("groundRiver", widthRiver, 12000, 32, scene1, false);
        ground.position= new BABYLON.Vector3(x_BaseRiver, z_BaseRiver, y_BaseRiver);
        ground.material = groundMaterial;
        ground.rotation.y = -ang_BaseRiver -90*deg;
        ground.receiveShadows = true;

        // Water
        const waterMesh = Mesh.CreateGround("waterMesh", widthRiver, 12000, 32, scene1, false);
        const water = new WaterMaterial("water", scene1);

        // Replace the followig section for HTML File
        //	water.bumpTexture = new BABYLON.Texture("{% static 'babylon/textures/waterbump.png' %}", scene1); 
        water.bumpTexture = new BABYLON.Texture("textures/waterbump.png", scene1); 
        // Replace the above section for HTML File

        waterMesh.position= new BABYLON.Vector3(x_BaseRiver, z_BaseRiver, y_BaseRiver);
        waterMesh.rotation.y = -ang_BaseRiver -90*deg;
            waterMesh.position.y = groundEev;

        // Water properties
        water.windForce = -15;
        water.waveHeight = 1.3;
        water.windDirection = new BABYLON.Vector2(1, 1);
        water.waterColor = new BABYLON.Color3(0.1, 0.1, 0.6);
        water.colorBlendFactor = 0.3;
        water.bumpHeight = 0.1;
        water.waveLength = 0.1;
        
        // Add skybox and ground to the reflection and refraction
        water.addToRenderList(skybox);
        water.addToRenderList(ground);
        
        // Assign the water material
        waterMesh.material = water;

        //SeaWalls next to edges of river
        var heightSeaWall = 30; //Height of the seaWall
        var widthSeaWall = 2; //Width of the seaWall
        var z_BaseSeaWall = 10 + groundEev; 
        var ang_BaseSeaWall = ang_BaseRiver;

        //Left SeaWall    
        var sta_off = -widthRiver /2;
        var z_off = 0;
        var pathSeaWallL = pathSubComponent(widthSeaWall,1,x_BaseRiver,y_BaseRiver,z_BaseSeaWall,ang_BaseSeaWall,sta_off,0,z_off);
        var shapeSeaWallL = shapeRectangular(20000,heightSeaWall);
        var seaWallL = BABYLON.MeshBuilder.ExtrudeShape("seaWallL", {shape: shapeSeaWallL, path: pathSeaWallL, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
        seaWallL.material = concreteMaterial;
        
        //Right SeaWall    
        var sta_off = widthRiver /2;
        var z_off = 0;
        var pathSeaWallR = pathSubComponent(widthSeaWall,1,x_BaseRiver,y_BaseRiver,z_BaseSeaWall,ang_BaseSeaWall,sta_off,0,z_off);
        var shapeSeaWallR = shapeRectangular(20000,heightSeaWall);
        var seaWallR = BABYLON.MeshBuilder.ExtrudeShape("seaWallR", {shape: shapeSeaWallR, path: pathSeaWallR, sideOrientation: BABYLON.Mesh.DOUBLESIDE, cap: BABYLON.Mesh.CAP_ALL}, scene1);     
        seaWallR.material = concreteMaterial;
        }

        function removeRiver(){
            const meshIDs = ["groundRiver", "waterMesh", "seaWallL", "seaWallR", "water"];
            meshIDs.forEach(id => {
                const mesh = scene1.getMeshById(id);
                if (mesh) {
                    mesh.dispose();
                    console.log(`Disposed mesh with ID: ${id}`);
                } else {
                    console.warn(`Mesh with ID "${id}" not found.`);
                }
            });
        }
        
        // Cars
        if (carsON == "YES"){
            showCarScene();
        }

        const cars_1: Mesh[] = [];
        const cars_2: Mesh[] = [];
        const sumtheta: number[] = [];
        
        // Declare individual car meshes
        let car2_1: Mesh;
        let car3_1: Mesh;
        let car4_1: Mesh;
        let car5_1: Mesh;
        let car6_1: Mesh;
        let car7_1: Mesh;
        let car8_1: Mesh;
        let car9_1: Mesh;
        let car10_1: Mesh;
        let car11_1: Mesh;
        let carBody_1: Mesh;
        
        let car2_2: Mesh;
        let car3_2: Mesh;
        let car4_2: Mesh;
        let car5_2: Mesh;
        let car6_2: Mesh;
        let car7_2: Mesh;
        let car8_2: Mesh;
        let car9_2: Mesh;
        let car10_2: Mesh;
        let car11_2: Mesh;
        let carBody_2: Mesh;
    
        
        // Initialize and position the camera
        const followCamera = new ArcRotateCamera("FollowCamera", Math.PI / 2, Math.PI / 4, 1000, new Vector3(0, 0, 0), scene1);
        followCamera.attachControl(canvas, true);

        // Function to show car scene
        function showCarScene(){ 

                    // Lane #1
            SceneLoader.ImportMesh(
                "",
                "https://cdn.rawgit.com/hjm0525/FreeCAD/master/",
                "Cars_less.babylon",
                scene1,
                function (
                    newMeshes: AbstractMesh[], 
                    particleSystems: IParticleSystem[], 
                    skeletons: Skeleton[], 
                    animationGroups: AnimationGroup[]
                ) {
                    // Create car meshes
                    car2_1 = Mesh.CreateBox("car2_1", 0.001, scene1); 
                    car3_1 = Mesh.CreateBox("car3_1", 0.001, scene1); 
                    car4_1 = Mesh.CreateBox("car4_1", 0.001, scene1); 
                    car5_1 = Mesh.CreateBox("car5_1", 0.001, scene1); 
                    car6_1 = Mesh.CreateBox("car6_1", 0.001, scene1); 
                    car7_1 = Mesh.CreateBox("car7_1", 0.001, scene1); 
                    car8_1 = Mesh.CreateBox("car8_1", 0.001, scene1); 
                    car9_1 = Mesh.CreateBox("car9_1", 0.001, scene1); 
                    car10_1 = Mesh.CreateBox("car10_1", 0.001, scene1); 
                    car11_1 = Mesh.CreateBox("car11_1", 0.001, scene1); 
                    carBody_1 = Mesh.CreateBox("carBody_1", 0.001, scene1); 

                    // Assign imported parts to respective car meshes
                    newMeshes.forEach(importPart => {
                        if (importPart instanceof Mesh) { // Ensure it's a Mesh
                            if (importPart.name.includes('car2')) { 
                                importPart.parent = car2_1;
                            } else if (importPart.name.includes('car3')) { 
                                importPart.parent = car3_1;
                            } else if (importPart.name.includes('car4')) { 
                                importPart.parent = car4_1;
                            } else if (importPart.name.includes('car5')) { 
                                importPart.parent = car5_1;
                            } else if (importPart.name.includes('car6')) { 
                                importPart.parent = car6_1;
                            } else if (importPart.name.includes('car7')) { 
                                importPart.parent = car7_1;
                            } else if (importPart.name.includes('car8')) { 
                                importPart.parent = car8_1; 
                            } else if (importPart.name.includes('car9')) { 
                                importPart.parent = car9_1; 
                            } else if (importPart.name.includes('car10')) { 
                                importPart.parent = car10_1; 
                            } else if (importPart.name.includes('car11')) { 
                                importPart.parent = car11_1; 
                            } else {  
                                // Optionally assign to carBody_1 or handle differently
                                // importPart.parent = carBody_1;
                            } 
                        } else {
                            console.warn(`Imported part "${importPart.name}" is not a Mesh.`);
                        }
                    });

                    // Scale car meshes
                    car2_1.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car3_1.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car4_1.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car5_1.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car6_1.scaling = new Vector3(3.281, 3.281, 3.281); 
                   // car6_1.rotate(Axis.Y, 180 * deg, Space.WORLD);   
                    car7_1.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car8_1.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car9_1.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car10_1.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car11_1.scaling = new Vector3(3.281, 3.281, 3.281); 

                    const carType: Mesh[] = [car2_1, car3_1, car4_1, car5_1, car6_1, car7_1, car9_1, car10_1, car11_1];

                    // Create new car instances
                    const carsNumber: number = 50; // Total number of cars for the same model
                    const carY0: number = 85; // Initial car location for y coordinate
                    const carZ0: number = 100; // Initial car location for z coordinate 
                    const staCarBegin: number = staPjBegin;  // Station to start the car path
                    const staCarEnd: number = staPjEnd; // Station to end the car path
                    const pathSpacing: number = 4; // Car path data point spacing
                    const pathOffBase: number = 5; // Offset from the car path to baseline
                    const carPath: Vector3[] = pathComponent(staCarBegin, staCarEnd, pathSpacing, pathOffBase, 0);
                    const carTan: number[] = pathTangentZ(staCarBegin, staCarEnd, pathSpacing);
                    const n: number = carPath.length; 
                    const carSpa: number = Math.max(13, Math.floor((n / 20) * Math.random())); // Car spacing  
                    const path3d: Path3D = new Path3D(carPath);
                    const normals: Vector3[] = path3d.getNormals();
                    const theta0: number = carTan[0];  
                    
                    // Initialize sumtheta array with explicit type and default values
                    for (let i = 0; i < carsNumber; i++) {
                        sumtheta[i] = 0;
                    }

                    // Initialize cars_1 array with car meshes or clones
                    for (let i = 0; i < carsNumber; i++) {
                        if (i < carType.length) { 
                            cars_1[i] = carType[i % carType.length]; 
                        } else {  
                            cars_1[i] = carType[i % carType.length].clone(`cars_1_${i}`);             
                        } 
                    }

                    // Position and rotate cars
                    for (let i = 0; i < carsNumber; i++) {             
                        cars_1[i].position.y = carY0;
                        cars_1[i].position.z = carZ0; 
                        const npoint0: number = (carSpa * i) % (n - 1);
                        const theta: number = carTan[npoint0];
                        cars_1[i].rotate(Axis.Y, theta, Space.WORLD);       
                        sumtheta[i] = theta - theta0;          
                    }

                    // Set follow camera target
                    if (cars_1.length > 4) {
                        followCamera.lockedTarget = cars_1[4];
                    } else {
                        console.warn("Not enough cars to set as camera target.");
                    }

                    let alpha: number = 0;
                    let i: number = 0;

                    // Register after render callback
                    scene1.registerAfterRender(function() {
                        alpha += 0.02;
                        followCamera.alpha = alpha % (2 * Math.PI);
                        
                        for (let j = 0; j < carsNumber; j++) {    
                            const npoint: number = (i + carSpa * j) % (n - 1);         
                            if (npoint === 0) {  
                                cars_1[j].rotate(Axis.Y, -sumtheta[j], Space.WORLD);
                                sumtheta[j] = 0.0;  
                            }         
                            cars_1[j].position.x = carPath[npoint].x;
                            cars_1[j].position.z = carPath[npoint].z;
                            cars_1[j].position.y = carPath[npoint].y;
                            
                            let value: number = Vector3.Dot(normals[npoint], normals[npoint + 1]);
                            value = Math.max(-1.0, Math.min(1.0, value)); // Clamp between -1 and 1
                            
                            const thetaNew: number = Math.acos(value);
                            let dir: number = Vector3.Cross(normals[npoint], normals[npoint + 1]).y;
                            dir = dir / (Math.abs(dir) + 1e-11); // Prevent division by zero
                            
                            cars_1[j].rotate(Axis.Y, dir * thetaNew, Space.WORLD);
                            sumtheta[j] += dir * thetaNew;
                        }
                        i = (i + 1) % (n - 1);	// Continuous looping  	
                    });    
                }
            );



            // Lane #2
            SceneLoader.ImportMesh(
                "",
                "https://cdn.rawgit.com/hjm0525/FreeCAD/master/",
                "Cars_less.babylon",
                scene1,
                function (
                    newMeshes: AbstractMesh[], 
                    particleSystems: IParticleSystem[], 
                    skeletons: Skeleton[], 
                    animationGroups: AnimationGroup[]
                ) {
                    // Create car meshes
                    car2_2 = Mesh.CreateBox("car2_2", 0.001, scene1); 
                    car3_2 = Mesh.CreateBox("car3_2", 0.001, scene1); 
                    car4_2 = Mesh.CreateBox("car4_2", 0.001, scene1); 
                    car5_2 = Mesh.CreateBox("car5_2", 0.001, scene1); 
                    car6_2 = Mesh.CreateBox("car6_2", 0.001, scene1);
                  //  car6_2.rotate(Axis.Y, 180 * deg, Space.WORLD); 
                    car7_2 = Mesh.CreateBox("car7_2", 0.001, scene1); 
                    car8_2 = Mesh.CreateBox("car8_2", 0.001, scene1); 
                    car9_2 = Mesh.CreateBox("car9_2", 0.001, scene1); 
                    car10_2 = Mesh.CreateBox("car10_2", 0.001, scene1); 
                    car11_2 = Mesh.CreateBox("car11_2", 0.001, scene1); 
                    carBody_2 = Mesh.CreateBox("carBody_2", 0.001, scene1); 

                    // Assign imported parts to respective car meshes
                    newMeshes.forEach(importPart => {
                        if (importPart instanceof Mesh) { // Ensure it's a Mesh
                            if (importPart.name.includes('car2')) { 
                                importPart.parent = car2_2;
                            } else if (importPart.name.includes('car3')) { 
                                importPart.parent = car3_2;
                            } else if (importPart.name.includes('car4')) { 
                                importPart.parent = car4_2;
                            } else if (importPart.name.includes('car5')) { 
                                importPart.parent = car5_2;
                            } else if (importPart.name.includes('car6')) { 
                                importPart.parent = car6_2;
                            } else if (importPart.name.includes('car7')) { 
                                importPart.parent = car7_2;
                            } else if (importPart.name.includes('car8')) { 
                                importPart.parent = car8_2; 
                            } else if (importPart.name.includes('car9')) { 
                                importPart.parent = car9_2; 
                            } else if (importPart.name.includes('car10')) { 
                                importPart.parent = car10_2; 
                            } else if (importPart.name.includes('car11')) { 
                                importPart.parent = car11_2; 
                            } else {  
                                // Optionally assign to carBody_2 or handle differently
                                // importPart.parent = carBody_2;
                            } 
                        } else {
                            console.warn(`Imported part "${importPart.name}" is not a Mesh.`);
                        }
                    });

                    // Scale car meshes
                    car2_2.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car3_2.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car4_2.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car5_2.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car6_2.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car7_2.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car8_2.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car9_2.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car10_2.scaling = new Vector3(3.281, 3.281, 3.281); 
                    car11_2.scaling = new Vector3(3.281, 3.281, 3.281); 

                    const carType: Mesh[] = [car2_2, car3_2, car4_2, car5_2, car6_2, car7_2, car9_2, car10_2, car11_2];

                    // Create new car instances
                    const carsNumber: number = 50; // Total number of cars for the same model
                    const carY0: number = 85; // Initial car location for y coordinate
                    const carZ0: number = 100; // Initial car location for z coordinate 
                    const staCarBegin: number = staPjBegin;  // Station to start the car path
                    const staCarEnd: number = staPjEnd; // Station to end the car path
                    const pathSpacing: number = 4; // Car path data point spacing
                    const pathOffBase: number = -7; // Offset from the car path to baseline
                    const carPath: Vector3[] = pathComponent(staCarBegin + 20, staCarEnd, pathSpacing, pathOffBase, 0);
                    const carTan: number[] = pathTangentZ(staCarBegin, staCarEnd, pathSpacing);
                    const n: number = carPath.length; 
                    const carSpa: number = Math.max(13, Math.floor((n / 20) * Math.random())); // Car spacing  
                    const path3d: Path3D = new Path3D(carPath);
                    const normals: Vector3[] = path3d.getNormals();
                    const theta0: number = carTan[0];  
                    
                    // Initialize sumtheta array with explicit type and default values
                    for (let i = 0; i < carsNumber; i++) {
                        sumtheta[i] = 0;
                    }

                    // Initialize cars_2 array with car meshes or clones
                    for (let i = 0; i < carsNumber; i++) {
                        if (i < carType.length) { 
                            cars_2[i] = carType[i % carType.length]; 
                        } else {  
                            cars_2[i] = carType[i % carType.length].clone(`cars_2_${i}`);             
                        } 
                    }

                    // Position and rotate cars
                    for (let i = 0; i < carsNumber; i++) {             
                        cars_2[i].position.y = carY0;
                        cars_2[i].position.z = carZ0; 
                        const npoint0: number = (carSpa * i) % (n - 1);
                        const theta: number = carTan[npoint0];
                        cars_2[i].rotate(Axis.Y, theta, Space.WORLD);       
                        sumtheta[i] = theta - theta0;          
                    }

                    let i: number = 0;

                    // Register after render callback
                    scene1.registerAfterRender(function() {
                        for (let j = 0; j < carsNumber; j++) {    
                            const npoint: number = (i + carSpa * j) % (n - 1);         
                            if (npoint === 0 && i !== 0) {  
                                cars_2[j].rotate(Axis.Y, -sumtheta[j], Space.WORLD);
                                sumtheta[j] = 0.0;  
                            }         
                            cars_2[j].position.x = carPath[npoint].x;
                            cars_2[j].position.z = carPath[npoint].z;
                            cars_2[j].position.y = carPath[npoint].y;
                            
                            let value: number = Vector3.Dot(normals[npoint], normals[npoint + 1]);
                            value = Math.max(-1.0, Math.min(1.0, value)); // Clamp between -1 and 1
                            
                            const thetaNew: number = Math.acos(value);
                            let dir: number = Vector3.Cross(normals[npoint], normals[npoint + 1]).y;
                            dir = dir / (Math.abs(dir) + 1e-11); // Prevent division by zero
                            
                            cars_2[j].rotate(Axis.Y, dir * thetaNew, Space.WORLD);
                            sumtheta[j] += dir * thetaNew;
                        }
                        i = (i + 1) % (n - 1);	// Continuous looping  	
                    });    
                }
            );
                
           // --- Remove Car Function ---
        
            function removeCar(){
                // Dispose cars_1 array
                cars_1.forEach(car => {
                    if (car) {
                        car.dispose();
                    }
                });
        
                // Dispose cars_2 array
                cars_2.forEach(car => {
                    if (car) {
                        car.dispose();
                    }
                });
        
                // Dispose individual meshes if they exist
                const individualMeshes: Mesh[] = [
                    car2_1, car3_1, car4_1, car5_1, car6_1, car7_1, car8_1, car9_1, car10_1, car11_1, carBody_1,
                    car2_2, car3_2, car4_2, car5_2, car6_2, car7_2, car8_2, car9_2, car10_2, car11_2, carBody_2
                ];
        
                individualMeshes.forEach(mesh => {
                    if (mesh) {
                        mesh.dispose();
                    }
                });
            }
        

        }



        } // Iternation for main



        engine.runRenderLoop(() => {
            if (boxRef.current) {
                const deltaTimeInMillis = scene1.getEngine().getDeltaTime();
                const rpm = 10;
                boxRef.current.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
            }
            scene1.render();
        });

        const resize = () => {
            scene1.getEngine().resize();
        };

        window.addEventListener("resize", resize);


        return () => {
            scene1.getEngine().dispose();
            window.removeEventListener("resize", resize);
        };


      }  //Close for fileReady
    }, [fileReady]);


   // Top-level menu definitions
   const mainMenus = [
    {
        title: "File",
        items: ["Open","Save", "Save As", "Show Inputs", "Print", "Exit"]
    },
    {
        title: "Views",
        items: ["Top", "Front", "Left", "FIT", "Structural Units", "Superstructure", "Substructure", "Approach Road", "Geometry", "Environment", "Annimation"]
    },
    {
        title: "Inputs",
        items: ["Geometry", "Stages", "Groups", "Units"]
    },
    {
        title: "Design Center",
        items: ["Code & Specs", "Finish Grades EL", "Deck Design", "Prestressed Beam", "Steel Plate", "Steel Box", "CIP Concrete Box", "Segmental Box", "End Bent", "Intermediate Pier", "Pile Bent", "Straddle Bent", "Arch", "C Pier", "Soil-Struc. Interact", "Section Analysis", "Rebar Details", "MSE Walls", "CIP Retaining Wall", "Sheet Pile Wall", "Quantities", "Cost Estimates", "Struct. Type Review", "Structures Plans"]
    },
    {
        title: "Drawings",
        items: ["Plan & Elevation", "Typical Section", "Finish Grades", "Other Drawings"]
    },
    {
        title: "Tools",
        items: ["Tool1", "Tool2", "Tool3", "Tool4", "Tool5"]
    },
    {
        title: "Help",
        items: ["User's Manual", "Reference Manual", "Version Updates", "About"]
    }
   ];


    // The function that handles menu item clicks
    function buttonCall(a: string): void {
        switch (a) {
            // Files  
            case "Open": openFile(); break;              
            case "Save": saveFile(); break;
            case "Save As": saveAsFile(); break;
            case "Show Inputs": showFile(); break;
            case "Print": printFile(); break;
            case "Exit": exitScene1(); break;
            // Views
            case "Left": setCamLeftView(); break;
            case "Top": setCamTopView(); break;
            case "Front": setCamFrontView(); break;
            case "FIT": setCamFitView(); break;   
            case "Structural Units": unitView(); break;
            case "Superstructure": superView(); break;
            case "Substructure": subView(); break; 
            case "Approach Road": approachView(); break;                          
            case "Geometry": geometryView(); break;
            case "Environment": environmentView(); break;
            case "Annimation": annimationView(); break;                                 
            // Inputs           
            case "Stages": stageInput(); break;
            case "Groups": groupInput(); break;
            case "Units": unitInput(); break;
            // Special note: "Geometry" appears under both Views and Inputs. 
            // If needed, differentiate logic based on current open menu or reorganize names.
            // Here we assume the same function can be called.
            case "Code & Specs": codeSpecsDesign(); break;
            case "Finish Grades EL": finishGradesDesign(); break;
            case "Deck Design": deckDesign(); break;
            case "Prestressed Beam": prestressedBeamDesign(); break;
            case "Steel Plate": steelPlateDesign(); break;
            case "Steel Box": steelBoxDesign(); break;
            case "CIP Concrete Box": concreteBoxDesign(); break;
            case "Segmental Box": segmentalBoxDesign(); break;
            case "End Bent": endBentDesign(); break;
            case "Intermediate Pier": intermediatePierDesign(); break;
            case "Pile Bent": pileBentDesign(); break;
            case "Straddle Bent": straddleBentDesign(); break;
            case "Arch": archDesign(); break;
            case "C Pier": cPierDesign(); break;
            case "Soil-Struc. Interact": soilStructInteractDesign(); break;
            case "Section Analysis": sectioinAnalysisDesign(); break;
            case "Rebar Details": rebarDetailDesign(); break;
            case "MSE Walls": mseWallDesign(); break;
            case "CIP Retaining Wall": cipRetainingWallDesign(); break;
            case "Sheet Pile Wall": sheetPileWallDesign(); break;
            case "Quantities": quantitiesDesign(); break;
            case "Cost Estimates": costEstimatesDesign(); break;
            case "Struct. Type Review": structTypeReviewDesign(); break;
            case "Structures Plans": structPlansDesign(); break;
            // Drawings
            case "Plan & Elevation": planElevationDraw(); break;                                          
            case "Typical Section": typicalSectionDraw(); break;  
            case "Finish Grades": finishGradesDraw(); break;                
            case "Other Drawings": othersDraw(); break;  
            // Tools                                        
            case "Tool1": tool1Tool(); break;  
            case "Tool2": tool2Tool(); break;    
            case "Tool3": tool3Tool(); break;                                          
            case "Tool4": tool4Tool(); break;  
            case "Tool5": tool5Tool(); break;      
            // Help            
            case "User's Manual": userManualHelp(); break;  
            case "Reference Manual": refManualHelp(); break;                                          
            case "Version Updates": versionHelp(); break;  
            case "About": aboutHelp(); 
        }
    }

    // Implementations of the functions called by buttonCall
    function openFile() {
        // Programmatically open file dialog
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset input in case user selects same file again
            fileInputRef.current.click();
        }
    }

    const onFileLoad = (data: string | ArrayBuffer | null) => {
        if (typeof data === 'string') {
          setInputFile(data);
          setFileLoaded(true);
        }
      };
      

    const handleFileSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const files = evt.target.files;
        if (!files || files.length === 0) return;
    
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target?.result ?? null; // Ensures no undefined
            onFileLoad(data); 
        };
        reader.readAsText(files[0]);
    };
    
    
    function saveFile(){ }
    function saveAsFile() { }
    function showFile() { var inputWin = window.open("", "input", "toolbar=no, menubar=no, resizable=yes,width=800,height=600"); }
    function printFile(){ }
    function exitScene1(){ }


    function fitScale(): number {
        const xMax = Number(maxValue(X_show));
        const xMin = Number(minValue(X_show));
        const yMax = Number(maxValue(Y_show));
        const yMin = Number(minValue(Y_show));
        const zMax = Number(maxValue(Z_show));
        const zMin = Number(minValue(Z_show));
    
        // Check if all required values are available
        if (!xMax || !xMin || !yMax || !yMin || !zMax || !zMin) {
            console.warn("fitScale: Could not determine max/min values for X_show, Y_show, or Z_show. Returning a default factor of 1.");
            return 1; // Fallback factor in case no valid data is available
        }
    
        const length_x = xMax - xMin;
        const length_y = yMax - yMin;
        const length_z = zMax - zMin;
    
        const maxLength = Math.max(length_x, length_y, length_z);
        const factor = maxLength / 50;
    
        return factor;
    }
    

    // Views
    function setCamLeftView() {
        if (!scene) {
            console.warn("Scene is not ready yet.");
            return;
        }
    
        const cam = scene.activeCamera;
        if (cam instanceof BABYLON.ArcRotateCamera) {
            cam.alpha = 0;
            cam.beta = Math.PI / 2;
            cam.target = new BABYLON.Vector3(0, 15, 0);
        } else {
            console.warn("Active camera is not an ArcRotateCamera.");
        }
    }
    

    function setCamTopView() {
        if (!scene) {
            console.warn("Scene is not ready yet.");
            return;
        }
        const cam = scene.activeCamera;

        if (cam instanceof BABYLON.ArcRotateCamera) {
            cam.beta = 0;
            cam.target = new BABYLON.Vector3(0, 15, 0);
        } else {
            console.warn("Active camera is not an ArcRotateCamera.");
        }
    }


    function setCamFrontView() {
        if (!scene) {
            console.warn("Scene is not ready yet.");
            return;
        }
        const cam = scene.activeCamera;

        if (cam instanceof BABYLON.ArcRotateCamera) {
            cam.alpha = Math.PI / 2;
            cam.beta = Math.PI / 2;
            cam.target = new BABYLON.Vector3(0, 15, 0);
        } else {
            console.warn("Active camera is not an ArcRotateCamera.");
        }
    }

    function setCamFitView() {
        if (!scene) {
            console.warn("Scene is not ready yet.");
            return;
        }
        const cam = scene.activeCamera;

        if (cam instanceof BABYLON.ArcRotateCamera) {
            const factor = fitScale();
            cam.position = new BABYLON.Vector3(0, 51 * factor, -100 * factor);
        } else {
            console.warn("Active camera is not an ArcRotateCamera.");
        }
    }



    function unitView(){ setShowSelectUnitPanel(true)}
    function superView(){ /*...*/ }
    function subView(){ /*...*/ }
    function approachView(){ /*...*/ }
    function geometryView(){ /*...*/ }
    function environmentView(){ /*...*/ }
    function annimationView(){ /*...*/ }

    function geometryInput(){ /*...*/ }
    function stageInput(){ /*...*/ }
    function groupInput(){ /*...*/ }
    function unitInput(){ /*...*/ }

    function codeSpecsDesign(){ /*...*/ }
    function finishGradesDesign(){ /*...*/ }
    function deckDesign(){ /*...*/ }
    function prestressedBeamDesign(){ /*...*/ }
    function steelPlateDesign(){ /*...*/ }
    function steelBoxDesign(){ /*...*/ }
    function concreteBoxDesign(){ /*...*/ }
    function segmentalBoxDesign(){ /*...*/ }
    function endBentDesign(){ /*...*/ }
    function intermediatePierDesign(){ /*...*/ }
    function pileBentDesign(){ /*...*/ }
    function straddleBentDesign(){ /*...*/ }
    function archDesign(){ /*...*/ }
    function cPierDesign(){ /*...*/ }
    function soilStructInteractDesign(){ /*...*/ }
    function sectioinAnalysisDesign(){ /*...*/ }
    function rebarDetailDesign(){ /*...*/ }
    function mseWallDesign(){ /*...*/ }
    function cipRetainingWallDesign(){ /*...*/ }
    function sheetPileWallDesign(){ /*...*/ }
    function quantitiesDesign(){ /*...*/ }
    function costEstimatesDesign(){ /*...*/ }
    function structTypeReviewDesign(){ /*...*/ }
    function structPlansDesign(){ /*...*/ }

    function planElevationDraw(){ /*...*/ }
    function typicalSectionDraw(){ /*...*/ }
    function finishGradesDraw(){ /*...*/ }
    function othersDraw(){ /*...*/ }

    function tool1Tool(){ /*...*/ }
    function tool2Tool(){ /*...*/ }
    function tool3Tool(){ /*...*/ }
    function tool4Tool(){ /*...*/ }
    function tool5Tool(){ /*...*/ }

    function userManualHelp(){ /*...*/ }
    function refManualHelp(){ /*...*/ }
    function versionHelp(){ /*...*/ }
    function aboutHelp(){ /*...*/ }


    // Handle main menu clicks
    const handleMainMenuClick = (menuTitle: string) => {
        setOpenMenu(prev => prev === menuTitle ? null : menuTitle);
    };

    // Handle submenu item click
    const handleMenuItemClick = (item: string) => {
        buttonCall(item);
        setOpenMenu(null); // Close the menu after selection
    };


    // Example functions to show/hide units based on state
    function updateUnitsVisibility() {
        if (!scene) return;
    }

    // Call updateUnitsVisibility whenever state changes
    useEffect(() => {
        updateUnitsVisibility();
    }, [showAllUnitComponents, showUnits,scene]);

    // Handlers for UI
    const handleAllUnitsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowAllUnitComponents(e.target.checked);
    };

    const handleUnitChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newState = [...showUnits];
        newState[index] = e.target.checked;
        setShowUnits(newState);
    };

    // Overlay click handler to close menu
    const handleOverlayClick = () => {
        setOpenMenu(null);
    };


    return (
            <div className="main-container">
                {/* Toolbar container */}
                <div className="toolbar-container" ref={toolbarRef}>
                    {mainMenus.map(menu => (
                        <div key={menu.title} className="toolbar-menu">
                            <button
                                onClick={() => handleMainMenuClick(menu.title)}
                                className="toolbar-menu-button"
                            >
                                {menu.title}
                            </button>
                            {openMenu === menu.title && (
                                <div className="toolbar-dropdown">
                                    {menu.items.map(item => (
                                        <div
                                            key={item}
                                            className="toolbar-dropdown-item"
                                            onClick={() => handleMenuItemClick(item)}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
        
                {/*  File input */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                />

                {showSelectUnitPanel && (
                <div className="modal-overlay">
                <div className="select-panel">
                    <h3>Show Structural Units</h3>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={showAllUnitComponents} 
                            onChange={handleAllUnitsChange} 
                        />
                        All Units
                    </label>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={showUnits[0]} 
                            onChange={handleUnitChange(0)} 
                        />
                        Unit 1
                    </label>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={showUnits[1]} 
                            onChange={handleUnitChange(1)} 
                        />
                        Unit 2
                    </label>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={showUnits[2]} 
                            onChange={handleUnitChange(2)} 
                        />
                        Unit 3
                    </label>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={showUnits[3]} 
                            onChange={handleUnitChange(3)} 
                        />
                        Unit 4
                    </label>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={showUnits[4]} 
                            onChange={handleUnitChange(4)} 
                        />
                        Unit 5
                    </label>
                    <button 
                    style={{ marginTop: '10px', padding: '5px 10px', cursor: 'pointer' }} 
                    onClick={() => setShowSelectUnitPanel(false)}
                    >
                    Close
                    </button>
                    {/* Similarly, add more sections for superstructure, substructure, etc. */}
                    </div>
                </div>
                )}


                {/* Only show the overlay if a menu is open */}
                {openMenu && (
                    <div
                        className="menu-overlay"
                        onClick={handleOverlayClick}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0,0,0,0)',
                            zIndex: 10 // Make sure this is behind your dropdown which should have a higher z-index
                        }}
                    ></div>
                )}

                {/* Babylon.js canvas */}
                <canvas ref={reactCanvas} id={id} {...rest} className="my-canvas" />
            </div>
        );
        

};

export default EngineerMain;
