import React, { useState, useEffect, useRef,useCallback } from 'react';
import { auth, storage } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL, getMetadata, listAll} from 'firebase/storage';
import { getFirestore, doc, getDoc, setDoc,updateDoc,collection,serverTimestamp } from 'firebase/firestore';
import './Base.css';
import { onAuthStateChanged} from 'firebase/auth';
import ReactPlayer from 'react-player/youtube';
import { gapi } from 'gapi-script';
import CustomConfirmModal from './CustomConfirmModal';

const db = getFirestore();

const Videos: React.FC = () => {
  const [userUID, setUserUID] = useState<string>('');
  const isProfileFetched = useRef<boolean>(false); // Using ref instead of state
  const [youtubeUrl, setYoutubeUrl] = useState<string>('');
  const [playingVideoUrl, setPlayingVideoUrl] = useState<string | null>(null);
  const playerRef = useRef<ReactPlayer | null>(null);
  const [showFileConfirmModal, setShowFileConfirmModal] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [plan, setPlan] = useState<string | null>(null); 
  const [loading, setLoading] = useState(false);

  // Reference for the chat messages container
  const captionEndRef = useRef<HTMLDivElement | null>(null);

  // Scroll to the bottom of the chat container
  const scrollToBottom = () => {
    if (captionEndRef.current) {
      captionEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Automatically scroll to the bottom when chat updates
    scrollToBottom();
  }, []);

  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);


  const fetchUserPlan = useCallback(async (uid: string) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subscriptionStatus = userData?.subscriptionStatus;

        // Determine user plan based on Firestore data
        let planType = 'Starter';
        if (subscriptionStatus === 'Premium') planType = 'Premium';
        else if (subscriptionStatus === 'Chat') planType = 'Chat';
        else if (subscriptionStatus === 'ProChat') planType = 'ProChat';

        setPlan(planType); // Set the user plan
      } else {
        console.warn("User document does not exist.");
      }
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  }, []);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        fetchUserPlan(user.uid); // Fetch user plan when authenticated
      } else {
        console.warn("No user authenticated, data fetching skipped.");
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [fetchUserPlan]);

  const initClient = () => {
    gapi.client.init({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
    });
  };
  
  useEffect(() => {
    gapi.load('client:auth2', initClient);
  }, []);
  
  // Fetch user profile and subscription status
  useEffect(() => {
    const fetchUserProfile = async (uid: string) => {
      isProfileFetched.current = true;
    };

    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserUID(currentUser.uid);

      if (!currentUser.isAnonymous && !isProfileFetched.current) {
        fetchUserProfile(currentUser.uid);
      } else {
      }

    }
  }, []);

  const getNewFileNumber = async (): Promise<number> => {
    try {
      const firestore = getFirestore();
      const countersDocRef = doc(firestore, 'admin', 'counters');
      const countersDoc = await getDoc(countersDocRef);
  
      // Get the current lastFileNumber or default to 0 if not present
      const lastFileNumber = countersDoc.exists() ? countersDoc.data()?.lastFileNumber || 0 : 0;
  
      // Increment the file number
      const newFileNumber = lastFileNumber + 1;
  
      // Update the lastFileNumber in Firestore
      await updateDoc(countersDocRef, { lastFileNumber: newFileNumber });
  
      return newFileNumber;
    } catch (error) {
      console.error('Error fetching or updating lastFileNumber:', error);
      throw new Error('Unable to fetch or update the last file number.');
    }
  };


// Save YouTube URL to Firebase and fetch video title
const handleSaveVideoUrl = async (shareWithPublic:string) => {
  setLoading(true)
  if (!youtubeUrl || !ReactPlayer.canPlay(youtubeUrl)) {
    alert('Please enter a valid YouTube URL.');
    setLoading(false)
    return;
  }

  try {
    const userFilesRef = ref(storage, `videos/`);
    const fileList = await listAll(userFilesRef);

    if (plan === 'Starter') {
      // Check if the starter user has already uploaded 2 files
      const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
      if (existingFiles.length >= 10) {
        alert('Starter users can only upload up to 10 files. Registering as a premium user will allow 50 file uploads.');
        setLoading(false);
        return; // Stop further file upload
      }
    }

    if (plan === 'Premium') {
      // Check if the starter user has already uploaded 2 files
      const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
      if (existingFiles.length >= 50) {
        alert('Premium users can only upload up to 50 files. Registering as a chat user will allow unlimited file uploads.');
        setLoading(false);
        return; // Stop further file upload
      }
    }

    // Fetch the video title from the custom yt-dlp endpoint
    const response = await fetch(`/api/youtube-title?url=${encodeURIComponent(youtubeUrl)}`);
    const data = await response.json();

    if (data.error) {
      throw new Error(data.error);
    }

    const videoTitle = data.title;
    const videoId = youtubeUrl.split('v=')[1];
    const storageRef = ref(storage, `videos/${userUID}_${videoId}.url`);
    const tagsString = tags.join(',');
    const newFileNumber = await getNewFileNumber();
    const metadata = {
      customMetadata: {
        ShareWithPublic: shareWithPublic,
        UserID: userUID,
        DocumentType: 'YouTube Video',
        YoutubeTitle: videoTitle, // Add video title to metadata
        Tags: tagsString,
        NewFileNumber: String(newFileNumber),
      },
    };

    // Set the URL to play immediately
    setPlayingVideoUrl(youtubeUrl);

    await uploadBytes(storageRef, new Blob([youtubeUrl], { type: 'text/plain' }), metadata);

    const fileUrl = await getDownloadURL(storageRef);
    const file_Metadata = await getMetadata(storageRef);
    const fileSizeInBytes = file_Metadata.size; // File size in bytes
    const file_name = `${videoId}.url`

    //Add metadata to firestore
    const fileMetadata = {
      name: file_name,
      userId: userUID,
      url:fileUrl,
      refPath: `videos/${userUID}_${videoId}.url`,
      createdAt: serverTimestamp(),
      viewCount: 0,
      documentType: 'YouTube Video',
      shareWithPublic,
      fileTags: tags.join(','),
      size: fileSizeInBytes,
      youtubeTitle: videoTitle, 
      youtubeUrl,
    };

    const firestore = getFirestore();
    const filesCollectionRef = collection(firestore, 'files');
    await setDoc(doc(filesCollectionRef, `${newFileNumber}`), fileMetadata);

    setYoutubeUrl(''); // Clear the input after saving
    setLoading(false);
    console.log('Video saved successfully with title:', videoTitle);
  } catch (error) {
    console.error('Error fetching YouTube video title or uploading:', error);
    alert('Failed to save video. Please try again.');
    setLoading(false);
  }
};

  //Additional functions to handle reload the page
  const fetchUserProfile = async (uid: string) => {
    isProfileFetched.current = true;
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserUID(currentUser.uid);
  
        if (!currentUser.isAnonymous) {
          fetchUserProfile(currentUser.uid);
        } else {
        }
  
        // Fetch recordings only when userUID is set
        if (currentUser.uid) {
        }
      } else {
        console.warn("User is not authenticated.");
        setUserUID("");
      }
    });
  
    return () => unsubscribe();
  }, []);


  // Function to handle file upload confirmation
  const handleSaveButonClick = async () => {
    setShowFileConfirmModal(true); // Show the confirmation modal
  };

  const confirmFileShareWithPublic = async () => {
    setShowFileConfirmModal(false); 
    await handleSaveVideoUrl('Yes'); 

  };
  
  const cancelFileShareWithPublic = async () => {
    setShowFileConfirmModal(false); 
    await handleSaveVideoUrl('No'); 
  };

  const cancelShareWithPublicWindow = async () => {
    setShowFileConfirmModal(false);
  };

  return (
          <div className="upload-content-inner">
            {/* YouTube Video Player */}
            <div className="video-player-container">
              
            <div className="video-url-input-container">
              <input
                type="text"
                placeholder="Enter YouTube URL"
                value={youtubeUrl}
                onChange={(e) => setYoutubeUrl(e.target.value)}
                className="youtube-url-input"
              />
              <button onClick={handleSaveButonClick} className="save-video-button">
                {loading?'Loading':'Submit'}
              </button>
            </div>
                <ReactPlayer
                  ref={playerRef}
                  url={playingVideoUrl || undefined} // Use undefined instead of null
                  playing={Boolean(playingVideoUrl)}
                  controls
                  className="react-player"
                  style={{ top: '60px' }}
                />
               </div>

               {showFileConfirmModal && (
              <CustomConfirmModal 
                message="Do you want to share this file with the public?"
                onPublic={confirmFileShareWithPublic}
                onPrivate={cancelFileShareWithPublic}
                onCancel={cancelShareWithPublicWindow}
                tags={tags}
                setTags={setTags}
              />
              )}

          </div>    
          

  );
};

export default Videos;