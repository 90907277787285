import React, { useState, useEffect, useRef, useCallback} from 'react';
import { auth, storage } from '../firebaseConfig';
import { ref, uploadBytes, listAll, getDownloadURL, updateMetadata, getMetadata} from 'firebase/storage';
import { getFirestore, doc, getDoc, updateDoc, collection, serverTimestamp, setDoc} from 'firebase/firestore';
import './Base.css';
import * as fontkit from 'fontkit'; 
import { PDFDocument, rgb} from 'pdf-lib';
import CustomConfirmModal from './CustomConfirmModal';
import OpenAI from "openai";
import {onAuthStateChanged} from 'firebase/auth';

// OpenAI API setup
const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,   
    dangerouslyAllowBrowser: true,
  })
const db = getFirestore();

const Recordings: React.FC = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [textareaContent, setTextareaContent] = useState<string>('');
  const [userUID, setUserUID] = useState<string>('');
  const [isAnonymous, setIsAnonymous] = useState<boolean>(true);
  const [isConverting, setIsConverting] = useState<boolean>(false); 
  const textareaRef1 = useRef<HTMLTextAreaElement | null>(null);  
  const [isRecording, setIsRecording] = useState(false);
  const [recordingProgress, setRecordingProgress] = useState<number>(0); 
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null); 
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null); 
  const [customFileName, setCustomFileName] = useState<string>(''); 
  const [showFileNamePrompt, setShowFileNamePrompt] = useState<boolean>(false);
  const [shareWithPublic, setShareWithPublic] = useState<boolean>(true);
  const audioChunks = useRef<Blob[]>([]);
  const transcriptionInterval = useRef<NodeJS.Timeout | null>(null);
  const recordingInterval = useRef<NodeJS.Timeout | null>(null);
  const transcriptionLog = useRef<string[]>([]); 
  const finalAudioChunks = useRef<Blob[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showFileConfirmModal, setShowFileConfirmModal] = useState<boolean>(false);
  const [plan, setPlan] = useState<string | null>(null); 
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);


  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserUID(currentUser.uid);
      setIsAnonymous(currentUser.isAnonymous);
    }
  }, []);


  const fetchUserPlan = useCallback(async (uid: string) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subscriptionStatus = userData?.subscriptionStatus;

        // Determine user plan based on Firestore data
        let planType = 'Starter';
        if (subscriptionStatus === 'Premium') planType = 'Premium';
        else if (subscriptionStatus === 'Chat') planType = 'Chat';
        else if (subscriptionStatus === 'ProChat') planType = 'ProChat';

        setPlan(planType); // Set the user plan
      } else {
        console.warn("User document does not exist.");
      }
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  }, []);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        fetchUserPlan(user.uid); // Fetch user plan when authenticated
      } else {
        console.warn("No user authenticated, data fetching skipped.");
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [fetchUserPlan]);



  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      await handleFileUpload(file);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await handleFileUpload(file);
    }
  };

  const filterUnsupportedCharacters = (text: string): string => {
    // Replace specific problematic characters with placeholders or remove them
    const filteredText = text
      .replace(/[\u2190-\u21FF]/g, '[ARROW]') // Arrows
      .replace(/[\u2200-\u22FF]/g, '[MATH]') // Mathematical operators
      .replace(/[\u2300-\u23FF]/g, '[TECH]') // Miscellaneous technical symbols
      .replace(/[\u2500-\u257F]/g, '[BOX]') // Box drawing
      .replace(/[\u2580-\u259F]/g, '[BLOCK]') // Block elements
      .replace(/[\u25A0-\u25FF]/g, '[GEOMETRIC]') // Geometric shapes
      .replace(/[\u2600-\u26FF]/g, '[SYMBOL]') // Miscellaneous symbols
      .replace(/[\u2700-\u27BF]/g, '[DINGBAT]') // Dingbats
      .replace(/[\u2B00-\u2BFF]/g, '[SYMBOL]') // Miscellaneous symbols and arrows
      .replace(/[\u2C60-\u2C7F]/g, '[LATIN]') // Latin Extended-C
      .replace(/[\u2E80-\u2EFF]/g, '[CJK]') // CJK Radicals Supplement
      .replace(/[\u2F00-\u2FDF]/g, '[KANGXI]') // Kangxi Radicals
      .replace(/[\u2FF0-\u2FFF]/g, '[IDEOGRAPH]') // Ideographic Description Characters
      .replace(/[\u3000-\u303F]/g, '[CJK SYMBOL]') // CJK Symbols and Punctuation
      .replace(/[\u3040-\u309F]/g, '[HIRAGANA]') // Hiragana
      .replace(/[\u30A0-\u30FF]/g, '[KATAKANA]') // Katakana
      .replace(/[\u3100-\u312F]/g, '[BOPOMOFO]') // Bopomofo
      .replace(/[\u3130-\u318F]/g, '[HANGUL]') // Hangul Compatibility Jamo
      .replace(/[\u3190-\u319F]/g, '[KANBUN]') // Kanbun
      .replace(/[\u31A0-\u31BF]/g, '[BOPOMOFO]') // Bopomofo Extended
      .replace(/[\u31F0-\u31FF]/g, '[KATAKANA]') // Katakana Phonetic Extensions
      .replace(/[\u3200-\u32FF]/g, '[ENCLOSED CJK]') // Enclosed CJK Letters and Months
      .replace(/[\u3300-\u33FF]/g, '[CJK COMPAT]') // CJK Compatibility
      .replace(/[\u3400-\u4DBF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs Extension A
      .replace(/[\u4E00-\u9FFF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs
      .replace(/[\uA000-\uA48F]/g, '[YI]') // Yi Syllables
      .replace(/[\uA490-\uA4CF]/g, '[YI]') // Yi Radicals
      .replace(/[\uA700-\uA71F]/g, '[MODIFIER]') // Modifier Tone Letters
      .replace(/[\uA720-\uA7FF]/g, '[LATIN]') // Latin Extended-D
      .replace(/[\uA800-\uA82F]/g, '[SYLOTI NAGRI]') // Syloti Nagri
      .replace(/[\uA830-\uA83F]/g, '[NUMERIC]') // Common Indic Number Forms
      .replace(/[\uA840-\uA87F]/g, '[PHAGS-PA]') // Phags-pa
      .replace(/[\uA880-\uA8DF]/g, '[SAURASHTRA]') // Saurashtra
      .replace(/[\uA900-\uA92F]/g, '[KAYAH]') // Kayah Li
      .replace(/[\uA930-\uA95F]/g, '[REJANG]') // Rejang
      .replace(/[\uA960-\uA97F]/g, '[HANGUL]') // Hangul Jamo Extended-A
      .replace(/[\uAC00-\uD7AF]/g, '[HANGUL]') // Hangul Syllables
      .replace(/[\uD800-\uDB7F]/g, '[SURROGATE]') // High Surrogates
      .replace(/[\uDB80-\uDBFF]/g, '[SURROGATE]') // High Private Use Surrogates
      .replace(/[\uDC00-\uDFFF]/g, '[SURROGATE]') // Low Surrogates
      .replace(/[\uE000-\uF8FF]/g, '[PRIVATE]') // Private Use Area
      .replace(/[\uF900-\uFAFF]/g, '[CJK COMPAT]') // CJK Compatibility Ideographs
      .replace(/[\uFB00-\uFB4F]/g, '[ALPHABETIC]') // Alphabetic Presentation Forms
      .replace(/[\uFB50-\uFDFF]/g, '[ARABIC]') // Arabic Presentation Forms-A
      .replace(/[\uFE00-\uFE0F]/g, '[VARIATION]') // Variation Selectors
      .replace(/[\uFE10-\uFE1F]/g, '[VERTICAL]') // Vertical Forms
      .replace(/[\uFE20-\uFE2F]/g, '[COMBINING]') // Combining Half Marks
      .replace(/[\uFE30-\uFE4F]/g, '[CJK COMPAT]') // CJK Compatibility Forms
      .replace(/[\uFE50-\uFE6F]/g, '[SMALL FORM]') // Small Form Variants
      .replace(/[\uFE70-\uFEFF]/g, '[ARABIC]') // Arabic Presentation Forms-B
      .replace(/[\uFF00-\uFFEF]/g, '[HALFWIDTH]') // Halfwidth and Fullwidth Forms
      .replace(/[\uFFF0-\uFFFF]/g, '[SPECIAL]'); // Specials    
    return filteredText;
  };


  const getNewFileNumber = async (): Promise<number> => {
    try {
      const firestore = getFirestore();
      const countersDocRef = doc(firestore, 'admin', 'counters');
      const countersDoc = await getDoc(countersDocRef);
  
      // Get the current lastFileNumber or default to 0 if not present
      const lastFileNumber = countersDoc.exists() ? countersDoc.data()?.lastFileNumber || 0 : 0;
  
      // Increment the file number
      const newFileNumber = lastFileNumber + 1;
  
      // Update the lastFileNumber in Firestore
      await updateDoc(countersDocRef, { lastFileNumber: newFileNumber });
  
      return newFileNumber;
    } catch (error) {
      console.error('Error fetching or updating lastFileNumber:', error);
      throw new Error('Unable to fetch or update the last file number.');
    }
  };

  // Function to handle file upload confirmation
  const handleFileUpload = async (file: File) => {
    setSelectedFile(file); // Store the file to upload
    setShowFileConfirmModal(true); // Show the confirmation modal
  };

  const confirmFileShareWithPublic = async () => {
    if (selectedFile) { // Check if selectedFile is not null
      await uploadFile(selectedFile,'Yes'); // Proceed with the upload after confirming
    } else {
      console.error('No file selected for upload.'); // Handle the case when selectedFile is null
    }
    setShowFileConfirmModal(false); // Close the modal
  };
  
  const cancelFileShareWithPublic = async () => {
    if (selectedFile) { // Check if selectedFile is not null
      await uploadFile(selectedFile,'No'); // Proceed with the upload without sharing
    } else {
      console.error('No file selected for upload.'); // Handle the case when selectedFile is null
    }
    setShowFileConfirmModal(false); // Close the modal
  };

  const cancelShareWithPublicWindow = async () => {
    setShowFileConfirmModal(false);
  };

  const uploadFile = async (file: File,sharePublic:string) => {
    const fileName = `${userUID}_${file.name}.wav`; // Convert the file to WAV format

    const fileNameToCheck = fileName;
    const recordingsFolderRef = ref(storage, 'recordings/');
    try {
      const fileList = await listAll(recordingsFolderRef);
      const fileExists = fileList.items.some((file) => file.name === fileNameToCheck);
      if (fileExists) {
        alert(`A file with the name "${file.name}" already exists. Please choose another name.`);
        return;
      }
    } catch (error) {
      console.error('Error checking for file existence:', error);
    }
    const storageRef = ref(storage, `recordings/${fileName}`);

    try {
      const userFilesRef = ref(storage, `recordings/`);
      const fileList = await listAll(userFilesRef);
  
      if (plan === 'Starter') {
        // Check if the starter user has already uploaded 2 files
        const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
        if (existingFiles.length >= 10) {
          alert('Starter users can only upload up to 10 files. Registering as a premium user will allow 50 file uploads.');
          return; // Stop further file upload
        }
      }

      if (plan === 'Premium') {
        // Check if the starter user has already uploaded 2 files
        const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
        if (existingFiles.length >= 50) {
          alert('Premium users can only upload up to 50 files. Registering as a chat user will allow unlimited file uploads.');
          return; // Stop further file upload
        }
      }

      // Step 1: Convert the audio file to ArrayBuffer and decode it
      const arrayBuffer = await file.arrayBuffer();
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
      // Step 2: Convert the AudioBuffer to WAV format
      const wavBlob = audioBufferToWav(audioBuffer);
  
      // Step 3: Upload the WAV file to Firebase Storage
      await uploadBytes(storageRef, wavBlob);
      console.log('WAV file uploaded to Firebase Storage.');
  
      // Step 4: Get the download URL of the uploaded file
      const downloadUrl = await getDownloadURL(storageRef);
      console.log('Download URL:', downloadUrl);
  
      // Step 5: Calculate the duration using AudioContext
      const calculateAudioDuration = async (audioUrl: string) => {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        return audioBuffer.duration;
      };
  
      const duration = await calculateAudioDuration(downloadUrl);
      console.log(`Calculated Duration: ${duration} seconds`);
  
      // Ask the user if they want to share the file with the public
      const shareWithPublic = window.confirm('Do you want to share this file with the public?');
      const newFileNumber = await getNewFileNumber();

      // Set custom metadata based on the user's selection
      const customMetadata = {
        customMetadata: {
          ShareWithPublic: sharePublic,
          UserID: userUID,
          DocumentType: 'Recording',
          Duration: duration.toFixed(2), // Save duration as metadata (in seconds)
          NewFileNumber:String(newFileNumber),
        },
      };
  
      // Step 6: Update the metadata in Firebase Storage
      await updateMetadata(storageRef, customMetadata);
      const fileUrl = await getDownloadURL(storageRef);

      //Add metadata to firestore
      const fileMetadata = {
        name: file.name,
        userId: userUID,
        url:fileUrl,
        refPath: `recordings/${fileName}`,
        createdAt: serverTimestamp(),
        viewCount: 0,
        documentType: 'Recording',
        shareWithPublic,
        fileTags: tags.join(','),
        size: file.size,
        duration: duration.toFixed(2), 
      };

      const firestore = getFirestore();
      const filesCollectionRef = collection(firestore, 'files');
      await setDoc(doc(filesCollectionRef, `${newFileNumber}`), fileMetadata);

      alert('Recording uploaded successfully!');
    } catch (error) {
      console.error('Upload error:', error);
    }
  };
  
  const generateUniqueFileName = async (originalName: string): Promise<string> => {
    const storageRef = ref(storage, `recordingTranscript/`);
    const fileList = await listAll(storageRef);
  
    let newFileName = originalName;
    let counter = 1;
    
    const existingFiles = fileList.items.map((file) => file.name);
  
    while (existingFiles.includes(newFileName)) {
      const fileNameWithoutExt = originalName;
      newFileName = `${fileNameWithoutExt}(${counter})`;
      counter++;
    }
    return `${newFileName}_transcript.pdf`;
  };

  const uploadTextAsPDF = async (text: string,fileName: string) => {
    const pdfDoc = await PDFDocument.create();
  
    // Register fontkit
    pdfDoc.registerFontkit(fontkit as any);
  
    // Load a custom Unicode font from a TTF file
    const fontUrl = process.env.PUBLIC_URL + '/assets/fonts/Roboto-Regular.ttf';
    const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
    const customFont = await pdfDoc.embedFont(fontBytes); // Embed custom font
  
    // Set up an 8x11 inch page in portrait format (8.5 inches wide, 11 inches tall)
    const pageWidth = 8.5 * 72;
    const pageHeight = 11 * 72;
  
    let page = pdfDoc.addPage([pageWidth, pageHeight]);
    const { width, height } = page.getSize();
    const fontSize = 12;
    const lineHeight = fontSize + 4;
    const margin = 50;
    const maxLineWidth = width - margin * 2;
  
    const sanitizedText = filterUnsupportedCharacters(text);
    const paragraphs = sanitizedText.split('\n');
  
    let y = height - margin;
  
    for (const paragraph of paragraphs) {
      const words = paragraph.split(' ');
      let currentLine = '';
  
      for (const word of words) {
        const testLine = currentLine + word + ' ';
        const testLineWidth = customFont.widthOfTextAtSize(testLine, fontSize);
  
        if (testLineWidth > maxLineWidth) {
          if (y - lineHeight < margin) {
            page = pdfDoc.addPage([pageWidth, pageHeight]);
            y = height - margin;
          }
          page.drawText(currentLine, {
            x: margin,
            y: y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });
          currentLine = word + ' ';
          y -= lineHeight;
        } else {
          currentLine = testLine;
        }
      }
  
      if (currentLine.trim()) {
        if (y - lineHeight < margin) {
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          y = height - margin;
        }
        page.drawText(currentLine.trim(), {
          x: margin,
          y: y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        y -= lineHeight;
      }
  
      // After processing each paragraph, add a line break
      y -= lineHeight;
    }
  
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });

    const originalName = fileName.split('.').slice(0, -1).join('.') || 'file'
    //console.log('originalName',originalName)
    const uniqueFileName = await generateUniqueFileName(originalName);

   // console.log('uniqueFileName',uniqueFileName )
    const storageRef = ref(storage, `recordingTranscript/${uniqueFileName}`);
    const recordingStorageRef = ref(storage, `recordings/${fileName}`);
  
    try {
      const userFilesRef = ref(storage, `recordingTranscript/`);
      const fileList = await listAll(userFilesRef);
  
      if (plan === 'Starter') {
        // Check if the starter user has already uploaded 2 files
        const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
        if (existingFiles.length >= 10) {
          alert('Starter users can only upload up to 10 files. Registering as a premium user will allow 50 file uploads.');
          return; // Stop further file upload
        }
      }

      if (plan === 'Premium') {
        // Check if the starter user has already uploaded 2 files
        const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
        if (existingFiles.length >= 50) {
          alert('Premium users can only upload up to 50 files. Registering as a chat user will allow unlimited file uploads.');
          return; // Stop further file upload
        }
      }
      
      // Set custom metadata based on the user's selection
      const customMetadata = {
        customMetadata: {
          ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
          UserID:userUID,
          DocumentType:'Note',
        },
      };

      await uploadBytes(storageRef, blob,customMetadata);

      // Get the download URL of the transcribed PDF
       const transcriptUrl = await getDownloadURL(storageRef);

       const recordingMetadata = {
        customMetadata: {
          TranscriptUrl: transcriptUrl,
        },
      };

      // Update metadata for the recording file
      await updateMetadata(recordingStorageRef, recordingMetadata);

      alert('PDF submitted successfully!');
      setShareWithPublic(false);

      setTextareaContent('');
  
    } catch (error) {
      console.error('Upload error:', error);
    }
  };


  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
  
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.current.push(event.data); // Accumulate audio data in chunks
            finalAudioChunks.current.push(event.data);
            //console.log('Data available:', event.data);
            //console.log('audioChunks.current length after push:', audioChunks.current.length);
          }
        };
  
        mediaRecorder.start(1000); // Emit data every 1 second
        setRecorder(mediaRecorder);
        setIsRecording(true);
        setTextareaContent('Recording... Transcribing in real-time...');
        setRecordingProgress(0);
  
        // Start a timer to increment the recording progress
        recordingInterval.current = setInterval(() => {
          setRecordingProgress((prev) => prev + 1);
        }, 1000);
  
        // Transcribe every 10 seconds
        transcriptionInterval.current = setInterval(processNextChunk, 10000);
  
        mediaRecorder.onstop = () => {
          clearInterval(transcriptionInterval.current as NodeJS.Timeout);
          clearInterval(recordingInterval.current as NodeJS.Timeout);
        };
  
      } catch (error) {
        console.error('Error starting audio recording:', error);
      }
    } else {
      alert('Audio recording is not supported in this browser.');
    }
  };


// Function to process and transcribe the latest chunk only
const processNextChunk = async () => {
  console.log('audioChunks.current.length',audioChunks.current.length)

  if (audioChunks.current.length > 0) {
    console.log('codes getting here')
    // Combine only the latest accumulated chunks for the next 10-second period
    const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });

    const firstChunk = audioChunks.current[0]; // Assuming each chunk is approximately 1 second
    audioChunks.current = [firstChunk]; // Keep only the first chunk
    //console.log('Retained only the first 1 second of audioChunks, new length:', audioChunks.current.length);

    try {
      // Transcribe the current 10-second chunk
      const transcription = await transcribeAudioChunk(audioBlob);
      
      // Add the transcription of this chunk to the log
      transcriptionLog.current.push(transcription);

      // Update textarea content by joining all transcriptions in the log
      setTextareaContent(transcriptionLog.current.join(' '));
    } catch (error) {
      console.error('Error processing transcription chunk:', error);
    }
  }
};

// Update your transcribeAudioChunk function to transcribe only the latest chunk
const transcribeAudioChunk = async (audioBlob: Blob): Promise<string> => {
  setIsConverting(true);
  try {
    const audioFile = new File([audioBlob], "audio.webm", { type: audioBlob.type });
    const transcriptionText = await transcribeAudioWithWhisper(audioFile);
    return transcriptionText;
  } catch (error) {
    console.error('Error during transcription:', error);
    return ''; // Return an empty string if an error occurs
  } finally {
    setIsConverting(false);
  }
};


// Call OpenAI Whisper for transcription
const transcribeAudioWithWhisper = async (audioFile: File): Promise<string> => {
  try {
    const transcription = await openai.audio.transcriptions.create({
      file: audioFile,
      model: "whisper-1",
      response_format: "text",
    });
    return String(transcription);
  } catch (error) {
    console.error("Error during transcription:", error);
    return "";
  }
};

const stopRecording = () => {
  if (recorder) {
    recorder.stop();
    recorder.stream.getTracks().forEach((track) => track.stop());
    setRecorder(null);
    setIsRecording(false);
    setTextareaContent((prevContent) => prevContent + '\nRecording stopped.');

    if (recordingInterval.current) clearInterval(recordingInterval.current);
    if (transcriptionInterval.current) clearInterval(transcriptionInterval.current);
    
    // Combine all chunks into a single blob
    const finalAudioBlob = new Blob(finalAudioChunks.current, { type: 'audio/webm' });
    setAudioBlob(finalAudioBlob); // Set the final audioBlob
    finalAudioChunks.current = []; 

    setShowFileNamePrompt(true); // Show file name prompt after stopping
    //console.log('isConverting',isConverting)

  }
};

// Utility to convert an AudioBuffer to WAV format
const audioBufferToWav = (buffer: AudioBuffer) => {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2 + 44;
    const bufferData = new ArrayBuffer(length);
    const view = new DataView(bufferData);
  
    // Write WAV header
    let offset = 0;
    const writeString = (s: string) => {
      for (let i = 0; i < s.length; i++) {
        view.setUint8(offset++, s.charCodeAt(i));
      }
    };
    const setUint16 = (data: number) => {
      view.setUint16(offset, data, true);
      offset += 2;
    };
    const setUint32 = (data: number) => {
      view.setUint32(offset, data, true);
      offset += 4;
    };
  
    // RIFF chunk descriptor
    writeString('RIFF');
    setUint32(length - 8);
    writeString('WAVE');
  
    // fmt sub-chunk
    writeString('fmt ');
    setUint32(16);
    setUint16(1);
    setUint16(numOfChan);
    setUint32(buffer.sampleRate);
    setUint32(buffer.sampleRate * 2 * numOfChan);
    setUint16(numOfChan * 2);
    setUint16(16);
  
    // data sub-chunk
    writeString('data');
    setUint32(length - offset - 4);
  
    // Write interleaved audio data
    const channels = [];
    for (let i = 0; i < numOfChan; i++) {
      channels.push(buffer.getChannelData(i));
    }
  
    let sample;
    for (let i = 0; i < buffer.length; i++) {
      for (let j = 0; j < numOfChan; j++) {
        sample = Math.max(-1, Math.min(1, channels[j][i]));
        sample = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
        view.setInt16(offset, sample, true);
        offset += 2;
      }
    }
  
    return new Blob([bufferData], { type: 'audio/wav' });
  };

  const uploadRecording = async () => {
    setShowFileNamePrompt(false); // Hide the prompt
    setLoading(true);
    //console.log('isConverting',isConverting)
    //console.log('userUID',userUID)
    //console.log('custom FileName',customFileName.trim())
    //console.log('!audioBLob',!audioBlob)
    
    if (!audioBlob || !customFileName.trim()) {
      setLoading(false);
      return;
    }

    const fileName = `${userUID}_${customFileName.trim()}.wav`; // Convert to WAV format
    //console.log('fileName',fileName)


    const storageRef = ref(storage, `recordings/${fileName}`);
    //console.log('fileName',fileName)

    try {
      // Step 1: Convert the audioBlob to ArrayBuffer
      const arrayBuffer = await audioBlob.arrayBuffer();
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
      // Step 2: Convert the AudioBuffer to WAV Blob
      const wavBlob = audioBufferToWav(audioBuffer);
  
      // Step 3: Upload the WAV file to Firebase Storage
      await uploadBytes(storageRef, wavBlob);
      console.log('Converted WAV file uploaded to Firebase Storage.');
  
      // Step 4: Get the download URL of the uploaded file
      const downloadUrl = await getDownloadURL(storageRef);
      console.log('Download URL:', downloadUrl);
  
      // Step 5: Calculate the duration using AudioContext
      const calculateAudioDuration = async (audioUrl: string) => {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        setLoading(false);
        return audioBuffer.duration; // Get the duration from the AudioBuffer
      };
  
      const duration = await calculateAudioDuration(downloadUrl);
      console.log(`Calculated Duration: ${duration} seconds`);
  
      // Step 6: Update the metadata with the calculated duration
      const tagsString = tags.join(',');
      const newFileNumber = await getNewFileNumber();

      const customMetadata = {
        customMetadata: {
          ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
          UserID: userUID,
          DocumentType: 'Recording',
          Duration: duration.toFixed(2), // Save duration as metadata (in seconds)
          Tags: tagsString,
          NewFileNumber: String(newFileNumber),
        },
      };
  
      // Step 7: Update the metadata in Firebase Storage
      await updateMetadata(storageRef, customMetadata);
      const fileUrl = await getDownloadURL(storageRef);
      const file_Metadata = await getMetadata(storageRef);
      const fileSizeInBytes = file_Metadata.size; // File size in bytes
      const file_name = `${customFileName.trim()}.wav`; 

      //Add metadata to firestore
      const fileMetadata = {
        name: file_name,
        userId: userUID,
        url:fileUrl,
        refPath: `recordings/${fileName}`,
        createdAt: serverTimestamp(),
        viewCount: 0,
        documentType: 'Recording',
        shareWithPublic,
        fileTags: tags.join(','),
        size: fileSizeInBytes,
        duration: duration.toFixed(2), 
      };

      const firestore = getFirestore();
      const filesCollectionRef = collection(firestore, 'files');
      await setDoc(doc(filesCollectionRef, `${newFileNumber}`), fileMetadata);

      // Step 8: Reset UI
      setAudioBlob(null);
      setCustomFileName('');

  
      // Step 9: Wait for isConverting to become false or timeout after 5 minutes
      const waitForConversionToComplete = async () => {
        const startTime = Date.now();
        const timeout = 5 * 60 * 1000; // 5 minutes in milliseconds
  
        while (isConverting) {
          if (Date.now() - startTime > timeout) {
            console.warn('Timeout waiting for isConverting to become false.');
            return;
          }
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Check every 1 second
        }
      };
      console.log('is converting',isConverting)
      await waitForConversionToComplete();
      
      // Step 10: Handle script submission
      if (!isConverting) {
        if (textareaContent.trim()) { // Check if selectedFile is not null
          //setShareWithPublic(true);
          console.log('start uploading pdf')

          await uploadTextAsPDF(textareaContent,fileName);
        } else {
          alert('Please paste some information before submitting.');
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Error uploading recording:', error);
      alert('An error occurred while uploading the recording.');
      setLoading(false);
    }
  };
  
  // Cancel file upload and close the prompt
  const handleCancel = () => {
      setCustomFileName('');
      setShowFileNamePrompt(false);
      setAudioBlob(null); 
    };
    
    useEffect(() => {
      const checkFileNameExists = async () => {
        if (!customFileName.trim() || !userUID) return;
    
        const fileNameToCheck = `${userUID}_${customFileName.trim()}.wav`;
        const recordingsFolderRef = ref(storage, 'recordings/');
    
        try {
          const fileList = await listAll(recordingsFolderRef);
    
          const fileExists = fileList.items.some((file) => file.name === fileNameToCheck);
    
          if (fileExists) {
            alert(`A file with the name "${customFileName.trim()}" already exists. Please choose another name.`);
            setCustomFileName(''); // Clear the custom file name
          }
        } catch (error) {
          console.error('Error checking for file existence:', error);
        }
      };
    
      checkFileNameExists();
    }, [customFileName, userUID]);
    
  const handleAddTag = () => {
    if (inputValue.trim()) {
      if (!tags.includes(inputValue.trim())) {
        setTags((prevTags) => [...prevTags, inputValue.trim()]);
        setInputValue('');
        setError('');
      } else {
        setError('Tag already exists.');
      }
    } else {
      setError('Tag cannot be empty.');
    }
  };


    const handleRemoveTag = (tagToRemove: string) => {
      setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
    };
  
    useEffect(() => {
      if (tags.length === 0) {
        setError('Please provide at least one tag for the document.');
      } else {
        setError('');
      }
    }, [tags]);
  


  return (
          <div className="upload-content-inner">
              <div 
                className={`audio-file-drop-area ${isDragging ? 'dragging' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                 >
                <p style={{ color: 'white', marginBottom: '0px',marginTop: '10px' }}>Drop your Audio files here or</p> 
                <input 
                type="file" 
                onChange={handleFileChange} 
                style={{ display: 'none' }} 
                id="fileInput"
                />
                
                <label htmlFor="fileInput" className="upload-button" style={{ marginTop: '5px' }}> {/* Added marginTop to move it upward */}
                Choose files
                </label>
               </div>

                {/* Recording Container */}
                <div className="recording-container" style={{ marginTop: '20px', textAlign: 'center' }}>
                {isRecording ? (
                    <>
                    <p style={{ color: 'white', marginBottom: '10px' }}>Recording... {new Date(recordingProgress * 1000).toISOString().slice(11, 19)}</p> {/* Shows time dynamically */}
                    <button onClick={stopRecording} style={{ backgroundColor: 'red', color: 'white', padding: '10px 20px' }}>
                       {loading ? 'Uploading...' : ' Stop Recording'}
                    </button>
                    </>
                ) : (
                    <button onClick={startRecording} style={{ backgroundColor: 'green', color: 'white', padding: '10px 20px',marginBottom: '30px' }}>
                    Start Recording
                    </button>
                )}

                <div className="textarea-container" style={{ position: 'relative',padding: '5px 0px' }}>
                  <textarea
                    value={textareaContent}
                    onChange={(e) => setTextareaContent(e.target.value)}
                    placeholder="Transcript for recording"
                    className="textarea-input"
                    readOnly={isConverting}
                    ref={textareaRef1} // Attach ref to textarea
                  />
                </div>

                    {!isRecording && showFileNamePrompt && ( 
                      <div className="modal-overlay">
                        <div className="modal-content qwindow-modal">
                          <div
                            className="file-name-prompt"
                            style={{ marginTop: '20px', textAlign: 'center' }}
                          >

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <label
                            className="qwindow-label"
                            htmlFor="fileName"
                            style={{
                              color: '#f4f4f9',
                              fontSize: '18px',
                              marginBottom: '10px',
                              display: 'block',
                            }}
                          >
                            Enter File Name for Your Recording
                          </label>
                          <input
                            type="text"
                            id="fileName"
                            value={customFileName}
                            onChange={(e) => setCustomFileName(e.target.value)}
                            placeholder="File name"
                            style={{
                              padding: '5px',
                              width: '100%',
                              maxWidth: '400px',
                              borderRadius: '8px',
                              border: '1px solid #ddd',
                              marginBottom: '20px',
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                              fontSize: '16px',
                            }}
                          />
                        </div>


                          <label className="qwindow-label" style={{ marginBottom: '10px' }}>
                                    Add Tags to the File
                                  </label>
                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <input
                                      type="text"
                                      value={inputValue}
                                      onChange={(e) => setInputValue(e.target.value)}
                                      placeholder="Enter a tag and press Add Tag"
                                      style={{ flex: 1, marginRight: '10px', padding: '5px' }}
                                    />
                                    <button onClick={handleAddTag} style={{ padding: '5px 5px' }}>
                                      Add Tag
                                    </button>
                                  </div>
                                  {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}

                                  <div style={{ color: 'white', textAlign: 'left', marginBottom: '10px' }}>
                                    <ul style={{ paddingLeft: '20px', margin: 0, listStyleType: 'disc' }}>
                                      {tags.map((tag, index) => (
                                        <li
                                          key={index}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '5px',
                                          }}
                                        >
                                          <span>{tag}</span>
                                          <button
                                            onClick={() => handleRemoveTag(tag)}
                                            style={{
                                              background: 'none',
                                              border: 'none',
                                              cursor: 'pointer',
                                              color: 'red',
                                            }}
                                            aria-label="Remove tag"
                                          >
                                            ❌
                                          </button>
                                        </li>
                                      ))}
                                    </ul>
                          </div>


                          <div style={{ textAlign: 'left', marginBottom: '20px' }}>
                            <div style={{ marginBottom: '10px' }}>
                              <label style={{ color: '#f4f4f9', fontSize: '16px' }}>
                                <input
                                  type="radio"
                                  name="privacyOption"
                                  value="private"
                                  checked={!shareWithPublic}
                                  onChange={() => setShareWithPublic(false)}
                                  style={{ marginRight: '10px' }}
                                />
                                Keep the File Private
                              </label>
                            </div>
                            <div>
                              <label style={{ color: '#f4f4f9', fontSize: '16px' }}>
                                <input
                                  type="radio"
                                  name="privacyOption"
                                  value="public"
                                  checked={shareWithPublic}
                                  onChange={() => setShareWithPublic(true)}
                                  style={{ marginRight: '10px' }}
                                />
                                Share with Public
                              </label>
                            </div>
                          </div>

                          {isConverting && (
                          <p  style={{ color: 'white' }}>Please wait. Recording is being processed...</p>
                          )}

                          <div
                            style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}
                          >

                            <button
                              onClick={uploadRecording}
                              className="ok-button qwindow-button"
                              disabled={!customFileName.trim()}
                            >
                              Upload Recording
                            </button>
                            <button
                              onClick={handleCancel}
                              className="cancel-button qwindow-button"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                   )} 

                  {showFileConfirmModal && (
                    <CustomConfirmModal 
                      message="Do you want to share this file with the public?"
                      onPublic={confirmFileShareWithPublic}
                      onPrivate={cancelFileShareWithPublic}
                      onCancel={cancelShareWithPublicWindow}
                      tags={tags}
                      setTags={setTags}
                    />
                  )}

                </div>

          </div>          
  );
};

export default Recordings;