import React, { CSSProperties, useEffect } from 'react';

const Terms: React.FC = () => {
  // Set background color of the entire page to black when component mounts
  useEffect(() => {
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = ''; // Reset background on unmount
    };
  }, []);

  return (
    <div  className="container-terms" >
    <div style={styles.container}>
      <h1 style={styles.heading}>Terms of Service</h1>
      
      <p style={styles.paragraph}>This document governs:</p>
      <ul>
        <li>The use of our website, and</li>
        <li>Any other related agreement or legal relationship with us</li>
      </ul>
      <p style={styles.paragraph}>in a legally binding way.</p>

      <p style={styles.paragraph}>You must read this document carefully.</p>

      <h2 style={styles.subHeading}>Our website is provided by:</h2>
      <p style={styles.paragraph}>Compreqna.com</p>
      <p style={styles.paragraph}>Contact email: <a href="mailto:customerservice@compreqna.com" style={styles.link}>customerservice@compreqna.com</a></p>

      <h2 style={styles.subHeading}>What You Should Know at a Glance</h2>
      <p style={styles.paragraph}>Please note that some provisions may only apply to certain categories of users. In particular, certain provisions may only apply to consumers or to those users that do not qualify as consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all users.</p>

      <h2 style={styles.subHeading}>Terms of Use</h2>
      <p style={styles.paragraph}>Unless stated otherwise, the terms in this section apply generally when using our website.</p>
      <p style={styles.paragraph}>Specific or additional conditions may apply in certain situations and are noted in this document.</p>

      <p style={styles.paragraph}>By using our website, you confirm the following:</p>
      <ul>
        <li>You are older than 10 years old;</li>
        <li>You are not in a country under a U.S. government embargo or designated as a "terrorist-supporting" country;</li>
        <li>You are not on any U.S. government list of prohibited or restricted parties.</li>
      </ul>

      <h2 style={styles.subHeading}>Account Registration</h2>
      <p style={styles.paragraph}>To use the service, you can register or create an account by providing complete and truthful information. You can also use the service without an account, but this might limit some features.</p>
      <p style={styles.paragraph}>You are responsible for keeping your login details confidential and must choose passwords that meet the highest standards of strength as allowed by our website.</p>

      <h2 style={styles.subHeading}>Conditions for Account Registration</h2>
      <p style={styles.paragraph}>Registration of accounts on our website is subject to the conditions outlined below. By registering, you agree to meet such conditions:</p>
      <ul>
        <li>It is not permitted to register accounts by bots or any other automated methods;</li>
        <li>You must register only one account, unless otherwise specified;</li>
        <li>Your account must not be shared with other persons unless otherwise specified.</li>
      </ul>

      <h2 style={styles.subHeading}>Account Termination</h2>
      <p style={styles.paragraph}>You can close your account and stop using our service at any time by contacting us at the contact details provided in this document.</p>

      <h2 style={styles.subHeading}>Account Suspension and Deletion</h2>
      <p style={styles.paragraph}>We reserve the right to suspend or delete your account at any time and without notice if we find it inappropriate, offensive, or in violation of these terms.</p>

      <h2 style={styles.subHeading}>Content on the Website</h2>
      <p style={styles.paragraph}>Unless otherwise noted, all content on our website is owned or provided by us or our licensors.</p>
      <p style={styles.paragraph}>Hateful content, pornographic PDF files, images, or videos are strictly prohibited and will be deleted without notice. </p>

      <h2 style={styles.subHeading}>Rights Regarding Content on Our Website - All Rights Reserved</h2>
      <p style={styles.paragraph}>We hold and reserve all intellectual property rights for all content.</p>

      <h2 style={styles.subHeading}>Access to External Resources</h2>
      <p style={styles.paragraph}>Through our website, you may access external resources provided by third parties. You acknowledge and accept that we have no control over these resources and are not responsible for their content or availability.</p>

      <h2 style={styles.subHeading}>Acceptable Use</h2>
      <p style={styles.paragraph}>Our website and service may only be used within the scope of what is provided for under these terms and applicable law.</p>

      <h2 style={styles.subHeading}>Terms and Conditions of Sale</h2>
      <p style={styles.paragraph}>Some of our products require payment. Details about fees, duration, and conditions are described in the dedicated sections of our website.</p>

      <h2 style={styles.subHeading}>Right of Withdrawal</h2>
      <p style={styles.paragraph}>You can withdraw from your subscription any time. However, the withdrawal will take effect starting from the next payment period.</p>

      <h2 style={styles.subHeading}>Liability and Indemnification</h2>
      <p style={styles.paragraph}>We limit our liability as much as legally allowed when executing agreements with you.</p>

      <h2 style={styles.subHeading}>Dispute Resolution</h2>
      <p style={styles.paragraph}>In case of any disputes arising from or related to these Terms of Service or your use of our service, both parties agree to make reasonable efforts to resolve disputes amicably through good faith negotiations.</p>
      <p style={styles.paragraph}>If a resolution cannot be reached through negotiation, the following terms apply:</p>
      <ul>
        <li><strong>Binding Arbitration:</strong> Any unresolved disputes shall be resolved by final and binding arbitration in accordance with the rules of the American Arbitration Association (AAA). The arbitration shall take place in a location mutually agreed upon or, if not agreed, in the jurisdiction where Compreqna is based.</li>
        <li><strong>Exceptions to Arbitration:</strong> Both parties retain the right to seek resolution in a court of law for disputes concerning intellectual property rights or in instances where injunctive relief is appropriate.</li>
        <li><strong>Governing Law:</strong> This agreement and any dispute arising from it shall be governed by the laws of the jurisdiction where Compreqna is based, without regard to conflict of law principles.</li>
        <li><strong>Class Action Waiver:</strong> You agree that any disputes will be resolved on an individual basis. You waive any right to participate in a class action or other collective proceedings.</li>
      </ul>

      <p style={styles.paragraph}>This document can be revised at any time, with or without notice, and the changes will apply from the time of posting on our website. By continuing to use the service, you agree to any updated terms. Please review periodically for any changes.</p>
    </div>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    overflowY: 'auto',
    height: '100vh',
    backgroundColor: 'black',
    color: '#c9d1d9',
    fontFamily: 'Arial, sans-serif',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/stardust.png)',
    backgroundSize: 'cover',
    borderRadius: '10px',
  },
  heading: {
    fontSize: '28px',
    marginBottom: '20px',
    color: '#58a6ff',
    textShadow: '0 0 10px #58a6ff',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '20px',
  },
  subHeading: {
    fontSize: '22px',
    marginBottom: '15px',
    color: '#58a6ff',
    textShadow: '0 0 5px #58a6ff',
  },
  link: {
    color: '#58a6ff',
    textDecoration: 'none',
  },
};

export default Terms;
