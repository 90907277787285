import React, { CSSProperties, useEffect } from 'react';

const Support: React.FC = () => {
  // Use useEffect to set the background color of the entire page to black
  useEffect(() => {
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = ''; // Reset to default when component unmounts
    };
  }, []);

  return (
    <div  className="container-terms" >
    <div style={styles.container}>
      <h1 style={styles.heading}>Support</h1>
      <p style={styles.paragraph}>
        Welcome to the support page. Here you can find help and guidance on using our application. 
        If you need further assistance, please contact our support team at <a href="mailto:customerservice@compreqna.com" style={styles.link}>customerservice@compreqna.com</a>.
      </p>

      <h2 style={styles.subHeading}>Frequently Asked Questions</h2>

      {questionsAndAnswers.map((qa, index) => (
        <div key={index} style={styles.qaContainer}>
          <h3 style={styles.question}>{index + 1}. {qa.question}</h3>
          <p style={styles.answer}>{qa.answer}</p>
        </div>
      ))}
    </div>
    </div>
  );
};

const questionsAndAnswers = [
  {
    question: "What is the difference between the free and paid version?",
    answer: "The free version of the website provides access to basic features, while the paid version unlocks premium features, such as additional tools, priority support, or exclusive content. You can find a detailed comparison on our pricing page."
  },
  {
    question: "How much does the subscription cost?",
    answer: "The subscription costs $4.99 per month. This gives you access to all premium features, and you can cancel at any time."
  },
  {
    question: "How can I subscribe to the paid version?",
    answer: "You can subscribe to the paid version by visiting the subscription page, selecting the plan, and completing the payment process. You’ll need to provide your payment details, and the subscription will automatically renew every month."
  },
  {
    question: "Do I get a free trial before subscribing?",
    answer: "For new users, we may offer first 7 days subscription free of charge. You can try all features of paid version without any limitations."
  },
  {
    question: "Can I switch back to the free version after subscribing?",
    answer: "Yes, you can switch back to the free version at any time by canceling your paid subscription. You will retain access to the premium features until the end of the current billing cycle, after which you will be downgraded to the free version."
  },
  {
    question: "How do I cancel my subscription?",
    answer: "To cancel your subscription, log in to your account, navigate to the subscription settings, and click on the \"Cancel Subscription\" option. Your account will revert to the free version at the end of the current billing cycle."
  },
  {
    question: "What happens if I cancel in the middle of the billing cycle?",
    answer: "If you cancel in the middle of the billing cycle, you will still have access to the premium features until the end of that month. You will not be charged for the next billing cycle."
  },
  {
    question: "Will my subscription renew automatically?",
    answer: "Yes, the subscription renews automatically every month on the same day. If you wish to stop the renewal, you must cancel the subscription before the next billing date."
  },
  {
    question: "Can I get a refund after I’ve been charged?",
    answer: "We generally do not offer refunds for monthly subscriptions, but if there’s been a billing error or a technical issue, please contact support, and we will review your request."
  },
  {
    question: "What payment methods are accepted?",
    answer: "We accept credit cards, debit cards, and some payment gateways like Apple Pay (or other options). You can choose the method that works best for you during the checkout process."
  },
  {
    question: "Can I change my payment method?",
    answer: "Yes, you can update your payment method by going to your account settings and selecting \"Billing Information.\" Here, you can add a new card or payment method."
  },
  {
    question: "Will I be notified before my payment is charged?",
    answer: "Generally, you will not receive a notification before each monthly charge since it's an automatic recurring payment. However, you can see your next billing date in your account dashboard."
  },
  {
    question: "What happens if my payment fails?",
    answer: "If your payment fails, we will notify you via email. You will have a grace period to update your payment method. If payment issues are not resolved, your account may revert to the free version."
  },
  {
    question: "Do I get additional benefits if I subscribe for a longer period?",
    answer: "Currently, we offer a monthly subscription, but we may introduce discounts for longer-term plans like yearly subscriptions in the future. Stay tuned for updates!"
  },
  {
    question: "Will I lose my data if I downgrade to the free version?",
    answer: "Yes, you may lose some of your data if you switch to the free version. You should download the data before your subscription expires."
  },
  {
    question: "Can I upgrade from the free to the paid version at any time?",
    answer: "Yes, you can upgrade to the paid version at any time. Simply go to the subscription page, select the premium plan, and complete the payment process."
  },
  {
    question: "Is the paid version ad-free?",
    answer: "Yes, subscribing to the paid version removes all ads from the website, allowing you to enjoy an uninterrupted experience."
  },
  {
    question: "How do I contact support for billing issues?",
    answer: "If you have any billing issues, you can contact our support team through the \"Contact Us\" page or email us at customerservice@compreqna.com. We aim to respond to billing queries within 24 hours."
  },
  {
    question: "Are there any discounts for students or nonprofits?",
    answer: "Sorry, we do not offer special discounts for students and nonprofit organizations."
  },
  {
    question: "Can I pause my subscription?",
    answer: "Currently, we do not offer the ability to pause a subscription. However, you can cancel your subscription and re-subscribe when you’re ready to continue using the premium features."
  }
];

const styles: { [key: string]: CSSProperties } = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    overflowY: 'auto', // Allows scrolling if the content is too long
    height: '100vh', // Ensures the container takes the full height of the viewport
    boxSizing: 'border-box',
    backgroundColor: 'black', // Dark space-like background
    color: '#c9d1d9', // Light text color for readability
    fontFamily: 'Arial, sans-serif',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/stardust.png)', // Subtle starry pattern
    backgroundSize: 'cover',
    borderRadius: '10px',
  },
  heading: {
    fontSize: '28px',
    marginBottom: '20px',
    color: '#58a6ff', // Bright, glowing blue for space-like heading
    textShadow: '0 0 10px #58a6ff', // Glow effect
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '20px',
  },
  subHeading: {
    fontSize: '22px',
    marginBottom: '15px',
    color: '#58a6ff', // Similar glowing effect for subheadings
    textShadow: '0 0 5px #58a6ff',
  },
  qaContainer: {
    marginBottom: '20px',
    padding: '15px',
    backgroundColor: '#161b22', // Slightly darker background for Q&A containers
    borderRadius: '8px',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.5)', // Soft shadow for depth
  },
  question: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#c9d1d9',
  },
  answer: {
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#8b949e', // Slightly lighter color for answers
  },
  link: {
    color: '#58a6ff', // Glowing blue for links
    textDecoration: 'none',
  },
};

export default Support;
