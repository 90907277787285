import React, { useEffect, useRef } from "react";
import * as BABYLON from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import { line2D} from "./draftTools/line2D"; // Adjust the path as necessary
//import Circle from "./draftTools/circle";  // Adjust the import paths as needed
//import CoordinatePanel from "./userInterface/coordinatePanel"; 

import "../Base.css";

interface SceneComponentProps {
    id: string; // required
    [key: string]: any; // allow additional props if needed
}



interface ScaleInputs {
    x: number;
    y: number;
    z: number;
    method: "FACTORS" | "POINTS";
    copy: "YES" | "NO";
}


interface RotateInputs {
    angle: number;           // Active rotate angle
    method: "FACTORS" | "POINTS"; // Rotation method
    copy: "YES" | "NO";     // Create copies when rotating
    direction: "CW" | "CCW"; // Direction of rotate angle
}

interface MirrorInputs {
    copy: "YES" | "NO";     // Create copies when mirroring
}

interface ParallelInputs {
    offset: number;         // Active offset value
    method: "OFFSET" | "POINT"; // Parallel method
    copy: "YES" | "NO";     // Create copies when paralleling a mesh
}

interface FilletInputs {
    radius: number;         // Active radius value
    copy: "YES" | "NO";     // Create copies when filleting a mesh
}

interface ChamferInputs {
    size: number;           // Active chamfer value
    copy: "YES" | "NO";     // Create copies when chamfering a mesh
}

interface HoverKeyPoint {
    name: string; // The name of the key point currently on mouse hover
}

interface ArrowProperties {
    length: number; // Length of the arrow
    width: number;  // Width of the arrow
    indent: number; // Indentation of the arrow
    type: 'full' | 'half'; // Type of the arrow
}

interface TextProperties {
    show_text: string;   // Text to show
    align_h: 'left' | 'center' | 'right'; // Horizontal alignment
    align_v: 'top' | 'center' | 'bottom';  // Vertical alignment
    backColor: string;  // Background color
    textColor: string;  // Text color
    font_name: string;  // Font name
    font_size: number;   // Font size
    fontsize_factor: number; // Factor for font size
    region: boolean;     // Whether it's a region or not
    width: number;       // Width of the text box
    height: number;      // Height of the text box
}

interface DimensionProperties {
    style: string; // Style of dimension
    show_text: string; // Text to show
    show_text1: string; // Secondary text to show
    align_h: 'left' | 'center' | 'right'; // Horizontal alignment
    align_v: 'top' | 'center' | 'bottom';  // Vertical alignment
    backColor: string;  // Background color
    textColor: string;  // Text color
    font_name: string;  // Font name
    font_size: number;   // Font size
    fontsize_factor: number; // Factor for font size
    region: boolean;     // Whether it's a region or not
    gap: number;         // Gap between dimension points
    ext: number;         // Line extension beyond two dimension lines
    horview: boolean;    // If dimension line runs horizontally
    verview: boolean;    // If dimension line runs vertically
    inclined: boolean;   // If dimension line runs inclined
    free: boolean;       // If dimension line runs in any direction
    top: boolean;        // If dimension line runs on top
    bot: boolean;        // If dimension line runs on bottom
    left: boolean;       // If dimension line runs on left
    right: boolean;      // If dimension line runs on right
    textOff: number;     // Text offset from dimension line
    textHeight: number;  // Dimension text height
    textLeft: boolean;   // Text on left side
    textCenter: boolean; // Text on center
    textRight: boolean;  // Text on right side
    textLoc: "center" | "left" | "right"; // Text location
    arrowLoc: "inside" | "outside"; // Arrow location
    autoLoc: "center" | "left" | "right"; // Auto adjust text and arrow locations
    secondText: string;  // Second line of text
    unit_fraction: "1/8" | "1/16" | "1/32"; // Fraction units
    unit_scale: number;   // Scale for units
    unit_type: string;    // Type of units
    unit_decimal: string; // Decimal precision for units
}

interface DrawingMeshes {
    line2D: BABYLON.Mesh[]; // 2D lines
    circle: BABYLON.Mesh[];  // Circles
    rect: BABYLON.Mesh[];    // Rectangles
    arc: BABYLON.Mesh[];     // Arcs
    text: BABYLON.Mesh[];    // Texts
    note: BABYLON.Mesh[];    // Notes
    dimension: BABYLON.Mesh[]; // Dimensions
    angle_dimension: BABYLON.Mesh[]; // Angle dimensions
    radius_dimension: BABYLON.Mesh[]; // Radius dimensions
}

// Define a type for the options parameter to include standardUV
interface Line2DOptions {
    path: BABYLON.Vector3[];
    width: number;
    updatable: boolean;
    closed?: boolean; // Optional
    standardUV?: boolean; // Optional
    instance?: BABYLON.LinesMesh | null; // Add the instance property
}


const CaddMain: React.FC<SceneComponentProps> = ({ id, ...rest }) => {
    const reactCanvas = useRef<HTMLCanvasElement | null>(null);
    const boxRef = useRef<BABYLON.AbstractMesh | undefined>(undefined); // Use useRef for the box

    useEffect(() => {
        const { current: canvas } = reactCanvas;

        if (!canvas) return;

        const engine = new BABYLON.Engine(canvas, true); // Antialiasing enabled by default
        const scene = new BABYLON.Scene(engine);

        // Create and position a free camera
        const camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 5, -10), scene);
        camera.setTarget(BABYLON.Vector3.Zero());
        camera.attachControl(canvas, true);




        
 // 1.1 View Controls
    /******************************************************************************************************************************/
    var showAllUnitComponents = 1; // if "0" each unit component can be selected indivudually; "0" all components in the same unit shown together
    var unitOnOff = [0,0,1,1,1]; // Dimension of the array needs match the array for unit definations    

    // Camera Control "0" for standard camera for model view and "1" for follow camera with cars on the structures
    var cameraView = 0;

    /****************************************************************************************************************************/    
    var scene3 = new BABYLON.Scene(engine);
    scene3.useRightHandedSystem = true; // use right handed
    scene3.getAnimationRatio();

    var dwg_ratio = 100; // Ratio to adjust the unit of the screen showing

    

    const scaleMesh: ScaleInputs = { x: 1, y: 1, z: 1, method: "FACTORS", copy: "NO" };


    const rotateMesh: RotateInputs = {
        angle: 0,
        method: "FACTORS",
        copy: "NO",
        direction: "CCW",
    };

    const mirrorMesh: MirrorInputs = {
        copy: "NO",
    };

    const parallelMesh: ParallelInputs = {
        offset: 5,
        method: "OFFSET",
        copy: "NO",
    };

    const filletMesh: FilletInputs = {
        radius: 5,
        copy: "NO",
    };

    const chamferMesh: ChamferInputs = {
        size: 1,
        copy: "NO",
    };

// Mesh and coordinates       
var myMesh_snap = null; 
var myMesh_arc = null;     
var myMesh_arc_snap = null;                      
var myMesh_line = null;
var myMesh_line_snap = null;           
var myMesh_line1 = null;
var myMesh_line1_snap = null;           
var myMesh_line2 = null;
var myMesh_line2_snap = null;           
var myMesh_line2A = null;
var myMesh_line2A_snap = null;           
var myMesh_line2B = null;
var myMesh_line2B_snap = null;                                 
var myMesh_line3 = null;
var myMesh_line3_snap = null;           
var myMesh_line4 = null;
var myMesh_line4_snap = null;                                                       
var myMesh_arrow = null;
var myMesh_arrow1 = null;           
var myMesh_arrow2 = null;
var myMesh_secondText = null;
var myMesh_node1 = null;
var myMesh_node2 = null;
var myMesh_node3 = null;
var myMesh_node4 = null;
var myMesh_node5 = null;
var myMesh_node6 = null;
var myMesh_node7 = null;
var myMesh_node8 = null;
var myMesh_node9 = null;
var myMesh_node10 = null;
var myMesh_node11 = null;
var myMesh_node12 = null;
var myMesh_node13 = null;
var myMesh_node14 = null;
var myMesh_node15 = null;
var myMesh_node16 = null;
var myMesh_node17 = null;
var myMesh_node18 = null;
var myMesh_node19 = null;
var myMesh_node20 = null;

var myMesh_x0 = []; // Original mesh location
var myMesh_y0 = [];
var myMesh_z0 = [];
var myMesh_center_x0 = []; 
var myMesh_center_y0 = [];
var myMesh_center_z0 = [];
var myMesh_radius0 = [];             
var myMesh_arc_x0 = []; 
var myMesh_arc_y0 = [];
var myMesh_arc_z0 = [];  
var myMesh_arc_center_x0 = [];
var myMesh_arc_center_y0 = [];
var myMesh_arc_center_z0 = [];                 
var myMesh_arrow_x0 = []; 
var myMesh_arrow_y0 = [];
var myMesh_arrow_z0 = [];
var myMesh_arrow1_x0 = []; 
var myMesh_arrow1_y0 = [];
var myMesh_arrow1_z0 = [];
var myMesh_arrow2_x0 = []; 
var myMesh_arrow2_y0 = [];
var myMesh_arrow2_z0 = [];                      
var myMesh_line_x0 = []; 
var myMesh_line_y0 = [];
var myMesh_line_z0 = [];
var myMesh_line1_x0 = []; 
var myMesh_line1_y0 = [];
var myMesh_line1_z0 = [];
var myMesh_line2_x0 = []; 
var myMesh_line2_y0 = [];
var myMesh_line2_z0 = [];
var myMesh_line2A_x0 = []; 
var myMesh_line2A_y0 = [];
var myMesh_line2A_z0 = [];
var myMesh_line2B_x0 = []; 
var myMesh_line2B_y0 = [];
var myMesh_line2B_z0 = [];                      
var myMesh_line3_x0 = []; 
var myMesh_line3_y0 = [];
var myMesh_line3_z0 = [];
var myMesh_line4_x0 = []; 
var myMesh_line4_y0 = [];
var myMesh_line4_z0 = [];  
var myMesh_arc_snap_x0 = []; 
var myMesh_arc_snap_y0 = [];
var myMesh_arc_snap_z0 = [];                                                      
var myMesh_line_snap_x0 = []; 
var myMesh_line_snap_y0 = [];
var myMesh_line_snap_z0 = []; 
var myMesh_line1_snap_x0 = []; 
var myMesh_line1_snap_y0 = [];
var myMesh_line1_snap_z0 = [];
var myMesh_line2_snap_x0 = []; 
var myMesh_line2_snap_y0 = [];
var myMesh_line2_snap_z0 = [];
var myMesh_line2A_snap_x0 = []; 
var myMesh_line2A_snap_y0 = [];
var myMesh_line2A_snap_z0 = [];
var myMesh_line2B_snap_x0 = []; 
var myMesh_line2B_snap_y0 = [];
var myMesh_line2B_snap_z0 = [];                      
var myMesh_line3_snap_x0 = []; 
var myMesh_line3_snap_y0 = [];
var myMesh_line3_snap_z0 = [];
var myMesh_line4_snap_x0 = []; 
var myMesh_line4_snap_y0 = [];
var myMesh_line4_snap_z0 = [];
var myMesh_secondText_x0 = []; 
var myMesh_secondText_y0 = [];
var myMesh_secondText_z0 = [];
var myMesh_nodes = []; 
var myMesh_node1_x0 = [];
var myMesh_node1_y0 = [];
var myMesh_node1_z0 = [];
var myMesh_node2_x0 = [];
var myMesh_node2_y0 = [];
var myMesh_node2_z0 = [];
var myMesh_node3_x0 = [];
var myMesh_node3_y0 = [];
var myMesh_node3_z0 = [];
var myMesh_node4_x0 = [];
var myMesh_node4_y0 = [];
var myMesh_node4_z0 = [];
var myMesh_node5_x0 = [];
var myMesh_node5_y0 = [];
var myMesh_node5_z0 = [];
var myMesh_node6_x0 = [];
var myMesh_node6_y0 = [];
var myMesh_node6_z0 = [];
var myMesh_node7_x0 = [];
var myMesh_node7_y0 = [];
var myMesh_node7_z0 = [];
var myMesh_node8_x0 = [];
var myMesh_node8_y0 = [];
var myMesh_node8_z0 = [];
var myMesh_node9_x0 = [];
var myMesh_node9_y0 = [];
var myMesh_node9_z0 = [];
var myMesh_node10_x0 = [];
var myMesh_node10_y0 = [];
var myMesh_node10_z0 = [];
var myMesh_node11_x0 = [];
var myMesh_node11_y0 = [];
var myMesh_node11_z0 = [];
var myMesh_node12_x0 = [];
var myMesh_node12_y0 = [];
var myMesh_node12_z0 = [];
var myMesh_node13_x0 = [];
var myMesh_node13_y0 = [];
var myMesh_node13_z0 = [];
var myMesh_node14_x0 = [];
var myMesh_node14_y0 = [];
var myMesh_node14_z0 = [];
var myMesh_node15_x0 = [];
var myMesh_node15_y0 = [];
var myMesh_node15_z0 = [];
var myMesh_node16_x0 = [];
var myMesh_node16_y0 = [];
var myMesh_node16_z0 = [];
var myMesh_node17_x0 = [];
var myMesh_node17_y0 = [];
var myMesh_node17_z0 = [];
var myMesh_node18_x0 = [];
var myMesh_node18_y0 = [];
var myMesh_node18_z0 = [];
var myMesh_node19_x0 = [];
var myMesh_node19_y0 = [];
var myMesh_node19_z0 = [];
var myMesh_node20_x0 = [];
var myMesh_node20_y0 = [];
var myMesh_node20_z0 = [];

var firstClickGlobal = new BABYLON.Vector3(0,0,0);
var sceneRotate = 0;
var hoverMesh = "none"; // The mesh name currently on mouse hover

// Define variables with their respective types
var hoverKeyPoint: HoverKeyPoint = {
    name: "none",
};

// Arrow input parameters
var arrowProp: ArrowProperties = {
    length: 1,
    width: 0.5,
    indent: 0.2,
    type: 'full',
};

// Text input parameters
var textProp: TextProperties = {
    show_text: "  ",
    align_h: "left",
    align_v: "top",
    backColor: 'black',
    textColor: 'white',
    font_name: 'Arial',
    font_size: 14,
    fontsize_factor: 10,
    region: false,
    width: 10,
    height: 5,
};

// Dimension input parameters
var dimProp: DimensionProperties = {
    style: "dimStyle1",
    show_text: "  ",
    show_text1: "*",
    align_h: "left",
    align_v: "top",
    backColor: 'black',
    textColor: 'white',
    font_name: 'Arial',
    font_size: 14,
    fontsize_factor: 10,
    region: false,
    gap: 0.2,
    ext: 0.5,
    horview: false,
    verview: false,
    inclined: false,
    free: false,
    top: false,
    bot: false,
    left: false,
    right: false,
    textOff: 0.2,
    textHeight: 0.3,
    textLeft: false,
    textCenter: false,
    textRight: false,
    textLoc: "center",
    arrowLoc: "inside",
    autoLoc: "left",
    secondText: " ",
    unit_fraction: "1/8",
    unit_scale: 1,
    unit_type: "Custom US",
    unit_decimal: "0.001",
};

// Drawing Meshes Information
var drawingMeshes: DrawingMeshes = {
    line2D: [],
    circle: [],
    rect: [],
    arc: [],
    text: [],
    note: [],
    dimension: [],
    angle_dimension: [],
    radius_dimension: [],
};


// Define temporary advancedtexture
var advancedTexture_temp;

// Standard Material
     var lineBlack = new BABYLON.StandardMaterial("lineBlack", scene3);
     lineBlack.diffuseColor = new BABYLON.Color3(0, 0, 0);
     lineBlack.specularColor = new BABYLON.Color3(0, 0, 0);
     lineBlack.emissiveColor = new BABYLON.Color3(0, 0, 0);
     lineBlack.ambientColor = new BABYLON.Color3(0, 0, 0);          

     var lineGray = new BABYLON.StandardMaterial("lineGray", scene3);
     lineGray.diffuseColor = new BABYLON.Color3(0.5, 0.5, 0.5);
     lineGray.specularColor = new BABYLON.Color3(0.5, 0.5, 0.5);
     lineGray.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
     lineGray.ambientColor = new BABYLON.Color3(0.5, 0.5, 0.5);            

     var lineGreen = new BABYLON.StandardMaterial("lineGreen", scene3);
     lineGreen.diffuseColor = new BABYLON.Color3(0, 1, 0);
     lineGreen.specularColor = new BABYLON.Color3(0, 1, 0);
     lineGreen.emissiveColor = new BABYLON.Color3(0, 1, 0);
     lineGreen.ambientColor = new BABYLON.Color3(0, 1, 0);            

     var linePurple = new BABYLON.StandardMaterial("linePurple", scene3);
     linePurple.diffuseColor = new BABYLON.Color3(1, 0, 1);
     linePurple.specularColor = new BABYLON.Color3(1, 0, 1);
     linePurple.emissiveColor = new BABYLON.Color3(1, 0, 1);
     linePurple.ambientColor = new BABYLON.Color3(1, 0, 1);          

     var lineRed = new BABYLON.StandardMaterial("lineRed", scene3);
     lineRed.diffuseColor = new BABYLON.Color3(1, 0, 0);
     lineRed.specularColor = new BABYLON.Color3(1, 0, 0);
     lineRed.emissiveColor = new BABYLON.Color3(1, 0, 0);
     lineRed.ambientColor = new BABYLON.Color3(1, 0, 0);          

     var lineWhite = new BABYLON.StandardMaterial("lineWhite", scene3);
     lineWhite.diffuseColor = new BABYLON.Color3(1, 1, 1);
     lineWhite.specularColor = new BABYLON.Color3(1, 1, 1);
     lineWhite.emissiveColor = new BABYLON.Color3(1, 1, 1);
     lineWhite.ambientColor = new BABYLON.Color3(1, 1, 1);          

     var lineBlue = new BABYLON.StandardMaterial("lineBlue", scene3);
     lineBlue.diffuseColor = new BABYLON.Color3(0, 0, 1);
     lineBlue.specularColor = new BABYLON.Color3(0, 0, 1);
     lineBlue.emissiveColor = new BABYLON.Color3(0, 0, 1);
     lineBlue.ambientColor = new BABYLON.Color3(0, 0, 1);          

     var lineYellow = new BABYLON.StandardMaterial("lineYellow", scene3);
     lineYellow.diffuseColor = new BABYLON.Color3(1, 1, 0);
     lineYellow.specularColor = new BABYLON.Color3(1, 1, 0);
     lineYellow.emissiveColor = new BABYLON.Color3(1, 1, 0);
     lineYellow.ambientColor = new BABYLON.Color3(1, 1, 0);            




    // 1.2 Drawing Camera and model Camera
    // Camera Functions
    const setTopBottomRatio = (camera: BABYLON.ArcRotateCamera) => {
        const ratio = canvas.height / canvas.width;

        // Use nullish coalescing operator to provide default values if null
        camera.orthoTop = camera.orthoRight ? camera.orthoRight * ratio : 1; // Default value can be adjusted
        camera.orthoBottom = camera.orthoLeft ? camera.orthoLeft * ratio : -1; // Default value can be adjusted
    };

    const zoom2DView = (camera: BABYLON.ArcRotateCamera, delta: number) => {
        const zoomingOut = delta < 0;
        // limit zooming in to no less than 3 units.
        if (!zoomingOut && camera.orthoLeft && Math.abs(camera.orthoLeft) <= 3) return;

        camera.orthoLeft = camera.orthoLeft ? camera.orthoLeft + delta : -1; // Default value can be adjusted
        camera.orthoRight = camera.orthoRight ? camera.orthoRight - delta : 1; // Default value can be adjusted
        setTopBottomRatio(camera);
        // decrease pan sensitivity the closer the zoom level.
        camera.panningSensibility = 4000 / Math.abs(camera.orthoLeft || 1); // Prevent division by zero
    };

    const resetCameraZoomPaper = (camera: BABYLON.ArcRotateCamera) => {
        camera.orthoLeft = -0.9 * dwg_ratio;
        camera.orthoRight = 0.9 * dwg_ratio;
        setTopBottomRatio(camera);
    };

    const resetCameraZoomModel = (camera: BABYLON.ArcRotateCamera) => {
        camera.orthoLeft = -0.9 * dwg_ratio;
        camera.orthoRight = 0.9 * dwg_ratio;
        setTopBottomRatio(camera);
    };

    var cameraPaper = new BABYLON.ArcRotateCamera('CameraPaper', Math.PI / 2, Math.PI / 2, 80, new BABYLON.Vector3(0, 0, 0), scene3);
    //cameraPaper.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
    const zoomSpeed = 1.0;
    cameraPaper.upperBetaLimit = 1.57;
    //scene3.activeCamera = camera;
    cameraPaper.attachControl(canvas);    
    cameraPaper.wheelPrecision = zoomSpeed; //Mouse wheel speed      
    cameraPaper._panningMouseButton = 0;
    cameraPaper.panningInertia = 0.8;   
    cameraPaper.panningSensibility = 80;
    //cameraPaper.buttons = [2, 1, 0];

  var cameraModel = new BABYLON.ArcRotateCamera('Camera', Math.PI / 2, Math.PI / 2, 80, new BABYLON.Vector3(0, 0, 0), scene3);
 // cameraModel.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;

    cameraModel.upperBetaLimit = 1.57;
    //scene3.activeCamera = camera;
    cameraModel.attachControl(canvas);    
    cameraModel.wheelPrecision = zoomSpeed; //Mouse wheel speed      
    cameraModel._panningMouseButton = 0;
    cameraModel.panningInertia = 0.8;   
    cameraModel.panningSensibility = 10;
    //cameraModel.buttons = [2, 1, 0];


    scene3.onPointerObservable.add((eventData) => {

        let buttonPressed = eventData.event.buttons & 2;
        let transformMatrix = BABYLON.Matrix.Zero();
        let localDirection = BABYLON.Vector3.Zero();
        let transformedDirection = BABYLON.Vector3.Zero();
        var sceneRotate = 0;
        // Use the right button for panning only. Use left button for click only.
        if (buttonPressed === 2) {
            var x = eventData.event.movementX/10;
            var y = eventData.event.movementY/10;
            var point1 = new BABYLON.Vector3(x, y, 0);
           // var point2 = rotTrans(point1,sceneRotate);
            var point2 = new BABYLON.Vector3(x, y, 0);           
            var rot_state = {x:cameraModel.alpha , y:cameraModel.beta};                        
            localDirection.x -= point2.x;
            localDirection.y += point2.y;
            cameraModel.getViewMatrix().invertToRef(transformMatrix);
            BABYLON.Vector3.TransformNormalToRef(localDirection, transformMatrix, transformedDirection);
            // Throw out y because we don't want to move up or down
            transformedDirection.z = 0;
            cameraModel.alpha = rot_state.x;
            cameraModel.beta = rot_state.y;
            cameraModel.target.addInPlace(transformedDirection);
            cameraModel.position.addInPlace(transformedDirection);            
        }
    });

    var cameraModelBox = BABYLON.MeshBuilder.CreateBox("box", {height: 2, width: 2, depth: 2},scene3);
    cameraModel.parent = cameraModelBox;
   // cameraModelBox.rotation.z = Math.PI/2;
    cameraModelBox.isVisible = false;

    var dxfData = "text data";

 //   var camera = new BABYLON.ArcRotateCamera("camera1",  -Math.PI / 2.5, Math.PI / 3, 25, new BABYLON.Vector3(0, 0, 0), scene);

//	var camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 1131.96, new BABYLON.Vector3(0,0,0), scene3);
//	camera.attachControl(canvas, true);


    // Attach a circle/plus/minus to curser
    var mousePlane1 = textFit("o",1,1,0,0,"Arial","white","black",0,2);   // Select 
    var mousePlane2 = textFit("+",1.5,1.5,0,0,"Arial","white","black",0,2); // Add
    var mousePlane3 = textFit("-",1.5,1.5,0,0,"Arial","white","black",0,2); // Reduce  

        // Plane showing not allowed symbol
        var mouseNotAllowed = BABYLON.MeshBuilder.CreatePlane("plane", {width:1, height:1,sideOrientation: BABYLON.Mesh.DOUBLESIDE}, scene3);
        var mat = new BABYLON.StandardMaterial("", scene3);

        mat.backFaceCulling = false;    
        mouseNotAllowed.material = mat;
        mouseNotAllowed.rotation.x = Math.PI;
        mouseNotAllowed.position.z = 0;
    
        const initialCameraRadius = cameraModel.radius;
        cameraModel.onViewMatrixChangedObservable.add(() => {
            const scale = cameraModel.radius / initialCameraRadius;
            mousePlane1.scaling.set(scale, scale, scale);
            mousePlane2.scaling.set(scale, scale, scale);
            mousePlane3.scaling.set(scale, scale, scale);
            mouseNotAllowed.scaling.set(scale, scale, scale);                
         });        
        mousePlane2.isVisible = false;
        mousePlane3.isVisible = false; 
        mouseNotAllowed.isVisible = false;

        window.addEventListener("mousemove", function(event) {
            var pickResult = scene3.pick(scene3.pointerX, scene3.pointerY); // Use pointerX and pointerY
            if (pickResult.hit && pickResult.pickedPoint) { // Check if an object was hit and pickedPoint is not null
                mousePlane1.position.x = pickResult.pickedPoint.x;
                mousePlane1.position.y = pickResult.pickedPoint.y;
                mousePlane1.position.z = pickResult.pickedPoint.z;
                mousePlane2.position.x = pickResult.pickedPoint.x;
                mousePlane2.position.y = pickResult.pickedPoint.y;
                mousePlane2.position.z = pickResult.pickedPoint.z;
                mousePlane3.position.x = pickResult.pickedPoint.x;
                mousePlane3.position.y = pickResult.pickedPoint.y;
                mousePlane3.position.z = pickResult.pickedPoint.z;        
            }
        });
        
    // Create Snap Symbels 
    var keyPlane1 = textFit("x",1,1,0,0,"Arial","white","black",0,2);   // Keypoint #1
    var keyPlane2 = textFit("x",1,1,0,0,"Arial","white","black",0,2);   // Keypoint #2 
    var keyPlane3 = textFit("x",1,1,0,0,"Arial","white","black",0,2);   // Keypoint #3
    var keyPlane4 = textFit("x",1,1,0,0,"Arial","white","black",0,2);   // Keypoint #4        
    var nearPlane = textFit("x",1,1,0,0,"Arial","white","black",0,2); // Near Point
    var midPlane1 = textFit("x",1,1,0,0,"Arial","white","black",0,2); // Mid Point
    var midPlane2 = textFit("x",1,1,0,0,"Arial","white","black",0,2); // Mid Point
    var midPlane3 = textFit("x",1,1,0,0,"Arial","white","black",0,2); // Mid Point
    var midPlane4 = textFit("x",1,1,0,0,"Arial","white","black",0,2); // Mid Point            
    var centerPlane = textFit("x",1,1,0,0,"Arial","white","black",0,2); // Center Point        
    var intersectPlane = textFit("x",1,1,0,0,"Arial","white","black",0,2); // Intersection Point
    var tangentPlane = textFit("x",1,1,0,0,"Arial","white","black",0,2); // Tangent Point
    var perpPlane = textFit("x",1,1,0,0,"Arial","white","black",0,2); // Perpendicular Point
    var allPlane = textFit("M",1,1,0,0,"Arial","white","black",0,2); // All snap points


    cameraModel.onViewMatrixChangedObservable.add(() => {
        const scale = cameraModel.radius / initialCameraRadius;
        keyPlane1.scaling.set(scale, scale, scale);
        keyPlane2.scaling.set(scale, scale, scale);
        keyPlane3.scaling.set(scale, scale, scale);
        keyPlane4.scaling.set(scale, scale, scale);                
        nearPlane.scaling.set(scale, scale, scale);
        midPlane1.scaling.set(scale, scale, scale);
        midPlane2.scaling.set(scale, scale, scale);
        midPlane3.scaling.set(scale, scale, scale);
        midPlane4.scaling.set(scale, scale, scale);                        
        centerPlane.scaling.set(scale, scale, scale);
        intersectPlane.scaling.set(scale, scale, scale);
        tangentPlane.scaling.set(scale, scale, scale);
        perpPlane.scaling.set(scale, scale, scale);
        allPlane.scaling.set(scale, scale, scale);        
     });


     keyPlane1.isVisible = false;
     keyPlane2.isVisible = false; 
     keyPlane3.isVisible = false;
     keyPlane4.isVisible = false;          
     nearPlane.isVisible = false;   
     midPlane1.isVisible = false;
     midPlane2.isVisible = false;
     midPlane3.isVisible = false;
     midPlane4.isVisible = false;               
     centerPlane.isVisible = false;  
     intersectPlane.isVisible = false;
     tangentPlane.isVisible = false;
     perpPlane.isVisible = false;
     allPlane.isVisible = false;   




     if (scene3.activeCameras) {
        scene3.activeCameras.push(cameraPaper);
    } else {
        scene3.activeCameras = [cameraPaper]; // Initialize if null
    }

        // Camera Zoom Function
        resetCameraZoomPaper(cameraPaper);

        scene3.onPointerObservable.add((eventData) => {
            // Check if the event is of type WheelEvent
            if (eventData.event instanceof WheelEvent) {
                const delta = (Math.max(-3, Math.min(3, -eventData.event.deltaY))) * 0.25;
                zoom2DView(cameraPaper, delta);
            }
        }, BABYLON.PointerEventTypes.POINTERWHEEL);

            
        // lock the camera's placement, zooming is done manually in orthographic mode.
        // Locking this fixes strange issues with Hemispheric Light
       // cameraPaper.lowerRadiusLimit = cameraPaper.radius;
       // cameraPaper.upperRadiusLimit = cameraPaper.radius;
        //camera.minZ = 0.1;
    
    // Lock camera movements
    cameraPaper.upperBetaLimit = Math.PI / 2;  
    cameraPaper.lowerBetaLimit = Math.PI / 2;  
    cameraPaper.upperAlphaLimit = Math.PI / 2;  
    cameraPaper.lowerAlphaLimit = Math.PI / 2;  

   // var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 1), scene3);
    var light1 = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 0, 1), scene3);   
//    light.intensity = 0.9;

//  Create ground for the paperspace to get coordinates
    var coordinateGround = BABYLON.MeshBuilder.CreateGround("coordinateGround", {width: 600, height: 600}, scene3);
        coordinateGround.rotation.x = -Math.PI / 2;
    var whiteCoordinatesGround = new BABYLON.StandardMaterial("white", scene3);
        whiteCoordinatesGround.alpha = 1.0;  
        whiteCoordinatesGround.diffuseColor = new BABYLON.Color3(1, 1, 1); 
        whiteCoordinatesGround.specularColor = new BABYLON.Color3(1, 1, 1);     
        whiteCoordinatesGround.ambientColor = new BABYLON.Color3(1, 1, 1);
        whiteCoordinatesGround.emissiveColor = new BABYLON.Color3(1, 1, 1);
        whiteCoordinatesGround.backFaceCulling = false;     

    var blackCoordinatesGround = new BABYLON.StandardMaterial("black", scene3);
        blackCoordinatesGround.alpha = 1.0;  
        blackCoordinatesGround.diffuseColor = new BABYLON.Color3(0, 0, 0); 
        blackCoordinatesGround.specularColor = new BABYLON.Color3(0, 0, 0);     
        blackCoordinatesGround.ambientColor = new BABYLON.Color3(0, 0, 0);
        blackCoordinatesGround.emissiveColor = new BABYLON.Color3(0, 0, 0);
        blackCoordinatesGround.backFaceCulling = false;   

        coordinateGround.enablePointerMoveEvents = true;

    // Axis for global coordinates for both paper and model
    var makeTextPlane = function(text: string, color: string, size: number): BABYLON.Mesh {
        var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", 50, scene3, true);
        dynamicTexture.hasAlpha = true;
        dynamicTexture.drawText(text, 0, 40, "bold 24px Arial", color, "transparent", true);
      
        var plane = BABYLON.MeshBuilder.CreatePlane("TextPlane", { width: size * 5, height: size }, scene3);
        
        // Create an instance of StandardMaterial
        var material = new BABYLON.StandardMaterial("TextPlaneMaterial", scene3);
        material.backFaceCulling = false;
        material.specularColor = new BABYLON.Color3(0, 0, 0);
        material.diffuseTexture = dynamicTexture;
    
        // Assign the material to the plane
        plane.material = material;
    
        return plane;
    };
    

    var showAxis_3D = function(size: number) { // Specify 'size' type as number
        // Create X Axis
        var axisX = BABYLON.Mesh.CreateLines("axisX", [ 
            new BABYLON.Vector3(0, 0, 0), 
            new BABYLON.Vector3(size, 0, 0), 
            new BABYLON.Vector3(size * 0.95, 0.05 * size, 0), 
            new BABYLON.Vector3(size, 0, 0), 
            new BABYLON.Vector3(size * 0.95, -0.05 * size, 0)
        ], scene3, false); // Not updatable
    
        axisX.color = new BABYLON.Color3(1, 0, 0); // Set color to red
    
        // Create Y Axis
        var axisY = BABYLON.Mesh.CreateLines("axisY", [
            new BABYLON.Vector3(0, 0, 0), 
            new BABYLON.Vector3(0, size, 0), 
            new BABYLON.Vector3(-0.05 * size, size * 0.95, 0), 
            new BABYLON.Vector3(0, size, 0), 
            new BABYLON.Vector3(0.05 * size, size * 0.95, 0)
        ], scene3, false); // Not updatable
    
        axisY.color = new BABYLON.Color3(0, 1, 0); // Set color to green
    
        // Create Z Axis
        var axisZ = BABYLON.Mesh.CreateLines("axisZ", [
            new BABYLON.Vector3(0, 0, 0), 
            new BABYLON.Vector3(0, 0, size), 
            new BABYLON.Vector3(0, -0.05 * size, size * 0.95), 
            new BABYLON.Vector3(0, 0, size), 
            new BABYLON.Vector3(0, 0.05 * size, size * 0.95)
        ], scene3, false); // Not updatable
    
        axisZ.color = new BABYLON.Color3(0, 0, 1); // Set color to blue
    };
    
    showAxis_3D(1);

        // Generate information for current sheet
       // var index_sheet = indexSheet(sheetID);

        // Define line width as a number
        var width_num_0 = 0.169 / 25.4 /12 *dwg_ratio;
        var width_num_1 = 0.375 / 25.4 /12 *dwg_ratio;
        var width_num_2 = 0.5 / 25.4 /12 *dwg_ratio;
        var width_num_3 = 0.625 / 25.4 /12 *dwg_ratio;
        var width_num_4 = 0.75 / 25.4 /12 *dwg_ratio;
        var width_num_5 = 0.875 / 25.4 /12 *dwg_ratio;
        var width_num_6 = 1.0 / 25.4 /12 *dwg_ratio;                        
        var width_num_7 = 1.125 / 25.4 /12 *dwg_ratio;
        var width_num_8 = 1.25 / 25.4 /12 *dwg_ratio;
        var width_num_9 = 1.375 / 25.4 /12 *dwg_ratio;
        var width_num_10 = 2.0 / 25.4 /12 *dwg_ratio;  
    
        var lineBlack = new BABYLON.StandardMaterial("lineBlack", scene3);
        lineBlack.diffuseColor = new BABYLON.Color3(0, 0, 0);
        lineBlack.specularColor = new BABYLON.Color3(0, 0, 0);
        lineBlack.emissiveColor = new BABYLON.Color3(0, 0, 0);
        lineBlack.ambientColor = new BABYLON.Color3(0, 0, 0);
    
        var lineWhite = new BABYLON.StandardMaterial("lineWhite", scene3);
        lineWhite.diffuseColor = new BABYLON.Color3(1, 1, 1);
        lineWhite.specularColor = new BABYLON.Color3(1, 1, 1);
        lineWhite.emissiveColor = new BABYLON.Color3(1, 1, 1);
        lineWhite.ambientColor = new BABYLON.Color3(1, 1, 1);
    
        var linePurple = new BABYLON.StandardMaterial("linePurple", scene3);
        linePurple.diffuseColor = new BABYLON.Color3(1, 0, 1);
        linePurple.specularColor = new BABYLON.Color3(1, 0, 1);
        linePurple.emissiveColor = new BABYLON.Color3(1, 0, 1);
        linePurple.ambientColor = new BABYLON.Color3(1, 0, 1);
    
        var lineRed = new BABYLON.StandardMaterial("lineRed", scene3);
        lineRed.diffuseColor = new BABYLON.Color3(1, 0, 0);
        lineRed.specularColor = new BABYLON.Color3(1, 0, 0);
        lineRed.emissiveColor = new BABYLON.Color3(1, 0, 0);
        lineRed.ambientColor = new BABYLON.Color3(1, 0, 0);
    
        var lineBlue = new BABYLON.StandardMaterial("lineBlue", scene3);
        lineBlue.diffuseColor = new BABYLON.Color3(0, 0, 1);
        lineBlue.specularColor = new BABYLON.Color3(0, 0, 1);
        lineBlue.emissiveColor = new BABYLON.Color3(0, 0, 1);
        lineBlue.ambientColor = new BABYLON.Color3(0, 0, 1);
    

        
            cameraModel.layerMask = 2;


  // Example inputs
  var pathLine1 = [ 	
    new BABYLON.Vector3(-15, 0, 0),
    new BABYLON.Vector3(15, 0, 0)
    ]

    var line1 = line2D("line2D-1", { path: pathLine1, width: width_num_0, updatable: true }, scene3, 2);
    line1.position.z = 0.1;
    line1.material = clayerColor("WHITE"); // Use "WHITE" as per ColorOptions
    drawingMeshes.line2D.push(line1); //

    var line1_snap = line2D("S-line2D-1", {path: pathLine1, width:5, updatable: true}, scene3,2);
    line1_snap.position.z = 1;           
    line1_snap.material =  clayerColor("WHITE");
    line1_snap.material.alpha = 0.0;  
    //lineMetadata(line1,width_num_0,clayer,cameraModel,scene3);  //Create metadata after snap mesh is created






      type ColorOptions = 
      | "BLACK"
      | "GRAY"
      | "GREEN"
      | "PURPLE"
      | "RED"
      | "WHITE" // Ensure this is in uppercase
      | "BLUE"
      | "YELLOW";
  
   // Return a color material based on current layer
   function clayerColor(color: ColorOptions) { 
    var colorMat: BABYLON.StandardMaterial; 
    switch (color) {
      case "BLACK":
       var lineBlack = new BABYLON.StandardMaterial("lineBlack", scene3);
       lineBlack.diffuseColor = new BABYLON.Color3(0, 0, 0);
       lineBlack.specularColor = new BABYLON.Color3(0, 0, 0);
       lineBlack.emissiveColor = new BABYLON.Color3(0, 0, 0);
       lineBlack.ambientColor = new BABYLON.Color3(0, 0, 0);          
        colorMat = lineBlack;
        break;
      case "GRAY":
       var lineGray = new BABYLON.StandardMaterial("lineGray", scene3);
       lineGray.diffuseColor = new BABYLON.Color3(0.5, 0.5, 0.5);
       lineGray.specularColor = new BABYLON.Color3(0.5, 0.5, 0.5);
       lineGray.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
       lineGray.ambientColor = new BABYLON.Color3(0.5, 0.5, 0.5);            
        colorMat = lineGray;
        break;
      case "GREEN":
       var lineGreen = new BABYLON.StandardMaterial("lineGreen", scene3);
       lineGreen.diffuseColor = new BABYLON.Color3(0, 1, 0);
       lineGreen.specularColor = new BABYLON.Color3(0, 1, 0);
       lineGreen.emissiveColor = new BABYLON.Color3(0, 1, 0);
       lineGreen.ambientColor = new BABYLON.Color3(0, 1, 0);            
        colorMat = lineGreen;
        break;
      case "PURPLE":
       var linePurple = new BABYLON.StandardMaterial("linePurple", scene3);
       linePurple.diffuseColor = new BABYLON.Color3(1, 0, 1);
       linePurple.specularColor = new BABYLON.Color3(1, 0, 1);
       linePurple.emissiveColor = new BABYLON.Color3(1, 0, 1);
       linePurple.ambientColor = new BABYLON.Color3(1, 0, 1);          
        colorMat = linePurple;
        break;
      case "RED":
       var lineRed = new BABYLON.StandardMaterial("lineRed", scene3);
       lineRed.diffuseColor = new BABYLON.Color3(1, 0, 0);
       lineRed.specularColor = new BABYLON.Color3(1, 0, 0);
       lineRed.emissiveColor = new BABYLON.Color3(1, 0, 0);
       lineRed.ambientColor = new BABYLON.Color3(1, 0, 0);          
        colorMat = lineRed;
        break;
      case "WHITE":
       var lineWhite = new BABYLON.StandardMaterial("lineWhite", scene3);
       lineWhite.diffuseColor = new BABYLON.Color3(1, 1, 1);
       lineWhite.specularColor = new BABYLON.Color3(1, 1, 1);
       lineWhite.emissiveColor = new BABYLON.Color3(1, 1, 1);
       lineWhite.ambientColor = new BABYLON.Color3(1, 1, 1);          
        colorMat = lineWhite;
        break;
      case "BLUE":
       var lineBlue = new BABYLON.StandardMaterial("lineBlue", scene3);
       lineBlue.diffuseColor = new BABYLON.Color3(0, 0, 1);
       lineBlue.specularColor = new BABYLON.Color3(0, 0, 1);
       lineBlue.emissiveColor = new BABYLON.Color3(0, 0, 1);
       lineBlue.ambientColor = new BABYLON.Color3(0, 0, 1);          
        colorMat = lineBlue;
        break;            
      case "YELLOW":
       var lineYellow = new BABYLON.StandardMaterial("lineYellow", scene3);
       lineYellow.diffuseColor = new BABYLON.Color3(1, 1, 0);
       lineYellow.specularColor = new BABYLON.Color3(1, 1, 0);
       lineYellow.emissiveColor = new BABYLON.Color3(1, 1, 0);
       lineYellow.ambientColor = new BABYLON.Color3(1, 1, 0);            
        colorMat = lineYellow;
    }
   return colorMat;         
}





        // Function for fitting a text in a specific area
        function textFit(
            text: string,           // Type for the text parameter
            planeWidth: number,     // Type for planeWidth parameter
            planeHeight: number,    // Type for planeHeight parameter
            locx: number,          // Type for locx parameter
            locy: number,          // Type for locy parameter
            font_type: string,      // Type for font_type parameter
            color: string,          // Type for color parameter
            colorb: string,         // Type for colorb parameter
            ang: number,            // Type for ang parameter
            layerMaskID: number     // Type for layerMaskID parameter
        ) {
            // Create plane
            var plane = BABYLON.MeshBuilder.CreatePlane("plane", { width: planeWidth, height: planeHeight }, scene3);
            // Set width and height for dynamic texture using the same multiplier
            var DTWidth = planeWidth * 60;
            var DTHeight = planeHeight * 60;
            // Create dynamic texture
            var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", { width: DTWidth, height: DTHeight }, scene3);
            // Check width of text for given font type at any size of font
            var ctx = dynamicTexture.getContext();
            var size = 12; // Any value will work
            ctx.font = size + "px " + font_type;
            var textWidth = ctx.measureText(text).width;

            // Calculate ratio of text width to size of font used
            var ratio = textWidth / size;
            // Set font to be actually used to write text on dynamic texture
            var font_size = Math.floor(DTWidth / (ratio * 1)); // Size of multiplier (1) can be adjusted, increase for smaller text
            var font = font_size + "px " + font_type;
            // Draw text
            dynamicTexture.drawText(text, null, null, font, color, colorb, true, true);
            // Create material
            var mat = new BABYLON.StandardMaterial("mat", scene3);
            mat.diffuseTexture = dynamicTexture;
            mat.backFaceCulling = false; //!NOTE HERE
            // Apply material
            plane.material = mat;
            plane.position.x = locx;
            plane.position.y = locy;
            plane.rotation.z -= ang / 180.0 * Math.PI; // Rotation is clockwise
            plane.layerMask = layerMaskID;
            return plane;
        }

        // Create a light
        const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), scene);
        light.intensity = 0.7;

        // Create a box and store it in the useRef
        boxRef.current = BABYLON.MeshBuilder.CreateBox("box", { size: 2 }, scene);
        if (boxRef.current) {
            boxRef.current.position.y = 1; // Move the box upward 1/2 its height
        }

        // Create a ground
        BABYLON.MeshBuilder.CreateGround("ground", { width: 6, height: 6 }, scene);

        engine.runRenderLoop(() => {
            if (boxRef.current) {
                const deltaTimeInMillis = scene.getEngine().getDeltaTime();
                const rpm = 10;
                boxRef.current.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
            }
            scene.render();
        });

        const resize = () => {
            scene.getEngine().resize();
        };

        window.addEventListener("resize", resize);

        return () => {
            scene.getEngine().dispose();
            window.removeEventListener("resize", resize);
        };
    }, []);

    return <canvas ref={reactCanvas} id={id} {...rest} />;
};



export default CaddMain;
